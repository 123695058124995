import React, { useEffect, useState, Fragment, useRef } from "react";
import { SimpleForm, useNotify, useRedirect, TextInput } from "react-admin";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { envSettings } from "../settings/env";
import axios from "axios";
import { buildErrorMessage } from "../utils/helpers";
import { CustomToolbar } from "../customComponents/CustomToolbar";
import { isValidEmail } from "../utils/customFunctions";

export const HRSettings = () => {
	const [loading, setLoading] = useState(true);
	const [currentEmailRecipients, setCurrentEmailRecipients] = useState("");
	const emailRecipientsRef = useRef(null);
	const notify = useNotify();
	const redirect = useRedirect();

	const validateB2BSettings = ({ emailRecipients }) => {
		const emails = emailRecipients?.split(",") ?? [];
		let validEmails = true;
		for (let email of emails) {
			validEmails = isValidEmail(email);
			if (!validEmails) break;
		}
		return {
			...(!validEmails && {
				emailRecipients: "Invalid emails",
			}),
		};
	};

	const onFailure = (error) => {
		notify(buildErrorMessage(error), "warning");
	};

	useEffect(() => {
		(async () => {
			const res = await axios.get(
				envSettings.baseUrl + "/hr/settings/support-emails",
				{
					withCredentials: true,
				}
			);

			if (res?.data?.emailRecipients) {
				setCurrentEmailRecipients(res?.data?.emailRecipients);
			}
			setLoading(false);
		})();
	}, []);

	return (
		<Card>
			<CardContent>
				{loading ? (
					<center>
						<h1> we are loading data</h1>
					</center>
				) : (
					<Fragment>
						<SimpleForm
							toolbar={<CustomToolbar />}
							submitOnEnter={true}
							save={async (data) => {
								try {
									const res = await axios.post(
										envSettings.baseUrl +
											"/hr/settings/support-emails",
										{
											emailRecipients:
												emailRecipientsRef &&
												emailRecipientsRef?.current
													?.value === ""
													? currentEmailRecipients
													: emailRecipientsRef.current
															.value,
										},
										{ withCredentials: true }
									);
									if (res.status === 200) {
										notify(
											"Updated Successfully!",
											"success"
										);
										redirect("/");
									} else {
										notify("Failed!", "error");
									}
								} catch (error) {
									onFailure(error);
								}
							}}
							validate={validateB2BSettings}
							initialValues={{
								emailRecipients: currentEmailRecipients,
							}}
						>
							<h4>HR Support Email Recipients:</h4>
							<TextInput
								source="emailRecipients"
								label="Email Recipients"
								style={{ width: "100%" }}
								inputRef={emailRecipientsRef}
							/>
							<span>
								<i>
									HR Support Email recipients should be comma
									separated. Example:
									fares@tuhoon.com,aymane@tuhoon.com,loay@tuhoon.com
								</i>
							</span>
						</SimpleForm>
					</Fragment>
				)}
			</CardContent>
		</Card>
	);
};
