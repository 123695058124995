import {
	Edit,
	SimpleForm,
	TextInput,
	useNotify,
	useRedirect,
	useRefresh,
	NumberInput,
	SelectInput,
	useUpdate,
	useGetOne,
	TextField,
} from "react-admin";
import { validateMemberData } from "./memberValidate";
import { CustomTransferList } from "../../customComponents/CustomTransferList";
import { genders, arabic } from "../../utils/constants";
import { setIndexesForData } from "../../utils/customFunctions";
import { CustomToolbar } from "../../customComponents/CustomToolbar";

export const MemberEdit = (props) => {
	const notify = useNotify();
	const refresh = useRefresh();
	const redirect = useRedirect();
	const { data: { email = null, phoneNumber = null } = {}, loading } =
		useGetOne("members", props.id) || {};
	const [update] = useUpdate("members", props.id);
	const onSuccess = () => {
		notify("members.edit.success", "info");
		redirect("/members");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	return (
		<>
			{loading ? (
				<center>
					<h1>Loading...</h1>
				</center>
			) : (
				<Edit
					title={"Edit Member"}
					{...props}
					mutationMode="pessimistic"
					toolbar={<CustomToolbar />}
				>
					<SimpleForm
						validate={validateMemberData}
						toolbar={<CustomToolbar />}
						save={(data) => {
							const indexedData = setIndexesForData(data, [
								"objectives",
							]);
							if (!indexedData?.objectives?.length) {
								notify(
									"You must add one objective at least!",
									"warning"
								);
								return;
							}
							indexedData.objectives = indexedData.objectives.map(
								({ id }) => id
							);
							update(
								{
									payload: {
										data: {
											...indexedData,
											onboardingData: {
												gender: indexedData.gender,
												objectives:
													indexedData.objectives,
												language: arabic,
											},
										},
									},
								},
								{
									onSuccess,
									onFailure,
								}
							);
						}}
					>
						{email && <TextField source="email" />}
						{phoneNumber && <TextField source="phoneNumber" />}
						<TextField source="method" />
						<TextField source="subscriptionType" />
						<TextField source="revokeDate" />
						<TextField source="validUntil" />
						<TextInput source="firstName" />
						<NumberInput
							source="days"
							label="Extra Free Days"
							helperText={
								"It will be extended from today, not from user's validity end date."
							}
						/>
						<SelectInput
							source="gender"
							choices={genders.slice(0, 2)}
							defaultValue="FEMALE"
							optionText={({ name }) => name}
							optionValue="id"
						/>
						<CustomTransferList
							source="objectives"
							referenceSource="objectives"
							referenceResource="objectives"
							referenceField="titles"
							isReferenceTranslatable={true}
							customFilter="(({comingSoon})=>!comingSoon)"
						/>
					</SimpleForm>
				</Edit>
			)}
		</>
	);
};
