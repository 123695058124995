import { isNotInRange } from "../../utils/customFunctions";

export const validateModuleData = async ({
	name,
	languageId,
	image,
	imageSocial,
	socialCaption,
	deleteSocialImage = null,
	description,
	writer,
	narrator,
	composer,
	allowedUserTypes,
	...rest
}) => {
	const errors = {};
	const { isVideo } = rest;
	const fileType = isVideo ? "video" : "audio";
	if (!name)
		errors.name = "modules.validationErrors.required.isRequired.name";
	if (!allowedUserTypes?.length)
		errors.allowedUserTypes =
			"modules.validationErrors.required.isRequired.allowedUserTypes";

	if (name && isNotInRange({ min: 3, max: 50, str: name })) {
		errors.name = {
			message: "validationErrors.minMax.name",
			args: { min: 3, max: 50 },
		};
	}

	if (!description)
		errors.description =
			"modules.validationErrors.required.isRequired.description";

	if (description && isNotInRange({ min: 3, max: 250, str: description })) {
		errors.description = {
			message: "validationErrors.minMax.descriptions",
			args: { min: 3, max: 250 },
		};
	}

	if (!composer && !narrator) {
		errors.narrator = {
			message: "modules.validationErrors.required.isRequired.name",
			args: { min: 3, max: 50 },
		};
	}

	if (composer && isNotInRange({ min: 3, max: 50, str: composer })) {
		errors.composer = {
			message: "validationErrors.minMax.name",
			args: { min: 3, max: 50 },
		};
	}

	if (narrator && isNotInRange({ min: 3, max: 50, str: narrator })) {
		errors.narrator = {
			message: "validationErrors.minMax.name",
			args: { min: 3, max: 50 },
		};
	}

	if (
		socialCaption &&
		isNotInRange({ min: 3, max: 50, str: socialCaption })
	) {
		errors.socialCaption = {
			message: "validationErrors.minMax.socialCaption",
			args: { min: 3, max: 50 },
		};
	}
	if (
		socialCaption &&
		!imageSocial &&
		(deleteSocialImage || deleteSocialImage === null)
	) {
		errors.imageSocial = {
			message: "validationErrors.required.requiredField",
			args: { field: "social Image" },
		};
	}

	if (!languageId) return errors;

	rest[`language_${languageId}`] &&
		Object.entries(rest[`language_${languageId}`]).forEach(
			([key, file]) => {
				const type = file?.rawFile?.type?.split("/")[0];
				if (type && type !== fileType)
					errors[`language_${languageId}`] = {
						[key]:
							fileType == "audio"
								? "modules.validationErrors.inCorrectFormat"
								: "modules.validationErrors.notAVideo",
					};
			}
		);
	return errors;
};
