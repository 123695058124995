import { useMemo } from "react";
import {
	BooleanInput,
	Datagrid,
	DeleteButton,
	EditButton,
	Filter,
	List,
	ShowButton,
	BooleanField,
	TextField,
	TextInput,
} from "react-admin";

import { CustomDeleteButtonWithConfirmation } from "../../customComponents/CustomButtons";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { constants } from "../../utils/constants";
import { useTranslate } from "ra-core";
import { CustomBooleanInput } from "../../customComponents/CustomBooleanInput";

const CategoryFilter = (props) => (
	<Filter {...props}>
		<TextInput source="search" alwaysOn />
		<BooleanInput source="isActive" />
		<BooleanInput source="comingSoon" />
	</Filter>
);

const CustomBooleanField = ({ record }) => {
	return record.comingSoon == true ? (
		<BooleanField source="comingSoon" label="Coming Soon" />
	) : (
		<></>
	);
};

const Delete = (props) => {
	const {
		record: { isDeletable, isLinked },
	} = props;
	const translate = useTranslate();
	if (!isDeletable) return <></>;
	if (isLinked)
		return (
			<CustomDeleteButtonWithConfirmation
				confirmTitle={translate("objectives.delete.confirmTitle")}
				confirmContent={translate("objectives.delete.confirmContent")}
				{...props}
			/>
		);
	return <DeleteButton {...props} />;
};

export const ObjectiveList = (props) => {
	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);
	return (
		<List
			{...props}
			perPage={constants.perPage}
			bulkActionButtons={false}
			filters={<CategoryFilter />}
		>
			<Datagrid>
				<TextField source="displayIndex" />
				<TextField
					source={`titles.${userPreferences.defaultTranslationLanguage}`}
					sortable={false}
				/>
				<TextField
					source={`recommendationTitles.${userPreferences.defaultTranslationLanguage}`}
					sortable={false}
				/>
				<TextField
					source={`contentType.titles.${defaultLocale}`}
					sortable={false}
				/>
				<CustomBooleanField label="Coming Soon" />
				<CustomBooleanInput source="isActive" sortable={false} /> 
				<ShowButton />
				<EditButton />
				<Delete />
			</Datagrid>
		</List>
	);
};
