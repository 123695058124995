import React, { useMemo } from "react";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { useTranslate } from "ra-core";
import {
	Datagrid,
	Show,
	SimpleShowLayout,
	TextField,
	ArrayField,
	NumberField,
} from "react-admin";

export const SurveyShow = (props) => {
	const translate = useTranslate();
	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);
	return (
		<Show {...props} title={translate("surveys.show.title")}>
			<SimpleShowLayout>
				<TextField source={`title.${defaultLocale}`} />
				<TextField source={`name.${defaultLocale}`} />
				<ArrayField source="allQuestions">
					<Datagrid>
						<TextField
							source="title.ar(Male)"
							label="Arabic (male)"
						/>
						<ArrayField source="answers">
							<Datagrid>
								<TextField
									source="title.ar(Male)"
									label="Arabic (male)"
								/>
								<NumberField source="weight" />
							</Datagrid>
						</ArrayField>
					</Datagrid>
				</ArrayField>
			</SimpleShowLayout>
		</Show>
	);
};
