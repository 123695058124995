import { isNotInRange, isValidSvg } from "../../utils/customFunctions";
export const validateJournalData = async ({
	name,
	questions,
	logo,
	fromCreate = false,
}) => {
	const errors = {};
	if (!name?.en && !name?.ar) {
		errors.name = {
			en: "validationErrors.required.oneIsRequired",
			ar: "validationErrors.required.oneIsRequired",
		};
	}

	if (name?.ar && isNotInRange({ min: 3, max: 50, str: name.ar })) {
		errors.name = {
			ar: {
				message: "validationErrors.minMax.name",
				args: { min: 3, max: 50 },
			},
		};
	}

	if (!logo && fromCreate) {
		errors.logo = {
			message: "validationErrors.required.requiredField",
			args: { field: "Journal Logo" },
		};
	}
	if (logo && !(await isValidSvg(logo.rawFile))) {
		errors.logo = "Logo should be a valid SVG file";
	}

	if (!questions) {
		errors.questions = {
			message: "validationErrors.required.requiredField",
			args: { field: "Questions" },
		};
	}

	return errors;
};
