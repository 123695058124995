import { useCallback, useEffect, useState, useMemo } from "react";
import {
	Edit,
	NumberField,
	NumberInput,
	SimpleFormIterator,
	ArrayInput,
	TabbedForm,
	BooleanInput,
	useGetOne,
	FormTab,
	TextInput,
	TranslatableInputs,
	useGetList,
	useMutation,
	useNotify,
	useRedirect,
	useRefresh,
	FileInput,
	FileField,
	useTranslate,
	useUpdate,
	SelectInput,
} from "react-admin";
import constants from "../../utils/constants";
import { CustomTransferList } from "../../customComponents/CustomTransferList";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { validateObjectiveData } from "./objectiveValidate";
import { setIndexesForData } from "../../utils/customFunctions";
import { transformToFormData } from "../../utils/customFunctions";

const CustomTranslatableInputs = ({ locales, source }) => {
	return (
		<TranslatableInputs locales={locales}>
			<TextInput source={source} />
		</TranslatableInputs>
	);
};

export const ObjectiveEdit = (props) => {
	const [languagesArray, setLanguagesArray] = useState([]);
	const [surveysArray, setSurveysArray] = useState([]);
	const [outcomesLanguagesArray, setOutcomesLanguagesArray] = useState([]);
	const [outcomesCount, setOutcomesCount] = useState(0);
	const [contentTypesArray, setContentTypesArray] = useState([]);

	const translate = useTranslate();
	const redirect = useRedirect();
	const [update] = useUpdate("objectives", props.id);
	const [mutate] = useMutation();
	const refresh = useRefresh();
	const notify = useNotify();

	const { data: contentTypesData } = useGetList("content-types");
	const { data: languagesData } = useGetList("languages");
	const { data: surveysData } = useGetList("surveys");

	const save = useCallback(
		async (values) => {
			try {
				await mutate(
					{
						type: "update",
						resource: "objectives",
						payload: { data: values },
					},
					{ returnPromise: true }
				);
			} catch (error) {
				if (error.body.errors) {
					return error.body.errors;
				}
			}
		},
		[mutate]
	);

	const { data: { outcomes } = {}, loading } = useGetOne(
		"objectives",
		props.id
	);

	const validateObjectDataCreation = ({
		titles,
		descriptions,
		icon,
		recommendationTitles,
		iconAssetId,
		contentTypeId,
	}) => {
		const errors = validateObjectiveData({
			titles,
			descriptions,
			icon,
			recommendationTitles,
			iconAssetId,
			contentTypeId,
		});
		return errors;
	};

	const onSuccess = () => {
		notify(`Updated successfully`);
		redirect("/objectives");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);

	useEffect(() => {
		setOutcomesLanguagesArray(() => {
			let languages = [];
			Object.values(languagesData).map((language) =>
				language.name === "AR"
					? languages.push("ar(Male)", "ar(Female)")
					: languages.push(language.name.toLowerCase())
			);
			return languages;
		});
	}, [languagesData]);

	useEffect(() => {
		setOutcomesCount(outcomes?.length);
	}, [outcomes]);

	useEffect(() => {
		setSurveysArray(
			Object.values(surveysData).map(({ id, name }) => ({
				id,
				name: name[defaultLocale],
			}))
		);
	}, [surveysData]);

	useEffect(() => {
		setLanguagesArray(
			Object.values(languagesData).map((language) =>
				language.name.slice(0, 2).toLowerCase()
			)
		);
	}, [languagesData]);

	useEffect(() => {
		setContentTypesArray(
			Object.values(contentTypesData).map(({ id, titles }) => ({
				id,
				name: titles[defaultLocale],
			}))
		);
	}, [contentTypesData]);

	const handleClick = (event) => {
		if (
			event.target.localName === "button" &&
			event.target.textContent === "Add"
		) {
			setOutcomesCount(outcomesCount + 1);
		}
		if (
			event.target.localName === "button" &&
			event.target.textContent === "Remove"
		) {
			const index = event.target.outerHTML
				.split("button-remove-outcomes-")
				.pop()
				.split(" ")[0];
			setOutcomesCount(outcomesCount - 1);
		}
	};

	return (
		<>
			{loading ? (
				<center>
					<h1>Loading...</h1>
				</center>
			) : (
				<Edit
					{...props}
					save={save}
					mutationMode="pessimistic"
					title={translate("objectives.edit.title")}
				>
					<TabbedForm
						validate={validateObjectDataCreation}
						toolbar={<CustomToolbar />}
						save={(data) => {
							const indexedData = setIndexesForData(data, [
								"plans",
								"timelines",
							]);
							indexedData.outcomes = !indexedData?.outcomes
								? []
								: indexedData.outcomes;
							const transformed =
								transformToFormData(indexedData);
							update(
								{
									payload: {
										data: transformed,
									},
								},
								{
									onSuccess,
									onFailure,
								}
							);
						}}
						syncWithLocation={false}
					>
						<FormTab
							label={translate("objectives.tabs.basicInfo.label")}
						>
							<TranslatableInputs
								locales={languagesArray}
								defaultLocale={
									userPreferences.defaultTranslationLanguage
								}
							>
								<TextInput source="titles" />
								<TextInput
									source="recommendationTitles"
									label="Recommendation Titles"
								/>
								<TextInput source="descriptions" />
							</TranslatableInputs>
							<BooleanInput
								defaultValue={false}
								source="comingSoon"
							/>
							<NumberField
								source="displayIndex"
								label="objectives.edit.currentDisplayIndex"
							/>
							<NumberInput source="newDisplayIndex" />
							<SelectInput
								source="contentTypeId"
								choices={contentTypesArray}
								translateChoice={false}
							/>
							<FileInput {...props} source="icon" label="Icon">
								<FileField source="src" title="Icon" />
							</FileInput>
						</FormTab>
						<FormTab
							label={translate(
								"objectives.tabs.outcomesManagement.label"
							)}
						>
							<ArrayInput
								source="outcomes"
								onClick={(event) => {
									handleClick(event);
								}}
							>
								<SimpleFormIterator
									disableAdd={
										outcomesCount > constants.outcomesLimit
											? true
											: false
									}
								>
									<CustomTranslatableInputs
										locales={outcomesLanguagesArray}
									/>
								</SimpleFormIterator>
							</ArrayInput>
						</FormTab>
						<FormTab
							label={translate(
								"objectives.tabs.plansManagement.label"
							)}
						>
							<CustomTransferList
								source="plans"
								referenceSource="plans"
								referenceResource="plans"
								referenceField="names"
								isReferenceTranslatable={true}
							/>
						</FormTab>
						<FormTab
							label={translate(
								"objectives.tabs.timelinesManagement.label"
							)}
						>
							<CustomTransferList
								source="timelines"
								referenceSource="timelines"
								referenceResource="timelines"
								referenceField="name"
								isReferenceTranslatable={true}
							/>
						</FormTab>
						<FormTab
							label={translate(
								"objectives.tabs.surveysManagement.label"
							)}
						>
							<SelectInput
								source="surveyId"
								choices={surveysArray}
								translateChoice={false}
							/>
							<NumberInput
								source="surveyWaitDays"
								label={"Wait Period (Days)"}
							/>
						</FormTab>
					</TabbedForm>
				</Edit>
			)}
		</>
	);
};
