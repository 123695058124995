import {
	useInput,
	Create,
	SimpleForm,
	TextInput,
	useNotify,
	useRedirect,
	useRefresh,
	NumberInput,
	SelectInput,
	PasswordInput,
	useTranslate,
} from "react-admin";
import { validateMemberData } from "./memberValidate";
import { RadioButtonGroupInput } from "react-admin";
import { CustomTransferList } from "../../customComponents/CustomTransferList";
import { genders, arabic } from "../../utils/constants";
import { useState } from "react";
import { envSettings } from "../../settings/env";
import axios from "axios";
import { setIndexesForData } from "../../utils/customFunctions";
import { buildErrorMessage } from "../../utils/helpers";
import { CustomToolbar } from "../../customComponents/CustomToolbar";

const Custom = (props) => {
	const {
		input: {
			value: { type },
		},
	} = useInput(props);
	return (
		<>
			<TextInput source="firstName" {...props} />
			<br />
			<TextInput
				source={type === "email" ? "email" : "phone"}
				{...props}
			/>
			<br />
			{type === "email" && (
				<>
					<PasswordInput source="password" {...props} />
					<br />
				</>
			)}
			<NumberInput
				source="days"
				{...props}
				helperText={
					"It will be extended from today, not from user's validity end date."
				}
			/>
			<br />
			<SelectInput
				source="gender"
				choices={genders.slice(0, 2)}
				defaultValue="FEMALE"
				optionText={({ name }) => name}
				optionValue="id"
				{...props}
			/>
			<br />
			<CustomTransferList
				source="objectives"
				referenceSource="objectives"
				referenceResource="objectives"
				referenceField="titles"
				isReferenceTranslatable={true}
				customFilter="(({comingSoon})=>!comingSoon)"
			/>
		</>
	);
};

export const MemberCreate = (props) => {
	const translate = useTranslate();
	const notify = useNotify();
	const refresh = useRefresh();
	const redirect = useRedirect();
	const [type, setType] = useState("email");

	const onSuccess = () => {
		notify("members.create.success", "info");
		redirect("/members");
		refresh();
	};
	const onFailure = (error) => {
		notify(buildErrorMessage(error), "warning");
	};

	return (
		<Create
			{...props}
			title={translate("members.create.title")}
			toolbar={<CustomToolbar />}
		>
			<SimpleForm
				validate={(data) => {
					return validateMemberData({ ...data, type, create: true });
				}}
				save={(data) => {
					const indexedData = setIndexesForData(data, ["objectives"]);
					if (!indexedData?.objectives?.length) {
						notify(
							"You must add one objective at least!",
							"warning"
						);
						return;
					}
					indexedData.objectives = indexedData.objectives.map(
						({ id }) => id
					);
					(async () => {
						try {
							await axios.post(
								envSettings.baseUrl + `/members/create/${type}`,
								{
									...(type === "email" && {
										email: indexedData.email,
									}),
									...(type === "phone" && {
										phone: indexedData.phone,
									}),
									firstName: indexedData.firstName,
									days: indexedData.days,
									...(type === "email" && {
										password: indexedData.password,
									}),
									confirmPassword: indexedData.password,
									onboardingData: {
										gender: indexedData.gender,
										objectives: indexedData.objectives,
										language: arabic,
									},
								},
								{ withCredentials: true }
							);
							onSuccess();
						} catch (error) {
							onFailure(error);
						}
					})();
				}}
			>
				<RadioButtonGroupInput
					source="type"
					choices={[
						{ id: "email", name: "Email" },
						{ id: "phone", name: "Phone" },
					]}
					onChange={(val) => setType(val)}
					defaultValue={"email"}
				/>
				<Custom />
			</SimpleForm>
		</Create>
	);
};
