import { useFormState } from "react-final-form";
import { useEffect, useState, useMemo } from "react";
import {
	Create,
	FileField,
	FileInput,
	ImageField,
	ImageInput,
	BooleanInput,
	AutocompleteInput,
	RadioButtonGroupInput,
	TextInput,
	useCreate,
	AutocompleteArrayInput,
	useGetList,
	useNotify,
	useRedirect,
	TabbedForm,
	FormTab,
	useRefresh,
	useTranslate,
} from "react-admin";
import { aspectRatios, userTypes } from "../../utils/constants";
import { getChoices } from "./shared";
import { validateModuleData } from "./moduleValidate";
import { narratorListenerVariations } from "../../utils/constants";
import { transformToFormData } from "../../utils/customFunctions";
import { CustomContentTypeArrayInput } from "../../customComponents/CustomContentTypeArrayInput";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { setIndexesForData } from "../../utils/customFunctions";
import { CustomTransferList } from "../../customComponents/CustomTransferList";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import CustomChipsArrayInput from "../../customComponents/CustomChipsArrayInput";
import CustomCircularProgress from "../../customComponents/CustomCircularProgress";

const transform = ({ ...data }) => {
	let mutatedData = {};
	Object.entries(data).forEach(([key, value]) => {
		const [keyPrefix, keyLanguageId] = key.split("_");

		if (keyPrefix !== "language") mutatedData[key] = value;

		if (keyPrefix === "language" && keyLanguageId === "" + data.languageId)
			Object.entries(value).forEach(([fileName, file]) => {
				if (file) mutatedData[fileName] = value[fileName];
			});
	});
	return transformToFormData(mutatedData);
};

const CustomVarianceInput = ({ translate, languageData, ...props }) => {
	const [hasLanguage, setHasLanguage] = useState(false);
	const [isNeutral, setIsNeutral] = useState(false);
	const [isVideo, setIsVideo] = useState(false);
	const { values } = useFormState();

	useEffect(() => {
		setHasLanguage(() => !!values?.languageId);
		setIsNeutral(
			() =>
				languageData &&
				languageData[values?.languageId]?.isGenderNeutral === false
		);
	}, [values.languageId, languageData]);
	if (!hasLanguage) return null;
	return (
		<>
			<BooleanInput
				source="isVideo"
				label="Video"
				defaultValue={false}
				onChange={() => setIsVideo((prev) => !prev)}
			/>
			{narratorListenerVariations[isNeutral]?.map(
				({ variant, narrator, listener }) => (
					<FileInput
						key={`language_${values.languageId}file${variant}`}
						{...props}
						source={`language_${values.languageId}.file${variant}`}
						label={translate(
							`modules.create.uploadFileLabel.${isNeutral}`,
							{
								narrator: translate(narrator),
								listener: listener ? translate(listener) : "",
							}
						)}
						disabled={!hasLanguage}
					>
						{isVideo ? (
							<FileField
								source="video"
								label="Video File"
								title="title"
								target="_blank"
							/>
						) : (
							<FileField
								source="src"
								target="_blank"
								title={translate(
									`modules.create.uploadFileLabel.${isNeutral}`,
									{
										narrator: translate(narrator),
										listener: listener
											? translate(listener)
											: "",
									}
								)}
							/>
						)}
					</FileInput>
				)
			)}
		</>
	);
};
export const ModuleCreate = (props) => {
	const [backgroundPlaylistsArray, setBackgroundPlaylistsArray] = useState(
		[]
	);
	const [objectivesArray, setObjectivesArray] = useState([]);
	const [isSingle, setIsSingle] = useState(false);
	const [isSharable, setIsSharable] = useState(false);
	const [languageData, setLanguageData] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const translate = useTranslate();
	const redirect = useRedirect();
	const refresh = useRefresh();
	const notify = useNotify();
	const [create] = useCreate("modules");

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);

	const { data: objectivesData } = useGetList("objectives");

	const { data: languagesDataObj } = useGetList(
		"languages",
		{ page: 1, perPage: 10 },
		{ field: "id", order: "DESC" }
	);

	useEffect(() => {
		setLanguageData(getChoices(languagesDataObj));
	}, [languagesDataObj]);

	const { data: backgroundPlaylistsData, loading } = useGetList(
		"background-playlists"
	);
	const onSuccess = () => {
		notify(translate("modules.create.success"), "info");
		setIsLoading(false);
		redirect("/modules");
		refresh();
	};

	const onFailure = (error) => {
		setIsLoading(false);
		notify(error.message, "warning");
	};

	useEffect(() => {
		setBackgroundPlaylistsArray(
			Object.values(backgroundPlaylistsData).map(({ id, names }) => ({
				id,
				name: names[defaultLocale],
			}))
		);
	}, [backgroundPlaylistsData]);

	useEffect(() => {
		setObjectivesArray(
			Object.values(objectivesData).map(({ id, titles }) => ({
				id,
				name: titles[defaultLocale],
			}))
		);
	}, [objectivesData]);

	return (
		<>
			{loading ? (
				<center>
					<h1>Loading...</h1>
				</center>
			) : (
				<Create
					transform={transform}
					{...props}
					title="modules.create.title"
				>
					<TabbedForm
						toolbar={<CustomToolbar loading={isLoading} />}
						validate={validateModuleData}
						submitOnEnter={false}
						save={({ doctors = [], ...data }) => {
							const indexedDataToSend = setIndexesForData(
								{ ...data, doctors },
								["doctors"]
							);
							setIsLoading(true);
							const transformed = transform(indexedDataToSend);
							create(
								{ payload: { data: transformed } },
								{
									onSuccess,
									onFailure,
								}
							);
						}}
					>
						<FormTab
							label={translate("modules.tabs.basicInfo.label")}
						>
							<CustomCircularProgress loading={isLoading} />
							<TextInput source="name" />
							<TextInput source="description" multiline={true} />
							<TextInput source="narrator" />
							<TextInput
								source="composer"
								label="Composer (optional)"
							/>
							<TextInput
								source="socialCaption"
								label="Social Caption (optional)"
							/>
							<AutocompleteArrayInput
								resettable
								source="allowedUserTypes"
								choices={userTypes}
							/>
							<BooleanInput
								source="isSharable"
								label="Sharable"
								defaultValue={false}
								onChange={() => setIsSharable((prev) => !prev)}
							/>
							<BooleanInput
								source="isSingle"
								label="Single"
								defaultValue={false}
								onChange={() => setIsSingle((prev) => !prev)}
							/>
							{isSingle && (
								<>
									<BooleanInput source="isRecommended" />
									<BooleanInput
										defaultValue={false}
										source="comingSoon"
									/>
									<AutocompleteInput
										resettable
										source="backgroundPlaylistId"
										choices={backgroundPlaylistsArray}
									/>
									<AutocompleteArrayInput
										resettable
										source="objectiveIds"
										choices={objectivesArray}
									/>
								</>
							)}
							<ImageInput
								source="image"
								accept="image/*"
								label="Image"
								helperText={
									"The aspect ratio should be " +
									aspectRatios.moduleInPlaylist
								}
								placeholder={"Upload Module Image"}
							>
								<ImageField source="src" />
							</ImageInput>
							<ImageInput
								source="imageSocial"
								accept="image/*"
								label="Social Image"
								helperText={
									"The aspect ratio should be " +
									aspectRatios.moduleInPlaylist
								}
								placeholder={"Upload Module Social Image"}
							>
								<ImageField source="src" />
							</ImageInput>
							<ImageInput
								source="imageCover"
								accept="image/*"
								label="Cover Image"
								placeholder={"Upload Module Cover Image"}
							>
								<ImageField source="src" />
							</ImageInput>
							<RadioButtonGroupInput
								source="languageId"
								choices={languageData}
								optionText={({ name }) =>
									`misc.names.languages.${name}`
								}
								optionValue="id"
							/>
							<CustomVarianceInput
								translate={translate}
								languageData={languageData}
							/>
						</FormTab>
						<FormTab
							label={translate(
								"modules.tabs.typesManagement.label"
							)}
						>
							<CustomContentTypeArrayInput />
						</FormTab>
						<FormTab
							label={translate(
								"modules.tabs.doctorsManagement.label"
							)}
						>
							<CustomTransferList
								source="doctors"
								referenceSource="doctors"
								referenceResource="doctors"
								referenceField="fullName"
								isReferenceTranslatable={true}
							/>
						</FormTab>
					</TabbedForm>
				</Create>
			)}
		</>
	);
};
