import { MemberCreate } from "./memberCreate";
import { MemberEdit } from "./memberEdit";
import { MemberList } from "./memberList";
import { MemberShow } from "./memberShow";

export default {
	create: MemberCreate,
	edit: MemberEdit,
	list: MemberList,
	show: MemberShow,
};
