import * as React from "react";
import { Route } from "react-router-dom";
import { UserVerify } from "./userVerify";
import { Sections } from "./sections";
import { ProfileSettings } from "./profileSettings";
import { AppSettings } from "./appSettings";
import { PlanCommunication } from "./planCommunications/planCommunication";
import { B2BSettings } from "./b2bSettings";
import { HRSettings } from "./hrSettings";
import { PromoPathway } from "./promoPathway";

const routes = [
	<Route exact path="/profileSettings" component={ProfileSettings} />,
	<Route exact path="/appSettings" component={AppSettings} />,
	<Route exact path="/b2bSettings" component={B2BSettings} />,
	<Route exact path="/hr/settings" component={HRSettings} />,
	<Route exact path="/verify" component={UserVerify} noLayout={true} />,
	<Route exact path="/sections" component={Sections} />,
	<Route exact path="/promo-pathway" component={PromoPathway} />,
	<Route
		exact
		path="/plan-communications/:id/:id"
		component={PlanCommunication}
	/>,
];

export default routes;
