import { BackgroundPlaylistCreate } from "./backgroundPlaylistCreate";
import { BackgroundPlaylistEdit } from "./backgroundPlaylistEdit";
import { BackgroundPlaylistList } from "./backgroundPlaylistList";
import { BackgroundPlaylistShow } from "./backgroundPlaylistShow";

export default {
	create: BackgroundPlaylistCreate,
	edit: BackgroundPlaylistEdit,
	list: BackgroundPlaylistList,
	show: BackgroundPlaylistShow,
};
