import React, { useState } from "react";
import {
	Create,
	SimpleForm,
	TextInput,
	DateTimeInput,
	useNotify,
	useRedirect,
	useRefresh,
	NumberInput,
	useCreate,
	BooleanInput,
} from "react-admin";

import { validatePromoCodeData } from "./promoCodeValidate";
import { useStyles } from "../../utils/styles";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { FormControlLabel, Switch } from "@material-ui/core";

export const PromoCodeCreate = (props) => {
	const redirect = useRedirect();
	const refresh = useRefresh();
	const notify = useNotify();
	const classes = useStyles();
	const [isFreeDays, setIsFreeDays] = useState(false);
	const [create] = useCreate("promo-codes");

	const onSuccess = () => {
		notify("promo-codes.create.success", "info");
		redirect("/promo-codes");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	return (
		<Create {...props}>
			<SimpleForm
				toolbar={<CustomToolbar />}
				validate={(data) =>
					validatePromoCodeData({ ...data, isFreeDays: isFreeDays })
				}
				save={(data) => {
					data.code = data.code.trim();
					data.isFreeDays = isFreeDays;
					isFreeDays
						? delete data.percentage
						: delete data.numberOfFreeDays;
					create(
						{ payload: { data: { ...data } } },
						{
							onSuccess,
							onFailure,
						}
					);
				}}
			>
				<TextInput source="code" className={classes.inline} />
				<DateTimeInput source="startDate" />
				<br />
				<NumberInput
					source="userUsageLimit"
					className={classes.inline}
				/>
				<DateTimeInput source="endDate" />
				<NumberInput source="globalUsageLimit" />
				<BooleanInput source="forBusiness" />
				<FormControlLabel
					control={
						<Switch
							name="freeDays"
							color="primary"
							checked={isFreeDays}
							onClick={() => setIsFreeDays(!isFreeDays)}
						/>
					}
					label={"Free Days"}
				/>
				{isFreeDays ? (
					<NumberInput source="numberOfFreeDays" max={365} min={1} />
				) : (
					<NumberInput source="percentage" max={99} min={1} />
				)}
			</SimpleForm>
		</Create>
	);
};

export default PromoCodeCreate;
