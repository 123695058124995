import { useEffect, useState, useMemo } from "react";
import {
	Edit,
	FormTab,
	useNotify,
	TextInput,
	ArrayInput,
	useGetList,
	TabbedForm,
	useRedirect,
	useTranslate,
	TranslatableInputs,
	SimpleFormIterator,
	FileInput,
	FileField,
	minLength,
	maxLength,
	useUpdate,
} from "react-admin";

import { userPreferences } from "../../utils/fakeUserPrefrences";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { transformToFormData } from "../../utils/customFunctions";
import { validateJournalData } from "./journalValidate";
import { SVGViewer } from "../../customComponents/CustomSVGViewer";
import CustomImageField from "../../customComponents/CustomImageField";

const CustomTranslatableInputs = ({ locales, source, label }) => {
	return (
		<TranslatableInputs locales={locales} defaultLocale="ar(Female)">
			<TextInput
				source={source}
				label={label}
				validate={[minLength(3), maxLength(200)]}
			/>
		</TranslatableInputs>
	);
};

const CustomSvgViewer = ({ record: { journalSvg = null, logoUrl } }) => {
	return journalSvg ? (
		SVGViewer({
			svg: journalSvg,
			title: "Logo SVG",
		})
	) : (
		<CustomImageField customLabel="Journal Image" source="logoUrl" />
	);
};

export const JournalEdit = (props) => {
	const [languagesArray, setLanguagesArray] = useState([]);
	const [customLanguagesArray, setCustomLanguagesArray] = useState([]);
	const [update] = useUpdate("journals", props.id);

	const notify = useNotify();
	const redirect = useRedirect();
	const translate = useTranslate();

	const { data: languagesData } = useGetList("languages");

	useEffect(() => {
		setCustomLanguagesArray(() => {
			let languages = [];
			Object.values(languagesData).map((language) =>
				language.name === "AR"
					? languages.push("ar(Male)", "ar(Female)")
					: languages.push(language.name.toLowerCase())
			);
			return languages;
		});
		setLanguagesArray(
			Object.values(languagesData).map((language) =>
				language.name.slice(0, 2).toLowerCase()
			)
		);
	}, [languagesData]);

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);

	const onSuccess = () => {
		notify(translate("journals.edit.success"), "info");
		redirect("/journals");
	};

	const onFailure = () => {
		notify(translate("journals.edit.fail"), "warning");
	};
	return (
		<>
			{false ? (
				<center>
					<h1>Loading...</h1>
				</center>
			) : (
				<Edit {...props} title={translate("journals.edit.title")}>
					<TabbedForm
						toolbar={<CustomToolbar />}
						submitOnEnter={false}
						syncWithLocation={false}
						validate={validateJournalData}
						save={(data) => {
							const transformed = transformToFormData(data);
							update(
								{ payload: { data: transformed } },
								{
									onSuccess,
									onFailure,
								}
							);
						}}
					>
						<FormTab
							label={translate("journals.tabs.basicInfo.label")}
						>
							<TranslatableInputs
								locales={languagesArray}
								defaultLocale={defaultLocale}
							>
								<TextInput source="name" />
							</TranslatableInputs>
							<CustomSvgViewer />
							<FileInput source="logo" label="Logo">
								<FileField source="src" title="Logo" />
							</FileInput>
						</FormTab>
						<FormTab
							label={translate("journals.tabs.questions.label")}
						>
							<ArrayInput source="questions">
								<SimpleFormIterator>
									<CustomTranslatableInputs
										locales={customLanguagesArray}
										label={"Question"}
									/>
								</SimpleFormIterator>
							</ArrayInput>
						</FormTab>
					</TabbedForm>
				</Edit>
			)}
		</>
	);
};
