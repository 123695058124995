import { useEffect, useMemo, useState } from "react";
import {
	Create,
	ImageField,
	ImageInput,
	useCreate,
	TextInput,
	SimpleForm,
	useNotify,
	useRedirect,
	useRefresh,
	TranslatableInputs,
	useTranslate,
	BooleanInput,
	useGetList,
} from "react-admin";
import { transformToFormData } from "../../utils/customFunctions";
import { validateDoctorData } from "./doctorValidate";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { getChoices } from "./shared";

export const DoctorCreate = (props) => {
	const translate = useTranslate();
	const [languagesArray, setLanguagesArray] = useState([]);
	const { data: languagesDataObj, loading } = useGetList("languages");
	const redirect = useRedirect();
	const refresh = useRefresh();
	const notify = useNotify();
	const [create] = useCreate("doctors");

	const onSuccess = () => {
		notify(translate("doctors.create.success"), "info");
		redirect("/doctors");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	useEffect(() => {
		setLanguagesArray(getChoices(languagesDataObj));
	}, [languagesDataObj, loading]);

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);
	
	return (
		<Create {...props} title={translate("doctors.create.title")}>
			<SimpleForm
				save={(data) => {
					const transformed = transformToFormData(data);
					create(
						{ payload: { data: transformed } },
						{
							onSuccess,
							onFailure,
						}
					);
				}}
				toolbar={<CustomToolbar />}
				validate={(data) =>
					validateDoctorData({ ...data, fromCreate: true })
				}
				syncWithLocation={false}
			>
				<TranslatableInputs
					locales={languagesArray}
					defaultLocale={defaultLocale}
				>
					<TextInput source="fullName" />
					<TextInput source="description" />
				</TranslatableInputs>
				<BooleanInput
					source="isPublic"
					defaultValue={false}
					label="Is Public "
				/>
				<ImageInput
					source="image"
					label="Profile Picture"
					accept="image/*"
					placeholder={translate(
						"doctors.create.uploadImagePlaceholder"
					)}
				>
					<ImageField source="src" />
				</ImageInput>
			</SimpleForm>
		</Create>
	);
};
