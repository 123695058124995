import {
	Datagrid,
	EditButton,
	Filter,
	List,
	DateField,
	BooleanInput,
	NumberField,
	TextField,
	TextInput,
} from "react-admin";
import { constants } from "../../utils/constants";
import { CustomBooleanInput } from "../../customComponents/CustomBooleanInput";
import { CustomDeleteButtonWithCustomConfirm } from "../../customComponents/CustomButtons";

const PromoCodeFilter = ({ ...props }) => (
	<Filter {...props}>
		<TextInput source="search" alwaysOn />
		<BooleanInput source="isActive" />
	</Filter>
);

export const PromoCodeList = (props) => {
	return (
		<List
			{...props}
			perPage={constants.perPage}
			bulkActionButtons={false}
			filters={<PromoCodeFilter />}
		>
			<Datagrid>
				<TextField source="code" label="Code" sortable={false} />
				<NumberField source="percentage" sortable={false} />
				<NumberField source="numberOfFreeDays" sortable={false} />
				<NumberField
					source="usageCount"
					label="Usage Count"
					sortable={false}
				/>
				<NumberField source="userUsageLimit" sortable={false} />
				<NumberField source="globalUsageLimit" sortable={false} />
				<DateField source="startDate" showTime />
				<DateField source="endDate" showTime />
				<CustomBooleanInput source="isActive" sortable={false} />
				<EditButton />
				<CustomDeleteButtonWithCustomConfirm mutationMode="pessimistic" />
			</Datagrid>
		</List>
	);
};
