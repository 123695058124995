import { useEffect, useMemo, useState } from "react";
import {
	Create,
	SimpleForm,
	TextInput,
	useCreate,
	useGetList,
	useNotify,
	useRedirect,
	useRefresh,
	useTranslate,
	SelectInput,
	FileInput,
	FileField,
} from "react-admin";

import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { validateContentData } from "./hrResourcesContentValidate";
import { transformToFormData } from "../../utils/customFunctions";
import { userPreferences } from "../../utils/fakeUserPrefrences";

export const HRResourcesContentCreate = (props) => {
	const [resourcesCategoriesChoices, setResourcesCategoriesChoices] =
		useState([]);

	const { data: resourcesCategoriesData } = useGetList(
		"hr/resources/categories"
	);
	const [create] = useCreate("/hr/resources/content");
	const translate = useTranslate();
	const redirect = useRedirect();
	const refresh = useRefresh();
	const notify = useNotify();

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);

	const onSuccess = () => {
		notify(translate("hr.resources.content.create.success"), "info");
		redirect("/hr/resources/content");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	useEffect(() => {
		resourcesCategoriesChoices.length <= 0 &&
			setResourcesCategoriesChoices(
				Object.values(resourcesCategoriesData).map(
					({ id, titles }) => ({
						id,
						name:
							titles?.[defaultLocale] ||
							titles[userPreferences.translationLanguages[0]] ||
							titles[userPreferences.translationLanguages[1]],
					})
				)
			);
	}, [resourcesCategoriesChoices]);

	return (
		<Create
			{...props}
			title={translate("hr.resources.content.create.title")}
		>
			<SimpleForm
				save={(data) => {
					const transformed = transformToFormData(data);

					create(
						{ payload: { data: transformed.formData } },
						{
							onSuccess,
							onFailure,
						}
					);
				}}
				toolbar={<CustomToolbar />}
				syncWithLocation={false}
				validate={(data) =>
					validateContentData({ ...data, fromCreate: true })
				}
			>
				<TextInput source="name" />
				<SelectInput
					label="category"
					source="categoryId"
					choices={resourcesCategoriesChoices}
				/>
				<FileInput
					source="content"
					label="Resources Content"
					placeholder={translate(
						"hr.resources.content.create.uploadContentPlaceholder"
					)}
				>
					<FileField title="rowFile.name" source="src" />
				</FileInput>
			</SimpleForm>
		</Create>
	);
};
