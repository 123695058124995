import { AppBar, Drawer, Toolbar, Typography } from "@material-ui/core";
import { useTranslate } from "ra-core";
import { Route } from "react-router";

export const CustomSidebar = ({
	anchor = "right",
	handleClose,
	ComponentEdit,
	ComponentCreate,
	drawerOptions,
	...props
}) => {
	const translate = useTranslate();

	if (!props.resource) return null;

	return (
		<>
			<Route exact path={`/${props.resource}/:id`}>
				{({ match }) => {
					const isMatch = match && match.params && match.isExact;
					const isMatchEdit = isMatch && match.params.id !== "create";
					const isMatchCreate = isMatch && match.params.id === "create";

					if (isMatchEdit) {
						return (
							<Drawer
								{...drawerOptions}
								open={isMatchEdit}
								anchor={anchor}
								onClose={handleClose}
							>
								<AppBar position="sticky">
									<Toolbar>
										<Typography>
											{translate(`${props.resource}.edit.title`)}
										</Typography>
									</Toolbar>
								</AppBar>
								<ComponentEdit
									id={isMatch ? match.params.id : null}
									{...props}
								/>
							</Drawer>
						);
					}

					if (isMatchCreate) {
						return (
							<Drawer
								{...drawerOptions}
								open={isMatchCreate}
								anchor={anchor}
								onClose={handleClose}
							>
								<AppBar position="sticky">
									<Toolbar>
										<Typography>
											{translate(`${props.resource}.create.title`)}
										</Typography>
									</Toolbar>
								</AppBar>
								<ComponentCreate {...props} />
							</Drawer>
						);
					}

					return null;
				}}
			</Route>
		</>
	);
};
