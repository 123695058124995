import { MoodTrackerCreate } from "./moodTrackerCreate";
import { MoodTrackerEdit } from "./moodTrackerEdit";
import { MoodTrackerList } from "./moodTrackerList";
import { MoodTrackerShow } from "./moodTrackerShow";

export default {
	create: MoodTrackerCreate,
	edit: MoodTrackerEdit,
	list: MoodTrackerList,
	show: MoodTrackerShow,
};
