import { ContentTypeCreate } from "./contentTypeCreate";
import { ContentTypeEdit } from "./contentTypeEdit";
import { ContentTypeList } from "./contentTypeList";
import { ContentTypeShow } from "./contentTypeShow";

export default {
	create: ContentTypeCreate,
	edit: ContentTypeEdit,
	list: ContentTypeList,
	show: ContentTypeShow,
};
