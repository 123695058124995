import React, { Fragment, useEffect, useState } from "react";
import {
	useNotify,
	useRedirect,
	Toolbar,
	useTranslate,
	SimpleForm,
} from "react-admin";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { envSettings } from "../settings/env";
import axios from "axios";
import { setIndexesForData } from "../utils/customFunctions";
import { CustomTransferList } from "../customComponents/CustomTransferList";

export const Sections = () => {
	const [selectedSections, setSelectedSections] = useState([]);
	const [loading, setLoading] = useState([]);
	const notify = useNotify();
	const redirect = useRedirect();
	const translate = useTranslate();
	useEffect(() => {
		(async () => {
			const res = await axios.get(
				envSettings.baseUrl + "/available-sections",
				{
					withCredentials: true,
				}
			);
			setSelectedSections(res?.data?.selectedSections);
			setLoading(false);
		})();
	}, []);

	const onSuccess = () => {
		notify(translate("sections.edit.success"), "info");
		redirect("/");
	};

	const onFailure = () => {
		notify(translate("sections.edit.fail"), "warning");
	};

	return (
		<Card>
			<CardContent>
				{loading ? (
					<center>
						<h1> we are loading data</h1>
					</center>
				) : (
					<Fragment>
						<SimpleForm
							toolbar={<Toolbar alwaysEnableSaveButton />}
							submitOnEnter={false}
							save={async (data) => {
								data = setIndexesForData(data, [
									"selectedSections",
								]);
								const res = await axios.post(
									envSettings.baseUrl +
										"/available-sections/select",
									data,
									{
										withCredentials: true,
									}
								);
								if (res?.status === 200) onSuccess();
								else onFailure();
							}}
							syncWithLocation={false}
						>
							<CustomTransferList
								source="selectedSections"
								referenceSource="available-sections"
								referenceResource="available-sections"
								referenceField="name"
								isReferenceTranslatable={false}
								currValues={selectedSections}
							/>
						</SimpleForm>
					</Fragment>
				)}
			</CardContent>
		</Card>
	);
};
