import React, { useMemo } from "react";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { useTranslate } from "ra-core";
import {
	Datagrid,
	FunctionField,
	Show,
	SimpleShowLayout,
	TextField,
	ArrayField,
} from "react-admin";
import { Typography } from "@material-ui/core";
import { SVGViewer } from "../../customComponents/CustomSVGViewer";
import CustomImageField from "../../customComponents/CustomImageField";

const CustomQuestions = ({ record: { questions } }) => {
	if (questions.length > 0) {
		return (
			<ArrayField source="questions">
				<Datagrid>
					<TextField source="ar(Male)" label="Arabic (male)" />
					<TextField source="ar(Female)" label="Arabic (female)" />
					<TextField source="en" label="English" />
				</Datagrid>
			</ArrayField>
		);
	}
	return <></>;
};

const CustomMessage = ({ record }) => {
	const translate = useTranslate();
	const message = translate("journals.show.isActive", {
		status: record?.isActive ? "" : "not",
	});
	return <Typography>{`${message}`}</Typography>;
};

const CustomSvgViewer = ({ record: { journalSvg = null, logoUrl } }) => {
	return journalSvg ? (
		SVGViewer({
			svg: journalSvg,
			title: "Logo SVG",
		})
	) : (
		<CustomImageField customLabel="Journal Image" source="logoUrl" />
	);
};

export const JournalShow = (props) => {
	const translate = useTranslate();
	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);
	return (
		<Show {...props} title={translate("journals.show.title")}>
			<SimpleShowLayout>
				<TextField source={`name.${defaultLocale}`} />
				<FunctionField
					source="isActive"
					render={(record) => <CustomMessage record={record} />}
				/>
				<CustomSvgViewer />
				<CustomQuestions />
			</SimpleShowLayout>
		</Show>
	);
};
