export const buildErrorMessage = (error) => {
	const message = error?.response?.data
		?.split("<pre>")[1]
		?.split("</pre>")[0]
		?.split("Error:")[1]
		?.split("<br>")[0];
	return message || "Failed!";
};

export const getAllCurrencies = () => [
	{ code: "AFA" },
	{ code: "ALL" },
	{ code: "DZD" },
	{ code: "AOA" },
	{ code: "ARS" },
	{ code: "AMD" },
	{ code: "AWG" },
	{ code: "AUD" },
	{ code: "AZN" },
	{ code: "BSD" },
	{ code: "BHD" },
	{ code: "BDT" },
	{ code: "BBD" },
	{ code: "BYR" },
	{ code: "BEF" },
	{ code: "BZD" },
	{ code: "BMD" },
	{ code: "BTN" },
	{ code: "BTC" },
	{ code: "BOB" },
	{ code: "BAM" },
	{ code: "BWP" },
	{ code: "BRL" },
	{ code: "GBP" },
	{ code: "BND" },
	{ code: "BGN" },
	{ code: "BIF" },
	{ code: "KHR" },
	{ code: "CAD" },
	{ code: "CVE" },
	{ code: "KYD" },
	{ code: "XOF" },
	{ code: "XAF" },
	{ code: "XPF" },
	{ code: "CLP" },
	{ code: "CNY" },
	{ code: "COP" },
	{ code: "KMF" },
	{ code: "CDF" },
	{ code: "CRC" },
	{ code: "HRK" },
	{ code: "CUC" },
	{ code: "CZK" },
	{ code: "DKK" },
	{ code: "DJF" },
	{ code: "DOP" },
	{ code: "XCD" },
	{ code: "EGP" },
	{ code: "ERN" },
	{ code: "EEK" },
	{ code: "ETB" },
	{ code: "EUR" },
	{ code: "FKP" },
	{ code: "FJD" },
	{ code: "GMD" },
	{ code: "GEL" },
	{ code: "DEM" },
	{ code: "GHS" },
	{ code: "GIP" },
	{ code: "GRD" },
	{ code: "GTQ" },
	{ code: "GNF" },
	{ code: "GYD" },
	{ code: "HTG" },
	{ code: "HNL" },
	{ code: "HKD" },
	{ code: "HUF" },
	{ code: "ISK" },
	{ code: "INR" },
	{ code: "IDR" },
	{ code: "IRR" },
	{ code: "IQD" },
	{ code: "ILS" },
	{ code: "ITL" },
	{ code: "JMD" },
	{ code: "JPY" },
	{ code: "JOD" },
	{ code: "KZT" },
	{ code: "KES" },
	{ code: "KWD" },
	{ code: "KGS" },
	{ code: "LAK" },
	{ code: "LVL" },
	{ code: "LBP" },
	{ code: "LSL" },
	{ code: "LRD" },
	{ code: "LYD" },
	{ code: "LTL" },
	{ code: "MOP" },
	{ code: "MKD" },
	{ code: "MGA" },
	{ code: "MWK" },
	{ code: "MYR" },
	{ code: "MVR" },
	{ code: "MRO" },
	{ code: "MUR" },
	{ code: "MXN" },
	{ code: "MDL" },
	{ code: "MNT" },
	{ code: "MAD" },
	{ code: "MZM" },
	{ code: "MMK" },
	{ code: "NAD" },
	{ code: "NPR" },
	{ code: "ANG" },
	{ code: "TWD" },
	{ code: "NZD" },
	{ code: "NIO" },
	{ code: "NGN" },
	{ code: "KPW" },
	{ code: "NOK" },
	{ code: "OMR" },
	{ code: "PKR" },
	{ code: "PAB" },
	{ code: "PGK" },
	{ code: "PYG" },
	{ code: "PEN" },
	{ code: "PHP" },
	{ code: "PLN" },
	{ code: "QAR" },
	{ code: "RON" },
	{ code: "RUB" },
	{ code: "RWF" },
	{ code: "SVC" },
	{ code: "WST" },
	{ code: "SAR" },
	{ code: "RSD" },
	{ code: "SCR" },
	{ code: "SLL" },
	{ code: "SGD" },
	{ code: "SKK" },
	{ code: "SBD" },
	{ code: "SOS" },
	{ code: "ZAR" },
	{ code: "KRW" },
	{ code: "XDR" },
	{ code: "LKR" },
	{ code: "SHP" },
	{ code: "SDG" },
	{ code: "SRD" },
	{ code: "SZL" },
	{ code: "SEK" },
	{ code: "CHF" },
	{ code: "SYP" },
	{ code: "STD" },
	{ code: "TJS" },
	{ code: "TZS" },
	{ code: "THB" },
	{ code: "TOP" },
	{ code: "TTD" },
	{ code: "TND" },
	{ code: "TRY" },
	{ code: "TMT" },
	{ code: "UGX" },
	{ code: "UAH" },
	{ code: "AED" },
	{ code: "UYU" },
	{ code: "USD" },
	{ code: "UZS" },
	{ code: "VUV" },
	{ code: "VEF" },
	{ code: "VND" },
	{ code: "YER" },
	{ code: "ZMK" },
];

export const convertToCSV = (data) => {
	const array = [Object.keys(data[0])].concat(data);
	return array
		.map((it) => {
			return Object.values(it)
				.map((field) => `"${field}"`)
				.join(",");
		})
		.join("\r\n");
};

export const formatDate = (dateStr) => {
	const date = new Date(dateStr);
	return (
		`${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}, ` +
		`${date.getHours() % 12 || 12}:${date
			.getMinutes()
			.toString()
			.padStart(2, "0")}:${date
			.getSeconds()
			.toString()
			.padStart(2, "0")} ` +
		`${date.getHours() >= 12 ? "PM" : "AM"}`
	);
};
