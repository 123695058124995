import { TimelineCreate } from "./timelineCreate";
import { TimelineEdit } from "./timelineEdit";
import { TimelineList } from "./timelineList";
import { TimelineShow } from "./timelineShow";

export default {
	create: TimelineCreate,
	edit: TimelineEdit,
	list: TimelineList,
	show: TimelineShow,
};
