const env = {
	prod: {
		baseUrl: "http://localhost:5000/api",
		socketBaseUrl: "http://localhost:5000/",
		//...
	},
	dev: {
		baseUrl: "https://stageapi.tuhoon.com/api/v1",
		socketBaseUrl: "https://stageapi.tuhoon.com/",
	},
};
export const envSettings = env.dev;
