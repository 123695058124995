import raEnglishMessages from "ra-language-english";
import { mergeTranslations } from "react-admin";

export const englishMessages = mergeTranslations(raEnglishMessages, {
	resources: {
		users: {
			name: "Users",
			fields: {
				username: "Email",
				id: "Id",
				roles: "Roles",
				firstName: "First Name",
				lastName: "Last Name",
				password: "Password",
				email: "Email",
				isActive: "Active Status",
				fullName: "Full Name",
			},
		},
		members: {
			name: "Members",
		},
		categories: {
			name: "Categories",
			fields: {
				titles: {
					en: "Title (English)",
					ar: "Title (Arabic)",
				},
				objectiveIds: "Objectives",
				id: "Id",
				index: "Index",
				isPublished: "Published Status",
				imageUrl: "Image",
			},
		},

		doctors: {
			name: "Doctors",
			fields: {
				fullName: {
					en: "Fullname (English)",
					ar: "Fullname (Arabic)",
				},
				description: {
					en: "Description (English)",
					ar: "Description (Arabic)",
				},
				imageUrl: "Profile Picture",
				currentIndex: "Current Index",
			},
		},

		journals: {
			name: "Journals",
			fields: {
				logoUrl: "Template Logo",
				name: {
					en: "Name (English)",
					ar: "Name (Arabic)",
				},
				isActive: "Active",
			},
		},

		surveys: {
			name: "Surveys",
			fields: {
				name: {
					en: "Name (English)",
					ar: "Name (Arabic)",
				},
				title: {
					en: "Title (English)",
					ar: "Title (Arabic)",
				},
				isActive: "Active",
				weight: "weight",
				questions: "Questions",
				answers: "Answers",
				allQuestions: "Questions",
			},
		},

		reminders: {
			name: "Reminders",
			fields: {
				message: "Messages",
				singleMessage: "Message",
				title: {
					en: "Title (English)",
					ar: "Title (Arabic)",
				},
				id: "Id",
				isActive: "Active Status",
			},
		},
		"day-portions": {
			name: "Day Portions",
			fields: {
				title: {
					en: "Title (English)",
					ar: "Title (Arabic)",
				},
				id: "Id",
				isActive: "Active Status",
			},
		},
		"daily-recommendations": {
			name: "Daily Recommendations",
			fields: {
				id: "Id",
				date: "Date",
			},
		},
		objectives: {
			name: "Objectives",
			titles: "Objectives",
			fields: {
				titles: {
					en: "Title (English)",
					ar: "Title (Arabic)",
				},
				descriptions: {
					en: "Description (English)",
					ar: "Description (Arabic)",
				},
				recommendationTitles: {
					en: "Recommendation Title (English)",
					ar: "Recommendation Title (Arabic)",
				},
				contentType: {
					titles: {
						en: "Content Type (English)",
						ar: "Content Type (Arabic)",
					},
				},
				id: "Id",
				displayIndex: "Display Index",
				status: "status",
				isActive: "Active Status",
				auditTracker: "Objective History",
			},
		},

		"communication-pages": {
			name: "Communication Pages",
			titles: "Communication Pages",
			title: "Communication Pages",
			fields: {
				id: "Id",
				language: {
					name: "Language",
				},
				entityType: "Type",
			},
		},

		"demo-requests": {
			name: "Call Requests",
			fields: {
				CreatedAt: "createdOn",
				fullName: "Full Name",
				email: "Email",
				country: "Country",
				companyName: "Company Name",
				jobDescription: "Job Description",
				purposeOfDemo: "Purpose of Call",
				enquiryDetails: "Enquiry Details",
			},
		},

		"promo-codes": {
			name: "Promo Codes",
			fields: {
				id: "id",
				code: "Code",
				percentage: "Percentage(%)",
				startDate: "Start Date",
				endDate: "End Date",
				usageCount: "Usage Count",
				userUsageLimit: "User Usage Limit",
				globalUsageLimit: "Global Usage Limit",
				isActive: "Active Status",
			},
		},

		businesses: {
			name: "Businesses",
			fields: {
				id: "id",
			},
		},

		"web-products": {
			name: "Web Products",
			fields: {
				id: "id",
				name: "Name",
				code: "Code",
				isActive: "Active Status",
			},
		},

		licenses: {
			name: "Licenses",
			fields: {
				id: "id",
				name: "Name",
				domain: "Domain",
				freeDays: "Days",
				contactEmail: "Contact email",
			},
		},

		"communication-flows": {
			name: "Communication Flows",
			titles: "Communication Flows",
			fields: {
				language: {
					name: "Language",
				},
				id: "Id",
			},
		},

		"user-suggestions": {
			name: "User Suggestions",
			fields: {
				"Related to": "Related To",
				Suggestion: "Suggestion",
			},
		},

		"content-types": {
			name: "Types",
			titles: "Types",
			fields: {
				titles: {
					en: "Title (English)",
					ar: "Title (Arabic)",
				},
				id: "Id",
			},
		},

		"sub-content-types": {
			name: "Sub Types",
			title: "Sub Types",
			fields: {
				title: {
					en: "Title (English)",
					ar: "Title (Arabic)",
				},
				id: "Id",
			},
		},

		"available-sections": {
			name: "Sections",
		},

		modules: {
			name: "Modules",
			fields: {
				name: "Name",
				imageUrl: "Image",
				id: "Id",
				status: "Status",
				maleImageUrl: "Male Image",
				femaleImageUrl: "Female Image",
				contentTypeId: "Content Type",
				contentTypeTitles: {
					en: "Content Type",
					ar: "Content Type",
				},
				language: {
					name: "Language",
				},
				isPublished: "Published Status",
				varients: {
					audioUrl: "Audio File",
				},
				auditTracker: "Module History",
			},
		},

		singles: {
			name: "Singles",
		},
		"background-modules": {
			name: "Background Modules",
			fields: {
				name: "Name",
				id: "Id",
				status: "Status",
				language: {
					name: "Language",
				},
				auditTracker: "Background Module History",
			},
		},
		playlists: {
			name: "Playlists",
			names: "Playlists",
			fields: {
				names: {
					en: "Name (English)",
					ar: "Name (Arabic)",
				},
				descriptions: {
					en: "Description (English)",
					ar: "Description (Arabic)",
				},
				objectiveIds: "Objectives",
				category: {
					titles: {
						en: "Category (English)",
						ar: "Category (Arabic)",
					},
				},
				modules: "Modules In This Playlist",
				contentTier: {
					name: "Content Tier",
				},
				backgroundPlaylist: {
					names: {
						en: "Background Playlist",
						ar: "Background Playlist",
					},
				},
				id: "Id",
				index: "Index",
				isPublished: "Published Status",
				imageUrl: "Image",
				auditTracker: "Playlist History",
			},
		},
		plans: {
			name: "Programs",
			names: "Programs",
			fields: {
				names: {
					en: "Name (English)",
					ar: "Name (Arabic)",
				},
				descriptions: {
					en: "Description (English)",
					ar: "Description (Arabic)",
				},
				category: {
					titles: {
						en: "Category (English)",
						ar: "Category (Arabic)",
					},
				},
				modules: "Modules In This Program",
				contentTier: {
					name: "Content Tier",
				},
				backgroundPlaylist: {
					names: {
						en: "Background Playlist (English)",
						ar: "Background Playlist (Arabic)",
					},
				},
				id: "Id",
				index: "Index",
				isPublished: "Published Status",
				imageUrl: "Image",
				auditTracker: "Program History",
			},
		},
		"lists-singles": {
			name: "Lists of Singles",
			names: "Lists of Singles",
			create: {
				title: "Create List Of Singles",
				success: "List of Singles created successfully ",
				fail: "Failed to create List of Singles",

				validationErrors: {},
			},
			fields: {
				names: {
					en: "Name (English)",
					ar: "Name (Arabic)",
				},
				descriptions: {
					en: "Description (English)",
					ar: "Description (Arabic)",
				},
				auditTracker: "List of Singles History",
				isPublished: "Published Status",
			},
			tabs: {
				basicInfo: {
					label: "Basic Information",
				},
				singlesManagement: {
					label: "Manage Singles",
				},
			},
			show: {
				title: "Show List Of Singles",
			},
			delete: {
				confirmTitle: "Delete List Of Singles",
			},
		},
		"background-playlists": {
			name: "Background Playlists",
			fields: {
				names: {
					en: "Name (English)",
					ar: "Name (Arabic)",
				},
				descriptions: {
					en: "Description (English)",
					ar: "Description (Arabic)",
				},
				category: {
					titles: {
						en: "Category (English)",
						ar: "Category (Arabic)",
					},
				},
				backgroundModules: "Background Modules",
				id: "Id",
				auditTracker: "Background Playlist History",
			},
		},
		subscriptions: {
			name: "Subscriptions",
			fields: {
				outcomes: {
					en: "Outcome (English)",
					ar: "Outcome (Arabic)",
				},
			},
		},
		tasks: {
			name: "Tasks",
			fields: {
				name: {
					en: "Name (English)",
					ar: "Name (Arabic)",
				},
			},
		},
		badges: {
			name: "Days",
			fields: {
				name: {
					en: "Name (English)",
					ar: "Name (Arabic)",
				},
			},
		},
		timelines: {
			name: "Pathways",
			fields: {
				name: {
					en: "Name (English)",
					ar: "Name (Arabic)",
				},
				description: {
					en: "Description (English)",
					ar: "Description (Arabic)",
				},
				subTitle: {
					en: "Sub Title (English)",
					ar: "Sub Title (Arabic)",
				},
				completionTitle: {
					en: "Completion Title (English)",
					ar: "Completion Title (Arabic)",
				},
				pushNotificationTitle: {
					en: "Push Notification Title (English)",
					ar: "Push Notification Title (Arabic)",
				},
				pushNotificationMessage: {
					en: "Push Notification Message (English)",
					ar: "Push Notification Message (Arabic)",
				},
				days: "Days To Finish",
			},
		},
		languages: {
			name: "Languages",
			fields: {
				names: {
					en: "English",
					ar: "Arabic",
				},
				id: "Id",
			},
		},
		"mood-tracker": {
			name: "Mood Tracker",

			fields: {
				titles: {
					ar: "Mood Tracker Title (Arabic)",
					en: "Mood Tracker Title (English)",
				},
				questions: {
					"ar(Male)": "Question Male (Arabic)",
					"ar(Female)": "Question Female (Arabic)",
					en: "Question (English)",
				},
				responseIllustration: "Response Illustration",
				value: "Value",
				isActive: "Active",
				id: "Response Id",
			},
		},
	},
});

export const CustomEnglishMessages = mergeTranslations(raEnglishMessages, {
	users: {
		create: {
			title: "Create New User Account",
			success: "User account created successfully",
			fail: "Failed to create user account",
			validationErrors: {},
		},
		edit: {
			title: "Edit User %{email}",
			success: "Successfully updated user account",
			fail: "Failed to update user account",
			validationErrors: {},
		},
		show: {
			title: "Show User: %{email}",
			isActive: "The user is %{status} active",
			isNotActive: "The user is not active",
		},
		delete: {
			confirmTitle: "Delete User Account",
		},
		changeStatus: {
			success: "Active status updated successfully",
			fail: "Failed to update active status",
		},
		validationErrors: {
			required: {
				firstName: "First name is required",
				lastName: "Last name is required",
				email: "Email is required",
				roles: "At least one role is required",
			},
			minMax: {
				email: "Email must be less than %{max}",
			},
		},
	},
	members: {
		create: {
			title: "Create New Member Account",
			success: "Member account created successfully",
			fail: "Failed to create member account",
			validationErrors: {},
		},
		edit: {
			title: "Edit Member",
			success: "Successfully updated member account",
			fail: "Failed to update member account",
			validationErrors: {},
		},
		show: {
			title: "Show Member",
			isActive: "The member is active",
			isNotActive: "The member is not active",
		},
		delete: {
			confirmTitle: "Delete Member Account",
		},
	},
	"day-portions": {
		create: {
			title: "Create New Day Portion",
			success: "Day portion created successfully",
			fail: "Failed to create day portion created",
			validationErrors: {},
		},
		edit: {
			title: "Edit Day Portion",
			success: "Successfully updated day portion",
			fail: "Failed to update day portion",
			validationErrors: {},
		},
		show: {
			title: "Show Day Portion",
		},
		delete: {
			confirmTitle: "Delete Day Portion",
		},
		changeStatus: {
			success: "Active status updated successfully",
			fail: "Failed to update active status",
		},
		validationErrors: {
			required: {
				firstName: "First name is required",
				lastName: "Last name is required",
				email: "Email is required",
				roles: "At least one role is required",
			},
			minMax: {
				email: "Email must be less than %{max}",
			},
		},
		tabs: {
			basicInfo: {
				label: "Basic Information",
			},
			singlesManagement: {
				label: "Manage Singles",
			},
			playlistsManagement: {
				label: "Manage Playlists",
			},
		},
	},

	"daily-recommendations": {
		title: "Title",
		create: {
			title: "Create New Daily Recommendation",
			success: " Daily recommendation created successfully",
			fail: "Failed to create daily recommendation created",
			validationErrors: {},
		},
		edit: {
			title: "Edit Daily Recommendation",
			success: "Successfully updated daily recommendation",
			fail: "Failed to update daily recommendation",
			validationErrors: {},
		},
		show: {
			title: "Show Daily Recommendation",
		},
		delete: {
			confirmTitle: "Delete Daily Recommendation",
		},
		validationErrors: {
			required: {
				date: "Date is required",
				title: "Title is required",
				minMax: "Name must be between 3 and 25 characters",
			},
		},
		tabs: {
			basicInfo: {
				label: "Basic Information",
			},
			singlesManagement: {
				label: "Manage Singles",
			},
			playlistsManagement: {
				label: "Manage Playlists",
			},
			plansManagement: {
				label: "Manage Programs",
			},
			singleListsManagement: {
				label: "Manage Single Lists",
			},
		},
	},

	sections: {
		edit: {
			success: "Sections updated successfully",
			fail: "Failed to update sections",
		},
	},

	featured: {
		name: "Featured Content",
		title: "Title",
		create: {
			title: "Create Featured Content",
			success: "Successfully created featured content",
			fail: "Failed to create featured content",
		},
		edit: {
			title: "Edit Featured Content",
			success: "Successfully updated featured content",
			fail: "Failed to update featured content",
		},
		tabs: {
			basicInfo: {
				label: "Basic Information",
			},
			singlesManagement: {
				label: "Manage Singles",
			},
			playlistsManagement: {
				label: "Manage Playlists",
			},
			plansManagement: {
				label: "Manage Programs",
			},
		},
		validationErrors: {
			required: {
				date: "Date is required",
				title: "Title is required",
				minMax: "Name must be between 3 and 25 characters",
			},
		},
	},

	categories: {
		create: {
			title: "Create Category",
			uploadImagePlaceholder: "Upload Category Image",
			success: "Category created successfully ",
			fail: "Failed to create category",
			validationErrors: {
				required: {
					imageUrl: "Image is required",
				},
			},
		},
		edit: {
			title: "Edit Category",
			newIndex: "New Index",
			currentIndex: "Current Index",
			uploadImagePlaceholder: "Upload new category image",
			success: "Successfully updated category",
			fail: "Failed to update category",
			validationErrors: {
				required: {
					index: "Index number is required",
				},
			},
		},
		show: {
			title: "Show Category",
		},
		delete: {
			confirmTitle: "Delete Category",
		},
		changeStatus: {
			success: "Published status updated successfully",
			fail: "Failed to update published status",
		},
		validationErrors: {},
		status: {
			published: "Published",
			notPublished: "Not Published",
		},
	},

	doctors: {
		create: {
			title: "Create Doctor",
			uploadImagePlaceholder: "Upload Profile Picture",
			success: "Doctor created successfully ",
			fail: "Failed to create doctor",
		},
		edit: {
			title: "Edit Doctor",
			newIndex: "New Index",
			currentIndex: "Current Index",
			uploadImagePlaceholder: "Upload new doctor profile picture",
			success: "Successfully updated doctor",
			fail: "Failed to update doctor",
			validationErrors: {
				required: {
					index: "Index number is required",
				},
			},
		},
		show: {
			title: "Show Doctor",
		},
		delete: {
			confirmTitle: "Delete Doctor",
		},
	},

	reminders: {
		create: {
			title: "Create Reminder",
			success: "Reminder created successfully ",
			fail: "Failed to create reminder",
		},
		edit: {
			title: "Edit Reminder",
			success: "Successfully updated reminder",
			fail: "Failed to update reminder",
		},
		show: {
			title: "Show Reminder",
			isActive: "The reminder is %{status} active",
		},
		changeStatus: {
			success: "Active status changed successfully",
			fail: "Failed to update active status",
		},
		validationErrors: {
			required: {
				isRequired: {
					title: "Title is required",
				},
			},
		},
	},

	objectives: {
		create: {
			title: "Create Objective",
			success: "Objective created successfully ",
			fail: "Failed to create Objective",
		},
		edit: {
			title: "Edit Objective",
			newDisplayIndex: "New Display Index",
			currentDisplayIndex: "Current Display Index",
			success: "Successfully updated objective",
			fail: "Failed to update objective",
			validationErrors: {
				required: {
					displayIndex: "Display index number is required",
				},
			},
		},
		show: {
			title: "Show Objective",
			isActive: "The objective is %{status} active",
		},
		tabs: {
			basicInfo: {
				label: "Basic Information",
			},
			outcomesManagement: {
				label: "Manage Outcomes",
			},
			plansManagement: {
				label: "Manage Programs",
			},
			contentTypesManagement: {
				label: "Manage Content Types",
			},
			timelinesManagement: {
				label: "Manage Timelines",
			},
			surveysManagement: {
				label: "Manage Survey",
			},
		},
		delete: {
			confirmTitle: "Delete Objective",
			confirmContent:
				"There is a playlist/category linked to this objective. Are you sure you want to unlink and delete this objective?",
		},
		changeStatus: {
			success: "Status updated successfully",
			fail: "Failed to update status",
		},
		validationErrors: {
			required: {
				contentTypeId: "Pick up a content type",
			},
		},
		status: {
			activated: "Activated",
			deactivated: "Deactivated",
		},
	},

	"user-suggestions": {
		show: {
			title: "Show User Suggestion",
		},
	},

	"content-types": {
		create: {
			title: "Create Content Type",
			success: "Content type created successfully",
			fail: "Failed to create content type",
			validationErrors: {},
		},
		edit: {
			title: "Edit Content Type",
			success: "Successfully updated content type",
			fail: "Failed to update content type",
			newIndex: "New Index",
			currentIndex: "Current Index",
			validationErrors: {},
		},
		show: {
			title: "Show Content Types",
		},
		delete: {
			confirmTitle: "Delete Content Type",
		},
		validationErrors: {},
	},

	"sub-content-types": {
		create: {
			title: "Create SubContent Type",
			success: "Sub content type created successfully",
			fail: "Failed to create sub content type",
			validationErrors: {},
		},
		edit: {
			title: "Edit Sub Content Type",
			success: "Successfully updated sub content type",
			fail: "Failed to update sub content type",
			validationErrors: {},
		},
		show: {
			title: "Show Sub Content Types",
		},
		delete: {
			confirmTitle: "Delete Sub Content Type",
		},
		validationErrors: {},
	},

	modules: {
		create: {
			title: "Create Module",
			uploadMaleImagePlaceholder: "Upload Male Module Image",
			maleImage: "Featured Image (Male)",
			femaleImage: "Featured Image (Female)",
			uploadFemaleImagePlaceholder: "Upload Female Module Image",
			uploadFileLabel: {
				true: "upload file with a narrator: %{narrator}",
				false: "upload file with a narrator: %{narrator} and a listener: %{listener}",
			},
			success: "Module created successfully ",
			fail: "Failed to create module",
			validationErrors: {
				required: {
					imageUrl: "Image is required",
				},
			},
		},
		edit: {
			title: "Edit Module",
			uploadImagePlaceholder: "Upload new module image",
			uploadFileLabel: {
				true: "upload file with a narrator: %{narrator}",
				false: "upload file with a narrator: %{narrator} and a listener: %{listener}",
			},
			success: "Successfully updated module",
			fail: "Failed to update module",
			validationErrors: {
				required: {},
			},
		},
		show: {
			title: "Show Module",
			maleImage: "Featured Image (Male)",
			femaleImage: "Featured Image (Female)",
		},
		tabs: {
			basicInfo: {
				label: "Basic Information",
			},
			typesManagement: {
				label: "Manage Types",
			},
			doctorsManagement: {
				label: "Manage Doctors",
			},
		},
		delete: {
			confirmTitle: "Delete Module",
		},
		list: {
			language: {
				isGenderNeutral: {
					urlTitle: "Audio file with narrator: %{narratorGender}",
				},
				isNotGenderNeutral: {
					urlTitle:
						"Audio file with narrator: %{narratorGender} and listener: %{listenerGender}",
				},
			},
			maleImage: "Featured Image (Male)",
			femaleImage: "Featured Image (Female)",
		},
		changeStatus: {
			success: "Published status updated successfully",
			fail: "Failed to update published status",
		},
		validationErrors: {
			required: {
				isRequired: {
					contentType: "Please pick a content type",
					name: "Name is required",
					description: "Description is required",
					title: "Title is required",
					writer: "Writer's name is required",
					narrator: "Narrator's name is required",
					allowedUserTypes: "Allowed user type is required",
				},
			},
			inCorrectFormat: "The file must be an mp3 file",
			notAVideo: "The file must be an mp4 file",
		},
		status: {
			PUBLISHED: "Published",
			UNPUBLISHED: "Not Published",
			PENDING: "Pending",
			REJECTED: "Rejected",
		},
	},

	"communication-pages": {
		create: {
			title: "Create Communication Page",
			uploadImagePlaceholder: "Upload Communication Page Illustration",
			success: "Communication page created successfully ",
			fail: "Failed to create Communication page",

			validationErrors: {},
		},
		edit: {
			title: "Edit Communication page",
			uploadImagePlaceholder:
				"Upload New Communication Page Illustration",
			success: "Communication page updated successfully",
			fail: "Failed to update Communication page",
			validationErrors: {},
		},
		show: {
			title: "Show Communication Page",
		},
		delete: {
			confirmTitle: "Delete Communication Page",
			confirmContent:
				"Are you sure you want to delete this communication page",
		},
		language: {
			name: "Language",
		},
		validationErrors: {
			required: {},
		},
		status: {
			published: "Published",
			notPublished: "Not Published",
		},
		tabs: {
			basicInfo: {
				label: "Basic Information",
			},
			questionDetails: {
				label: "Question Details",
			},
		},
	},

	subscriptions: {
		create: {
			title: "Create Subscription",
			maleImage: "Featured Image (Male)",
			femaleImage: "Featured Image (Female)",
			uploadMaleImagePlaceholder: "Upload Male Subscription Image",
			uploadFemaleImagePlaceholder: "Upload Female Subscription Image",
			uploadFileLabel: {
				true: "upload file with a narrator: %{narrator}",
				false: "upload file with a narrator: %{narrator} and a listener: %{listener}",
			},
			success: "Subscription created successfully ",
			fail: "Failed to create subscription",
			validationErrors: {
				required: {
					imageUrl: "Image is required",
				},
			},
		},
		edit: {
			title: "Edit Subscription",
			uploadFileLabel: {
				true: "upload file with a narrator: %{narrator}",
				false: "upload file with a narrator: %{narrator} and a listener: %{listener}",
			},
			uploadMaleImagePlaceholder: "Upload Male Subscription Image",
			uploadFemaleImagePlaceholder: "Upload Female Subscription Image",
			success: "Successfully updated subscription",
			fail: "Failed to update subscription",
			validationErrors: {
				required: {},
			},
		},
		show: {
			title: "Show Subscription",
			maleImage: "Featured Image (Male)",
			femaleImage: "Featured Image (Female)",
		},
		delete: {
			confirmTitle: "Delete Subscription",
		},
		list: {
			maleImage: "Featured Image (Male)",
			femaleImage: "Featured Image (Female)",
		},
		changeStatus: {
			success: "Published status updated successfully",
			fail: "Failed to update published status",
		},
		validationErrors: {
			required: {
				isRequired: {
					contentType: "Please pick a content type",
					name: "Name is required",
				},
			},
			inCorrectFormat: "The file must be an mp3 file",
			notAVideo: "The file must be an mp4 file",
		},
		status: {
			PUBLISHED: "Published",
			UNPUBLISHED: "Not Published",
			PENDING: "Pending",
			REJECTED: "Rejected",
		},
	},

	"background-modules": {
		create: {
			title: "Create Background Module",
			audioFile: "Background Audio",
			success: "Background module created successfully ",
			fail: "Failed to create background module",
			validationErrors: {
				required: {
					audioUrl: "Audio is required",
				},
			},
		},
		edit: {
			title: "Edit Background Module",
			currentAudioFile: "Current Audio",
			audioFile: "Audio",
			success: "Successfully updated background module",
			fail: "Failed to update background module",
			validationErrors: {},
		},
		show: {
			title: "Show Background Module",
			audioFile: "Audio",
		},
		delete: {
			confirmTitle: "Delete Background Module",
		},
		changeStatus: {
			success: "Status updated successfully",
			fail: "Failed to update status",
		},
		validationErrors: {
			required: {
				isRequired: {
					name: "Name is required",
				},
			},
			inCorrectFormat: "The file must be an mp3 file",
			notAVideo: "The file must be an mp4 file",
		},
		status: {
			published: "Published",
			notPublished: "Not Published",
		},
	},

	tasks: {
		create: {
			title: "Create Task",
			success: "Task created successfully ",
			fail: "Failed to create task",
		},
		edit: {
			title: "Edit Task",
			success: "Successfully updated task",
			fail: "Failed to update task",
			validationErrors: {},
		},
		show: {
			title: "Show Task",
		},
		delete: {
			confirmTitle: "Delete Task",
		},
	},

	badges: {
		create: {
			title: "Create Day",
			success: "Day created successfully ",
			fail: "Failed to create day",
		},
		edit: {
			title: "Edit Day",
			success: "Successfully updated day",
			fail: "Failed to update day",
			validationErrors: {},
		},
		show: {
			title: "Show Day",
		},
		delete: {
			confirmTitle: "Delete Day",
		},
	},

	timelines: {
		create: {
			title: "Create Timeline",
			success: "Timeline created successfully ",
			fail: "Failed to create timeline",
		},
		edit: {
			title: "Edit Timeline",
			success: "Successfully updated timeline",
			fail: "Failed to update timeline",
			validationErrors: {},
		},
		show: {
			title: "Show Timeline",
		},
		delete: {
			confirmTitle: "Delete Timeline",
		},
	},

	playlists: {
		create: {
			title: "Create Playlist",
			uploadPlaylistImagePlaceholder:
				"Upload Playlist Image In Playlist Page",
			uploadHomeImagePlaceholder: "Upload Playlist Image In Home Page",
			success: "Playlist created successfully ",
			fail: "Failed to create Playlist",

			validationErrors: {},
		},
		edit: {
			title: "Edit Playlist",
			newTags: "Replace Tags",
			currentTags: "Current Tags",
			uploadPlaylistImagePlaceholder:
				"Upload Playlist Image In Playlist Page",
			uploadHomeImagePlaceholder: "Upload Playlist Image In Home Page",
			success: "Playlist updated successfully",
			fail: "Failed to update playlist",
			validationErrors: {},
		},
		show: {
			title: "Show Playlist",
		},
		delete: {
			confirmTitle: "Delete Playlist",
		},
		changeStatus: {
			success: "Published status updated successfully",
			fail: "Failed to update published status",
		},
		validationErrors: {
			required: {
				contentTierId: "Pick up a content tier",
				categoryId: "Pick up a category",
			},
		},
		status: {
			published: "Published",
			notPublished: "Not Published",
		},
		tabs: {
			basicInfo: {
				label: "Basic Information",
			},
			modulesManagement: {
				label: "Manage Modules",
			},
			objectivesManagement: {
				label: "Manage Objectives",
			},
			typesManagement: {
				label: "Manage Types",
			},
		},
	},

	plans: {
		create: {
			title: "Create Program",
			uploadImagePlaceholder: "Upload Program Image",
			success: "Program created successfully ",
			fail: "Failed to create Program",

			validationErrors: {},
		},
		edit: {
			title: "Edit Program",
			newTags: "Replace Tags",
			currentTags: "Current Tags",
			uploadImagePlaceholder: "Upload new program image",
			success: "Program updated successfully",
			fail: "Failed to update program",
			validationErrors: {},
		},
		show: {
			title: "Show Program",
		},
		delete: {
			confirmTitle: "Delete Program",
		},
		changeStatus: {
			success: "Published status updated successfully",
			fail: "Failed to update published status",
		},
		validationErrors: {
			required: {
				contentTierId: "Pick up a content tier",
				categoryId: "Pick up a category",
			},
		},
		status: {
			published: "Published",
			notPublished: "Not Published",
		},
		tabs: {
			basicInfo: {
				label: "Basic Information",
			},
			modulesManagement: {
				label: "Manage Modules",
			},
			typesManagement: {
				label: "Manage Types",
			},
		},
	},

	journals: {
		create: {
			title: "Create Journal",
			success: "Journal created successfully ",
			fail: "Failed to create journal",

			validationErrors: {},
		},
		edit: {
			title: "Edit Journal",
			success: "Journal updated successfully",
			fail: "Failed to update journal",
			validationErrors: {},
		},
		show: {
			title: "Show Journal",
			isActive: "The journal is %{status} active",
		},
		delete: {
			confirmTitle: "Delete Journal",
		},
		changeStatus: {
			success: "Active status updated successfully",
			fail: "Failed to update active status",
		},
		validationErrors: {},
		tabs: {
			basicInfo: {
				label: "Basic Information",
			},
			questions: {
				label: "Manage Questions",
			},
		},
	},

	"lists-singles": {
		create: {
			title: "Create List Of Singles",
			success: "List Of Singles created successfully ",
			fail: "Failed to create List Of Singles",

			validationErrors: {},
		},
		edit: {
			title: "Edit List Of Singles",
			newTags: "Replace Tags",
			currentTags: "Current Tags",
			success: "List Of Singles updated successfully",
			fail: "Failed to update List Of Singles",
			validationErrors: {},
		},
		show: {
			title: "Show List Of Singles",
		},
		delete: {
			confirmTitle: "Delete List Of Singles",
		},
		changeStatus: {
			success: "Published status updated successfully",
			fail: "Failed to update published status",
		},
		validationErrors: {
			required: {
				contentTierId: "Pick up a content tier",
				categoryId: "Pick up a category",
			},
		},
		status: {
			published: "Published",
			notPublished: "Not Published",
		},
		tabs: {
			basicInfo: {
				label: "Basic Information",
			},
			singlesManagement: {
				label: "Manage Singles",
			},
		},
	},

	"promo-codes": {
		create: {
			success: "Promo Code is created successfully!",
			fail: "Failed to create a promo code!",
		},
		delete: {
			confirmTitle: "Delete Promo Code",
		},
		edit: {
			success: "Promo Code is updated successfully!",
			fail: "Failed to update the promo code!",
		},
		validationErrors: {
			required: {
				code: "Code is required",
				percentage: "Percentage is required",
				numberOfFreeDays: "Number Of Free Days is required",
				startDate: "Start date is required",
				endDate: "End date is required",
				usageCount: "Usage count is required",
				userUsageLimit: "User usage limit is required",
				globalUsageLimit: "Global usage limit is required",
			},
			logic: {
				endDate: "End date can't be before start date!",
			},
			minMax: {
				code: "Code must be between %{min} and %{max} characters",
				percentage: "Percentage must be between %{min} and %{max}",
			},
			value: {
				code: "Code must not contain any URL reserved characters",
				percentage: "Percentage must be a whole number",
				numberOfFreeDays: "Number Of Free Days must be a whole number",
				userUsageLimit: "User usage limit must be a whole number",
				globalUsageLimit: "Global usage limit must be a whole number",
			},
		},
	},

	businesses: {
		create: {
			title: "Create Business",
			success: "Business created successfully ",
			fail: "Failed to create business",
		},
		edit: {
			title: "Edit Business",
			success: "Business updated successfully",
			fail: "Failed to update business",
		},
		show: {
			title: "Show Business",
		},
		tabs: {
			basicInfo: {
				label: "Basic Information",
			},
			modulesManagement: {
				label: "Manage Modules",
			},
		},
		changeKeys: {
			success: "Key pairs are updated successfully",
			fail: "Failed to update the key pairs",
		},
		validationErrors: {
			required: {
				name: "Name is required",
				validFrom: "Valid From date is required",
				validTo: "Valid To date is required",
			},
			logic: {
				validTo: "End date can't be before start date!",
				invalid: "Dates can't be earlier than now!",
			},
			minMax: {
				name: "Name must be between %{min} and %{max} characters",
			},
			value: {
				code: "Code must not contain any URL reserved characters",
				percentage: "Percentage must be a whole number",
				numberOfFreeDays: "Number Of Free Days must be a whole number",
				userUsageLimit: "User usage limit must be a whole number",
				globalUsageLimit: "Global usage limit must be a whole number",
			},
		},
	},

	"web-products": {
		create: {
			title: "Create Web Product",
			success: "Web Product created successfully ",
			fail: "Failed to create business",
		},
		list: {
			title: "Web Products",
		},
		edit: {
			title: "Edit Web Product",
			success: "Web Product updated successfully",
			fail: "Failed to update business",
		},
		show: {
			title: "Show Web Product",
		},
		changeStatus: {
			success: "Active status updated successfully",
			fail: "Failed to update active status",
		},
		validationErrors: {
			required: {
				name: "Name is required",
				currency: "currency is required",
				price: "price is required",
			},
		},
	},

	licenses: {
		create: {
			title: "Create License",
			success: "License created successfully ",
			fail: "Failed to create license",
		},
		edit: {
			title: "Edit License",
			success: "License updated successfully",
			fail: "Failed to update license",
		},
		show: {
			title: "Show License",
		},
		delete: {
			confirmTitle: "Delete License",
		},
		tabs: {
			basicInfo: {
				label: "Basic Information",
			},
		},
		validationErrors: {
			required: {
				name: "Name is required",
				domain: "Domain is required",
				promoCode: "Promo Code is required",
				startDate: "Start date is required",
				endDate: "End date is required",
			},
			logic: {
				endDate: "End date can't be before start date!",
			},
			minMax: {
				name: "Name must be between %{min} and %{max} characters",
				domain: "Domain must be between %{min} and %{max} characters",
			},
		},
	},

	"plan-communication": {
		language: {
			name: "Language",
		},
		communicationFlow: {
			name: "Flow Name",
		},
	},

	"communication-flows": {
		communicationFlow: {
			name: "Flow Name",
		},
		create: {
			title: "Create Communication Flow",
		},
		edit: {
			title: "Edit Communication Flow",
		},
		tabs: {
			basicInfo: {
				label: "Basic Information",
			},
			pagesManagement: {
				label: "Manage Pages",
			},
		},
		delete: {
			confirmTitle: "Delete Communication Flow",
		},
	},

	"background-playlists": {
		create: {
			title: "Create Background Playlist",
			name: "Name",
			description: "Description",
			success: "Background playlist created successfully ",
			validationErrors: {},
		},
		edit: {
			title: "Edit Background Playlist",
			success: "Background playlist updated successfully",
			fail: "Failed to update background playlist",
			validationErrors: {
				required: {},
			},
		},
		show: {
			title: "Show Background Playlist",
		},
		delete: {
			confirmTitle: "Delete Background Playlist",
		},
		changeStatus: {
			success: "Published status updated successfully",
			fail: "Failed to update published status",
		},
		validationErrors: {
			required: {
				categoryId: "Pick up a category",
			},
		},
		status: {
			published: "Published",
			notPublished: "Not Published",
		},
	},

	validationErrors: {
		required: {
			oneIsRequired: "At least one of the fields is required",
			requiredField: "%{field} is required",
			bothLanguagesRequired: "Both languages are required",
		},
		minMax: {
			titles: "Title must be between %{min} and %{max} characters",
			fullName: "Full name must be between %{min} and %{max} characters",
			greeting: "Greeting must be between %{min} and %{max} characters",
			recommendationTitles:
				"Recommendation title must be between %{min} and %{max} characters",
			firstName:
				"First name must be between %{min} and %{max} characters",
			email: "Email must be between %{min} and %{max} characters",
			phone: "Phone number must be between %{min} and %{max} characters",
			lastName: "Last name must be between %{min} and %{max} characters",
			names: "Name must be between %{min} and %{max} characters",
			socialCaption:
				"Social caption must be between %{min} and %{max} characters",
			descriptions:
				"Description must be between %{min} and %{max} characters",
			name: "Name must be between %{min} and %{max} characters",
			title: "Title must be between %{min} and %{max} characters",
			message: "Message must be between %{min} and %{max} characters",
			password: "Password must be between %{min} and %{max} characters",
			confirmPassword:
				"Password Confirmation must be between %{min} and %{max} characters",
			comment: "Comment must be between %{min} and %{max} characters",
			questions: "Question must be between %{min} and %{max} characters",
			responses: "Response must be between %{min} and %{max} characters",
			notes: "Note must be between %{min} and %{max} characters",
		},
	},
	permissions: {
		notAllowed: "The right permissions are missing",
	},

	empty: {
		variant: "There is no audio files yet!",
		notifications: "No notifications.",
		playlist: "This playlist is empty",
		module: "No Modules",
		plan: "This program is empty",
		objectives: "No objectives.",
		history: "The history is empty",
		contentType: "The content type is empty",
		doctor: "The doctor is empty",
	},

	misc: {
		names: {
			languages: {
				EN: "English",
				AR: "Arabic",
			},
			genders: {
				female: "female",
				male: "male",
			},
		},
		statuses: {
			REJECTED: "rejected",
			PUBLISHED: "published",
			PENDING: "pending",
			UNPUBLISHED: "unpublished",
		},
	},

	buttons: {
		search: "search",
		refresh: "refresh",
		next: "next",
		signIn: "Sign In",
		delete: "Delete",
		confirm: "confirm",
		cancel: "cancel",
		reject: "reject",
		publish: "publish",
		unPublish: "unpublish",
	},

	messages: {
		loading: "loading",
		confirmDialog: {
			notDeletable: {
				title: "You can't delete this record",
				content:
					"Remove the resources using this record before attempting to delete it.",
			},
			changePublishStatus: {
				title: "Confirm publish status change",
				content: "Please confirm publish status change or click cancel",
			},
			changeStatus: {
				title: "Confirm status change",
				content:
					"Please confirm status change from %{oldStatus} to %{newStatus} or click cancel",
			},
		},
	},

	labels: {
		inputs: {
			search: "Search",
			date: "Calendar",
			comment: "Comment",
		},
	},

	tooltips: {
		actions: {
			clear: "Clear %{name}",
			show: "Show %{name}",
			hide: "Hide %{name}",
		},
		icons: {
			important: "important !!",
		},
	},

	dnd: {
		helperText:
			"Drag and drop to create or reorder items in the list or to remove an item from the list",
		selectedSource: "Selected %{source}",
		availableSource: "Available %{source}",
	},

	sort: {
		helperText:
			"The items will be shown in this order in the app, feel free to reorder them.",
		availableSource: "Available %{source}",
	},

	notify: {
		notifyFormErrors:
			"Please check the form for errors or contact the admin",
	},

	ra: {
		action: {
			move_up: "Move up",
			move_down: "Move down",
		},
	},

	"mood-tracker": {
		create: {
			title: "Create Mood Tracker",
			success: "Mood tracker created successfully",
			fail: "Failed to create mood tracker",
		},
		edit: {
			title: "Edit Mood Tracker",
			success: "Mood Tracker updated successfully",
			fail: "Failed to update Mood Tracker",
		},
		delete: {
			confirmTitle: "Delete Mood Tracker",
		},
		show: {
			title: "Show Mood Tracker",
			isActive: "The mood tracker is %{status} active",
		},
		tabs: {
			basicInfo: "Basic Information",
			responses: "Responses",
		},
	},

	surveys: {
		create: {
			title: "Create Survey",
			success: "Survey created successfully",
			fail: "Failed to create survey",
		},
		edit: {
			title: "Edit Survey",
			success: "Survey updated successfully",
			fail: "Failed to update Survey",
		},
		delete: {
			confirmTitle: "Delete Survey",
		},
		show: {
			title: "Show Survey",
			isActive: "The survey is %{status} active",
		},
		changeStatus: {
			success: "Active status updated successfully",
			fail: "Failed to update active status",
		},
		tabs: {
			basicInfo: "Basic Information",
			questions: "Manage Questions",
		},
	},

	hr: {
		resources: {
			categories: {
				create: {
					title: "Create category for HR resources",
					success: "category for HR resources created successfully",
					uploadImagePlaceholder:
						"Cover image for content of category",
				},
				edit: {
					title: "Edit category for HR resources",
					success: "category for HR resources updated successfully",
					uploadImagePlaceholder:
						"Cover image for content of category",
				},
			},

			content: {
				create: {
					title: "Create content for HR resources",
					success: "content for HR resources created successfully",
					uploadContentPlaceholder: "content for HR resources",
				},
				edit: {
					title: "Edit content for HR resources",
					success: "content for HR resources updated successfully",
					uploadContentPlaceholder: "content for HR resources",
				},
			},
		},
	},
});
