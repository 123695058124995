import React from "react";
import {
	ArrayField,
	BooleanInput,
	ChipField,
	Datagrid,
	EditButton,
	Filter,
	List,
	SelectInput,
	ShowButton,
	SingleFieldList,
	TextField,
	TextInput,
} from "react-admin";

import { CustomBooleanInput } from "../../customComponents/CustomBooleanInput";
import { CustomDeleteButtonWithConfirmation } from "../../customComponents/CustomButtons";
import { constants, rolesChoicesArr } from "../../utils/constants";
import { useTranslate } from "ra-core";

const UserFilter = ({ ...props }) => (
	<Filter {...props}>
		<TextInput source="search" alwaysOn />
		<BooleanInput source="isActive" />
		<SelectInput source="role" choices={rolesChoicesArr} />
	</Filter>
);

export const UserList = (props) => {
	const translate = useTranslate();
	return (
		<List
			{...props}
			perPage={constants.perPage}
			bulkActionButtons={false}
			filters={<UserFilter />}
		>
			<Datagrid>
				<TextField source="fullName" />
				<TextField source="email" />
				<ArrayField source="roles" sortable={false}>
					<SingleFieldList linkType={false}>
						<ChipField source="role" />
					</SingleFieldList>
				</ArrayField>
				<CustomBooleanInput source="isActive" sortable={false} />
				<ShowButton />
				<EditButton />
				<CustomDeleteButtonWithConfirmation
					confirmTitle={translate(`users.delete.confirmTitle`)}
				/>
			</Datagrid>
		</List>
	);
};
