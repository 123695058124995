import { useEffect, useMemo, useState, Fragment } from "react";
import keyBy from "lodash/keyBy";
import { SVGViewer } from "../../customComponents/CustomSVGViewer";
import CustomChipsArrayInput from "../../customComponents/CustomChipsArrayInput";

import {
	AutocompleteInput,
	Edit,
	FormTab,
	FileInput,
	FileField,
	SelectInput,
	TabbedForm,
	TextInput,
	TranslatableInputs,
	BooleanInput,
	useGetList,
	useNotify,
	useRedirect,
	useTranslate,
	useGetOne,
	useUpdate,
	EditButton,
	RadioButtonGroupInput,
} from "react-admin";
import * as React from "react";
import { Datagrid, TextField } from "react-admin";
import { getChoices } from "./shared";
import { useHistory } from "react-router-dom";
import { CustomTransferList } from "../../customComponents/CustomTransferList";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { CustomContentTypeArrayInput } from "../../customComponents/CustomContentTypeArrayInput";
import { validatePlanData } from "./planValidate";
import {
	setIndexesForData,
	transformToFormData,
} from "../../utils/customFunctions";

const CustomColumn = ({ record, handleClick, planId }) => {
	const history = useHistory();
	return (
		<EditButton
			label="Manage"
			onClick={() =>
				history.push({
					pathname: `/plan-communications/${planId}/${record.id}`,
				})
			}
		/>
	);
};

const Custom = ({ record, handleClick }) => {
	return (
		<Fragment>
			<Datagrid
				data={keyBy(record.modules, "id")}
				ids={record?.modules?.map(({ id }) => id)}
				currentSort={"id"}
				setSort={("id", "ASC")}
			>
				<TextField source="index" />
				<TextField source="name" label="Module Name" />
				<CustomColumn
					handleClick={handleClick}
					label=" Communications"
					planId={record.id}
				/>
			</Datagrid>
		</Fragment>
	);
};

export const PlanEdit = (props) => {
	const [backgroundPlaylistsArray, setBackgroundPlaylistsArray] = useState(
		[]
	);
	const [categoriesArray, setCategoriesArray] = useState([]);
	const [languagesData, setLanguagesData] = useState([]);
	const [currentModule, setCurrentModule] = useState({});
	const [communicationFlowId, setCommunicationFlowId] = useState(null);
	const [communications, setCommunications] = useState([]);
	const [open, setOpen] = useState(false);
	const [lang, setLanguage] = useState(2);
	const [currentType, setCurrentType] = useState(null);
	const [gender, setGender] = useState("MALE");
	const [communicationFlowsArray, setCommunicationFlowsArray] = useState([]);

	const [update] = useUpdate("plans", props.id);
	const translate = useTranslate();
	const redirect = useRedirect();
	const notify = useNotify();

	const { data: backgroundPlaylistsData } = useGetList(
		"background-playlists"
	);
	const { data: plan } = useGetOne("plans", props.id);

	const { data: categoriesData } = useGetList("categories");

	const { data: languagesDataObj } = useGetList(
		"languages",
		{ page: 1, perPage: 10 },
		{ field: "id", order: "DESC" }
	);
	useEffect(() => {
		setLanguagesData(getChoices(languagesDataObj));
	}, [languagesDataObj]);

	const { data: communicationFlowsData, loading } = useGetList(
		"communication-flows"
	);

	const handleClick = (id, planModuleId, type) => {
		setCurrentType(type);
		setCurrentModule({ id, planModuleId, type });
		setOpen(true);
	};

	const onSuccess = () => {
		notify(translate("plans.edit.success"), "info");
		redirect("/plans");
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);
	useEffect(() => {
		if (!loading) {
			let temp = Object.values(communicationFlowsData).map(
				({ id, name, gender, languageId }) => ({
					id,
					name,
					gender,
					languageId,
				})
			);
			if (lang != null)
				temp = temp.filter(
					(communicationFlow) => communicationFlow.languageId == lang
				);
			if (gender != null)
				temp = temp.filter(
					(communicationFlow) => communicationFlow.gender == gender
				);
			setCommunicationFlowsArray(temp);
			setCommunicationFlowId(temp[0]?.id);
		}
	}, [loading, lang, gender]);

	useEffect(() => {
		setCategoriesArray(
			Object.values(categoriesData).map(({ id, titles }) => ({
				id,
				name: titles[defaultLocale],
			}))
		);
	}, [categoriesData]);

	useEffect(() => {
		setBackgroundPlaylistsArray(
			Object.values(backgroundPlaylistsData).map(({ id, names }) => ({
				id,
				name: names[defaultLocale],
			}))
		);
	}, [backgroundPlaylistsData]);

	return (
		<>
			{loading ? (
				<center>
					<h1>Loading...</h1>
				</center>
			) : (
				<Edit {...props} title={translate("plans.edit.title")}>
					<TabbedForm
						submitOnEnter={false}
						toolbar={<CustomToolbar />}
						save={(data) => {
							let indexedDataToSend = setIndexesForData(data, [
								"modules",
							]);
							indexedDataToSend = {
								...indexedDataToSend,
								communications,
							};
							const transformed =
								transformToFormData(indexedDataToSend);
							update(
								{ payload: { data: transformed } },
								{
									onSuccess,
									onFailure,
								}
							);
						}}
						validate={validatePlanData}
						syncWithLocation={false}
					>
						<FormTab
							label={translate("plans.tabs.basicInfo.label")}
						>
							<TranslatableInputs
								locales={["en", "ar"]}
								defaultLocale={defaultLocale}
							>
								<TextInput source="names" />
								<TextInput multiline source="descriptions" />
							</TranslatableInputs>
							<AutocompleteInput
								resettable
								source="backgroundPlaylistId"
								choices={backgroundPlaylistsArray}
							/>
							<SelectInput
								source="categoryId"
								choices={categoriesArray}
							/>
							<RadioButtonGroupInput
								source="languageId"
								choices={languagesData}
								optionText={({ name }) =>
									`misc.names.languages.${name}`
								}
								optionValue="id"
							/>
							<BooleanInput
								defaultValue={false}
								source="comingSoon"
							/>
						</FormTab>
						<FormTab label={"Manage Images"}>
							{plan?.maleSvg &&
								SVGViewer({
									svg: plan.maleSvg,
									title: "Male SVG",
								})}
							<FileInput
								source="maleImage"
								label="Male Illustration"
								accept="image/*"
								placeholder={"Upload Male Plan Illustration"}
							>
								<FileField source="src" title="Illustration" />
							</FileInput>
							{plan?.femaleSvg &&
								SVGViewer({
									svg: plan.femaleSvg,
									title: "Female SVG",
								})}
							<FileInput
								source="femaleImage"
								label="Female Illustration"
								accept="image/*"
								placeholder={"Upload Female Plan Illustration"}
							>
								<FileField source="src" title="Illustration" />
							</FileInput>
							{plan?.planPageSvg &&
								SVGViewer({
									svg: plan.planPageSvg,
									title: "Plans Page SVG",
								})}
							<FileInput
								source="planPageImage"
								label="Plan Page Illustration"
								accept="image/*"
								placeholder={"Upload Plan Page Illustration"}
							>
								<FileField source="src" title="Illustration" />
							</FileInput>
						</FormTab>
						<FormTab
							label={translate(
								"plans.tabs.modulesManagement.label"
							)}
						>
							<CustomTransferList
								source="modules"
								referenceSource="modules"
								referenceResource="modules"
								referenceField="name"
								isReferenceTranslatable={false}
							/>
						</FormTab>
						<FormTab label="Manage Communications">
							<Custom handleClick={handleClick} />
						</FormTab>
						<FormTab
							label={translate(
								"plans.tabs.typesManagement.label"
							)}
						>
							<CustomContentTypeArrayInput />
						</FormTab>
					</TabbedForm>
				</Edit>
			)}
		</>
	);
};
