import { FeaturedCreate } from "./featuredCreate";
import { FeaturedEdit } from "./featuredEdit";
import { FeaturedList } from "./featuredList";
import { FeaturedShow } from "./featuredShow";

export default {
	create: FeaturedCreate,
	edit: FeaturedEdit,
	list: FeaturedList,
	show: FeaturedShow,
};
