import {
	Edit,
	TabbedForm,
	TextInput,
	TranslatableInputs,
	useNotify,
	useRedirect,
	FormTab,
	useUpdate,
	useTranslate,
	useRefresh,
	useGetOne,
} from "react-admin";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { validateSubContentTypeData } from "./subContentTypeValidate";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { CustomSortList } from "../../customComponents/CustomSortList";
import { setIndexesForData } from "../../utils/customFunctions";

const SortList = ({ record: { singles } }) => (
	<CustomSortList
		source="singles"
		referenceSource="singles"
		referenceResource="singles"
		referenceField="name"
		values={singles}
		isReferenceTranslatable={false}
	/>
);

export const SubContentTypeEdit = ({ ...props }) => {
	const redirect = useRedirect();
	const refresh = useRefresh();
	const translate = useTranslate();
	const notify = useNotify();
	const [update] = useUpdate("sub-content-types", props.id);
	const { data, loading } = useGetOne("sub-content-types", props.id);

	const onSuccess = () => {
		notify("sub-content-types.edit.success", "info");
		redirect("/sub-content-types");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	return (
		<>
			{loading ? (
				<center>
					<h1>Loading...</h1>
				</center>
			) : (
				<Edit
					title="Edit Sub Content Type"
					{...props}
					mutationMode="pessimistic"
					onSuccess={onSuccess}
					onFailure={onFailure}
				>
					<TabbedForm
						validate={validateSubContentTypeData}
						save={(data) => {
							data = setIndexesForData(data, ["singles"]);
							update(
								{
									payload: {
										data,
									},
								},
								{
									onSuccess,
									onFailure,
								}
							);
						}}
						toolbar={<CustomToolbar />}
					>
						<FormTab
							label={translate("plans.tabs.basicInfo.label")}
						>
							<TranslatableInputs
								locales={userPreferences.translationLanguages}
								defaultLocale={
									userPreferences.defaultTranslationLanguage
								}
							>
								<TextInput source="title" />
							</TranslatableInputs>
						</FormTab>
						<FormTab label={"Re-order Singles"}>
							<SortList data={data} />
						</FormTab>
					</TabbedForm>
				</Edit>
			)}
		</>
	);
};

export default SubContentTypeEdit;
