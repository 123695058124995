import { isNotInRange } from "../../utils/customFunctions";
import { taskEvents } from "../../utils/constants";

export const validateTaskData = ({
	name,
	taskEventAction,
	singleId = null,
	journalId = null,
}) => {
	const errors = {};
	if (!name) {
		errors.name = {
			message: "validationErrors.required.requiredField",
			args: { field: "Name" },
		};
	}

	if (name && isNotInRange({ min: 3, max: 50, str: name })) {
		errors.name = {
			message: "validationErrors.minMax.name",
			args: { min: 3, max: 50 },
		};
	}

	if (!taskEventAction) {
		errors.taskEventAction = {
			message: "validationErrors.required.requiredField",
			args: { field: "Task Event" },
		};
	}
	if (taskEventAction === taskEvents.listenToSingle && !singleId)
		errors.singleId = "Selecting Module is required!";
	if (taskEventAction === taskEvents.createJournal && !journalId)
		errors.journalId = "Selecting journal is required!";

	return errors;
};
