import React, { useMemo } from "react";
import {
	ArrayField,
	ChipField,
	Show,
	SimpleShowLayout,
	SingleFieldList,
	TextField,
	useRedirect,
	useTranslate,
	NumberField,
	BooleanField,
} from "react-admin";
import CustomImageField from "../../customComponents/CustomImageField";
import { userPreferences } from "../../utils/fakeUserPrefrences";

const CustomChipFieldWithRedirect = ({
	record,
	source,
	basePath,
	emptyText,
	redirectToResource,
	...props
}) => {
	const redirect = useRedirect();
	const handleRedirectClick = () => {
		redirect("show", `/${redirectToResource}`, record.id);
	};
	return (
		<ChipField
			onClick={handleRedirectClick}
			source={source}
			record={record}
			emptyText={emptyText}
			{...props}
		/>
	);
};

export const DoctorShow = (props) => {
	const translate = useTranslate();
	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);
	return (
		<Show {...props} title={translate("doctors.show.title")}>
			<SimpleShowLayout>
				<CustomImageField
					source="imageUrl"
					label={"Profile Picture"}
					sortable={false}
				/>
				<TextField source={`fullName.${defaultLocale}`} />
				<TextField source={`description.${defaultLocale}`} />
				<BooleanField source="isPublic" />
				<ArrayField source="modules">
					<SingleFieldList linkType={false}>
						<CustomChipFieldWithRedirect
							source="name"
							emptyText={translate("empty.module")}
							redirectToResource="modules"
						/>
					</SingleFieldList>
				</ArrayField>
				<NumberField source="index" />
			</SimpleShowLayout>
		</Show>
	);
};
