/** @format */

import { stringify } from "query-string";
import { fetchUtils } from "ra-core";
import axios from "axios";
import { buildErrorMessage } from "../utils/helpers";

export const dataServices = (apiUrl, httpClient = fetchUtils.fetchJson) => ({
	getList: async (resource, params) => {
		const { page, perPage } = params.pagination;
		const { field, order } = params.sort;
		const query = {
			sort: field,
			order: order,
			start: (page - 1) * perPage,
			perPage: perPage,
			filters: JSON.stringify(fetchUtils.flattenObject(params.filter)),
		};
		const {
			data: { data, total },
		} = await axios.get(`${apiUrl}/${resource}?${stringify(query)}`, {
			withCredentials: true,
		});

		return {
			data,
			total: parseInt(total?.toString()?.split("/").pop(), 10),
		};
	},

	getOne: async (resource, params) => {
		const {
			data: { data },
		} = await axios.get(`${apiUrl}/${resource}/id/${params.id}`, {
			withCredentials: true,
		});
		return {
			data,
		};
	},

	getMany: (resource, params) => {
		const query = {
			id: params.ids,
		};
		const url = `${apiUrl}/${resource}?${stringify(query)}`;
		return httpClient(url, { credentials: "include" }).then(({ json }) => ({
			data: json,
		}));
	},

	getManyReference: (resource, params) => {
		const { page, perPage } = params.pagination;
		const { field, order } = params.sort;
		const query = {
			...fetchUtils.flattenObject(params.filter),
			[params.target]: params.id,
			_sort: field,
			_order: order,
			_start: (page - 1) * perPage,
			_end: page * perPage,
		};
		const url = `${apiUrl}/${resource}?${stringify(query)}`;
		return httpClient(url, { credentials: "include" }).then(
			({ headers, json }) => {
				if (!headers.has("x-total-count")) {
					throw new Error(
						"The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?"
					);
				}
				return {
					data: json,
					total: parseInt(
						headers.get("x-total-count").split("/").pop(),
						10
					),
				};
			}
		);
	},

	update: async (resource, { id, data: newData }) => {
		let body;
		if (
			resource === "categories" ||
			resource === "modules" ||
			resource === "background-modules" ||
			resource === "content-types" ||
			resource === "playlists" ||
			resource === "plans" ||
			resource === "doctors" ||
			resource === "objectives" ||
			resource === "subscriptions" ||
			resource === "communication-pages" ||
			resource === "lists-singles" ||
			resource === "mood-tracker" ||
			resource === "timelines" ||
			resource === "journals"
		)
			body = newData.formData;
		else body = newData;

		try {
			await axios.put(`${apiUrl}/${resource}/id/${id}`, body, {
				withCredentials: true,
			});
			return {
				data: { id, ...newData },
			};
		} catch (error) {
			throw new Error(buildErrorMessage(error));
		}
	},

	// json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
	updateMany: (resource, params) => {
		return Promise.all(
			params.ids.map((id) =>
				httpClient(`${apiUrl}/${resource}/id/${id}`, {
					method: "PUT",
					body: JSON.stringify(params.data),
					credentials: "include",
				})
			)
		).then((responses) => ({ data: responses.map(({ json }) => json.id) }));
	},

	create: async (resource, params) => {
		let body;
		if (
			resource === "categories" ||
			resource === "modules" ||
			resource === "background-modules" ||
			resource === "content-types" ||
			resource === "playlists" ||
			resource === "plans" ||
			resource === "doctors" ||
			resource === "objectives" ||
			resource === "subscriptions" ||
			resource === "communication-pages" ||
			resource === "lists-singles" ||
			resource === "mood-tracker" ||
			resource === "timelines" ||
			resource === "journals"
		)
			body = params.data.formData;
		else body = params.data;

		try {
			await axios.post(`${apiUrl}/${resource}`, body, {
				withCredentials: true,
			});
			return {
				data: { ...params.data, id: null },
			};
		} catch (error) {
			throw new Error(buildErrorMessage(error));
		}
	},

	delete: async (resource, params) => {
		try {
			await axios.delete(`${apiUrl}/${resource}/id/${params.id}`, {
				withCredentials: true,
			});
			return { data: params.previousData };
		} catch (error) {
			throw new Error(buildErrorMessage(error));
		}
	},

	// json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
	deleteMany: (resource, params) =>
		Promise.all(
			params.ids.map((id) =>
				httpClient(`${apiUrl}/${resource}/${id}`, {
					method: "DELETE",
				})
			)
		).then((responses) => ({ data: responses.map(({ json }) => json.id) })),
});

export default dataServices;
