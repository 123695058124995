import {
	Show,
	SimpleShowLayout,
	TextField,
	useTranslate,
	useRedirect,
	ChipField,
	SingleFieldList,
	ArrayField,
} from "react-admin";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { useMemo } from "react";

const CustomChipFieldWithRedirect = ({
	record,
	source,
	basePath,
	emptyText,
	redirectToResource,
	...props
}) => {
	const redirect = useRedirect();
	const handleRedirectClick = () => {
		redirect("show", `/${redirectToResource}`, record.id);
	};
	return (
		<ChipField
			onClick={handleRedirectClick}
			source={source}
			record={record}
			emptyText={emptyText}
			{...props}
		/>
	);
};
export const DayPortionShow = (props) => {
	const translate = useTranslate();
	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);
	return (
		<Show {...props} title={translate("day-portions.show.title")}>
			<SimpleShowLayout>
				<TextField
					source={`title.${defaultLocale}`}
					label={`Title(${defaultLocale})`}
				/>
				<TextField
					source={`greeting.${defaultLocale}`}
					label={`Greeting(${defaultLocale})`}
				/>
				<TextField source={`startTime`} label={`Start Time`} sortable={false} />
				<TextField source={`endTime`} label={`End Time`} sortable={false} />
				<ArrayField source="modules">
					<SingleFieldList linkType={false}>
						<CustomChipFieldWithRedirect
							source="name"
							emptyText={translate("empty.module")}
							redirectToResource="modules"
						/>
					</SingleFieldList>
				</ArrayField>
				<ArrayField source="playlists">
					<SingleFieldList linkType={false}>
						<CustomChipFieldWithRedirect
							source="names['ar']"
							emptyText={translate("empty.playlist")}
							redirectToResource="playlists"
						/>
					</SingleFieldList>
				</ArrayField>
			</SimpleShowLayout>
		</Show>
	);
};
