import {
	CreateButton,
	Datagrid,
	EditButton,
	Filter,
	List,
	TextField,
	TextInput,
	ShowButton,
	TopToolbar,
	useRedirect,
	useRefresh,
	BooleanField,
	BooleanInput,
} from "react-admin";

import { CustomDeleteButtonWithCustomConfirm } from "../../customComponents/CustomButtons";
import { CustomSidebar } from "../../customComponents/CustomSidebar";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { ContentTypeCreate } from "./contentTypeCreate";
import { ContentTypeEdit } from "./contentTypeEdit";
import { constants } from "../../utils/constants";

const ContentTypeFilter = (props) => (
	<Filter {...props}>
		<TextInput source="search" alwaysOn />
		<BooleanInput source="isDefault" label="Default" />
	</Filter>
);

const CustomDelete = ({ record, resource }) => {
	if (record.isDefault)
		return (
			<CustomDeleteButtonWithCustomConfirm
				mutationMode="pessimistic"
				record={record}
				title="Cannot Delete Content Type "
				content="You must change the default content type before deleting this content type."
			/>
		);
	return (
		<CustomDeleteButtonWithCustomConfirm
			mutationMode="pessimistic"
			record={record}
			resource={resource}
		/>
	);
};
const CustomBooleanField = ({ record }) => {
	return record.isDefault == true ? (
		<BooleanField source="isDefault" label="Default" />
	) : (
		<></>
	);
};

export const ContentTypeList = ({ ...props }) => {
	const redirect = useRedirect();
	const refresh = useRefresh();

	const handleClose = () => {
		redirect("/content-types");
		refresh();
	};

	return (
		<>
			<List
				{...props}
				perPage={constants.perPage}
				bulkActionButtons={false}
				filters={<ContentTypeFilter />}
				empty={false}
			>
				<Datagrid>
					<TextField source="index" />
					{[
						...new Set([
							...userPreferences.translationLanguages,
							userPreferences.displayLanguage,
						]),
					].map((lang, index) => (
						<TextField
							key={`${lang}${index}`}
							source={`titles.${lang}`}
							sortable={false}
						/>
					))}
					<CustomBooleanField label="Default" />
					<ShowButton />
					<EditButton />
					<CustomDelete />
				</Datagrid>
			</List>
			{
				<CustomSidebar
					{...props}
					handleClose={handleClose}
					ComponentEdit={ContentTypeEdit}
					ComponentCreate={ContentTypeCreate}
					anchor="right"
				/>
			}
		</>
	);
};
