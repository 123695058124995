import { useEffect, useMemo, useState } from "react";
import {
	Create,
	FileField,
	FileInput,
	TextInput,
	TranslatableInputs,
	useCreate,
	useGetList,
	useNotify,
	useRedirect,
	useTranslate,
	SimpleForm,
} from "react-admin";

import { userPreferences } from "../../utils/fakeUserPrefrences";
import { validateSubscriptionData } from "./subscriptionValidate";
import { transformToFormData } from "../../utils/customFunctions";
import { CustomToolbar } from "../../customComponents/CustomToolbar";

export const SubscriptionCreate = (props) => {
	const [languagesArray, setLanguagesArray] = useState([]);
	const [create] = useCreate("subscriptions");
	const translate = useTranslate();
	const redirect = useRedirect();
	const notify = useNotify();

	const onSuccess = () => {
		notify(translate("subscriptions.create.success"), "info");
		redirect("/subscriptions");
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);

	const { data: languagesData } = useGetList("languages", {
		page: 1,
		perPage: 10,
	});

	useEffect(() => {
		setLanguagesArray(
			Object.values(languagesData).map((language) =>
				language.name.slice(0, 2).toLowerCase()
			)
		);
	}, [languagesData]);

	return (
		<Create {...props} title={translate("subscriptions.create.title")}>
			<SimpleForm
				save={(data) => {
					data.outcomes = [];
					data.outcomes.push(data.outcome_1, data.outcome_2);
					const transformed = transformToFormData(data);
					create(
						{ payload: { data: transformed } },
						{
							onSuccess,
							onFailure,
						}
					);
				}}
				toolbar={<CustomToolbar />}
				validate={validateSubscriptionData}
				syncWithLocation={false}
			>
				<TranslatableInputs
					locales={languagesArray}
					defaultLocale={defaultLocale}
				>
					<TextInput source="outcome_1" />
					<TextInput source="outcome_2" />
				</TranslatableInputs>
				<FileInput {...props} source="maleImage" label="Male Image">
					<FileField source="src" title="Icon" />
				</FileInput>
				<FileInput {...props} source="femaleImage" label="Female Image">
					<FileField source="src" title="Icon" />
				</FileInput>
			</SimpleForm>
		</Create>
	);
};
