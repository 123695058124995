import { BusinessCreate } from "./businessCreate";
import { BusinessEdit } from "./businessEdit";
import { BusinessList } from "./businessList";
import { BusinessShow } from "./businessShow";

export default {
	create: BusinessCreate,
	edit: BusinessEdit,
	list: BusinessList,
	show: BusinessShow,
};
