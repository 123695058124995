import { useCallback, useEffect, useState } from "react";
import {
	Edit,
	TabbedForm,
	useGetOne,
	FormTab,
	TextInput,
	RadioButtonGroupInput,
	useGetList,
	useMutation,
	useNotify,
	useRedirect,
	useRefresh,
	useTranslate,
	useUpdate,
} from "react-admin";
import { getChoices } from "./shared";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { CustomTransferList } from "../../customComponents/CustomTransferList";
import { setIndexesForData } from "../../utils/customFunctions";
import { genders } from "../../utils/constants";

export const CommunicationFlowEdit = (props) => {
	const translate = useTranslate();
	const [languagesData, setLanguageData] = useState([]);
	const [update] = useUpdate("communication-flows", props.id);
	const [lang, setLanguage] = useState(null);
	const redirect = useRedirect();
	const [mutate] = useMutation();
	const refresh = useRefresh();
	const notify = useNotify();

	const { data: languagesDataObj } = useGetList(
		"languages",
		{ page: 1, perPage: 10 },
		{ field: "id", order: "DESC" }
	);
	useEffect(() => {
		setLanguageData(getChoices(languagesDataObj));
	}, [languagesDataObj]);

	const save = useCallback(
		async (values) => {
			try {
				await mutate(
					{
						type: "update",
						resource: "communication-pages",
						payload: { data: values },
					},
					{ returnPromise: true }
				);
			} catch (error) {
				if (error.body.errors) {
					return error.body.errors;
				}
			}
		},
		[mutate]
	);

	const { data = {}, loading } = useGetOne("communication-pages", props.id);

	const onSuccess = () => {
		notify(`Updated successfully`);
		redirect("/communication-flows");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	return (
		<>
			{loading ? (
				<center>
					<h1>Loading...</h1>
				</center>
			) : (
				<Edit
					{...props}
					save={save}
					mutationMode="pessimistic"
					title={translate("communication-flows.edit.title")}
				>
					<TabbedForm
						toolbar={<CustomToolbar />}
						save={(data) => {
							const indexedDataToSend = setIndexesForData(data, [
								"communicationPages",
							]);
							update(
								{
									payload: {
										data: indexedDataToSend,
									},
								},
								{
									onSuccess,
									onFailure,
								}
							);
						}}
						// validate={validateCommunicationFlowData}
						syncWithLocation={false}
					>
						<FormTab
							label={translate("communication-flows.tabs.basicInfo.label")}
						>
							<TextInput source="name" />
							<RadioButtonGroupInput
								source="languageId"
								choices={languagesData}
								optionText={({ name }) => `misc.names.languages.${name}`}
								optionValue="id"
								onClick={(event) => setLanguage(event.target.value)}
							/>
							<RadioButtonGroupInput
								source="gender"
								choices={genders}
								optionText={({ name }) => name}
								optionValue="id"
							/>
						</FormTab>
						<FormTab
							label={translate(
								"communication-flows.tabs.pagesManagement.label"
							)}
						>
							<CustomTransferList
								source="communicationPages"
								referenceSource="communication-pages"
								referenceResource="communication-pages"
								referenceField="title"
								isReferenceTranslatable={false}
								languageId={lang}
							/>
						</FormTab>
					</TabbedForm>
				</Edit>
			)}
		</>
	);
};
