import CardContent from "@material-ui/core/CardContent";
import { useLocation } from "react-router-dom";
import LockIcon from "@material-ui/icons/Lock";
import { useEffect, useState } from "react";
import {
	useNotify,
	useRedirect,
	useTranslate,
	Notification,
} from "react-admin";
import {
	Box,
	TextField,
	Button,
	Card,
	makeStyles,
	Avatar,
} from "@material-ui/core";

import { isNotInRange } from "../utils/customFunctions";
import { envSettings } from "../settings/env";
import { useInputs } from "../customHooks/customHooks";

const useStyles = makeStyles({
	main: {
		height: "1px",
		display: "flex",
		minHeight: "100vh",
		alignItems: "center",
		flexDirection: "column",
		backgroundSize: "cover",
		justifyContent: "flex-start",
		backgroundImage:
			"radial-gradient(circle at 50% 14em, #313264 0%, #00023b 60%, #00023b 100%)",
		backgroundRepeat: "no-repeat",
	},
	card: {
		width: "300px",
		marginTop: "6em",
		overflow: "hidden",
		boxShadow:
			"0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
		borderRadius: "4px",
		color: "rgba(0, 0, 0, 0.87)",
		transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
		backgroundColor: " #fff",
	},
	form: {
		display: "block",
		marginTop: "0em",
		padding: "0 1em 1em 1em",
	},
	button: {
		width: "100%",
		borderRadius: "100px",
		border: "none",
		padding: "16px 32px",
		textDecoration: "none",
		margin: "4px 2px",
		cursor: "pointer",
	},
	input: {
		width: "100%",
		margin: "8px 0",
		borderColor: "rgb(49,50,100)",
	},
	label: {
		color: "#3f51b5",
		fontSize: "20px",
	},
});

export const UserVerify = () => {
	const [focusedElement, setFocusedElement] = useState(null);
	const [errors, setErrors] = useState({
		password: "",
		confirmPassword: "",
	});

	const [inputs, setInputs] = useInputs({});
	const translate = useTranslate();
	const location = useLocation();
	const redirect = useRedirect();
	const notify = useNotify();

	const verifyPassword = (inputToVerify) => () => {
		const error = {
			password: "",
			confirmPassword: "",
		};

		const { password, confirmPassword } = inputs;

		if (!password)
			error.password = translate("validationErrors.required.requiredField", {
				field: "Password",
			});

		if (password && isNotInRange({ min: 3, max: 32, str: password }))
			error.password = translate("validationErrors.minMax.password", {
				min: 3,
				max: 32,
			});

		if (inputToVerify === "password")
			setErrors((oldErrors) => ({ ...oldErrors, password: error.password }));

		if (!confirmPassword)
			error.confirmPassword = translate(
				"validationErrors.required.requiredField",
				{ field: "Confirm password" }
			);

		if (
			confirmPassword &&
			isNotInRange({ min: 3, max: 32, str: confirmPassword })
		)
			error.confirmPassword = translate(
				"validationErrors.minMax.confirmPassword",
				{
					min: 3,
					max: 32,
				}
			);

		if (inputToVerify === "confirmPassword")
			setErrors((oldErrors) => ({
				...oldErrors,
				confirmPassword: error.confirmPassword,
			}));

		if (!inputToVerify) setErrors({ ...error });

		return error.password === "" && error.confirmPassword === "";
	};

	const onFailure = (error) => {
		error =
			error?.response?.data
				?.split("<pre>")[1]
				?.split("</pre>")[0]
				?.split("Error:")[1]
				?.split("<br>")[0] || "Failed!";
		notify(error, "warning");
	};

	const submit = (e) => {
		e.preventDefault();

		const verified = verifyPassword();

		if (!verified())
			return notify(translate("notify.notifyFormErrors"), "warning");

		const { password, confirmPassword } = inputs;
		let code = location.search.slice(1).split("code=")[1];
		const request = new Request(envSettings.baseUrl + "/users/verify", {
			method: "POST",
			body: JSON.stringify({ code, password, confirmPassword }),
			headers: new Headers({ "Content-Type": "application/json" }),
			credentials: "include",
		});

		return fetch(request)
			.then((response) => {
				if (response.status !== 200) {
					// throw new Error("Cannot verify account!");
					return Promise.reject(response);
				} else {
					notify("Verification Completed", "success");
					redirect("/login");
				}
			})
			.catch((e) => {
				notify(`${translate("notify.notifyFormErrors")}`, "warning");
			});
	};

	const handleFocus = ({ target }) => setFocusedElement(target.name);

	const handleBlur = ({ target }) => {
		setFocusedElement(null);
		return verifyPassword(target.name);
	};

	useEffect(() => {
		focusedElement && verifyPassword(focusedElement)();
	}, [inputs]);

	const classes = useStyles();
	return (
		<Card className={classes.main}>
			<CardContent className={classes.card}>
				<form onSubmit={submit} className={classes.form}>
					<Box margin="1em" display="flex" justifyContent="center">
						<Avatar>
							<LockIcon />
						</Avatar>
					</Box>
					<TextField
						onFocus={handleFocus}
						onBlur={handleBlur}
						error={errors.password !== ""}
						className={classes.input}
						type="password"
						name="password"
						onChange={setInputs}
						label="Password"
						helperText={errors.password}
					/>
					<TextField
						onFocus={handleFocus}
						onBlur={handleBlur}
						error={errors.confirmPassword !== ""}
						className={classes.input}
						type="password"
						name="confirmPassword"
						onChange={setInputs}
						label="Password Confirmation"
						helperText={errors.confirmPassword}
					/>
				</form>
				<Button
					onClick={submit}
					fullWidth
					variant="contained"
					color="primary"
					type="submit"
					value="Verify"
				>
					{translate("buttons.signIn")}
				</Button>
			</CardContent>
			<Notification />
		</Card>
	);
};
