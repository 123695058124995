import { useEffect, useMemo, useState } from "react";
import {
	Create,
	FormTab,
	ImageField,
	ImageInput,
	TabbedForm,
	TextInput,
	TranslatableInputs,
	useCreate,
	useGetList,
	useNotify,
	useRedirect,
	useRefresh,
	useTranslate,
} from "react-admin";

import {
	setIndexesForData,
	transformToFormData,
} from "../../utils/customFunctions";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { validateCategoryData } from "./categoryValidate";
import { CustomTransferList } from "../../customComponents/CustomTransferList";
import { CustomToolbar } from "../../customComponents/CustomToolbar";

export const CategoryCreate = (props) => {
	const [languagesArray, setLanguagesArray] = useState([]);
	const [objectivesDataArray, setObjectivesDataArray] = useState([]);

	const translate = useTranslate();
	const redirect = useRedirect();
	const refresh = useRefresh();
	const notify = useNotify();
	const [create] = useCreate("categories");

	const response = useGetList("languages");

	const { data: languagesData, loading: loadingLanguages } = response;
	const { data: objectivesData, loading: loadingObjectives } =
		useGetList("objectives");

	const validateCategoryDataCreation = ({ titles, image }) => {
		const errors = validateCategoryData({ titles, image });
		return errors;
	};

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);

	const onSuccess = () => {
		notify(translate("categories.create.success"), "info");
		redirect("/categories");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	useEffect(() => {
		setLanguagesArray(
			Object.values(languagesData).map((language) =>
				language.name.slice(0, 2).toLowerCase()
			)
		);
	}, [languagesData]);

	useEffect(() => {
		setObjectivesDataArray(
			Object.values(objectivesData).map(({ id, titles }) => ({
				id,
				name: titles[defaultLocale],
			}))
		);
	}, [objectivesData]);

	return (
		<>
			{loadingObjectives || loadingLanguages ? (
				<center>
					<h1>Loading...</h1>
				</center>
			) : (
				<Create {...props} title={translate("categories.create.title")}>
					<TabbedForm
						save={(data) => {
							const indexedDataToSend = setIndexesForData(data, ["objectives"]);
							const transformed = transformToFormData(indexedDataToSend);
							create(
								{ payload: { data: transformed } },
								{
									onSuccess,
									onFailure,
								}
							);
						}}
						toolbar={<CustomToolbar />}
						validate={validateCategoryDataCreation}
						syncWithLocation={false}
					>
						<FormTab label="Basic Info">
							<TranslatableInputs
								locales={languagesArray}
								defaultLocale={defaultLocale}
							>
								<TextInput source="titles" />
							</TranslatableInputs>
							<ImageInput
								source="image"
								accept="image/*"
								placeholder={translate(
									"categories.create.uploadImagePlaceholder"
								)}
							>
								<ImageField source="src" />
							</ImageInput>
						</FormTab>
						<FormTab
							label={translate("playlists.tabs.objectivesManagement.label")}
						>
							<CustomTransferList
								source="objectives"
								referenceSource="objectives"
								referenceResource="objectives"
								referenceField="titles"
								isReferenceTranslatable={true}
							/>
						</FormTab>
					</TabbedForm>
				</Create>
			)}
		</>
	);
};
