import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchUtils } from "ra-core";
import {
	fetchEnd,
	fetchStart,
	useDataProvider,
	useNotify,
	useRefresh,
} from "react-admin";

import { envSettings } from "../settings/env";
import constants from "../utils/constants";

const httpClient = fetchUtils.fetchJson;

export const useCustomUpdate = ({
	endPoint,
	resource,
	record,
	source,
	method = "PUT",
}) => {
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const refresh = useRefresh();
	const notify = useNotify();

	const updateInPlace = (data, id) => {
		setLoading(true);
		dispatch(fetchStart());

		const url = endPoint
			? `${envSettings.baseUrl}/${resource}/${endPoint}`
			: `${envSettings.baseUrl}/${resource}`;
		fetch(url, {
			method,
			body: JSON.stringify({
				id: record?.id || id,
				[source]:
					data?.target === undefined ? data : data?.target?.value,
			}),
			headers: {
				"Content-Type": "application/json",
			},
			credentials: "include",
		})
			.then(({ status }) => {
				if (status !== 200) throw new Error();
				notify(`${resource}.${endPoint}.success`, "info");
				refresh();
			})
			.catch(() => {
				notify(`${resource}.${endPoint}.fail`, "warning");
				refresh();
			})
			.finally(() => {
				setLoading(false);
				dispatch(fetchEnd());
			});
	};

	return {
		loading,
		updateInPlace,
	};
};

// some wierd shenanedans are happining when using the hook in CustomNotficationComponent and CustomNotficationInfiniteList
// where the hook keeps refering to CustomNotficationComponent when accessing it from CustomNotficationInfiniteList
export const useCustomFetch = (props) => {
	const [loading, setLoading] = useState(true);
	// const [body, setBody] = useState({});
	const [reFetch, setReFetch] = useState(true);
	const [error, setError] = useState(false);
	const [data, setData] = useState(null);

	const dispatch = useDispatch();

	const customFetch = async ({
		method,
		resource,
		endPoint,
		customUrl,
		onSuccess,
		onFailure,
		requestBody,
	}) => {
		try {
			setLoading(true);
			dispatch(fetchStart());

			const url =
				customUrl || endPoint
					? `${envSettings.baseUrl}/${resource}/${endPoint}`
					: `${envSettings.baseUrl}/${resource}`;

			const request = {
				method,
				credentials: "include",
			};

			if (requestBody) request.body = JSON.stringify(requestBody);

			const result = await httpClient(url, request);

			const res = result?.json?.data || null;

			setData(res);
			setLoading(false);

			onSuccess && onSuccess();

			return Promise.resolve(res);
		} catch (err) {
			onFailure && onFailure();

			setError(err);
			setLoading(false);

			return err;
		} finally {
			setLoading(false);
			dispatch(fetchEnd());
		}
	};

	useEffect(() => {
		reFetch && props && customFetch(props);
		setReFetch(false);
	}, [reFetch]);

	return {
		loading,
		error,
		data,
		customFetch,
		reFetchData: () => setReFetch(true),
	};
};

export const useInputs = (initialValue) => {
	const [values, setValues] = useState(() => initialValue);

	return [
		values,
		({ target }) => {
			setValues((oldValues) => ({
				...oldValues,
				[target.name]: target.value,
			}));
		},
	];
};

export const useCustomGetListWithPagination = ({
	sort,
	resource,
	withPagination,
	initialPageNumber,
	initialFetchedData,
}) => {
	const [fetchedData, setFetchedData] = useState(initialFetchedData || []);
	const [pageNumber, setPageNumber] = useState(initialPageNumber || 1);
	const [hasMoreData, setHasMoreData] = useState(true);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [filter, setFilter] = useState({});
	const [next, setNext] = useState(false);

	const dataProvider = useDataProvider();

	const saveDataAndPagination = ({ data: newData, total }) => {
		const newDataArray = [...fetchedData, ...newData];
		const hasMore = total - newDataArray.length > 0;

		setHasMoreData(hasMore);

		hasMore && setPageNumber((oldPageNumber) => oldPageNumber + 1);

		setLoading(false);
		setFetchedData([...newDataArray]);
	};

	const refreshList = () => {
		getSearchResults("");
	};

	const getNext = () => setNext((oldValue) => !oldValue);

	const getSearchResults = (query) => {
		setPageNumber(1);
		setFetchedData([]);
		setHasMoreData(true);
		setFilter((oldFilter) => ({ ...oldFilter, ...query }));
	};

	useEffect(() => {
		!loading && getNext();
	}, [filter]);

	useEffect(() => {
		if (!hasMoreData && !loading) return;

		setLoading(true);
		setError(null);
		dataProvider
			.getList(resource, {
				filter,
				pagination: {
					page: pageNumber,
					perPage: withPagination
						? constants.perPage
						: constants.perPageMax,
				},
				sort: sort || { field: "id", order: "ASC" },
			})
			.then(saveDataAndPagination)
			.catch((error) => {
				setError(error);
				setLoading(false);
			});
	}, [next]);

	return {
		hasMoreData,
		fetchedData,
		pageNumber,
		loading,
		filter,
		error,
		getNext,
		refreshList,
		setFetchedData,
		getSearchResults,
	};
};

export const useLastComponentObserver = ({ loading, hasMoreData, getNext }) => {
	const observerRef = useRef();
	const rootRef = useRef();

	const lastElementRef = useCallback(
		(node) => {
			if (loading) return;

			if (observerRef.current) {
				observerRef.current.disconnect();
			}

			observerRef.current = new IntersectionObserver(
				(entries) => {
					entries[0].isIntersecting && hasMoreData && getNext();
				},
				{ root: rootRef.current }
			);
			if (node) observerRef.current.observe(node);
		},
		[loading, hasMoreData]
	);

	return {
		observerRef,
		rootRef,
		lastElementRef,
	};
};
