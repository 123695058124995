import { useEffect, useMemo, useRef } from "react";
import {
	Datagrid,
	EditButton,
	Filter,
	List,
	SelectInput,
	ShowButton,
	TextField,
	TextInput,
	usePermissions,
} from "react-admin";

	import { CustomDeleteButtonWithConfirmation } from "../../customComponents/CustomButtons";
import { checkIfExists, checkPermission } from "../../utils/customFunctions";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import {
	requiredPermissions,
	requiredStatuses,
	statusEnumsObject,
} from "./shared";

const BackgroundPlaylistFilter = ({ ...props }) => {
	return (
		<Filter {...props}>
			<TextInput source="search" alwaysOn />
			<SelectInput source="status" choices={statusEnumsObject} />
			<TextInput source="category" />
		</Filter>
	);
};

const EditLimitedModule = ({ record }) => {
	if (requiredStatuses.includes(record.status))
		return <EditButton record={record} />;
	else return <EditButton disabled={true} record={record} />;
};

export const BackgroundPlaylistList = (props) => {
	const { loadingPermissions, permissions } = usePermissions();

	const hasRequiredStatus = useRef(false);
	const hasPermission = useRef(false);

	let deleteBtn = null,
		editBtn = null;

	useEffect(() => {
		hasPermission.current = checkIfExists(
			props.permissions,
			requiredPermissions
		);
	}, [props.permissions]);

	useEffect(() => {
		hasRequiredStatus.current = checkIfExists(props.status, requiredStatuses);
	}, [props.status]);

	if (
		!loadingPermissions &&
		(!permissions || checkPermission(permissions, requiredPermissions))
	) {
		deleteBtn = <CustomDeleteButtonWithConfirmation />;
		editBtn = <EditButton />;
	} else editBtn = <EditLimitedModule />;

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);

	return (
		<List
			bulkActionButtons={false}
			{...props}
			filters={<BackgroundPlaylistFilter />}
		>
			<Datagrid>
				<TextField source={`names.${defaultLocale}`} sortable={false} />
				<TextField source={`descriptions.${defaultLocale}`} sortable={false} />
				<TextField
					source={`category.titles.${defaultLocale}`}
					sortable={false}
				/>
				<TextField source="status" sortable={false} />
				<ShowButton />
				{editBtn}
				{deleteBtn}
			</Datagrid>
		</List>
	);
};
