export const getChoices = (items) => Object.values(items).map((item) => item);

export const statusEnumsObject = [
	{ name: "modules.status.PENDING", id: "PENDING" },
	{ name: "modules.status.PUBLISHED", id: "PUBLISHED" },
	{ name: "modules.status.UNPUBLISHED", id: "UNPUBLISHED" },
	{ name: "modules.status.REJECTED", id: "REJECTED" },
];

export const statusEnumsArray = [
	"PENDING",
	"PUBLISHED",
	"UNPUBLISHED",
	"REJECTED",
];

export const requiredPermissions = ["CONTENT MANAGER", "SYSTEM ADMINISTRATOR"];

export const requiredStatuses = ["PENDING", "REJECTED", "UNPUBLISHED"];
