import React from "react";
import {
	Datagrid,
	Filter,
	List,
	SelectInput,
	ShowButton,
	TextField,
	TextInput,
	downloadCSV,
	DateInput,
} from "react-admin";
import { constants, suggestionsChoicesArr } from "../../utils/constants";
import jsonExport from "jsonexport/dist";
import DataProvider from "../../providers/dataProvider";
import { envSettings } from "../../settings/env";
import { useLocation } from "react-router-dom";

const UserFilter = ({ ...props }) => (
	<Filter {...props}>
		<TextInput source="search" alwaysOn />
		<SelectInput source="relatedTo" choices={suggestionsChoicesArr} />
		<DateInput source="dateFrom" />
		<DateInput source="dateTo" />
	</Filter>
);
const exporter = async (
	filteredData,
	dataProvider,
	resource,
	withoutFilter = true
) => {
	let data;
	if (withoutFilter) {
		data = (
			await dataProvider.getList(resource, {
				pagination: { page: 1, perPage: 10000000000 },
				sort: { field: "id", order: "DESC" },
				filters: {},
			})
		).data;
	} else {
		data = filteredData;
	}

	data = data.map((suggestion) => {
		delete suggestion.id;
		delete suggestion.relatedTo;
		return suggestion;
	});

	const BOM = "\uFEFF";
	jsonExport(
		data,
		{
			headers: ["userId", "suggestion"],
		},
		(err, csv) => {
			downloadCSV(`${BOM} ${csv}`, "Users Suggestions");
		}
	);
};
export const UserSuggestionList = (props) => {
	const location = useLocation();
	return (
		<List
			{...props}
			perPage={constants.perPage}
			bulkActionButtons={false}
			filters={<UserFilter />}
			exporter={(filteredData, _, __, resource) => {
				const withoutFilter =
					decodeURI(location.search).includes("filter={}") ||
					location.search === "";
				exporter(
					filteredData,
					DataProvider(envSettings.baseUrl),
					resource,
					withoutFilter
				);
			}}
		>
			<Datagrid>
				<TextField source="relatedTo" />
				<TextField source="suggestion" />
				<TextField source="createdOn" />
				<ShowButton />
			</Datagrid>
		</List>
	);
};
