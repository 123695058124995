import { isNotInRange } from "../../utils/customFunctions";

export const backgroundPlaylistValidate = ({
	names,
	descriptions,
	categoryId,
}) => {
	const errors = {};
	if (!names?.en && !names?.ar) {
		errors.names = {
			en: "validationErrors.required.oneIsRequired",
			ar: "validationErrors.required.oneIsRequired",
		};
	}

	if (names?.ar && isNotInRange({ min: 3, max: 50, str: names.ar })) {
		errors.names = {
			ar: {
				message: "validationErrors.minMax.names",
				args: { min: 3, max: 50 },
			},
		};
	}

	if (names?.en && isNotInRange({ min: 3, max: 50, str: names.en })) {
		errors.names = {
			en: {
				message: "validationErrors.minMax.names",
				args: { min: 3, max: 50 },
			},
		};
	}

	if (!descriptions?.en && !descriptions?.ar) {
		errors.descriptions = {
			en: "validationErrors.required.oneIsRequired",
			ar: "validationErrors.required.oneIsRequired",
		};
	}

	if (
		descriptions?.ar &&
		isNotInRange({ min: 3, max: 500, str: descriptions.ar })
	) {
		errors.descriptions = {
			ar: {
				message: "validationErrors.minMax.descriptions",
				args: { min: 3, max: 500 },
			},
		};
	}

	if (
		descriptions?.en &&
		isNotInRange({ min: 3, max: 500, str: descriptions.en })
	) {
		errors.descriptions = {
			en: {
				message: "validationErrors.minMax.descriptions",
				args: { min: 3, max: 500 },
			},
		};
	}

	if (!categoryId)
		errors.categoryId = "playlists.validationErrors.required.categoryId";

	return errors;
};
