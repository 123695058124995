import { isNotInRange } from "../../utils/customFunctions";

export const validateSubscriptionData = async ({
	outcome_1,
	outcome_2,
	maleImage,
	femaleImage,
}) => {
	const errors = {};
	if (
		(!outcome_1?.en && !outcome_1?.ar) ||
		(!outcome_2?.en && !outcome_2?.ar)
	) {
		errors.outcomes = {
			en: "validationErrors.required.oneIsRequired",
			ar: "validationErrors.required.oneIsRequired",
		};
	}

	if (outcome_1?.ar && isNotInRange({ min: 3, max: 400, str: outcome_1.ar })) {
		errors.outcome_1 = {
			ar: {
				message: "validationErrors.minMax.names",
				args: { min: 3, max: 400 },
			},
		};
	}

	if (outcome_1?.en && isNotInRange({ min: 3, max: 400, str: outcome_1.en })) {
		errors.outcome_1 = {
			en: {
				message: "validationErrors.minMax.names",
				args: { min: 3, max: 400 },
			},
		};
	}

	if (outcome_2?.ar && isNotInRange({ min: 3, max: 400, str: outcome_2.ar })) {
		errors.outcome_2 = {
			ar: {
				message: "validationErrors.minMax.names",
				args: { min: 3, max: 400 },
			},
		};
	}

	if (outcome_2?.en && isNotInRange({ min: 3, max: 400, str: outcome_2.en })) {
		errors.outcome_2 = {
			en: {
				message: "validationErrors.minMax.names",
				args: { min: 3, max: 400 },
			},
		};
	}

	return errors;
};
