import React, { useMemo } from "react";
import {
	Datagrid,
	FunctionField,
	Show,
	SimpleShowLayout,
	TextField,
	ChipField,
	ArrayField,
	useRedirect,
	SingleFieldList,
} from "react-admin";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { CustomAsideSimpleList } from "../../customComponents/CustomAsideSimpleList";
import { useTranslate } from "ra-core";
import { Typography } from "@material-ui/core";

const CustomOutcomes = ({ record: { outcomes } }) => {
	if (outcomes.length > 0) {
		return (
			<ArrayField source="outcomes">
				<Datagrid>
					<TextField source="ar(Male)" label="Arabic (male)" />
					<TextField source="ar(Female)" label="Arabic (female)" />
					<TextField source="en" label="English" />
				</Datagrid>
			</ArrayField>
		);
	}
	return <></>;
};
CustomOutcomes.defaultProps = {
	addLabel: true,
};
const CustomChipFieldWithRedirect = ({
	record,
	source,
	basePath,
	emptyText,
	redirectToResource,
	...props
}) => {
	const redirect = useRedirect();
	const handleRedirectClick = () => {
		redirect("show", `/${redirectToResource}`, record.id);
	};
	return (
		<ChipField
			onClick={handleRedirectClick}
			source={source}
			record={record}
			emptyText={emptyText}
			{...props}
		/>
	);
};

const CustomMessage = ({ record }) => {
	const translate = useTranslate();
	const message = translate("objectives.show.isActive", {
		status: record?.isActive ? "" : "not",
	});
	return <Typography>{`${message}`}</Typography>;
};

export const ObjectiveShow = (props) => {
	const translate = useTranslate();
	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);
	return (
		<Show
			aside={<CustomAsideSimpleList source="auditTracker" />}
			{...props}
			title={translate("objectives.show.title")}
		>
			<SimpleShowLayout>
				<TextField source={`titles.${defaultLocale}`} />
				<TextField source={`recommendationTitles.${defaultLocale}`} />
				<TextField source={`descriptions.${defaultLocale}`} />
				<TextField source="displayIndex" />
				<FunctionField
					source="isActive"
					render={(record) => <CustomMessage record={record} />}
				/>
				<ArrayField source="plans">
					<SingleFieldList linkType={false}>
						<CustomChipFieldWithRedirect
							source="names['ar']"
							emptyText={translate("empty.plan")}
							redirectToResource="plans"
						/>
					</SingleFieldList>
				</ArrayField>
				<TextField source={`contentType.titles.${defaultLocale}`} />
				<CustomOutcomes source="outcomes" />
				<ArrayField source="timelines">
					<SingleFieldList linkType={false}>
						<CustomChipFieldWithRedirect
							source="name['ar']"
							emptyText={translate("empty.plan")}
							redirectToResource="timelines"
						/>
					</SingleFieldList>
				</ArrayField>
			</SimpleShowLayout>
		</Show>
	);
};
