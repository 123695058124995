import { isNotInRange } from "../../utils/customFunctions";

export const validateLicenseData = ({
	name,
	startDate,
	endDate,
	promoCodeId,
}) => {
	const errors = {};
	if (!name) errors.name = "licenses.validationErrors.required.name";
	if (name && isNotInRange({ min: 3, max: 50, str: name })) {
		errors.name = {
			message: "licenses.validationErrors.minMax.name",
			args: { min: 3, max: 50 },
		};
	}
	if (!startDate)
		errors.startDate = "licenses.validationErrors.required.startDate";
	if (!endDate) errors.endDate = "licenses.validationErrors.required.endDate";
	if (startDate && endDate) {
		let _startDate = new Date(startDate).toISOString();
		let _endDate = new Date(endDate).toISOString();
		if (new Date(_startDate) >= new Date(_endDate)) {
			errors.startDate = "licenses.validationErrors.logic.endDate";
			errors.endDate = "licenses.validationErrors.logic.endDate";
		}
	}
	if (!promoCodeId)
		errors.promoCodeId = "licenses.validationErrors.required.promoCode";
	return errors;
};
