import { useEffect, useMemo, useRef } from "react";
import {
	Datagrid,
	List,
	ShowButton,
	TextField,
	EditButton,
	usePermissions,
	Filter,
	TextInput,
	SelectInput,
} from "react-admin";
import { CustomDeleteButtonWithConfirmation } from "../../customComponents/CustomButtons";
import { checkIfExists, checkPermission } from "../../utils/customFunctions";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import {
	requiredPermissions,
	requiredStatuses,
	statusEnumsObject,
} from "./shared";

const EditLimitedModule = ({ record }) => {
	if (requiredStatuses.includes(record.status)) {
		return <EditButton record={record} />;
	} else {
		return <EditButton disabled={true} record={record} />;
	}
};
const ListFilter = ({ ...props }) => {
	return (
		<Filter {...props}>
			<TextInput source="search" alwaysOn />
			<SelectInput source="status" choices={statusEnumsObject} />
		</Filter>
	);
};

export const ListSinglesList = (props) => {
	const { loadingPermissions, permissions } = usePermissions();

	const hasRequiredStatus = useRef(false);
	const hasPermission = useRef(false);

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);

	let editBtn = null;

	useEffect(() => {
		hasPermission.current = checkIfExists(
			props.permissions,
			requiredPermissions
		);
	}, [props.permissions]);

	useEffect(() => {
		hasRequiredStatus.current = checkIfExists(
			props.status,
			requiredStatuses
		);
	}, [props.status]);

	if (
		!loadingPermissions &&
		(!permissions || checkPermission(permissions, requiredPermissions))
	) {
		editBtn = <EditButton />;
	} else editBtn = <EditLimitedModule />;

	const CustomDeleteButton = (props) => {
		if (
			!loadingPermissions &&
			(!permissions || checkPermission(permissions, requiredPermissions))
		)
			return <CustomDeleteButtonWithConfirmation {...props} />;
		return <></>;
	};

	return (
		<List bulkActionButtons={false} {...props} filters={<ListFilter />}>
			<Datagrid>
				<TextField source="index" />
				<TextField source={`names.${defaultLocale}`} sortable={false} />
				<TextField
					source={`descriptions.${defaultLocale}`}
					sortable={false}
				/>
				<TextField source="status" sortable={false} />
				<ShowButton />
				<EditButton />
				<CustomDeleteButton />
			</Datagrid>
		</List>
	);
};
