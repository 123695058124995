import { isNotInRange } from "../../utils/customFunctions";

export const validateCategoryData = ({ titles }) => {
	const errors = {};
	if (!titles?.en && !titles?.ar) {
		errors.titles = {
			en: "validationErrors.required.oneIsRequired",
			ar: "validationErrors.required.oneIsRequired",
		};
	}

	if (titles?.ar && isNotInRange({ min: 3, max: 50, str: titles.ar })) {
		errors.titles = {
			ar: {
				message: "validationErrors.minMax.titles",
				args: { min: 3, max: 50 },
			},
		};
	}

	if (titles?.en && isNotInRange({ min: 3, max: 50, str: titles.en })) {
		errors.titles = {
			en: {
				message: "validationErrors.minMax.titles",
				args: { min: 3, max: 50 },
			},
		};
	}

	return errors;
};
