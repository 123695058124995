import {
	ArrayField,
	ChipField,
	Show,
	SimpleShowLayout,
	SingleFieldList,
	TextField,
	useRedirect,
	useTranslate,
} from "react-admin";
import { CustomBusinessActions } from "../../customComponents/CustomBusinessActions";

const CustomChipFieldWithRedirect = ({
	record,
	source,
	basePath,
	emptyText,
	redirectToResource,
	...props
}) => {
	const redirect = useRedirect();
	const handleRedirectClick = () => {
		redirect("show", `/${redirectToResource}`, record.id);
	};
	return (
		<ChipField
			onClick={handleRedirectClick}
			source={source}
			record={record}
			emptyText={emptyText}
			{...props}
		/>
	);
};

export const ProductShow = (props) => {
	const translate = useTranslate();
	return (
		<Show {...props} title={translate("web-products.show.title")}>
			<SimpleShowLayout>
				<TextField source="name" />
				<TextField source="price" />
				<TextField source="currency" />
			</SimpleShowLayout>
		</Show>
	);
};
