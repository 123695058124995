import {
	Datagrid,
	EditButton,
	Filter,
	List,
	DateField,
	TextField,
	TextInput,
	ShowButton,
} from "react-admin";
import { constants } from "../../utils/constants";

const BusinessFilter = ({ ...props }) => (
	<Filter {...props}>
		<TextInput source="search" alwaysOn />
	</Filter>
);

export const BusinessList = (props) => {
	return (
		<List
			{...props}
			perPage={constants.perPage}
			bulkActionButtons={false}
			filters={<BusinessFilter />}
		>
			<Datagrid>
				<TextField source="name" label="Name" sortable={false} />
				<DateField source="validFrom" showTime />
				<DateField source="validTo" showTime />
				<ShowButton />
				<EditButton />
			</Datagrid>
		</List>
	);
};
