import { useMemo } from "react";
import {
    ArrayField,
    ChipField,
    Show,
    SimpleShowLayout,
    SingleFieldList,
    TextField,
    useRedirect,
    useTranslate,
    NumberField
} from "react-admin";

import { CustomAsideSimpleList } from "../../customComponents/CustomAsideSimpleList";
import { CustomShowActions } from "../../customComponents/CustomShowActions";
import { requiredPermissions, requiredStatuses } from "./shared";
import { userPreferences } from "../../utils/fakeUserPrefrences";
const CustomChipFieldWithRedirect = ({
    record,
    source,
    basePath,
    emptyText,
    redirectToResource,
    ...props
}) => {
    const redirect = useRedirect();
    const handleRedirectClick = () => {
        redirect("show", `/${redirectToResource}`, record.id);
    };
    return (
        <ChipField
            onClick={handleRedirectClick}
            source={source}
            record={record}
            emptyText={emptyText}
            {...props}
        />
    );
};


export const ListSinglesShow = (props) => {
    const translate = useTranslate();

    const defaultLocale = useMemo(
        () => userPreferences.defaultTranslationLanguage,
        []
    );
    return (
        <Show
            aside={<CustomAsideSimpleList source="auditTracker" />}
            {...props}
            actions={
                <CustomShowActions
                    source="status"
                    requiredStatuses={requiredStatuses}
                    requiredPermissions={requiredPermissions}
                />
            }
            title={translate("playlists.show.title")}
        >
            <SimpleShowLayout>
                <TextField source={`names.${defaultLocale}`} />
                <TextField source={`descriptions.${defaultLocale}`} />
                <TextField source="status" />
                <NumberField source="index" label="categories.edit.currentIndex" />
                <ArrayField source="modules">
                    <SingleFieldList linkType={false}>
                        <CustomChipFieldWithRedirect
                            source="name"
                            emptyText={translate("empty.module")}
                            redirectToResource="modules"
                        />
                    </SingleFieldList>
                </ArrayField>
            </SimpleShowLayout>
        </Show>
    );
};