import {
	Datagrid,
	EditButton,
	Filter,
	List,
	TextField,
	TextInput,
	DeleteButton,
} from "react-admin";

import { userPreferences } from "../../utils/fakeUserPrefrences";
import { constants } from "../../utils/constants";
import { CustomDeleteButtonWithCustomConfirm } from "../../customComponents/CustomButtons";

const ContentTypeFilter = (props) => (
	<Filter {...props}>
		<TextInput source="search" alwaysOn />
	</Filter>
);

export const SubContentTypeList = ({ ...props }) => {
	return (
		<List
			{...props}
			perPage={constants.perPage}
			bulkActionButtons={false}
			filters={<ContentTypeFilter />}
			empty={false}
		>
			<Datagrid>
				{[
					...new Set([
						...userPreferences.translationLanguages,
						userPreferences.displayLanguage,
					]),
				].map((lang, index) => (
					<TextField
						key={`${lang}${index}`}
						source={`title.${lang}`}
						sortable={false}
					/>
				))}
				<EditButton />
				<CustomDeleteButtonWithCustomConfirm mutationMode="pessimistic" />
			</Datagrid>
		</List>
	);
};
