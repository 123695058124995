import { useFormState } from "react-final-form";
import { useEffect, useState } from "react";
import {
	SelectInput,
	SimpleFormIterator,
	useGetList,
	ArrayInput,
	CheckboxGroupInput,
} from "react-admin";

const getChoices = (items) => Object.values(items).map((item) => item);

const getSubContentTypeChoices = ({
	index,
	contentTypes = null,
	contentTypeData,
}) => {
	if (contentTypes) {
		const selectedContentTypeId = contentTypes[index]?.contentTypeId;
		const contentType = contentTypeData.find(
			({ id }) => id === selectedContentTypeId
		);
		return contentType?.subContentTypes;
	}
};

const CustomCheckboxGroupInput = ({ contentTypeData, ...props }) => {
	const { values } = useFormState();
	const index = props?.source?.split("contentTypes[")[1].split("]")[0];
	return (
		<CheckboxGroupInput
			source={`contentTypes[${index}].contentSubTypes`}
			label="Sub Content Types"
			choices={getSubContentTypeChoices({
				index,
				contentTypes: values.contentTypes,
				contentTypeData,
			})}
			optionText={"displayTitle"}
			optionValue="id"
		/>
	);
};

const CustomArrayInput = ({ contentTypeData }) => {
	return (
		<ArrayInput source="contentTypes" resource="modules">
			<SimpleFormIterator disableReordering>
				<SelectInput
					source="contentTypeId"
					choices={contentTypeData}
					label={"Content Type"}
					optionText={"displayTitle"}
				/>
				<CustomCheckboxGroupInput contentTypeData={contentTypeData} />
			</SimpleFormIterator>
		</ArrayInput>
	);
};
export const CustomContentTypeArrayInput = (props) => {
	const [contentTypeData, setContentTypeData] = useState([]);
	const { data: contentTypeDataObj } = useGetList(
		"content-types",
		{ page: 1, perPage: 10000 },
		{ field: "id", order: "asc" }
	);
	useEffect(() => {
		setContentTypeData(getChoices(contentTypeDataObj));
	}, [contentTypeDataObj]);

	return <CustomArrayInput contentTypeData={contentTypeData} />;
};
