import { useEffect, useState } from "react";
import {
	Create,
	TabbedForm,
	FormTab,
	TextInput,
	RadioButtonGroupInput,
	useGetList,
	useMutation,
	useNotify,
	useRedirect,
	useRefresh,
	useTranslate,
	useCreate,
} from "react-admin";
import { getChoices } from "./shared";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { CustomTransferList } from "../../customComponents/CustomTransferList";
import { setIndexesForData } from "../../utils/customFunctions";
import { genders } from "../../utils/constants";

export const CommunicationFlowCreate = (props) => {
	const translate = useTranslate();
	const [languagesData, setLanguageData] = useState([]);
	const [communicationPagesArray, setCommunicationPagesArray] = useState([]);
	const [lang, setLanguage] = useState(null);
	const [create] = useCreate("communication-flows", props.id);
	const redirect = useRedirect();
	const [mutate] = useMutation();
	const refresh = useRefresh();
	const notify = useNotify();

	const { data: languagesDataObj } = useGetList(
		"languages",
		{ page: 1, perPage: 10 },
		{ field: "id", order: "DESC" }
	);
	useEffect(() => {
		setLanguageData(getChoices(languagesDataObj));
	}, [languagesDataObj]);

	const { data: communicationPagesData } = useGetList("communication-pages");

	const onSuccess = () => {
		notify(`Created successfully`);
		redirect("/communication-flows");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	useEffect(() => {
		if (lang !== null)
			setCommunicationPagesArray(
				Object.values(communicationPagesData).filter(
					(communicationPageData) => {
						return communicationPageData.languageId === lang;
					}
				)
			);
		else setCommunicationPagesArray(communicationPagesData);
	}, [communicationPagesData, lang]);

	return (
		<Create
			{...props}
			mutationMode="pessimistic"
			title={translate("communication-flows.create.title")}
		>
			<TabbedForm
				toolbar={<CustomToolbar />}
				save={(data) => {
					const indexedDataToSend = setIndexesForData(data, [
						"communicationPages",
					]);

					create(
						{
							payload: {
								data: indexedDataToSend,
							},
						},
						{
							onSuccess,
							onFailure,
						}
					);
				}}
				// validate={validateCommunicationFlowData}
				syncWithLocation={false}
			>
				<FormTab label={translate("communication-flows.tabs.basicInfo.label")}>
					<TextInput source="name" />
					<RadioButtonGroupInput
						source="languageId"
						choices={languagesData}
						optionText={({ name }) => `misc.names.languages.${name}`}
						optionValue="id"
						onClick={(event) => setLanguage(event.target.value)}
					/>
					<RadioButtonGroupInput
						source="gender"
						choices={genders}
						optionText={({ name }) => name}
						optionValue="id"
					/>
				</FormTab>
				<FormTab
					label={translate("communication-flows.tabs.pagesManagement.label")}
				>
					<CustomTransferList
						source="communicationPages"
						referenceSource="communication-pages"
						referenceResource="communication-pages"
						referenceField="title"
						isReferenceTranslatable={false}
						languageId={lang}
					/>
				</FormTab>
			</TabbedForm>
		</Create>
	);
};
