import { forwardRef, useEffect, useMemo, useState } from "react";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import NotificationsIcon from "@material-ui/icons/Notifications";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import Brightness3Icon from "@material-ui/icons/Brightness3";
import io from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import Logo from "./Logo";

import {
	AppBar,
	Layout,
	useGetIdentity,
	useLocale,
	useRedirect,
	useTranslate,
	usePermissions,
	useNotify,
} from "react-admin";
import {
	Badge,
	Box,
	Button,
	Fade,
	Grow,
	IconButton,
	InputAdornment,
	List,
	ListItem,
	ListItemText,
	Menu,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { changeTheme } from "../actions";
import SyncIcon from "@material-ui/icons/Sync";
import { CustomDateWithTooltipField } from "../customComponents/CustomDateWithTooltipField";
import { getDateWithFormatAndDistance } from "../utils/customFunctions";
import { envSettings } from "../settings/env";
import { useStyles } from "../utils/styles";
import {
	useCustomFetch,
	useCustomGetListWithPagination,
	useInputs,
	useLastComponentObserver,
} from "../customHooks/customHooks";
import { rolesGlobal } from "../utils/constants";
import { buildErrorMessage } from "../utils/helpers";
import { CustomDialog } from "../customComponents/CustomDialog";
import { CustomButton } from "../customComponents/CustomButtons";
import CancelIcon from "@material-ui/icons/Cancel";
import classnames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const CustomListItem = forwardRef(
	(
		{
			primary = "",
			button = false,
			onClick,
			selected,
			displayIcon,
			secondary,
			inset,
			children,
			translate,
			...rest
		},
		ref
	) => (
		<Fade timeout={800} in ref={ref}>
			{/* we need to discuss the color */}
			<ListItem
				{...rest}
				divider
				selected={selected}
				onClick={onClick}
				button={button}
			>
				{children || (
					<>
						<ListItemText
							inset={inset}
							primary={primary}
							secondary={secondary}
						/>
						{displayIcon ? (
							<Tooltip
								enterDelay={150}
								leaveDelay={125}
								title={translate("tooltips.icons.important")}
								placement="top"
								arrow
								TransitionComponent={Fade}
								TransitionProps={{ timeout: 600 }}
							>
								<PriorityHighIcon color="action" />
							</Tooltip>
						) : (
							<span />
						)}
					</>
				)}
			</ListItem>
		</Fade>
	)
);

const CustomNotficationInfiniteList = ({
	onClose,
	anchorEl,
	onListItemClick,
}) => {
	const [showCalendar, setShowCalendar] = useState(false);
	const [inputs, setInputs] = useInputs({});

	const locale = useLocale();
	const translate = useTranslate();
	const redirect = useRedirect();
	const classes = useStyles();

	const {
		error,
		loading,
		hasMoreData,
		fetchedData: data,
		getSearchResults,
		refreshList,
		getNext,
	} = useCustomGetListWithPagination({
		resource: "notifications",
		sort: { field: "date", order: "DESC" },
	});

	const { customFetch } = useCustomFetch();

	const { rootRef, lastElementRef } = useLastComponentObserver({
		loading,
		hasMoreData,
		getNext,
	});

	if (!anchorEl) return <> </>;

	const handleRedirectToPage = ({ pageUrl, id, isRead }) => {
		onListItemClick((oldTotal) => (oldTotal > 0 ? oldTotal - 1 : 0));
		customFetch({
			method: "PUT",
			resource: "notifications",
			endPoint: "changeStatus",
			requestBody: { id, isRead: isRead ? isRead : !isRead },
		});
		redirect("../../" + pageUrl);
		onClose();
	};

	const handleInputChange = ({ target }) => {
		const tempFilter = { [target.name]: target.value };

		setInputs({ target });
		getSearchResults({ ...inputs, ...tempFilter });
	};

	const handleShowCalendar = ({ target }) => {
		if (target.name === "clear" && target.value)
			handleInputChange({ target: { name: "date", value: "" } });

		setShowCalendar((oldValue) => !oldValue);
	};

	return (
		<Menu
			variant="menu"
			getContentAnchorEl={null}
			anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
			transformOrigin={{ vertical: "top", horizontal: "right" }}
			anchorEl={anchorEl}
			open={Boolean(anchorEl)}
			onClose={onClose}
			ref={rootRef}
			id="scrollableList"
			style={{
				maxHeight: 500,
				overflow: "auto",
				marginTop: "0.125rem",
			}}
		>
			<Grow timeout={1900} in>
				<Box width="28.5rem">
					<List>
						<Fade timeout={2500} in>
							<ListItem alignItems="flex-start">
								<Box
									width={"100%"}
									display="flex"
									justifyContent="space-between"
									alignItems="flex-end"
								>
									{!showCalendar ? (
										<>
											<Box
												width="75%"
												paddingBottom="0.62rem"
											>
												<TextField
													fullWidth
													label={translate(
														"labels.inputs.search"
													)}
													name="search"
													value={inputs.search}
													onChange={handleInputChange}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">
																<IconButton
																	value=""
																	onClick={
																		handleInputChange
																	}
																	name="search"
																	edge="end"
																>
																	<Tooltip
																		enterDelay={
																			150
																		}
																		leaveDelay={
																			125
																		}
																		title={translate(
																			"tooltips.actions.clear",
																			{
																				name: translate(
																					"labels.inputs.search"
																				),
																			}
																		)}
																		placement="top"
																		arrow
																		TransitionComponent={
																			Fade
																		}
																		TransitionProps={{
																			timeout: 600,
																		}}
																	>
																		<ClearIcon
																			name="clear"
																			color="action"
																		/>
																	</Tooltip>
																</IconButton>
															</InputAdornment>
														),
													}}
												/>
											</Box>

											<IconButton
												edge="end"
												name="hide"
												onClick={handleShowCalendar}
											>
												<Tooltip
													enterDelay={150}
													leaveDelay={125}
													title={translate(
														"tooltips.actions.show",
														{
															name: translate(
																"labels.inputs.date"
															),
														}
													)}
													placement="top"
													arrow
													TransitionComponent={Fade}
													TransitionProps={{
														timeout: 600,
													}}
												>
													<CalendarTodayIcon color="action" />
												</Tooltip>
											</IconButton>
										</>
									) : (
										<>
											<Box
												width="62%"
												paddingBottom="0.62rem"
											>
												<TextField
													fullWidth
													value={inputs.date}
													name="date"
													onChange={handleInputChange}
													type="date"
												/>
											</Box>
											<Box
												display="flex"
												justifyContent="space-evenly"
											>
												<IconButton
													edge="end"
													onClick={handleShowCalendar}
													color="action"
												>
													<Tooltip
														enterDelay={150}
														leaveDelay={125}
														title={translate(
															"tooltips.actions.show",
															{
																name: translate(
																	"labels.inputs.search"
																),
															}
														)}
														placement="top"
														arrow
														TransitionComponent={
															Fade
														}
														TransitionProps={{
															timeout: 600,
														}}
													>
														<SearchIcon name="hide" />
													</Tooltip>
												</IconButton>

												<IconButton
													edge="end"
													onClick={handleInputChange}
													color="action"
													value=""
													name="date"
												>
													<Tooltip
														enterDelay={150}
														leaveDelay={125}
														title={translate(
															"tooltips.actions.clear",
															{
																name: translate(
																	"labels.inputs.date"
																),
															}
														)}
														placement="top"
														arrow
														TransitionComponent={
															Fade
														}
														TransitionProps={{
															timeout: 600,
														}}
													>
														<ClearIcon name="clear" />
													</Tooltip>
												</IconButton>
											</Box>
										</>
									)}
								</Box>
							</ListItem>
						</Fade>
						{!loading && !data.length ? (
							<ListItem>
								<Typography>
									{translate("empty.notifications")}
								</Typography>
							</ListItem>
						) : (
							data?.map(
								(
									{
										id,
										messages,
										hasPriority,
										isRead,
										resource,
										recordId,
										date,
									},
									index
								) => {
									let ref = null;
									const {
										formatedDate,
										formatedDistanceDate,
									} = getDateWithFormatAndDistance(date);

									const pageUrl = `${resource}/${recordId}/show`;

									if (data.length === index + 1)
										ref = lastElementRef;

									return (
										<CustomListItem
											ref={ref}
											classes={classes}
											key={`_${index + date}`}
											primary={messages[locale]}
											onClick={() =>
												handleRedirectToPage({
													pageUrl,
													id,
													isRead,
												})
											}
											disableTypography
											translate={translate}
											selected={isRead}
											displayIcon={hasPriority}
											button={true}
											secondary={
												<CustomDateWithTooltipField
													title={formatedDistanceDate}
													tooltipTitle={formatedDate}
												/>
											}
										/>
									);
								}
							)
						)}
						{loading && (
							<ListItem>
								<Typography>
									{translate("messages.loading")}
								</Typography>
							</ListItem>
						)}
						{!loading && !error && hasMoreData && (
							<ListItem onClick={getNext}>
								<Button>
									<Typography>
										{translate("buttons.next")}
									</Typography>
								</Button>
							</ListItem>
						)}
						{error && (
							<ListItem onClick={refreshList}>
								<Button>
									<Typography>
										{translate("buttons.refresh")}
									</Typography>
								</Button>
							</ListItem>
						)}
					</List>
				</Box>
			</Grow>
		</Menu>
	);
};

const CustomIconWithBadge = ({
	max = 9,
	onClick,
	overLap,
	badgeContent,
	color = "error",
	children,
}) => (
	<IconButton onClick={onClick}>
		<Badge {...overLap} badgeContent={badgeContent} max={max} color={color}>
			{children || <NotificationsIcon />}
		</Badge>
	</IconButton>
);

const CustomNotficationComponent = () => {
	const [totalUnread, setTotalUnread] = useState(0);
	const [anchorEl, setAnchorEl] = useState(null);

	const { identity, loading: loadingIdentity } = useGetIdentity();
	const { data, loading: loadingTotalUnread } = useCustomFetch({
		endPoint: "count",
		resource: "notifications",
		method: "GET",
	});

	useEffect(
		() => !loadingTotalUnread && data && setTotalUnread(data),
		[data, loadingTotalUnread]
	);

	useEffect(() => {
		if (loadingIdentity) return;
		const socket = io(envSettings.socketBaseUrl);

		socket.emit("hand-shake", { userId: identity?.id });

		socket.on("sendNotification", ({ totalUnread: total }) => {
			setTotalUnread(total);
		});
	}, [loadingIdentity, identity?.id]);

	const badgeDynamicProps = useMemo(
		() => (!totalUnread ? {} : { overlap: "rectangle" }),
		[totalUnread]
	);

	const handleNotificationListOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleNotificationListClose = () => {
		setAnchorEl(null);
	};

	return (
		<Box>
			<CustomIconWithBadge
				badgeContent={totalUnread}
				overLap={badgeDynamicProps}
				onClick={handleNotificationListOpen}
				max={99}
			/>
			{anchorEl && (
				<CustomNotficationInfiniteList
					onListItemClick={setTotalUnread}
					anchorEl={anchorEl}
					onClose={handleNotificationListClose}
				/>
			)}
		</Box>
	);
};

const CustomRefreshAppContent = ({ permissions }) => {
	const [openDialog, setOpenDialog] = useState(false);
	const notify = useNotify();

	const onFailure = (error) => {
		notify(buildErrorMessage(error), "warning");
	};

	const handleOpen = () => {
		setOpenDialog(true);
	};

	const handleConfirm = async () => {
		try {
			const res = await axios.post(
				envSettings.baseUrl + "/settings/app/force-refresh",
				{},
				{ withCredentials: true }
			);
			setOpenDialog(false);
			notify("Refreshed Successfully!", "success");
		} catch (error) {
			onFailure(error);
		}
	};

	const handleCancel = () => {
		setOpenDialog(false);
	};
	return (
		<>
			{(permissions?.includes(rolesGlobal.systemAdmin) ||
				permissions?.includes(rolesGlobal.contentManager)) && (
				<Tooltip
					title="Refresh Application Content"
					enterDelay={50}
					leaveDelay={50}
				>
					<IconButton onClick={handleOpen}>
						<SyncIcon />
					</IconButton>
				</Tooltip>
			)}
			<CustomDialog
				open={openDialog}
				title={"WARNING!"}
				content={
					"Are you sure you want to refresh the content for all the users?"
				}
				onClose={handleCancel}
			>
				{" "}
				<CustomButton
					onClick={handleCancel}
					innerStyle={{
						display: "flex",
						alignItems: "unset",
						justifyContent: "center",
					}}
					innerText="buttons.cancel"
					icon={<CancelIcon />}
					className={classnames("ra-confirm")}
					autoFocus
				/>
				<CustomButton
					onClick={handleConfirm}
					innerStyle={{
						display: "flex",
						alignItems: "unset",
						justifyContent: "center",
					}}
					innerText="buttons.confirm"
					icon={<CheckCircleIcon />}
					className={classnames("ra-confirm")}
					color="primary"
				/>
			</CustomDialog>
		</>
	);
};

export const CustomAppBar = ({ ...props }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const theme = useSelector((state) => state.theme);
	const { permissions } = usePermissions();

	return (
		<AppBar {...props}>
			<Typography
				variant="h6"
				color="inherit"
				className={classes.title}
				id="react-admin-title"
			/>

			<div className={classes.spacer}>
				<Logo />
			</div>
			<CustomRefreshAppContent permissions={permissions} />
			{theme === "dark" ? (
				<Tooltip title="Light" enterDelay={50} leaveDelay={50}>
					<IconButton
						value="light"
						onClick={() => dispatch(changeTheme("light"))}
					>
						<WbSunnyIcon />
					</IconButton>
				</Tooltip>
			) : (
				<Tooltip title="Dark" enterDelay={50} leaveDelay={50}>
					<IconButton
						value="dark"
						onClick={() => dispatch(changeTheme("dark"))}
					>
						<Brightness3Icon />
					</IconButton>
				</Tooltip>
			)}
			<CustomNotficationComponent {...props} />
		</AppBar>
	);
};
