import { DayPortionCreate } from "./dayPortionCreate";
import { DayPortionEdit } from "./dayPortionEdit";
import { DayPortionList } from "./dayPortionList";
import { DayPortionShow } from "./dayPortionShow";

export default {
	create: DayPortionCreate,
	edit: DayPortionEdit,
	list: DayPortionList,
	show: DayPortionShow,
};
