import { ObjectiveCreate } from "./objectiveCreate";
import { ObjectiveEdit } from "./objectiveEdit";
import { ObjectiveList } from "./objectiveList";
import { ObjectiveShow } from "./objectiveShow";

export default {
	create: ObjectiveCreate,
	edit: ObjectiveEdit,
	list: ObjectiveList,
	show: ObjectiveShow,
};
