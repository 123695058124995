import {
	ArrayField,
	ChipField,
	Show,
	SimpleShowLayout,
	SingleFieldList,
	TextField,
	useRedirect,
	useTranslate,
} from "react-admin";
import { useMemo } from "react";
import { userPreferences } from "../../utils/fakeUserPrefrences";

const CustomChipFieldWithRedirect = ({
	record,
	source,
	basePath,
	emptyText,
	redirectToResource,
	...props
}) => {
	const redirect = useRedirect();
	const handleRedirectClick = () => {
		redirect("show", `/${redirectToResource}`, record.id);
	};
	return (
		<ChipField
			onClick={handleRedirectClick}
			source={source}
			record={record}
			emptyText={emptyText}
			{...props}
		/>
	);
};

const CustomOptionsField = ({ record: { options } }) => {
	const translate = useTranslate();
	return options?.plans ? (
		<ArrayField source="options[plans]">
			<SingleFieldList linkType={false}>
				<CustomChipFieldWithRedirect
					source="names['ar']"
					emptyText={translate("empty.plan")}
					redirectToResource="plans"
				/>
			</SingleFieldList>
		</ArrayField>
	) : options?.playlists ? (
		<ArrayField source="options[playlists]">
			<SingleFieldList linkType={false}>
				<CustomChipFieldWithRedirect
					source="names['ar']"
					emptyText={translate("empty.playlist")}
					redirectToResource="playlists"
				/>
			</SingleFieldList>
		</ArrayField>
	) : options?.singles ? (
		<ArrayField source="options[singles]">
			<SingleFieldList linkType={false}>
				<CustomChipFieldWithRedirect
					source="name"
					emptyText={translate("empty.module")}
					redirectToResource="modules"
				/>
			</SingleFieldList>
		</ArrayField>
	) : (
		<></>
	);
};

export const TaskShow = (props) => {
	const translate = useTranslate();
	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);
	return (
		<Show {...props} title={translate("tasks.show.title")}>
			<SimpleShowLayout>
				<TextField source={`name.${defaultLocale}`} sortable={false} />
				<TextField source="taskEventName" label={"Event"} />
				<CustomOptionsField />
			</SimpleShowLayout>
		</Show>
	);
};
