import {
	AutocompleteArrayInput,
	Create,
	SimpleForm,
	TextInput,
	useNotify,
	useRedirect,
	useRefresh,
	useTranslate,
} from "react-admin";

import { rolesChoicesArr } from "../../utils/constants";
import { validateUserData } from "./userValidate";
import { useStyles } from "../../utils/styles";
import { CustomToolbar } from "../../customComponents/CustomToolbar";

export const UserCreate = (props) => {
	const classes = useStyles();
	const translate = useTranslate();
	const notify = useNotify();
	const refresh = useRefresh();
	const redirect = useRedirect();

	const onSuccess = () => {
		notify("users.create.success", "info");
		redirect("/users");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	return (
		<Create
			{...props}
			title={translate("users.create.title")}
			onSuccess={onSuccess}
			onFailure={onFailure}
			toolbar={<CustomToolbar />}
		>
			<SimpleForm validate={validateUserData}>
				<TextInput
					source="firstName"
					required={true}
					className={classes.inline}
				/>
				<TextInput
					source="lastName"
					required={true}
					className={classes.inline}
				/>
				<TextInput source="email" required={true} />
				<AutocompleteArrayInput
					isRequired={true}
					source="roles"
					choices={rolesChoicesArr}
				/>
			</SimpleForm>
		</Create>
	);
};
