import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslate } from "react-admin";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from "@material-ui/core";

export const CustomDialog = ({
	open,
	onClose,
	title,
	content,
	children,
	...rest
}) => {
	const [openState, setOpenState] = useState(false);
	const translate = useTranslate();

	const handleClose = useCallback(() => {
		if (typeof onClose === "function") return onClose();
		return setOpenState(false);
	}, [onClose]);

	useEffect(() => {
		setOpenState(!!open);
	}, [open]);

	const translatedTitle = useMemo(() => {
		if (title !== null && typeof title === "object")
			return translate(title.message, title.args);
		return translate(title);
	}, [title]);

	const translatedContent = useMemo(() => {
		if (content !== null && typeof content === "object")
			return translate(content.message, content.args);
		return translate(content);
	}, [content]);

	return (
		<Dialog
			{...rest}
			open={openState}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogTitle id="alert-dialog-title">{translatedTitle}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{translatedContent}
				</DialogContentText>
			</DialogContent>
			<DialogActions>{children}</DialogActions>
		</Dialog>
	);
};
