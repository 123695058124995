import { isNotInRange } from "../../utils/customFunctions";

export const validateBadgeData = ({ name }) => {
	const errors = {};
	if (!name) {
		errors.name = {
			message: "validationErrors.required.requiredField",
			args: { field: "Name" },
		};
	}

	if (name && isNotInRange({ min: 3, max: 50, str: name })) {
		errors.name = {
			message: "validationErrors.minMax.name",
			args: { min: 3, max: 50 },
		};
	}

	return errors;
};
