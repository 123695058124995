import {
	Create,
	useCreate,
	TextInput,
	SimpleForm,
	FileInput,
	useNotify,
	useRedirect,
	FileField,
	RadioButtonGroupInput,
	useRefresh,
	SimpleFormIterator,
	SelectInput,
	TranslatableInputs,
	useGetList,
	ArrayInput,
	useTranslate,
	BooleanInput,
} from "react-admin";
import { validateTimelineData } from "./timelineValidate";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { useEffect, useState, useMemo } from "react";
import {
	setIndexesForData,
	transformToFormData,
} from "../../utils/customFunctions";
import { getChoices } from "./shared";

export const TimelineCreate = (props) => {
	const [languagesArray, setLanguagesArray] = useState([]);
	const [languagesData, setLanguagesData] = useState([]);
	const [badgesArray, setBadgesArray] = useState([]);
	const translate = useTranslate();
	const redirect = useRedirect();
	const refresh = useRefresh();
	const notify = useNotify();
	const [create] = useCreate("timelines");
	const onSuccess = () => {
		notify(translate("timelines.create.success"), "info");
		redirect("/timelines");
		refresh();
	};
	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	const { data: languagesDataObj, loading } = useGetList("languages");
	const { data: badgesData } = useGetList("badges", {
		page: 1,
		perPage: Number.MAX_SAFE_INTEGER,
	});

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);

	useEffect(() => {
		setLanguagesArray(
			Object.values(languagesDataObj).map((language) =>
				language.name.slice(0, 2).toLowerCase()
			)
		);
		setLanguagesData(getChoices(languagesDataObj));
	}, [languagesDataObj]);

	useEffect(() => {
		setBadgesArray(
			Object.values(badgesData).map(({ id, name }) => ({
				id,
				name: name[defaultLocale],
			}))
		);
	}, [badgesData, defaultLocale]);

	return (
		<>
			{loading ? (
				<center>
					<h1>Loading...</h1>
				</center>
			) : (
				<Create {...props} title={translate("timelines.create.title")}>
					<SimpleForm
						save={(data) => {
							let error = null;
							const indexedDataToSend = setIndexesForData(data, [
								"badges",
							]);
							data.badges = data.badges ? data.badges : [];
							if (data.badges?.length !== 0) {
								const badgesIds =
									data.badges.map(
										({ id = null } = {}) => id
									) ?? [];
								const isDuplicate = badgesIds.some(
									(id, idx) => badgesIds.indexOf(id) !== idx
								);
								if (isDuplicate)
									error =
										"Badge should not be repeated in the same timeline!";
							}
							if (!error) {
								const transformed =
									transformToFormData(indexedDataToSend);
								create(
									{
										payload: {
											data: transformed,
										},
									},
									{
										onSuccess,
										onFailure,
									}
								);
							} else notify(error, "error");
						}}
						toolbar={<CustomToolbar />}
						validate={validateTimelineData}
						syncWithLocation={false}
					>
						<TranslatableInputs
							locales={languagesArray}
							defaultLocale={defaultLocale}
						>
							<TextInput source="name" />
							<TextInput source="description" />
							<TextInput source="subTitle" label={"Sub Title"} />
							<TextInput
								source="completionTitle"
								label={"Completion Title"}
							/>
							<TextInput
								source="pushNotificationTitle"
								label={"Push Notification Title"}
								inputProps={{ maxLength: 60 }}
								helperText="Maximum 60 characters"
							/>
							<TextInput
								source="pushNotificationMessage"
								label={"Push Notification Message"}
								inputProps={{ maxLength: 220 }}
								helperText="Maximum 220 characters"
							/>
						</TranslatableInputs>
						<RadioButtonGroupInput
							source="languageId"
							choices={languagesData}
							optionText={({ name }) =>
								`misc.names.languages.${name}`
							}
							optionValue="id"
						/>
						<BooleanInput
							source="isChallenge"
							label="Challenge pathway"
							defaultValue={false}
						/>
						<FileInput
							{...props}
							source="icon"
							label="Timeline Icon"
						>
							<FileField source="src" title="Timeline Icon" />
						</FileInput>
						<FileInput
							{...props}
							source="completionIcon"
							label="Timeline Completion Icon"
						>
							<FileField source="src" title="Timeline Icon" />
						</FileInput>
						<ArrayInput source="badges" label="Days">
							<SimpleFormIterator>
								{/* <NumberInput
									source="days"
									validate={[
										minValue(1),
										(num) => {
											if (!num)
												return "Must be a number!";
										},
									]}
								/> */}
								<SelectInput
									source="id"
									choices={badgesArray}
									label={"Day"}
								/>
							</SimpleFormIterator>
						</ArrayInput>
					</SimpleForm>
				</Create>
			)}
		</>
	);
};
