import { useInput, useTranslate } from "react-admin";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useMemo, useState } from "react";
import {
	Box,
	Card,
	CardContent,
	Divider,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Typography,
} from "@material-ui/core";

import {
	useInputs,
	useLastComponentObserver,
} from "../customHooks/customHooks";
import { userPreferences } from "../utils/fakeUserPrefrences";

export const reorderLists = ({
	removeFrom,
	insertTo,
	removeIndex,
	insertIndex,
}) => {
	const removedFromArray = [...removeFrom];
	const insertedToArray =
		removeFrom === insertTo ? removedFromArray : [...insertTo];
	const [removed] = removedFromArray.splice(removeIndex, 1);
	insertedToArray.splice(insertIndex, 0, removed);

	return {
		removedFromResultArray: removedFromArray,
		insertedToResultArray: insertedToArray,
	};
};

const getItemStyle = (isDragging, draggableStyle) => ({
	...draggableStyle,

	...(isDragging && {
		background: "rgba(235,235,235, 0.6)",
	}),
});

const getListStyle = (isDraggingOver, length = 1) => ({
	height: 49 * length,
	background: !isDraggingOver ? "" : "rgba(235,235,235, 0.4)",
});

export const CustomSortList = ({
	source,
	referenceResource,
	referenceField,
	isReferenceTranslatable,
	values,
}) => {
	let {
		input: { onChange },
	} = useInput({ source });
	return (
		<CustomDragDropSortList
			setDataToSend={onChange}
			values={values}
			source={source}
			referenceResource={referenceResource}
			referenceField={referenceField}
			isReferenceTranslatable={isReferenceTranslatable}
		/>
	);
};

const CustomDragDropSortList = ({
	values,
	referenceResource,
	referenceField,
	setDataToSend,
	isReferenceTranslatable,
	source,
}) => {
	const translate = useTranslate();
	const [data, setData] = useState(values);
	const { rootRef, lastElementRef } = useLastComponentObserver({
		loading: false,
		hasMoreData: false,
		getNext: () => {},
	});

	const onDragEnd = ({ destination, source }) => {
		if (!destination) return;
		if (
			source.droppableId === "main" &&
			destination.droppableId === "main"
		) {
			const { insertedToResultArray: result } = reorderLists({
				removeFrom: data,
				insertTo: data,
				removeIndex: source.index,
				insertIndex: destination.index,
			});
			setData([...result]);
			setDataToSend([...result]);
		}
	};
	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Box my="1.25rem">
				<Typography color="textSecondary">
					{translate("sort.helperText", {
						source: referenceResource,
						referenceSource: source,
					})}
				</Typography>
			</Box>
			<Box my="3.25rem" display="flex">
				<Box mr="2rem" minWidth={300} maxWidth="auto">
					<Box mb="0.25rem">
						<Typography color="textSecondary">
							{translate("sort.availableSource", {
								source: translate(
									`resources.${referenceResource}.${referenceField}`
								),
							})}
						</Typography>
					</Box>
					<Card variant="outlined">
						<CardContent>
							<Droppable droppableId="main">
								{(provided, snapshot) => (
									<Box
										style={{
											height: 400,
											overflow: "auto",
										}}
										ref={rootRef}
									>
										<Box
											ref={provided.innerRef}
											style={getListStyle(
												snapshot.isDraggingOver,
												data?.length
											)}
										>
											<List>
												{data?.map((item, index) => (
													<Draggable
														key={
															item?.id + "" ??
															Math.random()
														}
														draggableId={
															item?.id + "" ??
															Math.random()
														}
														index={index}
													>
														{(
															provided,
															snapshot
														) => (
															<Box
																ref={
																	data.length ===
																	index + 1
																		? lastElementRef
																		: null
																}
															>
																<ListItem
																	button
																	ContainerComponent="li"
																	ContainerProps={{
																		ref: provided.innerRef,
																	}}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	style={getItemStyle(
																		snapshot.isDragging,
																		provided
																			.draggableProps
																			.style
																	)}
																>
																	<ListItemText
																		primary={
																			item[
																				referenceField
																			]
																				? isReferenceTranslatable ===
																				  true
																					? item[
																							referenceField
																					  ][
																							"ar"
																					  ] ??
																					  item[
																							referenceField
																					  ][
																							"en"
																					  ]
																					: item[
																							referenceField
																					  ]
																				: ""
																		}
																		secondary={
																			item.secondary
																		}
																	/>
																	<ListItemSecondaryAction />
																</ListItem>
																<Divider />
															</Box>
														)}
													</Draggable>
												))}
												{provided.placeholder}
											</List>
										</Box>
									</Box>
								)}
							</Droppable>
						</CardContent>
					</Card>
				</Box>
			</Box>
		</DragDropContext>
	);
};
export default CustomSortList;
