import React, { useState } from "react";
import {
	DateTimeInput,
	Create,
	FormTab,
	useRefresh,
	TabbedForm,
	TextInput,
	useCreate,
	useNotify,
	useRedirect,
	useTranslate,
} from "react-admin";
import { setIndexesForData } from "../../utils/customFunctions";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { CustomTransferList } from "../../customComponents/CustomTransferList";
import { validateBusinessData } from "./businessValidate";

export const BusinessCreate = (props) => {
	const redirect = useRedirect();
	const refresh = useRefresh();
	const translate = useTranslate();
	const notify = useNotify();
	const [create] = useCreate("businesses");

	const onSuccess = () => {
		notify("businesses.create.success", "info");
		redirect("/businesses");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	return (
		<Create {...props} title={translate("businesses.create.title")}>
			<TabbedForm
				toolbar={<CustomToolbar />}
				save={({ validFrom, validTo, ...data }) => {
					validFrom = new Date(validFrom).toLocaleString();
					validTo = new Date(validTo).toLocaleString();
					const indexedDataToSend = setIndexesForData(data, [
						"modules",
					]);
					create(
						{
							payload: {
								data: {
									...indexedDataToSend,
									validFrom,
									validTo,
								},
							},
						},
						{
							onSuccess,
							onFailure,
						}
					);
				}}
				validate={validateBusinessData}
				syncWithLocation={false}
			>
				<FormTab label={translate("businesses.tabs.basicInfo.label")}>
					<TextInput source="name" />
					<DateTimeInput source="validFrom" />
					<DateTimeInput source="validTo" />
				</FormTab>
				<FormTab
					label={translate("businesses.tabs.modulesManagement.label")}
				>
					<CustomTransferList
						source="modules"
						referenceSource="modules"
						referenceResource="modules"
						referenceField="name"
						isReferenceTranslatable={false}
					/>
				</FormTab>
			</TabbedForm>
		</Create>
	);
};
