import { Labeled, Show, SimpleShowLayout, TextField } from "react-admin";
import { useTranslate } from "ra-core";
import { CustomAsideSimpleList } from "../../customComponents/CustomAsideSimpleList";
import { CustomShowActions } from "../../customComponents/CustomShowActions";
import { requiredPermissions, requiredStatuses } from "./shared";

const AudioPlayerWithLabel = ({ record: { audioUrl, audioDisplayName } }) => {
	const translate = useTranslate();
	return (
		<Labeled label={translate(`background-modules.show.audioFile`)}>
			<>
				<h6>{audioDisplayName}</h6>
				<audio controls src={audioUrl} />
			</>
		</Labeled>
	);
};

export const BackgroundModuleShow = (props) => {
	const translate = useTranslate();
	return (
		<Show
			aside={<CustomAsideSimpleList source="auditTracker" />}
			{...props}
			actions={
				<CustomShowActions
					source="status"
					requiredStatuses={requiredStatuses}
					requiredPermissions={requiredPermissions}
				/>
			}
			title={translate(`background-modules.show.title`)}
		>
			<SimpleShowLayout>
				<TextField source="name" />
				<TextField source="language.name" />
				<TextField source="status" />
				<AudioPlayerWithLabel source="audioUrl" label="Audio" />
			</SimpleShowLayout>
		</Show>
	);
};
