import { useEffect, useMemo, useState } from "react";
import {
	AutocompleteInput,
	Edit,
	FormTab,
	ImageField,
	ImageInput,
	SelectInput,
	BooleanInput,
	TabbedForm,
	TextInput,
	TranslatableInputs,
	useGetList,
	useNotify,
	useRedirect,
	useTranslate,
	useUpdate,
	RadioButtonGroupInput,
} from "react-admin";
import { getChoices } from "./shared";
import { aspectRatios } from "../../utils/constants";
import { CustomTransferList } from "../../customComponents/CustomTransferList";
import CustomImageField from "../../customComponents/CustomImageField";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { CustomContentTypeArrayInput } from "../../customComponents/CustomContentTypeArrayInput";
import { validatePlaylistData } from "./playlistValidate";
import {
	setIndexesForData,
	transformToFormData,
} from "../../utils/customFunctions";
import CustomChipsArrayInput from "../../customComponents/CustomChipsArrayInput";

export const PlaylistEdit = (props) => {
	const [backgroundPlaylistsArray, setBackgroundPlaylistsArray] = useState(
		[]
	);
	const [categoriesArray, setCategoriesArray] = useState([]);
	const [languagesArray, setLanguagesArray] = useState([]);
	const [languageData, setLanguageData] = useState([]);

	const [update] = useUpdate("playlists", props.id);
	const translate = useTranslate();
	const redirect = useRedirect();
	const notify = useNotify();

	const { data: backgroundPlaylistsData } = useGetList(
		"background-playlists"
	);

	const { data: categoriesData } = useGetList("categories");

	const { data: languagesData } = useGetList("languages");

	const onSuccess = () => {
		notify(translate("playlists.edit.success"), "info");
		redirect("/playlists");
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);

	useEffect(() => {
		setLanguageData(getChoices(languagesData));
		setLanguagesArray(
			Object.values(languagesData).map((language) =>
				language.name.slice(0, 2).toLowerCase()
			)
		);
	}, [languagesData]);
	useEffect(() => {
		setCategoriesArray(
			Object.values(categoriesData).map(({ id, titles }) => ({
				id,
				name: titles[defaultLocale],
			}))
		);
	}, [categoriesData]);

	useEffect(() => {
		setBackgroundPlaylistsArray(
			Object.values(backgroundPlaylistsData).map(({ id, names }) => ({
				id,
				name: names[defaultLocale],
			}))
		);
	}, [backgroundPlaylistsData]);

	return (
		<Edit {...props} title={translate("playlists.edit.title")}>
			<TabbedForm
				submitOnEnter={false}
				toolbar={<CustomToolbar />}
				save={(data) => {
					const indexedDataToSend = setIndexesForData(data, [
						"modules",
						"objectives",
					]);
					const transformed = transformToFormData(indexedDataToSend);
					update(
						{ payload: { data: transformed } },
						{
							onSuccess,
							onFailure,
						}
					);
				}}
				validate={validatePlaylistData}
				syncWithLocation={false}
			>
				<FormTab label={translate("playlists.tabs.basicInfo.label")}>
					<TranslatableInputs
						locales={languagesArray}
						defaultLocale={defaultLocale}
					>
						<TextInput source="names" />
						<TextInput multiline source="descriptions" />
					</TranslatableInputs>

					<AutocompleteInput
						resettable
						source="backgroundPlaylistId"
						choices={backgroundPlaylistsArray}
					/>

					<SelectInput
						source="categoryId"
						choices={categoriesArray}
					/>
					<RadioButtonGroupInput
						source="languageId"
						choices={languageData}
						optionText={({ name }) =>
							`misc.names.languages.${name}`
						}
						optionValue="id"
					/>
					<BooleanInput defaultValue={false} source="comingSoon" />
				</FormTab>
				<FormTab label="Manage Images">
					<CustomImageField source="homeImageUrl" />
					<ImageInput
						source="homePageImage"
						accept="image/*"
						helperText={
							"The aspect ratio should be " +
							aspectRatios.playlistInHomePage
						}
						placeholder={translate(
							"playlists.edit.uploadHomeImagePlaceholder"
						)}
					>
						<ImageField source="src" />
					</ImageInput>

					<CustomImageField source="playlistImageUrl" />
					<ImageInput
						source="playlistPageImage"
						accept="image/*"
						helperText={
							"The aspect ratio should be " +
							aspectRatios.playlistInPlaylistPage
						}
						placeholder={translate(
							"playlists.edit.uploadPlaylistImagePlaceholder"
						)}
					>
						<ImageField source="src" />
					</ImageInput>
				</FormTab>
				<FormTab
					label={translate("playlists.tabs.modulesManagement.label")}
				>
					<CustomTransferList
						source="modules"
						referenceSource="modules"
						referenceResource="modules"
						referenceField="name"
						isReferenceTranslatable={false}
					/>
				</FormTab>
				<FormTab
					label={translate(
						"playlists.tabs.objectivesManagement.label"
					)}
				>
					<CustomTransferList
						source="objectives"
						referenceSource="objectives"
						referenceResource="objectives"
						referenceField="titles"
						isReferenceTranslatable={true}
					/>
				</FormTab>
				<FormTab
					label={translate("playlists.tabs.typesManagement.label")}
				>
					<CustomContentTypeArrayInput />
				</FormTab>
			</TabbedForm>
		</Edit>
	);
};
