import { SurveyCreate } from "./surveyCreate";
import { SurveyEdit } from "./surveyEdit";
import { SurveyList } from "./surveyList";
import { SurveyShow } from "./surveyShow";

export default {
	create: SurveyCreate,
	edit: SurveyEdit,
	list: SurveyList,
	show: SurveyShow,
};
