export const SVGViewer = ({ svg = null, title, alt }) => {
	return (
		<>
			{svg && (
				<>
					<img
						src={`data:image/svg+xml;base64,${btoa(svg)}`}
						alt={alt || title}
						title={title}
						width="150px"
						height="150px"
						style={{
							marginTop: "15px",
							marginRight: "15px",
							marginLeft: "15px",
						}}
					/>
					<h6>{title}</h6>
				</>
			)}
		</>
	);
};
