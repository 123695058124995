import React from "react";
import { Show, SimpleShowLayout, TextField } from "react-admin";
import { useTranslate } from "ra-core";

export const UserSuggestionShow = (props) => {
	const translate = useTranslate();
	return (
		<Show {...props} title={translate("user-suggestions.show.title")}>
			<SimpleShowLayout>
				<TextField source="relatedTo" />
				<TextField source="suggestion" />
			</SimpleShowLayout>
		</Show>
	);
};
