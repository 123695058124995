import React from "react";
import {
	Datagrid,
	EditButton,
	Filter,
	List,
	ShowButton,
	TextField,
	useTranslate,
} from "react-admin";
import { constants } from "../../utils/constants";
import { CustomDeleteButtonWithConfirmation } from "../../customComponents/CustomButtons";
import { useMemo } from "react";
import { userPreferences } from "../../utils/fakeUserPrefrences";

const DayPortionFilter = ({ ...props }) => <Filter {...props}></Filter>;

export const FeaturedList = (props) => {
	const translate = useTranslate();
	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);
	return (
		<List
			{...props}
			perPage={constants.perPage}
			bulkActionButtons={false}
			title={translate("featured.name")}
			filters={<DayPortionFilter />}
		>
			<Datagrid>
				<TextField
					source={`title`}
					label={`Title(en)`}
					sortable={false}
				/>
				<TextField source="date" />
				<ShowButton />
				<EditButton />
				<CustomDeleteButtonWithConfirmation
					confirmTitle={"Delete Featured Content"}
				/>
			</Datagrid>
		</List>
	);
};
