import {
	Create,
	TextInput,
	SimpleForm,
	SelectInput,
	useNotify,
	useRedirect,
	useRefresh,
	TranslatableInputs,
	useTranslate,
	AutocompleteInput,
	useGetList,
} from "react-admin";
import { validateTaskData } from "./taskValidate";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { useEffect, useState, useMemo } from "react";
import axios from "axios";
import { envSettings } from "../../settings/env";
import { taskEvents } from "../../utils/constants";
import { useCustomGetListWithPagination } from "../../customHooks/customHooks";
export const TaskCreate = (props) => {
	const [modulesData, setModulesData] = useState([]);
	const [journalsData, setJournalsData] = useState([]);
	const [languagesArray, setLanguagesArray] = useState([]);
	const [events, setEvents] = useState([]);
	const [currentEvent, setCurrentEvent] = useState(null);
	const [loading, setLoading] = useState(true);
	const translate = useTranslate();
	const redirect = useRedirect();
	const refresh = useRefresh();
	const notify = useNotify();

	let { loading: modulesLoading, fetchedData: modulesDataObj } =
		useCustomGetListWithPagination({
			resource: "modules",
			withPagination: false,
			sort: { field: "id", order: "DESC" },
		});

	const { data: journalsDataObj, loading: journalsLoading } =
		useGetList("journals");

	const getModuleChoices = (modules) =>
		Object.values(modules).map((module) => module);

	const getJournalChoices = (journals) => [
		{
			id: Infinity,
			name: { ar: "كتابة حرة" },
		},
		...Object.values(journals).map((journal) => journal),
	];

	const onSuccess = () => {
		notify(translate("tasks.create.success"), "info");
		redirect("/tasks");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	const { data: languagesData } = useGetList("languages");

	useEffect(() => {
		setLanguagesArray(
			Object.values(languagesData).map((language) =>
				language.name.slice(0, 2).toLowerCase()
			)
		);
	}, [languagesData]);

	useEffect(() => {
		if (!modulesLoading) setModulesData(getModuleChoices(modulesDataObj));
	}, [modulesDataObj, modulesLoading]);

	useEffect(() => {
		if (!journalsLoading)
			setJournalsData(getJournalChoices(journalsDataObj));
	}, [journalsDataObj, journalsLoading]);

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);

	useEffect(() => {
		(async () => {
			const res = await axios.get(envSettings.baseUrl + "/tasks/events", {
				withCredentials: true,
			});
			setEvents(res?.data?.data);
			setLoading(false);
		})();
	}, []);

	return (
		<>
			{loading || modulesLoading ? (
				<center>
					<h1>Loading...</h1>
				</center>
			) : (
				<Create
					{...props}
					title={translate("tasks.create.title")}
					onSuccess={onSuccess}
					onFailure={onFailure}
				>
					<SimpleForm
						toolbar={<CustomToolbar />}
						validate={validateTaskData}
						syncWithLocation={false}
					>
						<TranslatableInputs
							locales={languagesArray}
							defaultLocale={defaultLocale}
						>
							<TextInput source="name" />
						</TranslatableInputs>
						<SelectInput
							source="taskEventAction"
							choices={events}
							label={"Task Event"}
							optionText={"name"}
							optionValue={"action"}
							onChange={(event) => {
								setCurrentEvent(event.target.value);
							}}
						/>

						{currentEvent === taskEvents.listenToSingle && (
							<AutocompleteInput
								source="singleId"
								label="Module"
								choices={modulesData}
							/>
						)}

						{currentEvent === taskEvents.createJournal && (
							<AutocompleteInput
								source="journalId"
								choices={journalsData}
								optionText={"name['ar']"}
							/>
						)}
					</SimpleForm>
				</Create>
			)}
		</>
	);
};
