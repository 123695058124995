import { useFormState } from "react-final-form";
import { useEffect, useRef, useState, useMemo } from "react";
import {
	Edit,
	FileField,
	FileInput,
	ImageField,
	ImageInput,
	RadioButtonGroupInput,
	SaveButton,
	AutocompleteArrayInput,
	AutocompleteInput,
	useGetOne,
	BooleanInput,
	TextInput,
	Toolbar,
	TabbedForm,
	FormTab,
	useGetList,
	useNotify,
	useRedirect,
	useRefresh,
	useTranslate,
	useUpdate,
	Button,
} from "react-admin";
import { getChoices, requiredPermissions, requiredStatuses } from "./shared";
import { validateModuleData } from "./moduleValidate";
import { narratorListenerVariations } from "../../utils/constants";
import {
	checkIfExists,
	transformToFormData,
} from "../../utils/customFunctions";
import { CustomVariantList } from "../../customComponents/CustomVariantList";
import CustomImageField from "../../customComponents/CustomImageField";
import { aspectRatios, userTypes } from "../../utils/constants";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { CustomContentTypeArrayInput } from "../../customComponents/CustomContentTypeArrayInput";
import ActionDelete from "@material-ui/icons/Delete";
import { CustomDialog } from "../../customComponents/CustomDialog";
import { CustomButton } from "../../customComponents/CustomButtons";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import classnames from "classnames";
import { Box } from "@material-ui/core";
import { CustomTransferList } from "../../customComponents/CustomTransferList";
import { setIndexesForData } from "../../utils/customFunctions";
import { SVGViewer } from "../../customComponents/CustomSVGViewer";
import CustomChipsArrayInput from "../../customComponents/CustomChipsArrayInput";
import CustomCircularProgress from "../../customComponents/CustomCircularProgress";

const transform = ({ ...data }) => {
	let mutatedData = {};
	Object.entries(data).forEach(([key, value]) => {
		const [keyPrefix, keyLanguageId] = key.split("_");

		if (keyPrefix !== "language") mutatedData[key] = value;

		if (keyPrefix === "language" && keyLanguageId === "" + data.languageId)
			Object.entries(value).forEach(([fileName, file]) => {
				if (file) mutatedData[fileName] = value[fileName];
			});
	});

	return transformToFormData(mutatedData);
};

const CustomVarianceInput = ({
	setDeletedVariantIds,
	translate,
	languageData,
	...props
}) => {
	const [hasLanguage, setHasLanguage] = useState(false);
	const [isNeutral, setIsNeutral] = useState(false);

	const { values } = useFormState();

	useEffect(() => {
		setHasLanguage(() => !!values?.languageId);
		setIsNeutral(
			() =>
				languageData &&
				languageData[values?.languageId]?.isGenderNeutral === false
		);
	}, [values.languageId, languageData]);
	if (!hasLanguage) return <></>;
	return (
		<>
			<BooleanInput source="isVideo" label="Video" defaultValue={false} />

			<CustomVariantList
				setDeletedVariantIds={setDeletedVariantIds}
				type="edit"
				label="variants"
				{...props}
			/>
			{narratorListenerVariations[isNeutral]?.map(
				({ variant, narrator, listener }) => (
					<>
						<FileInput
							key={`language_${values.languageId}file${variant}`}
							{...props}
							source={`language_${values.languageId}.file${variant}`}
							label={translate(
								`modules.edit.uploadFileLabel.${isNeutral}`,
								{
									narrator: translate(narrator),
									listener: listener
										? translate(listener)
										: "",
								}
							)}
							disabled={!hasLanguage}
						>
							<FileField
								source="src"
								target="_blank"
								title={translate(
									`modules.edit.uploadFileLabel.${isNeutral}`,
									{
										narrator: translate(narrator),
										listener: listener
											? translate(listener)
											: "",
									}
								)}
							/>
						</FileInput>
					</>
				)
			)}
		</>
	);
};

const ModuleEditToolbar = ({ isLoading, ...props }) => {
	return (
		<Toolbar {...props}>
			<SaveButton disabled={isLoading} />
		</Toolbar>
	);
};

export const ModuleEdit = (props) => {
	const [languageData, setLanguageData] = useState([]);
	const [backgroundPlaylistsArray, setBackgroundPlaylistsArray] = useState(
		[]
	);
	const [isSingle, setIsSingle] = useState(true);
	const hasRequiredStatus = useRef(false);
	const hasPermission = useRef(false);
	const [objectivesArray, setObjectivesArray] = useState([]);
	const [deletedVariantIds, setDeletedVariantIds] = useState([]);
	const [deleteSocialImage, setDeleteSocialImage] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	let { data: module, loading } = useGetOne("modules", props.id);
	const { data: objectivesData } = useGetList("objectives");
	const translate = useTranslate();
	const redirect = useRedirect();
	const refresh = useRefresh();
	const notify = useNotify();
	const [update] = useUpdate("modules", props.id);

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);
	const { data: languageDataObj, loading: languageLoading } = useGetList(
		"languages",
		{ page: 1, perPage: 10 },
		{ field: "id", order: "DESC" }
	);

	const {
		data: backgroundPlaylistsData,
		loading: backgroundPlaylistLoading,
	} = useGetList("background-playlists");

	const onSuccess = () => {
		notify(translate("modules.edit.success"), "info");
		setIsLoading(false);
		redirect("/modules");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
		setIsLoading(false);
	};

	useEffect(() => {
		if (module) setIsSingle(module.isSingle);
	}, []);

	useEffect(() => {
		setLanguageData(getChoices(languageDataObj));
	}, [languageDataObj]);

	useEffect(() => {
		setObjectivesArray(
			Object.values(objectivesData).map(({ id, titles }) => ({
				id,
				name: titles[defaultLocale],
			}))
		);
	}, [objectivesData]);

	useEffect(() => {
		hasPermission.current = checkIfExists(
			props.permissions,
			requiredPermissions
		);
	}, [props.permissions]);

	useEffect(() => {
		hasRequiredStatus.current = checkIfExists(
			props.status,
			requiredStatuses
		);
	}, [props.status]);

	useEffect(() => {
		setBackgroundPlaylistsArray(
			Object.values(backgroundPlaylistsData).map(({ id, names }) => ({
				id,
				name: names[defaultLocale],
			}))
		);
	}, [backgroundPlaylistsData]);

	if (!hasPermission && !hasRequiredStatus) {
		notify(translate("permissions.notAllowed"), "warning");
		redirect("/modules");
	}
	return (
		<>
			{loading || languageLoading || backgroundPlaylistLoading ? (
				<center>
					<h1>Loading...</h1>
				</center>
			) : (
				<Edit
					{...props}
					title="modules.edit.title"
					mutationMode="pessimistic"
				>
					<TabbedForm
						submitOnEnter={false}
						save={({ doctors = [], ...data }) => {
							const indexedDataToSend = setIndexesForData(
								{ ...data, doctors },
								["doctors"]
							);
							setIsLoading(true);
							const transformed = transform({
								...indexedDataToSend,
								deletedVariantIds,
								deleteSocialImage,
							});
							update(
								{ payload: { data: transformed } },
								{
									onSuccess,
									onFailure,
								}
							);
						}}
						validate={(data) =>
							validateModuleData({ ...data, deleteSocialImage })
						}
						toolbar={<ModuleEditToolbar isLoading={isLoading} />}
					>
						<FormTab
							label={translate("modules.tabs.basicInfo.label")}
						>
							<CustomCircularProgress loading={isLoading} />
							<TextInput source="name" />
							<TextInput source="description" multiline={true} />
							<TextInput source="narrator" />
							<TextInput source="writer" />
							<TextInput
								source="composer"
								label="Composer (optional)"
							/>
							<TextInput
								source="socialCaption"
								label="Social Caption (optional)"
							/>
							<AutocompleteArrayInput
								resettable
								source="allowedUserTypes"
								choices={userTypes}
							/>
							<BooleanInput
								source="isSharable"
								label="Sharable"
								defaultValue={false}
							/>

							<BooleanInput
								source="isSingle"
								label="Single"
								onChange={() => setIsSingle((prev) => !prev)}
							/>
							{isSingle && (
								<>
									<BooleanInput
										disabled={!isSingle}
										source="isRecommended"
									/>
									<BooleanInput
										defaultValue={false}
										source="comingSoon"
									/>
									<AutocompleteInput
										resettable
										source="backgroundPlaylistId"
										choices={backgroundPlaylistsArray}
									/>
									<AutocompleteArrayInput
										resettable
										source="objectiveIds"
										choices={objectivesArray}
									/>
								</>
							)}
							{module?.svg ? (
								SVGViewer({
									svg: module.svg,
									title: "Module SVG",
								})
							) : (
								<CustomImageField
									customLabel="Module Image"
									source="imageUrl"
								/>
							)}
							<ImageInput
								source="image"
								accept="image/*"
								label="Image"
								helperText={
									"The aspect ratio should be " +
									aspectRatios.moduleInPlaylist
								}
								placeholder={"Upload Module Image"}
							>
								<ImageField source="src" />
							</ImageInput>
							{module?.socialSvg ? (
								SVGViewer({
									svg: module.socialSvg,
									title: "Social SVG",
								})
							) : (
								<CustomImageField
									label="Social Image"
									source="imageSocialUrl"
								/>
							)}
							<ImageInput
								source="imageSocial"
								accept="image/*"
								label="Social Image"
								helperText={
									"The aspect ratio should be " +
									aspectRatios.moduleInPlaylist
								}
								placeholder={"Upload Module Social Image"}
							>
								<ImageField source="src" />
							</ImageInput>
							<CustomImageField
								label="Cover Image"
								source="imageCoverUrl"
							/>
							<ImageInput
								source="imageCover"
								accept="image/*"
								label="Cover Image"
								placeholder={"Upload Module Cover Image"}
							>
								<ImageField source="src" />
							</ImageInput>
							<RadioButtonGroupInput
								source="languageId"
								choices={languageData}
								optionText={({ name }) =>
									`misc.names.languages.${name}`
								}
								optionValue="id"
							/>
							<CustomVarianceInput
								translate={translate}
								languageData={languageData}
								setDeletedVariantIds={setDeletedVariantIds}
							/>
						</FormTab>
						<FormTab
							label={translate(
								"modules.tabs.typesManagement.label"
							)}
						>
							<CustomContentTypeArrayInput />
						</FormTab>
						<FormTab
							label={translate(
								"modules.tabs.doctorsManagement.label"
							)}
						>
							<CustomTransferList
								source="doctors"
								referenceSource="doctors"
								referenceResource="doctors"
								referenceField="fullName"
								isReferenceTranslatable={true}
							/>
						</FormTab>
					</TabbedForm>
				</Edit>
			)}
		</>
	);
};
