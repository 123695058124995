import React from "react";
import {
	Create,
	useRefresh,
	TextInput,
	useNotify,
	useRedirect,
	useTranslate,
	SimpleForm,
	NumberInput,
	SelectInput,
} from "react-admin";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { getAllCurrencies } from "../../utils/helpers";
import { validateProductData } from "./productValidate";

export const ProductCreate = (props) => {
	const redirect = useRedirect();
	const refresh = useRefresh();
	const translate = useTranslate();
	const notify = useNotify();
	const currencies = getAllCurrencies();
	const onSuccess = () => {
		notify("web-products.create.success", "info");
		redirect("/web-products");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	return (
		<Create
			{...props}
			title={translate("web-products.create.title")}
			onSuccess={onSuccess}
			onFailure={onFailure}
		>
			<SimpleForm
				toolbar={<CustomToolbar />}
				validate={validateProductData}
				syncWithLocation={false}
			>
				<TextInput source="name" />
				<NumberInput source="price" />
				<SelectInput
					source="currency"
					choices={currencies}
					defaultValue="SAR"
					optionText={({ code }) => code}
					optionValue="code"
				/>
			</SimpleForm>
		</Create>
	);
};
