import { isNotInRange } from "../../utils/customFunctions";

export const validateMemberData = ({
	firstName,
	type,
	days,
	password,
	email,
	phone,
	create = false,
}) => {
	const errors = {};
	if (!firstName) {
		errors.firstName = "users.validationErrors.required.firstName";
	} else if (isNotInRange({ min: 3, max: 40, str: firstName })) {
		errors.firstName = {
			message: "validationErrors.minMax.firstName",
			args: { min: 3, max: 40 },
		};
	}

	if (
		create &&
		(!days ||
			days <= 0 ||
			isNotInRange({ min: 1, max: 6, str: days.toString() }))
	)
		errors.days = "Invalid number of days!";

	if (create && !password && type === "email") {
		errors.password = "Passowrd is Required!";
	}
	if (
		create &&
		password &&
		isNotInRange({ min: 8, max: 50, str: password }) &&
		type === "email"
	) {
		errors.password = {
			message: "validationErrors.minMax.password",
			args: { min: 8, max: 50 },
		};
	}

	if (create && !email && type === "email") {
		errors.email = {
			message: "validationErrors.required.requiredField",
			args: { field: "Email" },
		};
	}

	if (
		create &&
		email &&
		isNotInRange({ min: 5, max: 50, str: email }) &&
		type === "email"
	) {
		errors.email = {
			message: "validationErrors.minMax.email",
			args: { min: 10, max: 50 },
		};
	}

	if (create && !phone && type === "phone") {
		errors.phone = {
			message: "validationErrors.required.requiredField",
			args: { field: "Phone" },
		};
	}

	if (
		create &&
		phone &&
		isNotInRange({ min: 6, max: 50, str: phone }) &&
		type === "phone"
	) {
		errors.phone = {
			message: "validationErrors.minMax.phone",
			args: { min: 10, max: 50 },
		};
	}

	return errors;
};
