import { useEffect, useState } from "react";
import {
	Edit,
	SaveButton,
	SelectInput,
	SimpleForm,
	TextInput,
	Toolbar,
	TranslatableInputs,
	useGetList,
	useNotify,
	useRedirect,
	useTranslate,
	useUpdate,
} from "react-admin";

import { CustomTransferList } from "../../customComponents/CustomTransferList";
import { backgroundPlaylistValidate } from "./backgroundPlaylistValidate";
import { setIndexesForData } from "../../utils/customFunctions";
import { userPreferences } from "../../utils/fakeUserPrefrences";

const BackgroundPlaylistEditToolbar = (props) => {
	return (
		<Toolbar {...props}>
			<SaveButton />
		</Toolbar>
	);
};

export const BackgroundPlaylistEdit = (props) => {
	const [languagesArray, setLanguagesArray] = useState([]);
	const [categoriesArray, setCategoriesArray] = useState([]);

	const [update] = useUpdate("background-playlists", props.id);
	const translate = useTranslate();
	const redirect = useRedirect();
	const notify = useNotify();

	const { data: languagesData } = useGetList("languages", {
		page: 1,
		perPage: 10,
	});

	const { data: categoriesData } = useGetList("categories", {
		page: 1,
		perPage: 10,
	});

	useEffect(() => {
		languagesData &&
			setLanguagesArray(
				Object.values(languagesData).map((language) =>
					language.name.slice(0, 2).toLowerCase()
				)
			);
	}, [languagesData]);

	useEffect(() => {
		setCategoriesArray(
			Object.values(categoriesData).map(({ id, titles }) => ({
				id,
				name: titles[userPreferences.defaultTranslationLanguage],
			}))
		);
	}, [categoriesData]);

	const onSuccess = () => {
		notify(translate("background-playlists.edit.success"), "info");
		redirect("/background-playlists");
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};
	return (
		<Edit
			{...props}
			title={translate("background-playlists.edit.title")}
		>
			<SimpleForm
				save={(data) => {
					const dataToSend = setIndexesForData(data, ["backgroundModules"]);
					update(
						{ payload: { data: dataToSend } },
						{
							onSuccess,
							onFailure,
						}
					);
				}}
				validate={backgroundPlaylistValidate}
				toolbar={<BackgroundPlaylistEditToolbar />}
			>
				<TranslatableInputs
					locales={languagesArray}
					defaultLocale={userPreferences.defaultTranslationLanguage}
				>
					<TextInput source="names" />
					<TextInput multiline source="descriptions" />
				</TranslatableInputs>
				<SelectInput source="categoryId" choices={categoriesArray} />
				<CustomTransferList
					source="backgroundModules"
					referenceSource="backgroundModules"
					referenceResource="background-modules"
					referenceField="name"
					isReferenceTranslatable={false}
				/>
			</SimpleForm>
		</Edit>
	);
};
