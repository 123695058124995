import { ReminderCreate } from "./reminderCreate";
import { ReminderEdit } from "./reminderEdit";
import { ReminderList } from "./reminderList";
import { ReminderShow } from "./reminderShow";

export default {
	create: ReminderCreate,
	edit: ReminderEdit,
	list: ReminderList,
	show: ReminderShow,
};
