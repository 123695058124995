import {
	Show,
	SimpleShowLayout,
	TextField,
	BooleanField,
	useRedirect,
	useTranslate,
	ArrayField,
	SingleFieldList,
	ChipField,
	useGetOne,
} from "react-admin";

import { CustomAsideSimpleList } from "../../customComponents/CustomAsideSimpleList";
import { CustomShowActions } from "../../customComponents/CustomShowActions";
import { CustomVariantList } from "../../customComponents/CustomVariantList";
import { requiredPermissions, requiredStatuses } from "./shared";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import CustomImageField from "../../customComponents/CustomImageField";
import { SVGViewer } from "../../customComponents/CustomSVGViewer";
import Chip from "@material-ui/core/Chip";

const TagsField = ({ record }) => (
	<>
		{record?.tags?.map((item) => (
			<Chip label={item} />
		))}
	</>
);
TagsField.defaultProps = { addLabel: true };

const CustomChipFieldWithRedirect = ({
	record,
	source,
	basePath,
	emptyText,
	redirectToResource,
	...props
}) => {
	const redirect = useRedirect();
	const handleRedirectClick = () => {
		redirect("show", `/${redirectToResource}`, record.id);
	};
	return (
		<ChipField
			onClick={handleRedirectClick}
			source={source}
			record={record}
			emptyText={emptyText}
			{...props}
		/>
	);
};

const Doctor = ({ record, record: { fullName }, translate, ...props }) =>
	fullName?.ar ? (
		<CustomChipFieldWithRedirect
			source="fullName.ar"
			emptyText={translate("empty.doctor")}
			redirectToResource="doctors"
			record={record}
		/>
	) : (
		<CustomChipFieldWithRedirect
			source="fullName.en"
			emptyText={translate("empty.doctor")}
			redirectToResource="doctors"
			record={record}
		/>
	);

export const ModuleShow = (props) => {
	const translate = useTranslate();
	let { data: module } = useGetOne("modules", props.id);
	return (
		<Show
			aside={<CustomAsideSimpleList source="auditTracker" />}
			{...props}
			actions={
				<CustomShowActions
					source="status"
					requiredStatuses={requiredStatuses}
					requiredPermissions={requiredPermissions}
				/>
			}
			title={translate("modules.show.title")}
		>
			<SimpleShowLayout>
				<TextField source="name" />
				<TextField source="language.name" />
				<TextField source="narrator" />
				<TextField source="writer" />
				<TextField source="composer" />
				<TextField source="status" />
				<TextField source="socialCaption" />
				<BooleanField source="isSingle" />
				<BooleanField source="isSharable" looseValue={true} />
				<BooleanField source="isVideo" looseValue={true} />
				<BooleanField source="isRecommended" />
				<ArrayField source="objectives">
					<SingleFieldList linkType={false}>
						<CustomChipFieldWithRedirect
							source={`titles.${userPreferences.defaultTranslationLanguage}`}
							emptyText={translate("empty.objectives")}
							redirectToResource="objectives"
						/>
					</SingleFieldList>
				</ArrayField>
				<ArrayField source="doctors">
					<SingleFieldList linkType={false}>
						<Doctor translate={translate} />
					</SingleFieldList>
				</ArrayField>
				<TagsField label={"Tags"} />

				{module?.svg ? (
					SVGViewer({
						svg: module.svg,
						title: "Module SVG",
					})
				) : (
					<CustomImageField
						customLabel="Module Image"
						source="imageUrl"
					/>
				)}
				<br />
				<br />
				{module?.socialSvg ? (
					SVGViewer({
						svg: module.socialSvg,
						title: "Social SVG",
					})
				) : (
					<CustomImageField
						label="Social Image"
						source="imageSocialUrl"
					/>
				)}
				<CustomVariantList source="variants" />
			</SimpleShowLayout>
		</Show>
	);
};
