import { useEffect, useMemo, useState, useRef } from "react";
import {
	Create,
	TextInput,
	TranslatableInputs,
	useGetList,
	useNotify,
	useRedirect,
	TabbedForm,
	FormTab,
	useTranslate,
	useCreate,
} from "react-admin";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { validateDayPortionData } from "./dayPortionValidate";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { CustomTransferList } from "../../customComponents/CustomTransferList";
import { setIndexesForData } from "../../utils/customFunctions";
import { CustomToolbar } from "../../customComponents/CustomToolbar";

export const DayPortionCreate = (props) => {
	const [languagesArray, setLanguagesArray] = useState([]);
	const startTimeRef = useRef(null);
	const endTimeRef = useRef(null);

	const translate = useTranslate();
	const redirect = useRedirect();
	const notify = useNotify();

	const onSuccess = () => {
		notify(translate("day-portions.create.success"), "info");
		redirect("/day-portions");
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);

	const { data: languagesData } = useGetList("languages", {
		page: 1,
		perPage: 10,
	});

	const [create] = useCreate("day-portions");
	const useStyles = makeStyles((theme) => ({
		container: {
			display: "flex",
			flexWrap: "wrap",
		},
		textField: {
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
			width: 200,
		},
	}));
	const classes = useStyles();

	useEffect(() => {
		setLanguagesArray(
			Object.values(languagesData).map((language) =>
				language.name.slice(0, 2).toLowerCase()
			)
		);
	}, [languagesData]);

	return (
		<Create {...props} title={translate("day-portions.create.title")}>
			<TabbedForm
				validate={validateDayPortionData}
				submitOnEnter={false}
				syncWithLocation={false}
				toolbar={<CustomToolbar />}
				save={(data) => {
					if (
						!!!startTimeRef.current?.value ||
						!!!endTimeRef.current?.value ||
						startTimeRef.current?.value === endTimeRef.current?.value
					) {
						notify("Invalid Time Range", "warning");
						return;
					}
					const indexedDataToSend = setIndexesForData(data, [
						"modules",
						"playlists",
					]);
					create(
						{
							payload: {
								data: {
									...indexedDataToSend,
									startTime: startTimeRef.current.value,
									endTime: endTimeRef.current.value,
								},
							},
						},
						{
							onSuccess,
							onFailure,
						}
					);
				}}
			>
				<FormTab label={translate("modules.tabs.basicInfo.label")}>
					<TranslatableInputs
						locales={languagesArray}
						defaultLocale={defaultLocale}
					>
						<TextInput source="title" />
						<TextInput source="greeting" />
					</TranslatableInputs>
					<br />
					<TextField
						required={true}
						name="startTime"
						source="startTime"
						label="Starts at: "
						type="time"
						inputRef={startTimeRef}
						className={classes.textField}
						InputLabelProps={{
							shrink: true,
						}}
						inputProps={{
							step: 300,
						}}
					/>
					<br />
					<TextField
						source="endTime"
						required={true}
						name="endTime"
						label="Ends at: "
						type="time"
						inputRef={endTimeRef}
						className={classes.textField}
						InputLabelProps={{
							shrink: true,
						}}
						inputProps={{
							step: 300,
						}}
					/>
				</FormTab>
				<FormTab label={translate("day-portions.tabs.singlesManagement.label")}>
					<CustomTransferList
						source="modules"
						referenceSource="modules"
						referenceResource="modules"
						referenceField="name"
						isReferenceTranslatable={false}
						customFilter="(({isSingle})=>isSingle)"
					/>
				</FormTab>
				<FormTab
					label={translate("day-portions.tabs.playlistsManagement.label")}
				>
					<CustomTransferList
						source="playlists"
						referenceSource="playlists"
						referenceResource="playlists"
						referenceField="names"
						isReferenceTranslatable={true}
					/>
				</FormTab>
			</TabbedForm>
		</Create>
	);
};
