const genderNameTranslationPath = {
	female: "misc.names.genders.female",
	male: "misc.names.genders.male",
};

export const constants = {
	perPage: 15,
	perPageMax: 1000000000,
	roles: {
		systemAdmin: "SYSTEM ADMINISTRATOR",
		userStaffAdmin: "STAFF USERS ADMINISTRATOR",
		contentOfficer: "CONTENT OFFICER",
		contentManager: "CONTENT MANAGER",
		commercialManager: "COMMERCIAL MANAGER",
		dataOfficer: "DATA OFFICER",
	},
	promocodes: {
		licensePrefix: "Tuhoon_",
	},
	outcomesLimit: 4,
	userTypes: [
		{ id: "B2B", name: "B2B" },
		{ id: "PAYING", name: "PAYING" },
		{ id: "FREE", name: "FREE" },
	],
	subOutcomes: 2,
	arabic: "ar",
	publicUrls: {
		verify: "verify",
	},
	taskEvents: {
		listenToSingle: "listenToSingle",
		createJournal: "createJournal",
	},
	genders: [
		{ id: "MALE", name: "Male" },
		{ id: "FEMALE", name: "Female" },
		{ id: "ANY", name: "Any" },
	],
	communicationTypes: [
		{ id: "INFO", name: "Message" },
		{ id: "QUESTION", name: "Question" },
	],
	aspectRatios: {
		subscription: "20:19",
		playlistInHomePage: "7:5",
		playlistInPlaylistPage: "5:4",
		moduleInPlaylist: "7:5",
		category: "16:9",
	},
	rolesChoicesArr: [
		{ id: "Content Manager", name: "Content Manager" },
		{ id: "Content Officer", name: "Content Officer" },
		{ id: "Data Officer", name: "Data Officer" },
		{ id: "Commercial Manager", name: "Commercial Manager" },
		{
			id: "Staff Users Administrator",
			name: "Staff Users Administrator",
		},
	],
	authMethodsArr: [
		{ id: "GOOGLE", name: "GOOGLE" },
		{ id: "EMAIL", name: "EMAIL" },
		{ id: "PHONE", name: "PHONE" },
		{ id: "APPLE", name: "APPLE" },
		{ id: "FACEBOOK", name: "FACEBOOK" },
	],

	subscriptionTypesArr: [
		{ id: "UNSUBSCRIBED", name: "UNSUBSCRIBED" },
		{ id: "SUBSCRIBED", name: "SUBSCRIBED" },
		{ id: "FREEMIUM", name: "FREEMIUM" },
		{ id: "FREEMIUM_PASS", name: "FREEMIUM_PASS" },
		{ id: "FREEMIUM_ANGHAMI", name: "FREEMIUM_ANGHAMI" },
		{ id: "FREEMIUM_SLEEP", name: "FREEMIUM_SLEEP" },
		{ id: "FREEMIUM_PROMO_CODE", name: "FREEMIUM_PROMO_CODE" },
		{ id: "FREE_TRIAL", name: "FREE_TRIAL" },
		{ id: "PRESIGNED", name: "PRESIGNED" },
		{ id: "PROMO_CODE", name: "PROMO_CODE" },
		{ id: "CANCELLED", name: "CANCELLED" },
	],
	nightTime: {
		start: "21:00",
		end: "07:00",
	},
	suggestionsChoicesArr: [
		{ id: "general", name: "General" },
		{ id: "objectives", name: "Objectives" },
	],
	englishLanguageId: 1,
	arabicLanguageId: 2,
	narratorListenerVariations: {
		// isGenderNeutral
		true: [
			{ variant: "FF", narrator: genderNameTranslationPath.female },
			{ variant: "MM", narrator: genderNameTranslationPath.male },
		],
		false: [
			{
				variant: "FF",
				narrator: genderNameTranslationPath.female,
				listener: genderNameTranslationPath.female,
			},
			{
				variant: "MM",
				narrator: genderNameTranslationPath.male,
				listener: genderNameTranslationPath.male,
			},
			{
				variant: "MF",
				narrator: genderNameTranslationPath.male,
				listener: genderNameTranslationPath.female,
			},
			{
				variant: "FM",
				narrator: genderNameTranslationPath.female,
				listener: genderNameTranslationPath.male,
			},
		],
	},
};

export const rolesGlobal = constants.roles;
export const genders = constants.genders;
export const communicationTypes = constants.communicationTypes;
export const aspectRatios = constants.aspectRatios;
export const publicUrls = constants.publicUrls;
export const rolesChoicesArr = constants.rolesChoicesArr;
export const authMethodsArr = constants.authMethodsArr;
export const subscriptionTypesArr = constants.subscriptionTypesArr;
export const suggestionsChoicesArr = constants.suggestionsChoicesArr;
export const narratorListenerVariations = constants.narratorListenerVariations;
export const nightTime = constants.nightTime;
export const arabic = constants.arabic;
export const taskEvents = constants.taskEvents;
export const userTypes = constants.userTypes;
export const englishLanguageId = constants.englishLanguageId;
export const arabicLanguageId = constants.arabicLanguageId;
export default constants;
