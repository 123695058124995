import React from "react";
import {
	BooleanInput,
	Datagrid,
	EditButton,
	Filter,
	List,
	SelectInput,
	ShowButton,
	TextField,
	TextInput,
	BooleanField,
} from "react-admin";
import { CustomDeleteButtonWithCustomConfirm } from "../../customComponents/CustomButtons";
import {
	constants,
	authMethodsArr,
	subscriptionTypesArr,
} from "../../utils/constants";

const MemberFilter = ({ ...props }) => (
	<Filter {...props}>
		<TextInput source="search" alwaysOn />
		<BooleanInput source="verified" label="Is Verified" />
		<BooleanInput source="isDeleted" label="Is Deleted" />
		<SelectInput source="method" choices={authMethodsArr} />
		<SelectInput source="subscriptionType" choices={subscriptionTypesArr} />
	</Filter>
);

export const MemberList = (props) => {
	return (
		<List
			{...props}
			perPage={constants.perPage}
			bulkActionButtons={false}
			filters={<MemberFilter />}
		>
			<Datagrid>
				<TextField source="firstName" />
				<TextField source="gender" />
				<TextField source="verified" />
				<TextField source="email" />
				<TextField source="phoneNumber" />
				<TextField source="method" />
				<TextField source="subscriptionType" />
				<TextField source="revokeDate" />
				<BooleanField source="isDeleted" label={"Is Deleted"} />
				<ShowButton />
				<EditButton />
				<CustomDeleteButtonWithCustomConfirm mutationMode="pessimistic" />
			</Datagrid>
		</List>
	);
};
