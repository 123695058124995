import { isNotInRange } from "../../utils/customFunctions";
export const validateSurveyData = ({ name, title }) => {
	const isNameFound = !!(name?.en || name?.ar);
	const isTitleFound = !!(title?.en || title?.ar);
	const errors = {};
	if (!isTitleFound) {
		errors.title = {
			ar: {
				message: "validationErrors.required.requiredField",
				args: { field: "Title" },
			},
		};
	} else {
		errors.title = {};
		Object.keys(title).forEach((locale) => {
			if (
				title[locale] &&
				isNotInRange({ min: 3, max: 50, str: title[locale] })
			) {
				errors.title[locale] = {
					message: "validationErrors.minMax.title",
					args: { min: 3, max: 50 },
				};
			}
		});
	}

	if (!isNameFound) {
		errors.name = {
			ar: {
				message: "validationErrors.required.requiredField",
				args: { field: "Name" },
			},
		};
	} else {
		errors.name = {};
		Object.keys(name).forEach((locale) => {
			if (
				name[locale] &&
				isNotInRange({ min: 3, max: 50, str: name[locale] })
			) {
				errors.name[locale] = {
					message: "validationErrors.minMax.name",
					args: { min: 3, max: 50 },
				};
			}
		});
	}
	return errors;
};
