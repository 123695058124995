import { useCallback, useEffect, useState } from "react";
import {
	Edit,
	FormTab,
	ImageField,
	ImageInput,
	NumberField,
	NumberInput,
	TabbedForm,
	TextInput,
	TranslatableInputs,
	useGetList,
	useMutation,
	useNotify,
	useRedirect,
	useRefresh,
	useTranslate,
	useUpdate,
} from "react-admin";

import CustomImageField from "../../customComponents/CustomImageField";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import {
	setIndexesForData,
	transformToFormData,
} from "../../utils/customFunctions";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { validateCategoryData } from "./categoryValidate";
import { CustomTransferList } from "../../customComponents/CustomTransferList";

export const CategoryEdit = (props) => {
	const translate = useTranslate();
	const [languagesArray, setLanguagesArray] = useState([]);

	const redirect = useRedirect();
	const [mutate] = useMutation();
	const refresh = useRefresh();
	const notify = useNotify();
	const [update] = useUpdate("categories", props.id);

	const { data: languagesData } = useGetList("languages");

	const save = useCallback(
		async (values) => {
			try {
				await mutate(
					{
						type: "update",
						resource: "categories",
						payload: { data: values },
					},
					{ returnPromise: true }
				);
			} catch (error) {
				if (error.body.errors) {
					return error.body.errors;
				}
			}
		},
		[mutate]
	);

	const validateCategoryDataEdition = ({ titles, index }) => {
		const errors = validateCategoryData({ titles });

		if (!titles?.en && !titles?.ar) {
			errors.titles = {
				en: "categories.validationErrors.required.oneIsRequired",
				ar: "categories.validationErrors.required.oneIsRequired",
			};
		}

		if (!index) {
			errors.index = "categories.edit.validationErrors.required.index";
		}

		return errors;
	};

	const onSuccess = () => {
		notify(`Updated successfully`);
		redirect("/categories");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	useEffect(() => {
		setLanguagesArray(
			Object.values(languagesData).map((language) =>
				language.name.slice(0, 2).toLowerCase()
			)
		);
	}, [languagesData]);

	return (
		<Edit
			{...props}
			title={translate("categories.edit.title")}
			mutationMode="pessimistic"
		>
			<TabbedForm
				toolbar={<CustomToolbar />}
				save={(data) => {
					const indexedDataToSend = setIndexesForData(data, ["objectives"]);
					const transformed = transformToFormData(indexedDataToSend);
					update(
						{ payload: { data: transformed } },
						{
							onSuccess,
							onFailure,
						}
					);
				}}
				validate={validateCategoryDataEdition}
				syncWithLocation={false}
			>
				<FormTab label="Basic Info">
					<TranslatableInputs
						locales={languagesArray}
						defaultLocale={userPreferences.defaultTranslationLanguage}
					>
						<TextInput source="titles" />
					</TranslatableInputs>
					<NumberField source="index" label="categories.edit.currentIndex" />
					<NumberInput source="newIndex" />
					<CustomImageField source="imageUrl" />
					<ImageInput
						source="image"
						accept="image/*"
						placeholder={translate("categories.create.uploadImagePlaceholder")}
					>
						<ImageField source="src" />
					</ImageInput>
				</FormTab>
				<FormTab label={translate("playlists.tabs.objectivesManagement.label")}>
					<CustomTransferList
						source="objectives"
						referenceSource="objectives"
						referenceResource="objectives"
						referenceField="titles"
						isReferenceTranslatable={true}
					/>
				</FormTab>
			</TabbedForm>
		</Edit>
	);
};
