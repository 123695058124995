import {
	isNotInRange,
	isNotInRangeValue,
	isWholeNumber,
	checkURLReservedChars,
} from "../../utils/customFunctions";

export const validateBusinessData = ({ name, validFrom, validTo }) => {
	const errors = {};
	if (!name) errors.name = "businesses.validationErrors.required.name";
	if (name && isNotInRange({ min: 3, max: 50, str: name })) {
		errors.name = {
			message: "businesses.validationErrors.minMax.name",
			args: { min: 3, max: 50 },
		};
	}
	if (!validFrom)
		errors.validFrom = "businesses.validationErrors.required.validFrom";
	if (!validTo)
		errors.validTo = "businesses.validationErrors.required.validTo";
	if (validFrom && validTo) {
		let _validFrom = new Date(validFrom).toISOString();
		let _validTo = new Date(validTo).toISOString();
		if (new Date(_validFrom) >= new Date(_validTo)) {
			errors.validFrom = "businesses.validationErrors.logic.validTo";
			errors.validTo = "businesses.validationErrors.logic.validTo";
		}
		if (new Date(_validFrom) < new Date())
			errors.validFrom = "businesses.validationErrors.logic.invalid";
		if (new Date(_validTo) < new Date())
			errors.validTo = "businesses.validationErrors.logic.invalid";
	}
	return errors;
};
