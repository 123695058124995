import React, { useState, useEffect, useMemo } from "react";
import {
	BooleanField,
	Datagrid,
	EditButton,
	Filter,
	List,
	ShowButton,
	TextField,
	TranslatableInputs,
	useRedirect,
	TopToolbar,
	CreateButton,
	ExportButton,
	Button,
	SimpleForm,
	TextInput,
	useNotify,
	useGetList,
	useTranslatableContext,
} from "react-admin";
import { envSettings } from "../../settings/env";
import axios from "axios";
import { buildErrorMessage } from "../../utils/helpers";
import TitleIcon from "@mui/icons-material/Title";
import { CustomDeleteButtonWithCustomConfirm } from "../../customComponents/CustomButtons";
import CustomImageField from "../../customComponents/CustomImageField";
import { constants } from "../../utils/constants";
import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import { userPreferences } from "../../utils/fakeUserPrefrences";

const DoctorFilter = (props) => (
	<Filter {...props}>
		<TextInput source="search" alwaysOn />
	</Filter>
);

const CustomDelete = ({ record: { isDeletable }, record, ...props }) =>
	isDeletable ? (
		<CustomDeleteButtonWithCustomConfirm {...props} record={record} />
	) : null;

export const DoctorList = (props) => {
	const [languagesArray, setLanguagesArray] = useState([]);
	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const [selectedLocale, setSelectedLocale] = useState(null);
	const [title, setTitle] = useState(false);
	const notify = useNotify();
	const ListActions = (props) => (
		<TopToolbar>
			<CreateButton />
			<ExportButton />
			<Button
				onClick={() => {
					setOpen(true);
				}}
				label="Mobile Title"
			>
				<TitleIcon />
			</Button>
		</TopToolbar>
	);
	const onFailure = (error) => {
		notify(buildErrorMessage(error), "warning");
	};
	const { data: languagesData } = useGetList("languages");

	useEffect(() => {
		setLanguagesArray(
			Object.values(languagesData).map((language) =>
				language.name.slice(0, 2).toLowerCase()
			)
		);
	}, [languagesData]);

	useEffect(() => {
		(async () => {
			const res = await axios.get(
				envSettings.baseUrl + "/doctors/title",
				{
					withCredentials: true,
				}
			);
			if (res?.data?.title) setTitle(res.data.title);
			setLoading(false);
		})();
	}, []);

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);

	const redirect = useRedirect();

	const Selector = () => {
		const { locales, selectLocale, selectedLocale } =
			useTranslatableContext();
		setSelectedLocale(selectedLocale);
		const handleChange = (event) => {
			selectLocale(event.target.value);
		};

		return (
			<select
				aria-label="Select the locale"
				onChange={handleChange}
				value={selectedLocale}
			>
				{locales.map((locale) => (
					<option
						key={locale}
						value={locale}
						id={`translatable-header-${locale}`}
					>
						{locale}
					</option>
				))}
			</select>
		);
	};
	return (
		<>
			{loading ? (
				<center>
					<h1>Loading...</h1>
				</center>
			) : (
				<List
					{...props}
					perPage={constants.perPage}
					bulkActionButtons={false}
					filters={<DoctorFilter />}
					actions={<ListActions />}
				>
					<>
						<Datagrid>
							<CustomImageField
								source="imageUrl"
								sortable={false}
							/>
							<TextField source="index" />
							<TextField source={`fullName.${defaultLocale}`} />
							<TextField
								source={`description.${defaultLocale}`}
							/>
							<BooleanField source="isPublic" />
							<EditButton />
							<ShowButton />
							<CustomDelete />
						</Datagrid>
						<Dialog
							open={open}
							onClose={() => {
								setOpen(false);
							}}
							aria-labelledby="alert-dialog-title"
							aria-describedby="alert-dialog-description"
						>
							<DialogTitle id="alert-dialog-title">
								Set Mobile Title
							</DialogTitle>
							<DialogActions>
								<SimpleForm
									save={async (data) => {
										setOpen(false);
										try {
											const res = await axios.post(
												envSettings.baseUrl +
													"/doctors/title",
												data,
												{ withCredentials: true }
											);
											if (res.status === 200) {
												redirect("/");
												notify(
													"Updated Successfully!",
													"success"
												);
											} else {
												notify("Failed!", "error");
											}
										} catch (error) {
											onFailure(error);
										}
									}}
								>
									<TranslatableInputs
										locales={languagesArray}
										defaultLocale={defaultLocale}
										selector={<Selector />}
									>
										<TextInput
											source="title"
											initialValue={title[selectedLocale]}
										/>
									</TranslatableInputs>
								</SimpleForm>
							</DialogActions>
						</Dialog>
					</>
				</List>
			)}
		</>
	);
};

export default DoctorList;
