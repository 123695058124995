import { useEffect, useMemo, useState } from "react";
import {
	Create,
	ImageField,
	ImageInput,
	SimpleForm,
	TextInput,
	TranslatableInputs,
	useCreate,
	useGetList,
	useNotify,
	useRedirect,
	useRefresh,
	useTranslate,
} from "react-admin";

import { validateCategoryData } from "./hrResourcesCtegoriesValidate";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { transformToFormData } from "../../utils/customFunctions";
import { userPreferences } from "../../utils/fakeUserPrefrences";

export const HRResourcesCtegoriesCreate = (props) => {
	const [languagesArray, setLanguagesArray] = useState([]);

	const translate = useTranslate();
	const redirect = useRedirect();
	const refresh = useRefresh();
	const notify = useNotify();
	const [create] = useCreate("/hr/resources/categories");

	const response = useGetList("languages");

	const { data: languagesData } = response;

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);

	const onSuccess = () => {
		notify(translate("hr.resources.categories.create.success"), "info");
		redirect("/hr/resources/categories");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	useEffect(() => {
		setLanguagesArray(
			Object.values(languagesData).map((language) =>
				language.name.slice(0, 2).toLowerCase()
			)
		);
	}, [languagesData]);

	return (
		<Create
			{...props}
			title={translate("hr.resources.categories.create.title")}
		>
			<SimpleForm
				save={(data) => {
					const transformed = transformToFormData(data);

					create(
						{ payload: { data: transformed.formData } },
						{
							onSuccess,
							onFailure,
						}
					);
				}}
				toolbar={<CustomToolbar />}
				syncWithLocation={false}
				validate={(data) =>
					validateCategoryData({ ...data, fromCreate: true })
				}
			>
				<TranslatableInputs
					locales={languagesArray}
					defaultLocale={defaultLocale}
				>
					<TextInput source="titles" />
				</TranslatableInputs>
				<ImageInput
					source="coverImage"
					accept="image/*"
					label="Cover Picture"
					placeholder={translate(
						"hr.resources.categories.create.uploadImagePlaceholder"
					)}
				>
					<ImageField source="src" />
				</ImageInput>
			</SimpleForm>
		</Create>
	);
};
