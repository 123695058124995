import { FormControlLabel, FormGroup, Switch } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import classnames from "classnames";
import { useState } from "react";

import { useCustomUpdate } from "../customHooks/customHooks";
import { CustomDialog } from "./CustomDialog";
import { CustomButton } from "./CustomButtons";

export const CustomBooleanInput = ({
	label,
	color,
	disabled,
	inputRef,
	value,
	name,
	resource,
	title = null,
	content = null,
	record,
	source,
}) => {
	const [open, setOpen] = useState(false);

	const { loading, updateInPlace } = useCustomUpdate({
		resource,
		record,
		source,
		endPoint: "changeStatus",
	});

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleConfirm = () => {
		updateInPlace(!record[source]);
		setOpen(false);
	};

	const handleCancel = () => {
		setOpen(false);
	};

	return (
		<>
			<FormGroup row>
				<FormControlLabel
					control={
						<Switch
							checked={record[source]}
							onChange={handleClickOpen}
							name={name}
							color={color || "primary"}
							disabled={disabled || loading}
							value={value || record[source]}
							inputRef={inputRef}
						/>
					}
					label={label}
				/>
			</FormGroup>
			<CustomDialog
				open={open}
				onClose={handleCancel}
				title={
					title
						? title
						: "messages.confirmDialog.changePublishStatus.title"
				}
				content={
					content
						? content
						: "messages.confirmDialog.changePublishStatus.content"
				}
			>
				<CustomButton
					onClick={handleCancel}
					innerStyle={{
						display: "flex",
						alignItems: "unset",
						justifyContent: "center",
					}}
					innerText="buttons.cancel"
					icon={<CancelIcon />}
					className={classnames("ra-confirm")}
					autoFocus
				/>
				<CustomButton
					onClick={handleConfirm}
					innerStyle={{
						display: "flex",
						alignItems: "unset",
						justifyContent: "center",
					}}
					innerText="buttons.confirm"
					icon={<CheckCircleIcon />}
					className={classnames("ra-confirm")}
					color="primary"
				/>
			</CustomDialog>
		</>
	);
};
