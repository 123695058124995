import { DoctorCreate } from "./doctorCreate";
import { DoctorEdit } from "./doctorEdit";
import { DoctorList } from "./doctorList";
import { DoctorShow } from "./doctorShow";

export default {
	create: DoctorCreate,
	edit: DoctorEdit,
	list: DoctorList,
	show: DoctorShow,
};
