import { isNotInRange } from "../../utils/customFunctions";

export const validateObjectiveData = async ({
	titles,
	recommendationTitles,
	descriptions,
	icon,
	iconAssetId = null,
	contentTypeId,
}) => {
	const errors = {};
	if (!titles?.en && !titles?.ar) {
		errors.titles = {
			en: "validationErrors.required.oneIsRequired",
			ar: "validationErrors.required.oneIsRequired",
		};
	}

	if (!recommendationTitles?.en && !recommendationTitles?.ar) {
		errors.recommendationTitles = {
			en: "validationErrors.required.oneIsRequired",
			ar: "validationErrors.required.oneIsRequired",
		};
	}

	if (!descriptions?.en && !descriptions?.ar) {
		errors.descriptions = {
			en: "validationErrors.required.oneIsRequired",
			ar: "validationErrors.required.oneIsRequired",
		};
	}

	if (
		recommendationTitles?.ar &&
		isNotInRange({ min: 3, max: 50, str: recommendationTitles.ar })
	) {
		errors.recommendationTitles = {
			ar: {
				message: "validationErrors.minMax.recommendationTitles",
				args: { min: 3, max: 50 },
			},
		};
	}

	if (titles?.ar && isNotInRange({ min: 3, max: 50, str: titles.ar })) {
		errors.titles = {
			ar: {
				message: "validationErrors.minMax.titles",
				args: { min: 3, max: 50 },
			},
		};
	}

	if (
		descriptions?.ar &&
		isNotInRange({ min: 3, max: 50, str: descriptions.ar })
	) {
		errors.descriptions = {
			ar: {
				message: "validationErrors.minMax.descriptions",
				args: { min: 3, max: 50 },
			},
		};
	}

	if (
		recommendationTitles?.en &&
		isNotInRange({ min: 3, max: 50, str: recommendationTitles.en })
	) {
		errors.recommendationTitles = {
			en: {
				message: "validationErrors.minMax.recommendationTitles",
				args: { min: 3, max: 50 },
			},
		};
	}

	if (titles?.en && isNotInRange({ min: 3, max: 50, str: titles.en })) {
		errors.titles = {
			en: {
				message: "validationErrors.minMax.titles",
				args: { min: 3, max: 50 },
			},
		};
	}

	if (
		descriptions?.en &&
		isNotInRange({ min: 3, max: 50, str: descriptions.en })
	) {
		errors.titles = {
			en: {
				message: "validationErrors.minMax.descriptions",
				args: { min: 3, max: 50 },
			},
		};
	}
	if (!contentTypeId)
		errors.contentTypeId =
			"objectives.validationErrors.required.contentTypeId";

	if (!icon && !iconAssetId) errors.icon = "Icon is required!";

	return errors;
};
