import {
	Datagrid,
	EditButton,
	Filter,
	List,
	DateField,
	TextField,
	TextInput,
	ShowButton,
} from "react-admin";
import { constants } from "../../utils/constants";
import { CustomDeleteButtonWithCustomConfirm } from "../../customComponents/CustomButtons";

const LicenseFilter = ({ ...props }) => (
	<Filter {...props}>
		<TextInput source="search" alwaysOn />
	</Filter>
);

export const LicenseList = (props) => {
	return (
		<List
			{...props}
			perPage={constants.perPage}
			bulkActionButtons={false}
			filters={<LicenseFilter />}
		>
			<Datagrid>
				<TextField source="name" />
				<TextField source="promoCode['code']" label={"Promo Code"} />
				<DateField source="startDate" showTime />
				<DateField source="endDate" showTime />
				<ShowButton />
				<EditButton />
				<CustomDeleteButtonWithCustomConfirm mutationMode="pessimistic" />
			</Datagrid>
		</List>
	);
};
