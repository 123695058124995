import React, { useState, useEffect, useMemo } from "react";
import {
	Edit,
	ImageField,
	ImageInput,
	NumberField,
	NumberInput,
	useUpdate,
	TextInput,
	SimpleForm,
	useNotify,
	useRedirect,
	useRefresh,
	useTranslate,
	BooleanInput,
	TranslatableInputs,
	useGetList,
} from "react-admin";
import { transformToFormData } from "../../utils/customFunctions";
import { validateDoctorData } from "./doctorValidate";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { getChoices } from "./shared";

export const DoctorEdit = (props) => {
	const translate = useTranslate();
	const redirect = useRedirect();
	const refresh = useRefresh();
	const notify = useNotify();
	const [update] = useUpdate("doctors", props.id);
	const [languagesArray, setLanguagesArray] = useState([]);
	const { data: languagesDataObj, loading } = useGetList("languages");

	const onSuccess = () => {
		notify(translate("doctors.edit.success"), "info");
		redirect("/doctors");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	useEffect(() => {
		setLanguagesArray(getChoices(languagesDataObj));
	}, [languagesDataObj, loading]);

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);

	return (
		<Edit {...props} title={translate("doctors.edit.title")}>
			<SimpleForm
				save={(data) => {
					const transformed = transformToFormData(data);
					update(
						{ payload: { data: transformed } },
						{
							onSuccess,
							onFailure,
						}
					);
				}}
				toolbar={<CustomToolbar />}
				validate={validateDoctorData}
				syncWithLocation={false}
			>
				<TranslatableInputs
					locales={languagesArray}
					defaultLocale={defaultLocale}
				>
					<TextInput source="fullName" />
					<TextInput multiline source="description" />
				</TranslatableInputs>

				<BooleanInput
					source="isPublic"
					defaultValue={false}
					label="Is Public "
				/>
				<NumberField source="index" label="doctors.edit.currentIndex" />
				<NumberInput source="newIndex" />
				<ImageInput
					source="image"
					label="Profile Picture"
					accept="image/*"
					placeholder={translate(
						"doctors.edit.uploadImagePlaceholder"
					)}
				>
					<ImageField source="src" />
				</ImageInput>
			</SimpleForm>
		</Edit>
	);
};
