import { isNotInRange } from "../../utils/customFunctions";

export const validateSubContentTypeData = async ({ title }) => {
	const errors = {};

	if (!title?.en && !title?.ar) {
		errors.title = {
			en: "validationErrors.required.oneIsRequired",
			ar: "validationErrors.required.oneIsRequired",
		};
	}

	if (title?.ar && isNotInRange({ min: 3, max: 50, str: title.ar })) {
		errors.title = {
			ar: {
				message: "validationErrors.minMax.title",
				args: { min: 3, max: 50 },
			},
		};
	}

	if (title?.en && isNotInRange({ min: 3, max: 50, str: title.en })) {
		errors.title = {
			en: {
				message: "validationErrors.minMax.title",
				args: { min: 3, max: 50 },
			},
		};
	}

	return errors;
};
