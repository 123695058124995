import { DailyRecommendationCreate } from "./dailyRecommendationCreate";
import { DailyRecommendationEdit } from "./dailyRecommendationEdit";
import { DailyRecommendationList } from "./dailyRecommendationList";
import { DailyRecommendationShow } from "./dailyRecommendationShow";

export default {
	create: DailyRecommendationCreate,
	edit: DailyRecommendationEdit,
	list: DailyRecommendationList,
	show: DailyRecommendationShow,
};
