import { Fade, Tooltip, Typography } from "@material-ui/core";

export const CustomDateWithTooltipField = ({
	id,
	title = "",
	tooltipTitle = "",
	placement,
}) => (
	<Tooltip
		id={id}
		enterDelay={150}
		leaveDelay={125}
		title={tooltipTitle}
		placement={placement || "left"}
		arrow
		TransitionComponent={Fade}
		TransitionProps={{ timeout: 600 }}
	>
		<Typography>{title}</Typography>
	</Tooltip>
);
