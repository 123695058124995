import React, { useEffect, useState } from "react";
import {
	Create,
	TextInput,
	TranslatableInputs,
	useNotify,
	useRedirect,
	useRefresh,
	FileField,
	FileInput,
	useCreate,
	TabbedForm,
	FormTab,
} from "react-admin";

import { userPreferences } from "../../utils/fakeUserPrefrences";
import { validateContentTypeData } from "./contentTypeValidate";
import { getCustomEndpoint } from "../../services/http";
import { CustomDialog } from "../../customComponents/CustomDialog";
import { CustomButton } from "../../customComponents/CustomButtons";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import classnames from "classnames";
import { FormControlLabel, FormGroup, Switch } from "@material-ui/core";
import {
	transformToFormData,
	setIndexesForData,
} from "../../utils/customFunctions";
import { CustomTransferList } from "../../customComponents/CustomTransferList";
import { CustomToolbar } from "../../customComponents/CustomToolbar";

export const CustomBooleanInput = ({
	label,
	color,
	disabled,
	inputRef,
	setCurrentDefault,
	currentDefault,
	name,
}) => {
	const [open, setOpen] = useState(false);

	const handleClickOpen = (event) => {
		let value = !(event.target.value === "true");
		if (value) setOpen(true);
		else setCurrentDefault(false);
	};

	return (
		<>
			<FormGroup row>
				<FormControlLabel
					control={
						<Switch
							checked={currentDefault}
							onChange={handleClickOpen}
							name={name}
							color={color || "primary"}
							disabled={disabled}
							value={currentDefault}
							inputRef={inputRef}
						/>
					}
					label={label}
				/>
			</FormGroup>
			<CustomDialog
				open={open}
				title={"WARNING!"}
				content={
					"Are you sure you want to mark this as the default content type?"
				}
			>
				<CustomButton
					onClick={() => {
						setCurrentDefault(false);
						setOpen(false);
					}}
					innerStyle={{
						display: "flex",
						alignItems: "unset",
						justifyContent: "center",
					}}
					innerText="No"
					icon={<CancelIcon />}
					className={classnames("ra-confirm")}
					color="primary"
					autoFocus
				/>
				<CustomButton
					onClick={() => {
						setCurrentDefault(true);
						setOpen(false);
					}}
					innerStyle={{
						display: "flex",
						alignItems: "unset",
						justifyContent: "center",
					}}
					innerText="Yes"
					icon={<CheckCircleIcon />}
					className={classnames("ra-confirm")}
					color="primary"
					autoFocus
				/>
			</CustomDialog>
		</>
	);
};

export const ContentTypeCreate = (props) => {
	const redirect = useRedirect();
	const refresh = useRefresh();
	const notify = useNotify();
	const [isDefaultFound, setIsDefaultFound] = useState(false);
	const [currentDefault, setCurrentDefault] = useState(false);
	const [create] = useCreate("content-types");

	const onSuccess = () => {
		notify("content-types.create.success", "info");
		redirect("/content-types");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	useEffect(() => {
		(async () => {
			const { data = null } = await getCustomEndpoint(
				"/content-types/default"
			);
			if (data) setIsDefaultFound(true);
		})();
	}, []);

	return (
		<Create {...props}>
			<TabbedForm
				toolbar={<CustomToolbar />}
				validate={validateContentTypeData}
				save={(data) => {
					data.isDefault = currentDefault;
					data = data?.subContentTypes
						? setIndexesForData(data, ["subContentTypes"])
						: data;
					const transformed = transformToFormData(data);
					create(
						{
							payload: {
								data: transformed,
							},
						},
						{
							onSuccess,
							onFailure,
						}
					);
				}}
			>
				<FormTab label={"Basic Information"}>
					<TranslatableInputs
						locales={userPreferences.translationLanguages}
						defaultLocale={
							userPreferences.defaultTranslationLanguage
						}
					>
						<TextInput source="titles" />
					</TranslatableInputs>
					<CustomBooleanInput
						source="isDefault"
						label="Default"
						currentDefault={currentDefault}
						setCurrentDefault={setCurrentDefault}
					/>

					<FileInput {...props} source="icon" label="Icon">
						<FileField source="src" title="Icon" />
					</FileInput>
					<FileInput
						{...props}
						source="illustration"
						label="Illustration"
					>
						<FileField source="src" title="Illustration" />
					</FileInput>
				</FormTab>
				<FormTab label={"Manage Sub Types"}>
					<CustomTransferList
						source="subContentTypes"
						referenceSource="sub-content-types"
						referenceResource="sub-content-types"
						referenceField="title"
						isReferenceTranslatable={true}
					/>
				</FormTab>
			</TabbedForm>
		</Create>
	);
};

export default ContentTypeCreate;
