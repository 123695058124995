import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { Box, TextField } from "@material-ui/core";
import classnames from "classnames";
import { useState } from "react";
import {
	Button,
	EditButton,
	TopToolbar,
	usePermissions,
	useTranslate,
} from "react-admin";

import { useCustomUpdate, useInputs } from "../customHooks/customHooks";
import { checkIfExists } from "../utils/customFunctions";
import { CustomButton } from "./CustomButtons";
import { CustomDialog } from "./CustomDialog";

export const CustomShowActions = ({
	basePath,
	data,
	resource,
	requiredStatuses,
	requiredPermissions,
}) => {
	const [open, setOpen] = useState(false);
	const [error, setError] = useState(false);
	const [touched, setTouched] = useState(false);
	const [clickedButton, setClickedButton] = useState("");

	const [inputs, setInputs] = useInputs({});

	const translate = useTranslate();
	const { loadingPermissions, permissions } = usePermissions();
	const { loading, updateInPlace } = useCustomUpdate({
		record: data,
		source: "data",
		resource,
		endPoint: "changeStatus",
	});

	if (
		loadingPermissions ||
		data?.status === "REJECTED" ||
		(permissions && !checkIfExists(permissions, requiredPermissions))
	)
		return (
			<TopToolbar>
				{requiredStatuses.includes(data?.status) ? (
					<EditButton basePath={basePath} record={data} />
				) : (
					<></>
				)}
			</TopToolbar>
		);

	const handleClickOpen = ({ target }) => {
		setClickedButton(target.value);
		setOpen(true);
	};

	const handleConfirm = () => {
		const valid = !validate({
			target: { value: inputs.comment, name: "comment" },
		});
		if (!valid) return;

		const data = { status: clickedButton };

		if (inputs.comment) data.comment = inputs.comment ? inputs.comment : "";

		updateInPlace(data);
		setInputs({ target: { value: "", name: "comment" } });
		setOpen(false);
	};

	const handleCancel = () => {
		setInputs({ target: { value: "", name: "comment" } });
		setOpen(false);
	};

	const validate = ({ target }) => {
		let err = false;
		setError(false);
		if (clickedButton === "REJECTED" && !target.value) {
			err = true;
			setError(
				translate("validationErrors.required.requiredField", {
					field: translate("labels.inputs.comment"),
				})
			);
		}

		if (target?.value?.length > 250) {
			err = true;
			setError(
				translate("validationErrors.minMax.comment", {
					min: 3,
					max: 250,
				})
			);
		}
		return err;
	};

	const handleChange = (event) => {
		if (event.target.value.length > 250) {
			setError(
				translate("validationErrors.minMax.comment", {
					min: 3,
					max: 250,
				})
			);
			return;
		}
		validate(event);
		setInputs(event);
	};
	const renderSwitch = (status) => {
		switch (status) {
			case "PUBLISHED":
				return (
					<Button
						value="UNPUBLISHED"
						label={translate("buttons.unPublish")}
						color="primary"
						disabled={loading}
						onClick={handleClickOpen}
					/>
				);
			case "PENDING":
				return (
					<>
						<Button
							value="PUBLISHED"
							label={translate("buttons.publish")}
							color="primary"
							disabled={loading}
							onClick={handleClickOpen}
						/>
						<Button
							value="REJECTED"
							label={translate("buttons.reject")}
							color="primary"
							disabled={loading}
							onClick={handleClickOpen}
						/>
					</>
				);
			case "UNPUBLISHED":
				return (
					<Button
						value="PUBLISHED"
						label={translate("buttons.publish")}
						color="primary"
						disabled={loading}
						onClick={handleClickOpen}
					/>
				);
			default:
				return <></>;
		}
	};
	return (
		<>
			<TopToolbar>
				{renderSwitch(data?.status)}
				<EditButton basePath={basePath} record={data} />
			</TopToolbar>
			<CustomDialog
				open={open}
				onClose={handleCancel}
				title="messages.confirmDialog.changeStatus.title"
				content={{
					message: "messages.confirmDialog.changeStatus.content",
					args: {
						oldStatus: translate(`misc.statuses.${data?.status}`),
						newStatus: translate(`misc.statuses.${clickedButton}`),
					},
				}}
			>
				<Box width="100%">
					<Box component="div" display="block">
						<TextField
							value={inputs.comment}
							name="comment"
							onFocus={() => setTouched(true)}
							// onBlur={validate}
							onChange={handleChange}
							error={error}
							helperText={error}
							fullWidth
							multiline
							variant="outlined"
							label={translate("labels.inputs.comment")}
						/>
					</Box>
					<Box
						display="flex"
						justifyContent="flex-end"
						component="div"
						mt="0.5rem"
					>
						<CustomButton
							onClick={handleCancel}
							innerStyle={{
								display: "flex",
								alignItems: "unset",
								justifyContent: "center",
							}}
							innerText="buttons.cancel"
							icon={<CancelIcon />}
							className={classnames("ra-confirm")}
							autoFocus
						/>
						<CustomButton
							disabled={error}
							onClick={handleConfirm}
							innerStyle={{
								display: "flex",
								alignItems: "unset",
								justifyContent: "center",
							}}
							innerText="buttons.confirm"
							icon={<CheckCircleIcon />}
							className={classnames("ra-confirm")}
							color="primary"
						/>
					</Box>
				</Box>
			</CustomDialog>
		</>
	);
};
