import React from "react";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import {
	Datagrid,
	EditButton,
	Filter,
	List,
	ShowButton,
	TextField,
	TopToolbar,
	CreateButton,
	ExportButton,
	TextInput,
	DeleteButton,
	useRecordContext,
} from "react-admin";

import userPreferences from "../../utils/fakeUserPrefrences";
import { constants } from "../../utils/constants";

const ResourcesCategoryFilter = (props) => (
	<Filter {...props}>
		<TextInput source="search" alwaysOn />
	</Filter>
);

const ListActions = (props) => (
	<TopToolbar>
		<CreateButton />
		<ExportButton />
	</TopToolbar>
);

const CustomFileField = (props) => {
	const record = useRecordContext(props);
	return record ? (
		<a
			style={{
				color: "rgba(0, 0, 0, 0.87)",
				alignItems: "center",
				display: "flex",
				gap: "8px",
				fontSize: "0.875rem",
				fontFamily: "'Roboto', 'Helvetica', 'Arial', sans_serif",
				fontWeight: "400",
				lineHeight: "1.43",
				letterSpacing: "0.01071em",
			}}
			href={record.contentUrl}
		>
			<CloudDownloadIcon />
			{record.name}
		</a>
	) : null;
};

export const HRResourcesContentList = (props) => {
	return (
		<List
			{...props}
			perPage={constants.perPage}
			bulkActionButtons={false}
			filters={<ResourcesCategoryFilter />}
			actions={<ListActions />}
		>
			<Datagrid>
				<CustomFileField source="name" />
				<TextField
					source={`categoryTitles.${userPreferences.translationLanguages[1]}`}
					sortable={true}
					label="Category title (Arabic)"
				/>
				<TextField
					source={`categoryTitles.${userPreferences.translationLanguages[0]}`}
					sortable={true}
					label="Category title (English)"
				/>
				<EditButton />
				<DeleteButton />
			</Datagrid>
		</List>
	);
};
