import { useEffect, useState, useMemo } from "react";
import {
	Create,
	DateInput,
	TextInput,
	useNotify,
	useRedirect,
	TabbedForm,
	useGetList,
	FormTab,
	useTranslate,
	useCreate,
	BooleanInput,
} from "react-admin";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { validateDailyRecommendationData } from "./dailyRecommendationValidate";
import { CustomTransferList } from "../../customComponents/CustomTransferList";
import { setIndexesForData } from "../../utils/customFunctions";
import axios from "axios";
import { envSettings } from "../../settings/env";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { arabicLanguageId } from "../../utils/constants";

export const DailyRecommendationCreate = (props) => {
	const [languagesArray, setLanguagesArray] = useState([]);
	const [loading, setLoading] = useState(true);
	const [nearestDate, setNearestDate] = useState(null);
	const [isSingleDay, setIsSingleDay] = useState(true);
	const translate = useTranslate();
	const redirect = useRedirect();
	const notify = useNotify();
	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);

	const { data: languagesData } = useGetList("languages", {
		page: 1,
		perPage: 10,
	});

	const onSuccess = () => {
		notify(translate("daily-recommendations.create.success"), "info");
		redirect("/daily-recommendations-ar");
	};

	const onSingleDateChange = (val) => setIsSingleDay(val);

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	useEffect(() => {
		setLanguagesArray(
			Object.values(languagesData).map((language) =>
				language.name.slice(0, 2).toLowerCase()
			)
		);
	}, [languagesData]);

	useEffect(() => {
		(async () => {
			const res = await axios.get(
				envSettings.baseUrl + "/daily-recommendations-ar/date/nearest",
				{
					withCredentials: true,
				}
			);
			setNearestDate(res?.data?.nearestDate);
			setLoading(false);
		})();
	}, []);

	const [create] = useCreate("daily-recommendations-ar");
	return (
		<>
			{loading ? (
				<center>
					<h1>Loading...</h1>
				</center>
			) : (
				<Create
					{...props}
					title={translate("daily-recommendations.create.title")}
				>
					<TabbedForm
						toolbar={<CustomToolbar />}
						validate={validateDailyRecommendationData}
						submitOnEnter={false}
						syncWithLocation={false}
						save={(data) => {
							const indexedDataToSend = setIndexesForData(data, [
								"modules",
								"playlists",
								"plans",
								"plans",
								"singlesLists",
							]);
							create(
								{
									payload: {
										data: indexedDataToSend,
									},
								},
								{
									onSuccess,
									onFailure,
								}
							);
						}}
					>
						<FormTab
							label={translate("modules.tabs.basicInfo.label")}
						>
							<TextInput
								source="title"
								label={"daily-recommendations.title"}
							/>
							<BooleanInput
								label="Is Single Day"
								source="is_single_day"
								defaultValue={isSingleDay}
								onChange={onSingleDateChange}
							/>
							<DateInput
								source="date"
								label={isSingleDay ? "Date" : "Starting Date"}
								defaultValue={
									new Date(nearestDate)
										.toISOString()
										.split("T")[0]
								}
							/>
							{!isSingleDay && (
								<DateInput
									source="endDate"
									label={"End Date"}
									defaultValue={
										new Date(
											new Date().setDate(
												new Date(
													nearestDate
												).getDate() + 1
											)
										)
											.toISOString()
											.split("T")[0]
									}
								/>
							)}
						</FormTab>
						<FormTab
							label={translate(
								"daily-recommendations.tabs.singlesManagement.label"
							)}
						>
							<CustomTransferList
								source="modules"
								referenceSource="modules"
								referenceResource="modules"
								languageId={arabicLanguageId}
								referenceField="name"
								isReferenceTranslatable={false}
								customFilter="(({isSingle})=>isSingle)"
							/>
						</FormTab>
						<FormTab
							label={translate(
								"daily-recommendations.tabs.playlistsManagement.label"
							)}
						>
							<CustomTransferList
								source="playlists"
								referenceSource="playlists"
								referenceResource="playlists"
								referenceField="names"
								languageId={arabicLanguageId}
								isReferenceTranslatable={true}
							/>
						</FormTab>
						<FormTab
							label={translate(
								"daily-recommendations.tabs.plansManagement.label"
							)}
						>
							<CustomTransferList
								source="plans"
								referenceSource="plans"
								referenceResource="plans"
								referenceField="names"
								languageId={arabicLanguageId}
								isReferenceTranslatable={true}
							/>
						</FormTab>
						<FormTab
							label={translate(
								"daily-recommendations.tabs.singleListsManagement.label"
							)}
						>
							<CustomTransferList
								source="singlesLists"
								referenceSource="lists-singles"
								referenceResource="lists-singles"
								referenceField="names"
								customFilter="((listsSingle)=> listsSingle?.names?.ar !== null)"
								isReferenceTranslatable={true}
							/>
						</FormTab>
					</TabbedForm>
				</Create>
			)}
		</>
	);
};
