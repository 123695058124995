import { useMemo, useEffect, useState } from "react";
import {
	Show,
	SimpleShowLayout,
	SingleFieldList,
	ArrayField,
	useRedirect,
	ChipField,
	TextField,
	useTranslate,
	useGetOne,
} from "react-admin";
import { userPreferences } from "../../utils/fakeUserPrefrences";

const CustomChipFieldWithRedirect = ({
	record,
	source,
	basePath,
	emptyText,
	redirectToResource,
	...props
}) => {
	const redirect = useRedirect();
	const handleRedirectClick = () => {
		redirect("show", `/${redirectToResource}`, record.id);
	};

	return (
		<ChipField
			onClick={handleRedirectClick}
			source={source}
			record={record}
			emptyText={emptyText}
			{...props}
		/>
	);
};
export const FeaturedShow = (props) => {
	const [isSingleDay, setIsSingleDay] = useState(null);
	const translate = useTranslate();
	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);
	const { data = {}, loading } = useGetOne("featured-ar", props.id);
	useEffect(() => {
		if (!loading) setIsSingleDay(data?.isSingleDay);
	}, [data, loading]);

	return (
		<Show {...props} title={translate("day-portions.show.title")}>
			<SimpleShowLayout>
				<TextField source={`title`} label={`Title(en)`} />
				<TextField
					source="date"
					label={isSingleDay ? `Date` : `Starting Date`}
				/>
				{!isSingleDay && (
					<TextField source="endDate" label={`End Date`} />
				)}
				<ArrayField source="modules">
					<SingleFieldList linkType={false}>
						<CustomChipFieldWithRedirect
							source="name"
							emptyText={translate("empty.module")}
							redirectToResource="modules"
						/>
					</SingleFieldList>
				</ArrayField>
				<ArrayField source="playlists">
					<SingleFieldList linkType={false}>
						<CustomChipFieldWithRedirect
							source="names['ar']"
							emptyText={translate("empty.playlist")}
							redirectToResource="playlists"
						/>
					</SingleFieldList>
				</ArrayField>
				<ArrayField source="plans">
					<SingleFieldList linkType={false}>
						<CustomChipFieldWithRedirect
							source="names['ar']"
							emptyText={translate("empty.plan")}
							redirectToResource="plans"
						/>
					</SingleFieldList>
				</ArrayField>
			</SimpleShowLayout>
		</Show>
	);
};
