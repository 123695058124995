import { SubscriptionCreate } from "./subscriptionCreate";
import { SubscriptionEdit } from "./subscriptionEdit";
import { SubscriptionList } from "./subscriptionList";
import { SubscriptionShow } from "./subscriptionShow";

export default {
	create: SubscriptionCreate,
	edit: SubscriptionEdit,
	list: SubscriptionList,
	show: SubscriptionShow,
};
