import { useCallback, useEffect, useState } from "react";
import { Dialog, DialogActions } from "@material-ui/core";

export const CustomEmptyDialog = ({
	open,
	onClose,
	title,
	content,
	children,
	...rest
}) => {
	const [openState, setOpenState] = useState(false);

	const handleClose = useCallback(() => {
		if (typeof onClose === "function") return onClose();
		return setOpenState(false);
	}, [onClose]);

	useEffect(() => {
		setOpenState(!!open);
	}, [open]);

	return (
		<Dialog
			{...rest}
			open={openState}
			onClose={handleClose}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<DialogActions>{children}</DialogActions>
		</Dialog>
	);
};
