import React from "react";
import {
	BooleanInput,
	Datagrid,
	EditButton,
	Filter,
	List,
	TextField,
	TextInput,
	ShowButton,
	DeleteButton,
} from "react-admin";

import { userPreferences } from "../../utils/fakeUserPrefrences";
import { constants } from "../../utils/constants";
import { CustomBooleanInput } from "../../customComponents/CustomBooleanInput";

const JournalFilter = (props) => (
	<Filter {...props}>
		<TextInput source="search" alwaysOn />
		<BooleanInput source="isActive" defaultValue={true} />
	</Filter>
);

export const JournalList = (props) => {
	return (
		<List
			{...props}
			perPage={constants.perPage}
			bulkActionButtons={false}
			filters={<JournalFilter />}
			exporter={false}
		>
			<Datagrid>
				<TextField
					source={`name.${userPreferences.defaultTranslationLanguage}`}
					sortable={false}
				/>
				<CustomBooleanInput source="isActive" sortable={false} />
				<ShowButton />
				<EditButton />
				<DeleteButton mutationMode="pessimistic" />
			</Datagrid>
		</List>
	);
};

export default JournalList;
