import { useEffect, useState, useMemo } from "react";
import {
	Edit,
	TextInput,
	useGetList,
	DateInput,
	useNotify,
	useRedirect,
	useTranslate,
	useRefresh,
	useUpdate,
	TabbedForm,
	FormTab,
	TranslatableInputs,
	BooleanInput,
	useGetOne,
} from "react-admin";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { CustomTransferList } from "../../customComponents/CustomTransferList";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { validateDailyRecommendationData } from "./dailyRecommendationValidate";
import { setIndexesForData } from "../../utils/customFunctions";
import axios from "axios";
import { envSettings } from "../../settings/env";
import { englishLanguageId } from "../../utils/constants";

export const DailyRecommendationEdit = (props) => {
	const [languagesArray, setLanguagesArray] = useState([]);
	const translate = useTranslate();
	const redirect = useRedirect();
	const notify = useNotify();
	const refresh = useRefresh();
	const [isSingleDay, setIsSingleDay] = useState(null);
	const { data: languagesData } = useGetList("languages", {
		page: 1,
		perPage: 10,
	});
	const [nearestDate, setNearestDate] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		(async () => {
			const res = await axios.get(
				envSettings.baseUrl + "/daily-recommendations-en/date/nearest",
				{
					withCredentials: true,
				}
			);
			setNearestDate(res?.data?.nearestDate);
			setLoading(false);
		})();
	}, []);

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);

	const { data = {}, loading: dailyLoaded } = useGetOne(
		"daily-recommendations-en",
		props.id
	);

	useEffect(() => {
		if (!dailyLoaded) setIsSingleDay(data?.isSingleDay);
	}, [data, dailyLoaded]);

	const onSingleDateChange = (val) => setIsSingleDay(val);

	const onSuccess = () => {
		notify(translate("daily-recommendations.edit.success"), "info");
		redirect("/daily-recommendations-en");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	const [update] = useUpdate("daily-recommendations-en", props.id);

	useEffect(() => {
		setLanguagesArray(
			Object.values(languagesData).map((language) =>
				language.name.slice(0, 2).toLowerCase()
			)
		);
	}, [languagesData]);

	return (
		<Edit
			{...props}
			mutationMode="pessimistic"
			title={translate("daily-recommendations.edit.title")}
		>
			<TabbedForm
				toolbar={<CustomToolbar />}
				submitOnEnter={false}
				validate={validateDailyRecommendationData}
				save={(data) => {
					const indexedDataToSend = setIndexesForData(data, [
						"modules",
						"playlists",
						"plans",
						"singlesLists",
					]);
					update(
						{
							payload: {
								data: indexedDataToSend,
							},
						},
						{
							onSuccess,
							onFailure,
						}
					);
				}}
				syncWithLocation={false}
			>
				<FormTab
					label={translate(
						"daily-recommendations.tabs.basicInfo.label"
					)}
				>
					<TextInput
						source="title"
						label={"daily-recommendations.title"}
					/>
					<BooleanInput
						label="Is Single Day"
						source="isSingleDay"
						onChange={onSingleDateChange}
					/>
					<DateInput
						source="date"
						label={isSingleDay ? "Date" : "Starting Date"}
					/>
					{!isSingleDay && (
						<DateInput
							source="endDate"
							label={"End Date"}
							defaultValue={
								new Date(
									new Date().setDate(
										new Date(nearestDate).getDate() + 1
									)
								)
							}
						/>
					)}
				</FormTab>
				<FormTab
					label={translate(
						"daily-recommendations.tabs.singlesManagement.label"
					)}
				>
					<CustomTransferList
						source="modules"
						referenceSource="modules"
						referenceResource="modules"
						referenceField="name"
						isReferenceTranslatable={false}
						languageId={englishLanguageId}
						customFilter="(({isSingle})=>isSingle)"
					/>
				</FormTab>
				<FormTab
					label={translate(
						"daily-recommendations.tabs.playlistsManagement.label"
					)}
				>
					<CustomTransferList
						source="playlists"
						referenceSource="playlists"
						referenceResource="playlists"
						referenceField="names"
						languageId={englishLanguageId}
						isReferenceTranslatable={true}
					/>
				</FormTab>
				<FormTab
					label={translate(
						"daily-recommendations.tabs.plansManagement.label"
					)}
				>
					<CustomTransferList
						source="plans"
						referenceSource="plans"
						referenceResource="plans"
						referenceField="names"
						languageId={englishLanguageId}
						isReferenceTranslatable={true}
					/>
				</FormTab>
				<FormTab
					label={translate(
						"daily-recommendations.tabs.singleListsManagement.label"
					)}
				>
					<CustomTransferList
						source="singlesLists"
						referenceSource="lists-singles"
						referenceResource="lists-singles"
						referenceField="names"
						customFilter="((listsSingle)=> listsSingle?.names?.en !== null)"
						isReferenceTranslatable={true}
					/>
				</FormTab>
			</TabbedForm>
		</Edit>
	);
};
