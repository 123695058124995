import { useEffect, useMemo, useState } from "react";
import {
	Create,
	FormTab,
	TabbedForm,
	TextInput,
	TranslatableInputs,
	useCreate,
	useGetList,
	useNotify,
	useRedirect,
	useTranslate,
} from "react-admin";
import { CustomTransferList } from "../../customComponents/CustomTransferList";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { validateListSinglesData } from "./listSinglesValidate";
import {
	setIndexesForData,
	transformToFormData,
} from "../../utils/customFunctions";
import { CustomToolbar } from "../../customComponents/CustomToolbar";

export const ListSinglesCreate = (props) => {
	const [languagesArray, setLanguagesArray] = useState([]);
	const [create] = useCreate("lists-singles");
	const translate = useTranslate();
	const redirect = useRedirect();
	const notify = useNotify();

	const { data: languagesData } = useGetList("languages");

	const onSuccess = () => {
		notify(translate("lists-singles.create.success"), "info");
		redirect("/lists-singles");
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	useEffect(() => {
		setLanguagesArray(
			Object.values(languagesData).map((language) =>
				language.name.slice(0, 2).toLowerCase()
			)
		);
	}, [languagesData]);

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);

	return (
		<Create {...props} title={translate("lists-singles.create.title")}>
			<TabbedForm
				toolbar={<CustomToolbar />}
				save={(data) => {
					const indexedDataToSend = setIndexesForData(data, ["modules"]);
					const transformed = transformToFormData(indexedDataToSend);
					create(
						{ payload: { data: transformed } },
						{
							onSuccess,
							onFailure,
						}
					);
				}}
				validate={validateListSinglesData}
				syncWithLocation={false}
			>
				<FormTab label={translate("lists-singles.tabs.basicInfo.label")}>
					<TranslatableInputs
						locales={languagesArray}
						defaultLocale={defaultLocale}
					>
						<TextInput source="names" />
						<TextInput multiline source="descriptions" />
					</TranslatableInputs>
				</FormTab>
				<FormTab
					label={translate("lists-singles.tabs.singlesManagement.label")}
				>
					<CustomTransferList
						source="modules"
						referenceSource="modules"
						referenceResource="modules"
						referenceField="name"
						isReferenceTranslatable={false}
						customFilter="(({isSingle, comingSoon})=>isSingle && !comingSoon)"
					/>
				</FormTab>
			</TabbedForm>
		</Create>
	);
};
