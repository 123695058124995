import * as React from "react";
import {
	ArrayField,
	AutocompleteArrayInput,
	ChipField,
	Edit,
	PasswordInput,
	SimpleForm,
	SingleFieldList,
	TextInput,
	useNotify,
	useRedirect,
	useRefresh,
	useTranslate,
} from "react-admin";

import { Typography } from "@material-ui/core";

import { rolesChoicesArr } from "../../utils/constants";
import { validateUserData } from "./userValidate";
import { useStyles } from "../../utils/styles";
import { CustomToolbar } from "../../customComponents/CustomToolbar";

const UserEmail = ({ record }) => {
	const translate = useTranslate();

	return (
		<Typography>
			{`${translate("users.edit.title", { email: record.email })}`}
		</Typography>
	);
};

export const UserEdit = (props) => {
	const notify = useNotify();
	const classes = useStyles();
	const refresh = useRefresh();
	const redirect = useRedirect();

	const onSuccess = () => {
		notify("users.edit.success", "info");
		redirect("/users");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	return (
		<Edit
			{...props}
			mutationMode="pessimistic"
			title={<UserEmail />}
			onSuccess={onSuccess}
			onFailure={onFailure}
		>
			<SimpleForm validate={validateUserData} toolbar={<CustomToolbar />}>
				<TextInput disabled source="id" className={classes.inline} />
				<TextInput disabled source="email" />
				<TextInput
					source="firstName"
					required={true}
					className={classes.inline}
				/>
				<TextInput source="lastName" required={true} />
				<PasswordInput source="password" />
				<ArrayField source="roles">
					<SingleFieldList linkType={false}>
						<ChipField source="role" clickable={false} />
					</SingleFieldList>
				</ArrayField>
				<AutocompleteArrayInput source="newRoles" choices={rolesChoicesArr} />
			</SimpleForm>
		</Edit>
	);
};
