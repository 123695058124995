import {
	ArrayField,
	Datagrid,
	Show,
	SimpleShowLayout,
	TextField,
	useTranslate,
} from "react-admin";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { useMemo } from "react";

export const ReminderShow = (props) => {
	const translate = useTranslate();
	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);
	return (
		<Show {...props} title={translate("subscriptions.show.title")}>
			<SimpleShowLayout>
				<TextField
					source={`title.${defaultLocale}`}
					label={`Title(${defaultLocale})`}
				/>
				<ArrayField
					source={`message.${defaultLocale}`}
					label={`Messages(${defaultLocale})`}
				>
					<Datagrid>
						<TextField
							source="singleMessage"
							label={`Message(${defaultLocale})`}
						/>
					</Datagrid>
				</ArrayField>
			</SimpleShowLayout>
		</Show>
	);
};
