import { useEffect, useMemo, useState } from "react";
import {
	TextInput,
	Edit,
	TranslatableInputs,
	useGetList,
	useNotify,
	useRedirect,
	useTranslate,
	useRefresh,
	SimpleForm,
	ArrayInput,
	SimpleFormIterator,
	FormDataConsumer,
} from "react-admin";

import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { validateReminderData } from "./reminderValidate";

export const ReminderEdit = (props) => {
	const [languagesArray, setLanguagesArray] = useState([]);

	const translate = useTranslate();
	const redirect = useRedirect();
	const notify = useNotify();
	const refresh = useRefresh();

	const { data: languagesData } = useGetList("languages");

	const onSuccess = () => {
		notify(translate("reminders.edit.success"), "info");
		redirect("/reminders");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);

	useEffect(() => {
		setLanguagesArray(
			Object.values(languagesData).map((language) =>
				language.name.slice(0, 2).toLowerCase()
			)
		);
	}, [languagesData]);

	return (
		<Edit
			{...props}
			mutationMode="pessimistic"
			onSuccess={onSuccess}
			onFailure={onFailure}
			title={translate("reminders.edit.title")}
		>
			<SimpleForm
				toolbar={<CustomToolbar />}
				validate={validateReminderData}
				syncWithLocation={false}
			>
				<TranslatableInputs
					locales={languagesArray}
					defaultLocale={defaultLocale}
				>
					<TextInput source="title" />
					<ArrayInput source="message">
						<FormDataConsumer>
							{({ formData, ...rest }) => (
								<SimpleFormIterator
									disableReordering={true}
									{...rest}
									disableAdd={
										rest?.fields?.length >= 3 ? true : false
									}
								>
									<TextInput
										source="singleMessage"
										helperText={"Insert your message here"}
									/>
								</SimpleFormIterator>
							)}
						</FormDataConsumer>
					</ArrayInput>
				</TranslatableInputs>
			</SimpleForm>
		</Edit>
	);
};
