import { envSettings } from "../settings/env";
import { fetchUtils } from "ra-core";

const { publicUrls } = require("../utils/constants");

const httpClient = fetchUtils.fetchJson;

export const authServices = {
	login: ({ username, password }) => {
		const email = username;
		const request = new Request(envSettings.baseUrl + "/users/login", {
			method: "POST",
			body: JSON.stringify({ email, password }),
			headers: new Headers({ "Content-Type": "application/json" }),
			credentials: "include",
		});
		return fetch(request)
			.then((response) => {
				if (response.status < 200 || response.status >= 300) {
					throw new Error(response.message);
				}
				return response.json();
			})
			.then(() => {
				localStorage.setItem("authenticated", "true");
			});
	},

	checkError: (error) => {
		return Promise.resolve();
	},
	checkAuth: () => {
		const code = window.location.hash.split("/" + publicUrls.verify + "?")[1]
			? window.location.hash
					.split("/" + publicUrls.verify + "?")[1]
					.split("code=")[1]
			: null;
		const codeLength = code ? code.length : 0;
		if (codeLength === 36) return Promise.resolve();
		if (localStorage.getItem("authenticated") !== "true")
			return Promise.reject();
		const request = new Request(envSettings.baseUrl + "/users/me", {
			method: "GET",
			headers: new Headers({ "Content-Type": "application/json" }),
			credentials: "include",
		});
		return fetch(request).then((response) => {
			if (response.status !== 200) {
				return Promise.reject();
			} else return Promise.resolve();
		});
	},

	logout: () => {
		if (localStorage.getItem("authenticated") !== "true")
			return Promise.resolve();
		const request = new Request(envSettings.baseUrl + "/users/logout", {
			method: "POST",
			headers: new Headers({ "Content-Type": "application/json" }),
			credentials: "include",
		});
		return fetch(request)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject();
				} else {
					localStorage.removeItem("authenticated");
					return Promise.resolve();
				}
			})
			.catch((err) => {
				console.log(err);
			});
	},

	getIdentity: () => {
		return httpClient(envSettings.baseUrl + "/users/me", {
			method: "GET",
			headers: new Headers({ "Content-Type": "application/json" }),
			credentials: "include",
		}).then(({ body }) => {
			const { id, firstName, lastName, avatar } = JSON.parse(body);

			return Promise.resolve({
				id,
				fullName: `${firstName} ${lastName}`,
				avatar: avatar || null,
			});
		});
	},

	getPermissions: () => {
		const code = window.location.hash.split("/" + publicUrls.verify + "?")[1]
			? window.location.hash
					.split("/" + publicUrls.verify + "?")[1]
					.split("code=")[1]
			: null;
		const codeLength = code ? code.length : 0;
		if (codeLength === 36) return Promise.resolve([]);
		if (localStorage.getItem("authenticated") !== "true") {
			return Promise.reject();
		}
		const request = new Request(envSettings.baseUrl + "/users/permissions", {
			method: "GET",
			headers: new Headers({ "Content-Type": "application/json" }),
			credentials: "include",
		});
		return fetch(request)
			.then((response) => {
				if (response.status !== 200) {
					return Promise.reject();
				} else {
					return response.json();
				}
			})
			.then(({ permissions }) => {
				return Promise.resolve(permissions);
			});
	},
};

export default authServices;
