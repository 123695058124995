import { isNotInRange } from "../../utils/customFunctions";

export const validateContentData = (data) => {
	const { name, content, contentUrl, categoryId, fromCreate = false } = data;

	const errors = {};

	if (!name) {
		errors.name = {
			message: "validationErrors.required.requiredField",
			args: { field: "name" },
		};
	}

	if (name && isNotInRange({ min: 3, max: 50, str: name })) {
		errors.name = {
			message: "validationErrors.minMax.name",
			args: { min: 3, max: 50 },
		};
	}

	if (!categoryId) {
		errors.categoryId = {
			message: "validationErrors.required.requiredField",
			args: { field: "category" },
		};
	}

	if (!content && fromCreate) {
		errors.content = {
			message: "validationErrors.required.requiredField",
			args: { field: "content file" },
		};
	}

	if (!content && !fromCreate && !contentUrl) {
		errors.content = {
			message: "validationErrors.required.requiredField",
			args: { field: "content file" },
		};
	}

	return errors;
};
