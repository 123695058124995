import {
	ArrayField,
	ChipField,
	Show,
	SimpleShowLayout,
	SingleFieldList,
	TextField,
	useRedirect,
	useTranslate,
} from "react-admin";
import { useMemo } from "react";
import { userPreferences } from "../../utils/fakeUserPrefrences";
const CustomChipFieldWithRedirect = ({
	record,
	source,
	basePath,
	emptyText,
	redirectToResource,
	...props
}) => {
	const redirect = useRedirect();
	const handleRedirectClick = () => {
		redirect("show", `/${redirectToResource}`, record.id);
	};
	return (
		<ChipField
			onClick={handleRedirectClick}
			source={source}
			record={record}
			emptyText={emptyText}
			{...props}
		/>
	);
};

export const BadgeShow = (props) => {
	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);
	const translate = useTranslate();
	return (
		<Show {...props} title={translate("badges.show.title")}>
			<SimpleShowLayout>
				<TextField source={`name.${defaultLocale}`} sortable={false} />
				<ArrayField source="tasks">
					<SingleFieldList linkType={false}>
						<CustomChipFieldWithRedirect
							source="name.ar"
							emptyText={translate("empty.module")}
							redirectToResource="tasks"
						/>
					</SingleFieldList>
				</ArrayField>
			</SimpleShowLayout>
		</Show>
	);
};
