import { SaveButton, Toolbar } from "react-admin";
import { CustomDeleteButtonWithConfirmation } from "./CustomButtons";

export const CustomToolbar = ({ deleteButton, source, loading, ...props }) => {
	return (
		<Toolbar {...props}>
			<SaveButton disabled={loading} />
			{deleteButton ? (
				<CustomDeleteButtonWithConfirmation source={source} />
			) : null}

			{props.children}
		</Toolbar>
	);
};
