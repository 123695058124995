import React, { useState, useEffect } from "react";
import {
	Edit,
	SimpleForm,
	TextInput,
	DateTimeInput,
	useNotify,
	useRedirect,
	useRefresh,
	NumberInput,
	useUpdate,
	BooleanInput,
} from "react-admin";

import { validatePromoCodeData } from "./promoCodeValidate";
import { useStyles } from "../../utils/styles";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { FormControlLabel, Switch } from "@material-ui/core";

const FreeDaysSection = ({ record, isFreeDays, setIsFreeDays, ...props }) => {
	useEffect(() => {
		setIsFreeDays(record.isFreeDays);
	}, []);

	return (
		<>
			<FormControlLabel
				control={
					<Switch
						name="freeDays"
						color={"primary"}
						checked={isFreeDays}
						onClick={() => setIsFreeDays(!isFreeDays)}
						disabled={record.usageCount > 0}
					/>
				}
				label={"Free Days"}
			/>
			<br />
			{isFreeDays ? (
				<NumberInput
					source="numberOfFreeDays"
					max={365}
					min={1}
					disabled={record.usageCount > 0}
				/>
			) : (
				<NumberInput
					{...props}
					source="percentage"
					max={99}
					min={1}
					disabled={record.usageCount > 0}
				/>
			)}
		</>
	);
};

export const PromoCodeEdit = (props) => {
	const redirect = useRedirect();
	const refresh = useRefresh();
	const notify = useNotify();
	const classes = useStyles();
	const [isFreeDays, setIsFreeDays] = useState(false);
	const [update] = useUpdate("promo-codes", props.id);

	const onSuccess = () => {
		notify("promo-codes.edit.success", "info");
		redirect("/promo-codes");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	return (
		<Edit {...props} mutationMode="pessimistic">
			<SimpleForm
				toolbar={<CustomToolbar />}
				validate={(data) =>
					validatePromoCodeData({ ...data, isFreeDays: isFreeDays })
				}
				save={(data) => {
					data.code = data.code.trim();
					data.isFreeDays = isFreeDays;
					isFreeDays
						? delete data.percentage
						: delete data.numberOfFreeDays;
					update(
						{ payload: { data: data } },
						{
							onSuccess,
							onFailure,
						}
					);
				}}
			>
				<TextInput source="code" className={classes.inline} />
				<DateTimeInput source="startDate" />
				<br />
				<NumberInput
					source="userUsageLimit"
					className={classes.inline}
				/>
				<DateTimeInput source="endDate" />
				<NumberInput source="globalUsageLimit" />
				<BooleanInput source="forBusiness" />
				<FreeDaysSection
					isFreeDays={isFreeDays}
					setIsFreeDays={setIsFreeDays}
				/>
			</SimpleForm>
		</Edit>
	);
};

export default PromoCodeEdit;
