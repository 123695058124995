import { Typography } from "@material-ui/core";
import {
	ArrayField,
	ChipField,
	FunctionField,
	Show,
	SimpleShowLayout,
	SingleFieldList,
	TextField,
	useTranslate,
} from "react-admin";

const UserEmail = ({ record }) => {
	const translate = useTranslate();

	const message = translate("users.show.title", { email: record.email });

	return <Typography>{`${message}`}</Typography>;
};

const CustomMessage = ({ record }) => {
	const translate = useTranslate();
	const message = translate("users.show.isActive", {
		status: record?.isActive ? "" : "not",
	});
	return <Typography>{` ${message}`}</Typography>;
};

export const UserShow = (props) => {
	return (
		<Show {...props} title={<UserEmail />}>
			<SimpleShowLayout>
				<TextField source="firstName" />
				<TextField source="lastName" />
				<TextField source="email" />
				<FunctionField
					source="isActive"
					render={(record) => <CustomMessage record={record} />}
				/>
				<ArrayField source="roles">
					<SingleFieldList linkType={false}>
						<ChipField source="role" />
					</SingleFieldList>
				</ArrayField>
			</SimpleShowLayout>
		</Show>
	);
};
