import { LicenseCreate } from "./licenseCreate";
import { LicenseEdit } from "./licenseEdit";
import { LicenseList } from "./licenseList";
import { LicenseShow } from "./licenseShow";

export default {
	create: LicenseCreate,
	edit: LicenseEdit,
	list: LicenseList,
	show: LicenseShow,
};
