import { useMemo } from "react";
import {
	ArrayField,
	ChipField,
	Show,
	SimpleShowLayout,
	SingleFieldList,
	TextField,
	useLocale,
	useRedirect,
	useTranslate,
} from "react-admin";

import { CustomAsideSimpleList } from "../../customComponents/CustomAsideSimpleList";
import { CustomShowActions } from "../../customComponents/CustomShowActions";
import { requiredPermissions, requiredStatuses } from "./shared";
import { userPreferences } from "../../utils/fakeUserPrefrences";

const CustomChipFieldWithRedirect = ({
	record,
	source,
	basePath,
	emptyText,
	redirectToResource,
	...props
}) => {
	const redirect = useRedirect();

	const handleRedirectClick = () => {
		redirect("show", `/${redirectToResource}`, record.id);
	};

	return (
		<ChipField
			onClick={handleRedirectClick}
			source={source}
			emptyText={emptyText}
			{...props}
		/>
	);
};

export const BackgroundPlaylistShow = (props) => {
	const locale = useLocale();
	const translate = useTranslate();

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);

	return (
		<Show
			aside={<CustomAsideSimpleList source="auditTracker" />}
			{...props}
			actions={
				<CustomShowActions
					source="status"
					requiredStatuses={requiredStatuses}
					requiredPermissions={requiredPermissions}
				/>
			}
			title={translate("background-playlists.show.title")}
		>
			<SimpleShowLayout>
				<TextField source={`names.${defaultLocale}`} />
				<TextField source={`descriptions.${defaultLocale}`} />
				<TextField
					source={`category.titles.${defaultLocale}`}
					sortable={false}
				/>
				<TextField source="status" sortable={false} />
				<ArrayField source="backgroundModules">
					<SingleFieldList linkType={false}>
						<CustomChipFieldWithRedirect
							source="name"
							emptyText={translate("empty.playlist")}
							redirectToResource="background-modules"
						/>
					</SingleFieldList>
				</ArrayField>
			</SimpleShowLayout>
		</Show>
	);
};
