import { isNotInRange } from "../../utils/customFunctions";

export const validateReminderData = async ({ title, message }) => {
	const errors = {};
	if (!title?.en || !title?.ar) {
		errors.title = {
			en: "validationErrors.required.bothLanguagesRequired",
			ar: "validationErrors.required.bothLanguagesRequired",
		};
	}

	if (!message?.en && !message?.ar) {
		errors.message = {
			en: "validationErrors.required.oneIsRequired",
			ar: "validationErrors.required.oneIsRequired",
		};
	}

	if (title?.ar && isNotInRange({ min: 3, max: 400, str: title.ar })) {
		errors.title = {
			ar: {
				message: "validationErrors.minMax.title",
				args: { min: 3, max: 400 },
			},
		};
	}

	if (title?.en && isNotInRange({ min: 3, max: 400, str: title.en })) {
		errors.title = {
			en: {
				message: "validationErrors.minMax.title",
				args: { min: 3, max: 400 },
			},
		};
	}

	if (message?.ar) {
		message?.ar.forEach((msg, index) => {
			let messageText = msg?.singleMessage ?? "";
			if (
				isNotInRange({ min: 3, max: 400, str: messageText }) &&
				msg?.singleMessage != null
			) {
				errors.title = {
					ar: {
						message: "validationErrors.minMax.message",
						args: { min: 3, max: 400 },
					},
				};
			}
		});
	}

	if (message?.en) {
		message?.en.forEach((msg) => {
			let messageText = msg?.singleMessage ?? "";
			if (
				isNotInRange({ min: 3, max: 400, str: messageText }) &&
				msg?.singleMessage != null
			) {
				errors.title = {
					en: {
						message: "validationErrors.minMax.message",
						args: { min: 3, max: 400 },
					},
				};
			}
		});
	}

	return errors;
};
