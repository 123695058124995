import { Box, Typography } from "@material-ui/core";
import { useTranslate, Button } from "react-admin";
import { Labeled } from "ra-ui-materialui";
import ActionDelete from "@material-ui/icons/Delete";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import classnames from "classnames";
import { CustomDialog } from "../customComponents/CustomDialog";
import { CustomButton } from "../customComponents/CustomButtons";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import Player from "./CustomVideoAudioPlayer";

export const CustomVariantList = ({
	type = "show",
	label,
	source,
	setDeletedVariantIds,
	record: { variants, language, isVideo },
}) => {
	const [currVariants, setCurrVariants] = useState([]);
	const [open, setOpen] = useState(false);
	const [indexToDelete, setIndexToDelete] = useState(null);
	useEffect(() => {
		setCurrVariants(variants);
	}, []);

	const handleOpenDialog = (indexToDelete) => {
		setIndexToDelete(indexToDelete);
		setOpen(true);
	};

	const handleVariantDeletion = () => {
		setDeletedVariantIds((deletedVariants) => [
			...deletedVariants,
			currVariants && currVariants[indexToDelete].id,
		]);
		setCurrVariants((oldCurrVariants) => {
			oldCurrVariants.splice(indexToDelete, 1);
			return oldCurrVariants;
		});
	};
	const translate = useTranslate();
	const useStyles = makeStyles(
		(theme) => ({
			deleteButton: {
				color: theme.palette.error.main,
				"&:hover": {
					backgroundColor: fade(theme.palette.error.main, 0.12),
					"@media (hover: none)": {
						backgroundColor: "transparent",
					},
				},
			},
			flex: {
				display: "flex",
				justifyContent: "flex-end",
			},
		}),
		{ name: "RaDeleteWithUndoButton" }
	);
	const classes = useStyles();
	return (
		<>
			<Labeled label={label || source}>
				<Box>
					{currVariants?.length === 0 ? (
						<Typography>{translate("empty")}</Typography>
					) : (
						currVariants?.map(
							(
								{
									audioUrl,
									narratorGender,
									listenerGender,
									displayName,
								},
								index
							) => {
								return (
									<>
										<Typography
											key={`${audioUrl}t${index}`}
										>
											{translate(
												`modules.list.language.${
													language?.isGenderNeutral
														? "isGenderNeutral"
														: "isNotGenderNeutral"
												}.urlTitle`,
												{
													narratorGender:
														translate(
															narratorGender
														),
													listenerGender:
														listenerGender
															? translate(
																	listenerGender
															  )
															: "",
												}
											)}
										</Typography>
										<h6>{displayName}</h6>
										<Box
											my={1.5}
											minWidth={400.5}
											key={`${audioUrl}b${index}`}
											className={classes.flex}
										>
											{!isVideo ? (
												<audio
													key={`${audioUrl}a${index}`}
													controls
													src={audioUrl}
												/>
											) : (
												<Player
													key={`${audioUrl}a${index}`}
													url={audioUrl}
												></Player>
											)}
											{type === "edit" && (
												<Button
													onClick={() =>
														handleOpenDialog(index)
													}
													key="button"
													className={
														classes.deleteButton
													}
												>
													<ActionDelete />
												</Button>
											)}
										</Box>
									</>
								);
							}
						)
					)}
				</Box>
			</Labeled>
			<CustomDialog
				open={open}
				onClose={() => setOpen(false)}
				title="Delete Module Variant!"
				content="Are you sure you want to delete this module variant?"
			>
				<CustomButton
					onClick={() => {
						setOpen(false);
					}}
					innerStyle={{
						display: "flex",
						alignItems: "unset",
						justifyContent: "center",
					}}
					innerText="No"
					icon={<CancelIcon />}
					className={classnames("ra-confirm")}
					color="primary"
					autoFocus
				/>
				<CustomButton
					onClick={() => {
						handleVariantDeletion();
						setOpen(false);
					}}
					innerStyle={{
						display: "flex",
						alignItems: "unset",
						justifyContent: "center",
					}}
					innerText="Yes"
					icon={<CheckCircleIcon />}
					className={classnames("ra-confirm")}
					color="primary"
					autoFocus
				/>
			</CustomDialog>
		</>
	);
};
