import { useMemo } from "react";
import {
	ArrayField,
	ChipField,
	Show,
	SimpleShowLayout,
	SingleFieldList,
	TextField,
	useGetOne,
	useRedirect,
	useTranslate,
} from "react-admin";
import { CustomAsideSimpleList } from "../../customComponents/CustomAsideSimpleList";
import { CustomShowActions } from "../../customComponents/CustomShowActions";
import { SVGViewer } from "../../customComponents/CustomSVGViewer";
import { requiredPermissions, requiredStatuses } from "./shared";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import Chip from "@material-ui/core/Chip";

const TagsField = ({ record }) => (
	<>
		{record?.tags?.map((item) => (
			<Chip label={item} />
		))}
	</>
);
TagsField.defaultProps = { addLabel: true };

const CustomChipFieldWithRedirect = ({
	record,
	source,
	basePath,
	emptyText,
	redirectToResource,
	...props
}) => {
	const redirect = useRedirect();
	const handleRedirectClick = () => {
		redirect("show", `/${redirectToResource}`, record.id);
	};
	return (
		<ChipField
			onClick={handleRedirectClick}
			source={source}
			record={record}
			emptyText={emptyText}
			{...props}
		/>
	);
};

export const PlanShow = (props) => {
	const translate = useTranslate();
	let { data: plan } = useGetOne("plans", props.id);
	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);

	return (
		<Show
			aside={<CustomAsideSimpleList source="auditTracker" />}
			{...props}
			actions={
				<CustomShowActions
					source="status"
					requiredStatuses={requiredStatuses}
					requiredPermissions={requiredPermissions}
				/>
			}
			title={translate("plans.show.title")}
		>
			<SimpleShowLayout>
				<TextField source={`names.${defaultLocale}`} />
				<TextField source={`descriptions.${defaultLocale}`} />
				<TextField source={`category.titles.${defaultLocale}`} />
				<TextField
					source={`backgroundPlaylist.names.${defaultLocale}`}
				/>
				<TextField source="status" />
				<ArrayField source="modules">
					<SingleFieldList linkType={false}>
						<CustomChipFieldWithRedirect
							source="name"
							emptyText={translate("empty.module")}
							redirectToResource="modules"
						/>
					</SingleFieldList>
				</ArrayField>
				<TagsField label={"Tags"} />
				{plan?.femaleSvg &&
					SVGViewer({
						svg: plan.femaleSvg,
						title: "Female SVG",
					})}
				<br />
				<br />
				{plan?.maleSvg &&
					SVGViewer({
						svg: plan.maleSvg,
						title: "Male SVG",
					})}
				<br />
				<br />
				{plan?.planPageSvg &&
					SVGViewer({
						svg: plan.planPageSvg,
						title: "Plans Page SVG",
					})}
				<br />
			</SimpleShowLayout>
		</Show>
	);
};
