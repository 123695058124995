import { useState, useEffect } from "react";
import {
	BooleanInput,
	Datagrid,
	EditButton,
	Filter,
	List,
	useTranslate,
	TextField,
	useGetList,
	SelectInput,
	ShowButton,
	TextInput,
} from "react-admin";

import { CustomDeleteButtonWithConfirmation } from "../../customComponents/CustomButtons";
import { communicationTypes } from "../../utils/constants";
import { constants } from "../../utils/constants";
import { getChoices } from "./shared";

export const CommunicationPageFilter = (props) => {
	const [languageData, setLanguageData] = useState([]);
	const translate = useTranslate();

	const { data: languageDataObj } = useGetList(
		"languages",
		{ page: 1, perPage: 10 },
		{ field: "id", order: "DESC" }
	);

	useEffect(() => {
		setLanguageData(getChoices(languageDataObj));
	}, [languageDataObj]);
	return (
		<Filter {...props}>
			<TextInput source="search" alwaysOn />
			<SelectInput
				source="languageId"
				choices={languageData}
				optionText={(record) =>
					translate(`misc.names.languages.${record.name}`)
				}
			/>
			<SelectInput
				source="type"
				choices={communicationTypes}
				optionText={({ name }) => name}
			/>
		</Filter>
	);
};

export const CommunicationPageList = (props) => {
	return (
		<List
			{...props}
			perPage={constants.perPage}
			bulkActionButtons={false}
			filters={<CommunicationPageFilter />}
		>
			<Datagrid>
				<TextField source="title" />
				<TextField source="language.name" />
				<TextField source="entityDisplay" label="Type" />
				<EditButton />
				<CustomDeleteButtonWithConfirmation />
			</Datagrid>
		</List>
	);
};
