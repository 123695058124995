import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Fragment } from "react";
import {
	Datagrid,
	SimpleForm,
	useTranslate,
	TextField,
	useGetOne,
	useGetList,
	SelectInput,
	SaveButton,
	Button,
	Toolbar,
	useNotify,
} from "react-admin";
import { envSettings } from "../../settings/env";
import keyBy from "lodash/keyBy";
import { getChoices } from "./shared";
import ActionDelete from "@material-ui/icons/Delete";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { makeStyles } from "@material-ui/core/styles";

const CustomToolbar = (props) => (
	<Toolbar {...props}>
		<SaveButton disabled={props.communicationFlowsArray.length === 0} />
	</Toolbar>
);

export const PlanCommunication = (props) => {
	const useStyles = makeStyles(
		(theme) => ({
			deleteButton: {
				color: theme.palette.error.main,
				"&:hover": {
					backgroundColor: fade(theme.palette.error.main, 0.12),
					"@media (hover: none)": {
						backgroundColor: "transparent",
					},
				},
			},
		}),
		{ name: "RaDeleteWithUndoButton" }
	);

	const location = useLocation();
	let module = [];
	const planId = location.pathname.split("/")[2];
	const moduleId = location.pathname.split("/")[3];
	const [languagesData, setLanguagesData] = useState([]);
	const translate = useTranslate();
	const notify = useNotify();
	const [communicationFlowId, setCommunicationFlowId] = useState(null);
	const [communications, setCommunications] = useState([]);
	const [type, setType] = useState("pre");
	const [lang, setLanguage] = useState(2);
	const [gender, setGender] = useState("MALE");
	const [communicationFlowsArray, setCommunicationFlowsArray] = useState([]);
	let { data: plan, loading } = useGetOne("plans", planId);

	const { data: communicationFlowsData, loadingcomm } = useGetList(
		"communication-flows"
	);

	const { data: languagesDataObj } = useGetList(
		"languages",
		{ page: 1, perPage: 10 },
		{ field: "id", order: "DESC" }
	);
	useEffect(() => {
		if (!loading) {
			module = plan.modules.find(({ id }) => id == moduleId) || {};
			if (module?.communications?.length > 0)
				setCommunications(module.communications);
		}
	}, [plan]);

	const CustomDelete = ({ record: { id } }) => {
		const classes = useStyles();
		return (
			<Button
				onClick={() => deletePlanModuleCommunication(id)}
				label="Delete"
				key="button"
				className={classes.deleteButton}
			>
				<ActionDelete />
			</Button>
		);
	};

	useEffect(() => {
		setLanguagesData(getChoices(languagesDataObj));
	}, [languagesDataObj]);

	useEffect(() => {
		if (!loadingcomm) {
			let temp = Object.values(communicationFlowsData).map(
				({ id, name, gender, languageId }) => ({
					id,
					name,
					gender,
					languageId,
				})
			);
			if (lang != null)
				temp = temp.filter(
					(communicationFlow) => communicationFlow.languageId == lang
				);
			if (gender != null)
				temp = temp.filter(
					(communicationFlow) => communicationFlow.gender == gender
				);
			setCommunicationFlowsArray(temp);
			setCommunicationFlowId(temp[0]?.id);
		}
	}, [loadingcomm, lang, gender]);

	const deletePlanModuleCommunication = async (id) => {
		const request = new Request(
			envSettings.baseUrl +
				`/plans/${planId}/modules/${moduleId}/communication/`,
			{
				method: "delete",
				body: JSON.stringify({
					id,
				}),
				headers: new Headers({
					"Content-Type": "application/json",
				}),
				credentials: "include",
			}
		);
		const response = await fetch(request);
		const { data } = await response.json();
		module = data.modules.find(({ id }) => id == moduleId) || {};
		setCommunications(module.communications);
	};

	return (
		<>
			{loading == true || loadingcomm == true ? (
				<center>
					<h1>Loading...</h1>
				</center>
			) : (
				<>
					<Fragment>
						<br />
						<SimpleForm
							save={({ languageId, type, communicationFlow, gender }) => {
								if (
									!communicationFlowsArray
										.map(({ id }) => id)
										.includes(communicationFlow)
								) {
									notify("You must select a communication flow", "warning");
									return;
								}
								(async () => {
									let ind = communications.findIndex(
										(o) =>
											o.gender == gender &&
											o.type == type &&
											o.languageId == languageId
									);
									if (ind != -1)
										await deletePlanModuleCommunication(communications[ind].id);
									const request = new Request(
										envSettings.baseUrl +
											`/plans/${planId}/modules/${moduleId}/communication`,
										{
											method: "POST",
											body: JSON.stringify({
												languageId,
												type,
												communicationFlow,
												gender,
											}),
											headers: new Headers({
												"Content-Type": "application/json",
											}),
											credentials: "include",
										}
									);
									const response = await fetch(request);
									const { data } = await response.json();
									module = data.modules.find(({ id }) => id == moduleId) || {};
									setCommunications(module.communications);
								})();
							}}
							syncWithLocation={false}
							toolbar={
								<CustomToolbar
									communicationFlowsArray={communicationFlowsArray}
								/>
							}
						>
							<SelectInput
								source="languageId"
								choices={languagesData}
								optionText={({ name }) => `misc.names.languages.${name}`}
								optionValue="id"
								defaultValue="2"
								onChange={(event) => setLanguage(event.target.value)}
							/>
							<br />
							<SelectInput
								source="type"
								choices={[
									{ id: "PRE", name: "pre" },
									{ id: "POST", name: "post" },
								]}
								defaultValue="PRE"
								optionText={({ name }) => name}
								optionValue="id"
								onChange={(event) => setType(event.target.value)}
							/>
							<SelectInput
								source="gender"
								choices={[
									{ id: "MALE", name: "Male" },
									{ id: "FEMALE", name: "Female" },
									{ id: "ANY", name: "Any" },
								]}
								defaultValue="MALE"
								optionText={({ name }) => name}
								optionValue="id"
								onChange={(event) => setGender(event.target.value)}
							/>
							<br />
							<SelectInput
								source="communicationFlow"
								label="Flow"
								choices={communicationFlowsArray}
								defaultValue={communicationFlowId}
								optionText={({ name }) => name}
								optionValue="id"
								onChange={(event) => setCommunicationFlowId(event.target.value)}
							/>
						</SimpleForm>
						<br />
						<Datagrid
							data={keyBy(communications, "id")}
							ids={communications.map(({ id }) => id)}
							currentSort={"id"}
							setSort={("id", "ASC")}
						>
							<TextField
								source="language.name"
								label={translate(`plan-communication.language.name`)}
							/>
							<TextField source="gender" />
							<TextField source="type" />
							<TextField
								source="communicationFlow.name"
								label={translate(`plan-communication.communicationFlow.name`)}
							/>
							<CustomDelete />
						</Datagrid>
					</Fragment>
				</>
			)}
		</>
	);
};
