import {
	Create,
	SimpleForm,
	TextInput,
	TranslatableInputs,
	useNotify,
	useRedirect,
	useRefresh,
} from "react-admin";

import { userPreferences } from "../../utils/fakeUserPrefrences";
import { validateSubContentTypeData } from "./subContentTypeValidate";
import { CustomToolbar } from "../../customComponents/CustomToolbar";

export const SubContentTypeCreate = (props) => {
	const redirect = useRedirect();
	const refresh = useRefresh();
	const notify = useNotify();

	const onSuccess = () => {
		notify("sub-content-types.create.success", "info");
		redirect("/sub-content-types");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	return (
		<Create {...props} onSuccess={onSuccess} onFailure={onFailure}>
			<SimpleForm
				validate={validateSubContentTypeData}
				toolbar={<CustomToolbar />}
			>
				<TranslatableInputs
					locales={userPreferences.translationLanguages}
					defaultLocale={userPreferences.defaultTranslationLanguage}
				>
					<TextInput source="title" />
				</TranslatableInputs>
			</SimpleForm>
		</Create>
	);
};

export default SubContentTypeCreate;
