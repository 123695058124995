import { isNotInRange } from "../../utils/customFunctions";
export const validateMoodTrackerData = ({
	titles,
	questions,
	fromCreate = false,
	imageMoodTracker,
	responses,
}) => {
	const errors = {};
	if (!titles) {
		errors.titles = {
			ar: {
				message: "validationErrors.required.requiredField",
				args: { field: "title" },
			},
		};
	} else {
		errors.titles = {};
		Object.keys(titles).forEach((locale) => {
			if (
				titles[locale] &&
				isNotInRange({ min: 3, max: 50, str: titles[locale] })
			) {
				errors.titles[locale] = {
					message: "validationErrors.minMax.titles",
					args: { min: 3, max: 50 },
				};
			}
		});
	}
	if (!questions) {
		errors.questions = {
			"ar(Male)": {
				message: "validationErrors.required.requiredField",
				args: { field: "question" },
			},
			"ar(Female)": {
				message: "validationErrors.required.requiredField",
				args: { field: "question" },
			},
		};
	} else {
		errors.questions = {};
		if (!("ar(Male)" in questions))
			errors.questions["ar(Male)"] = {
				message: "validationErrors.required.requiredField",
				args: { field: "Response" },
			};

		if (!("ar(Female)" in questions))
			errors.questions["ar(Female)"] = {
				message: "validationErrors.required.requiredField",
				args: { field: "Response" },
			};
		Object.keys(questions).forEach((locale) => {
			if (
				questions[locale] &&
				isNotInRange({ min: 3, max: 50, str: questions[locale] })
			) {
				errors.questions[locale] = {
					message: "validationErrors.minMax.questions",
					args: { min: 3, max: 50 },
				};
			}
		});
	}

	if (!imageMoodTracker && fromCreate) {
		errors.imageMoodTracker = {
			message: "validationErrors.required.requiredField",
			args: { field: "Mood Tracker Image" },
		};
	}

	if (!responses) {
		errors.responses = {
			message: "validationErrors.required.requiredField",
			args: { field: "Responses" },
		};
	}
	if (responses && Array.isArray(responses) && responses.length > 0) {
		errors.responses = [];
		let translatedResponses = {},
			translatedNotes = {},
			value = {},
			responseIllustration = {};
		for (let response of responses) {
			if (!response) {
				translatedResponses = {
					"ar(Male)": {
						message: "validationErrors.required.requiredField",
						args: { field: "Response" },
					},
					"ar(Female)": {
						message: "validationErrors.required.requiredField",
						args: { field: "Response" },
					},
				};
				value = {
					message: "validationErrors.required.requiredField",
					args: { field: "value" },
				};
			} else {
				if (!("responses" in response)) {
					translatedResponses = {
						"ar(Male)": {
							message: "validationErrors.required.requiredField",
							args: { field: "Response" },
						},
						"ar(Female)": {
							message: "validationErrors.required.requiredField",
							args: { field: "Response" },
						},
					};
				} else {
					if (Object.keys(response.responses).length === 0) {
						translatedResponses = {
							"ar(Male)": {
								message:
									"validationErrors.required.requiredField",
								args: { field: "Response" },
							},
							"ar(Female)": {
								message:
									"validationErrors.required.requiredField",
								args: { field: "Response" },
							},
						};
					} else {
						if (!("ar(Male)" in response.responses))
							translatedResponses["ar(Male)"] = {
								message:
									"validationErrors.required.requiredField",
								args: { field: "Response" },
							};

						if (!("ar(Female)" in response.responses))
							translatedResponses["ar(Female)"] = {
								message:
									"validationErrors.required.requiredField",
								args: { field: "Response" },
							};

						Object.keys(response.responses).forEach((locale) => {
							if (
								response.responses[locale] &&
								isNotInRange({
									min: 3,
									max: 50,
									str: response.responses[locale],
								})
							) {
								translatedResponses[locale] = {
									message:
										"validationErrors.minMax.responses",
									args: { min: 3, max: 50 },
								};
							}
						});
					}
				}

				if (!("notes" in response)) {
					translatedNotes = {
						"ar(Male)": {
							message: "validationErrors.required.requiredField",
							args: { field: "Note" },
						},
						"ar(Female)": {
							message: "validationErrors.required.requiredField",
							args: { field: "Note" },
						},
					};
				} else {
					if (Object.keys(response.notes).length === 0) {
						translatedNotes = {
							"ar(Male)": {
								message:
									"validationErrors.required.requiredField",
								args: { field: "Note" },
							},
							"ar(Female)": {
								message:
									"validationErrors.required.requiredField",
								args: { field: "Note" },
							},
						};
					} else {
						if (!("ar(Male)" in response.notes))
							translatedNotes["ar(Male)"] = {
								message:
									"validationErrors.required.requiredField",
								args: { field: "Note" },
							};

						if (!("ar(Female)" in response.notes))
							translatedNotes["ar(Female)"] = {
								message:
									"validationErrors.required.requiredField",
								args: { field: "Note" },
							};

						Object.keys(response.notes).forEach((locale) => {
							if (
								response.notes[locale] &&
								isNotInRange({
									min: 3,
									max: 250,
									str: response.notes[locale],
								})
							) {
								translatedNotes[locale] = {
									message: "validationErrors.minMax.notes",
									args: { min: 3, max: 250 },
								};
							}
						});
					}
				}
			}
			if (!response?.value)
				value = {
					message: "validationErrors.required.requiredField",
					args: { field: "Value" },
				};

			if (
				!response?.responseIllustration &&
				(fromCreate || !response?.id)
			) {
				responseIllustration = {
					message: "validationErrors.required.requiredField",
					args: { field: "Response Illustration" },
				};
			}
			errors.responses.push({
				...(Object.keys(translatedResponses).length > 0 && {
					responses: translatedResponses,
				}),
				...(Object.keys(value).length > 0 && { value }),
				...(Object.keys(responseIllustration).length > 0 && {
					responseIllustration,
				}),
			});
		}
	}

	return errors;
};
