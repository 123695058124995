import { useEffect, useState, useMemo } from "react";
import {
	Edit,
	FormTab,
	useNotify,
	TextInput,
	ArrayInput,
	useGetList,
	TabbedForm,
	useRedirect,
	useTranslate,
	TranslatableInputs,
	SimpleFormIterator,
	FileInput,
	NumberInput,
	FileField,
	useUpdate,
	useGetOne,
} from "react-admin";
import { CustomDialog } from "../../customComponents/CustomDialog";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { CustomButton } from "../../customComponents/CustomButtons";
import { FormControlLabel, FormGroup, Switch } from "@material-ui/core";
import { transformToFormDataNestedArray } from "../../utils/customFunctions";
import { validateMoodTrackerData } from "./moodTrackerValidate";

import classnames from "classnames";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

export const CustomBooleanInput = ({
	label,
	color,
	disabled,
	inputRef,
	setIsActive,
	isActive,
	name,
}) => {
	const [open, setOpen] = useState(false);

	const handleClickOpen = (event) => {
		let value = !(event.target.value === "true");
		if (value) setOpen(true);
		else setIsActive(false);
	};

	return (
		<>
			<FormGroup row>
				<FormControlLabel
					control={
						<Switch
							checked={isActive}
							onChange={handleClickOpen}
							name={name}
							color={color || "primary"}
							disabled={disabled}
							value={isActive}
							inputRef={inputRef}
						/>
					}
					label={label}
				/>
			</FormGroup>
			<CustomDialog
				open={open}
				title={"WARNING!"}
				content={
					"Are you sure you want to mark this as the active mood tracker?"
				}
			>
				<CustomButton
					onClick={() => {
						setIsActive(false);
						setOpen(false);
					}}
					innerStyle={{
						display: "flex",
						alignItems: "unset",
						justifyContent: "center",
					}}
					innerText="No"
					icon={<CancelIcon />}
					className={classnames("ra-confirm")}
					color="primary"
					autoFocus
				/>
				<CustomButton
					onClick={() => {
						setIsActive(true);
						setOpen(false);
					}}
					innerStyle={{
						display: "flex",
						alignItems: "unset",
						justifyContent: "center",
					}}
					innerText="Yes"
					icon={<CheckCircleIcon />}
					className={classnames("ra-confirm")}
					color="primary"
					autoFocus
				/>
			</CustomDialog>
		</>
	);
};

const CustomTranslatableInputs = ({ locales, source, label }) => {
	return (
		<TranslatableInputs locales={locales} defaultLocale="ar(Male)">
			<TextInput source={source} label={label} />
		</TranslatableInputs>
	);
};

export const MoodTrackerEdit = (props) => {
	const { data = {}, loading } = useGetOne("mood-tracker", props.id);
	const [languagesArray, setLanguagesArray] = useState([]);
	const [isActive, setIsActive] = useState(false);
	const [customLanguagesArray, setCustomLanguagesArray] = useState([]);
	const [update] = useUpdate("mood-tracker", props.id);

	const notify = useNotify();
	const redirect = useRedirect();
	const translate = useTranslate();

	const { data: languagesData } = useGetList("languages");

	useEffect(() => {
		setCustomLanguagesArray(() => {
			let languages = [];
			Object.values(languagesData).map((language) =>
				language.name === "AR"
					? languages.push("ar(Male)", "ar(Female)")
					: languages.push(language.name.toLowerCase())
			);
			return languages;
		});
		setLanguagesArray(
			Object.values(languagesData).map((language) =>
				language.name.slice(0, 2).toLowerCase()
			)
		);
	}, [languagesData]);

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);
	useEffect(() => {
		setIsActive(data.isActive);
	}, [loading]);

	const onSuccess = () => {
		notify(translate("mood-tracker.edit.success"), "info");
		redirect("/mood-tracker");
	};

	const onFailure = () => {
		notify(translate("mood-tracker.edit.fail"), "warning");
	};

	return (
		<>
			{loading ? (
				<center>
					<h1>Loading...</h1>
				</center>
			) : (
				<Edit {...props} title={translate("mood-tracker.edit.title")}>
					<TabbedForm
						toolbar={<CustomToolbar />}
						submitOnEnter={false}
						syncWithLocation={false}
						validate={validateMoodTrackerData}
						save={(data) => {
							data.isActive = isActive;
							const transformed =
								transformToFormDataNestedArray(data);
							update(
								{ payload: { data: transformed } },
								{
									onSuccess,
									onFailure,
								}
							);
						}}
					>
						<FormTab
							label={translate("mood-tracker.tabs.basicInfo")}
						>
							<TranslatableInputs
								locales={languagesArray}
								defaultLocale={defaultLocale}
							>
								<TextInput source="titles" />
							</TranslatableInputs>
							<CustomTranslatableInputs
								source="questions"
								locales={customLanguagesArray}
							/>
							<CustomBooleanInput
								source="isActive"
								label="Active"
								isActive={isActive}
								setIsActive={setIsActive}
							/>
							<FileInput source="imageMoodTracker" label="Image">
								<FileField source="src" title="Image" />
							</FileInput>
						</FormTab>
						<FormTab
							label={translate("mood-tracker.tabs.responses")}
						>
							<ArrayInput source="responses">
								<SimpleFormIterator>
									<NumberInput
										source="id"
										style={{ display: "none" }}
									></NumberInput>
									<CustomTranslatableInputs
										source="responses"
										locales={customLanguagesArray}
										label="Responses"
									/>
									<CustomTranslatableInputs
										source="notes"
										locales={customLanguagesArray}
										label="Notes"
									/>
									<NumberInput source="value"></NumberInput>
									<FileInput source="responseIllustration">
										<FileField source="src" title="Image" />
									</FileInput>
								</SimpleFormIterator>
							</ArrayInput>
						</FormTab>
					</TabbedForm>
				</Edit>
			)}
		</>
	);
};
