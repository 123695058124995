import { useInput, useRefresh, useTranslate } from "react-admin";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useEffect, useState } from "react";
import ClearIcon from "@material-ui/icons/Clear";
import {
	Box,
	Card,
	CardActions,
	CardContent,
	Divider,
	Fade,
	IconButton,
	InputAdornment,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";

import { CustomButton } from "./CustomButtons";
import {
	useCustomGetListWithPagination,
	useInputs,
	useLastComponentObserver,
} from "../customHooks/customHooks";

export const reorderLists = ({
	removeFrom,
	insertTo,
	removeIndex,
	insertIndex,
}) => {
	const removedFromArray = [...removeFrom];
	const insertedToArray =
		removeFrom === insertTo ? removedFromArray : [...insertTo];
	const [removed] = removedFromArray.splice(removeIndex, 1);
	insertedToArray.splice(insertIndex, 0, removed);

	return {
		removedFromResultArray: removedFromArray,
		insertedToResultArray: insertedToArray,
	};
};

const getItemStyle = (isDragging, draggableStyle) => ({
	...draggableStyle,
	...(isDragging && {
		background: "rgba(235,235,235, 0.6)",
	}),
});

const getListStyle = (isDraggingOver, length = 0, defaultLength) => {
	return {
		height:
			defaultLength > parseInt(49 * length) || !length
				? defaultLength
				: 49 * length,
		background: !isDraggingOver ? "" : "rgba(235,235,235, 0.4)",
	};
};

export const CustomTransferList = ({
	source,
	referenceResource,
	referenceField,
	isReferenceTranslatable,
	languageId,
	currValues,
	leftTitle,
	rightTitle,
	customFilter = (_) => _,
}) => {
	const [left, setLeft] = useState([]);
	const [right, setRight] = useState([]);
	const refresh = useRefresh();

	let {
		input: { value, onChange },
	} = useInput({ source });

	value = typeof value === "object" ? value : currValues;

	let {
		error: errorLeftData,
		loading,
		hasMoreData,
		fetchedData: leftData,
		getSearchResults,
		refreshList,
		getNext,
	} = useCustomGetListWithPagination({
		resource: referenceResource,
		withPagination: false,
		sort: { field: "id", order: "DESC" },
	});

	useEffect(() => {
		if (languageId)
			leftData = leftData.filter(
				({ languageId: lang }) => lang == languageId
			);
		if (leftData && value) {
			const leftDataArray = Object.values(leftData)
				.filter(eval(customFilter))
				.map(({ id, [referenceField]: referenceFieldValue }) => ({
					id,
					[referenceField]: referenceFieldValue,
				}))
				.filter(({ id }) => {
					return !value.find(({ id: sourceId }) => id === sourceId);
				});
			setLeft([...leftDataArray]);
		}

		if (leftData && !value) {
			const leftDataArray = Object.values(leftData)
				.filter(eval(customFilter))
				.map(({ id, [referenceField]: referenceFieldValue }) => ({
					id,
					[referenceField]: referenceFieldValue,
				}));
			setLeft([...leftDataArray]);
		}
	}, [leftData, languageId]);

	useEffect(() => {
		if (value) {
			setRight([...value]);
		}
	}, [value]);

	if (errorLeftData)
		return <CustomButton type="refresh" onClick={refresh()} />;

	return (
		<CustomDragDropTransferList
			loading={loading}
			hasMoreData={hasMoreData}
			getSearchResults={getSearchResults}
			refreshList={refreshList}
			getNext={getNext}
			setLeftList={setLeft}
			setRightList={setRight}
			setDataToSend={onChange}
			leftList={left}
			rightList={right}
			value={value}
			source={source}
			leftTitle={leftTitle}
			rightTitle={rightTitle}
			referenceResource={referenceResource}
			referenceField={referenceField}
			isReferenceTranslatable={isReferenceTranslatable}
		/>
	);
};

const CustomDragDropTransferList = ({
	leftList,
	rightList,
	setLeftList,
	setRightList,
	setDataToSend,
	loading,
	hasMoreData,
	getSearchResults,
	getNext,
	leftTitle,
	rightTitle,
	referenceResource,
	referenceField,
	isReferenceTranslatable,
	source,
}) => {
	const translate = useTranslate();
	const [inputs, setInputs] = useInputs({});
	const { rootRef, lastElementRef } = useLastComponentObserver({
		loading,
		hasMoreData,
		getNext,
	});

	const handleInputChange = ({ target }) => {
		const tempFilter = { [target.name]: target.value };
		setInputs({ target });
		getSearchResults({ ...inputs, ...tempFilter });
	};

	const onDragEnd = ({ destination, source }) => {
		if (
			!destination ||
			(source.droppableId === "left" &&
				destination.droppableId === "left")
		) {
			return;
		}

		if (
			source.droppableId === "right" &&
			destination.droppableId === "left"
		) {
			const {
				insertedToResultArray: resultLeft,
				removedFromResultArray: resultRight,
			} = reorderLists({
				removeFrom: rightList,
				insertTo: leftList,
				removeIndex: source.index,
				insertIndex: destination.index,
			});

			setLeftList([...resultLeft]);
			setRightList([...resultRight]);
			setDataToSend([...resultRight]);
		}

		if (
			source.droppableId === "left" &&
			destination.droppableId === "right"
		) {
			const {
				insertedToResultArray: resultRight,
				removedFromResultArray: resultLeft,
			} = reorderLists({
				removeFrom: leftList,
				insertTo: rightList,
				removeIndex: source.index,
				insertIndex: destination.index,
			});

			setLeftList([...resultLeft]);
			setRightList([...resultRight]);
			setDataToSend([...resultRight]);
		}

		if (
			source.droppableId === "right" &&
			destination.droppableId === "right"
		) {
			const { insertedToResultArray: resultRight } = reorderLists({
				removeFrom: rightList,
				insertTo: rightList,
				removeIndex: source.index,
				insertIndex: destination.index,
			});

			setRightList([...resultRight]);
			setDataToSend([...resultRight]);
		}
	};
	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<Box my="1.25rem">
				<Typography color="textSecondary">
					{translate("dnd.helperText", {
						source: referenceResource,
						referenceSource: source,
					})}
				</Typography>
			</Box>
			<Box my="3.25rem" display="flex">
				<Box mr="2rem" minWidth={300} maxWidth="auto">
					<Box mb="0.25rem">
						<Typography color="textSecondary">
							{leftTitle
								? leftTitle
								: translate("dnd.availableSource", {
										source: translate(
											`resources.${referenceResource}.${referenceField}`
										),
								  })}
						</Typography>
					</Box>
					<Card variant="outlined">
						<CardActions>
							<TextField
								fullWidth
								label={translate("labels.inputs.search")}
								name="search"
								value={inputs.search}
								onChange={handleInputChange}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												value=""
												onClick={handleInputChange}
												name="search"
												edge="end"
											>
												<Tooltip
													enterDelay={150}
													leaveDelay={125}
													title={translate(
														"tooltips.actions.clear",
														{
															name: translate(
																"labels.inputs.search"
															),
														}
													)}
													placement="top"
													arrow
													TransitionComponent={Fade}
													TransitionProps={{
														timeout: 600,
													}}
												>
													<ClearIcon
														name="clear"
														color="action"
													/>
												</Tooltip>
											</IconButton>
										</InputAdornment>
									),
								}}
							/>
						</CardActions>
						<CardContent>
							<Droppable droppableId="left">
								{(provided, snapshot) => (
									<Box
										style={{
											height: 350,
											overflow: "auto",
										}}
										ref={rootRef}
									>
										<Box
											ref={provided.innerRef}
											style={getListStyle(
												snapshot.isDraggingOver,
												leftList?.length
											)}
										>
											<List>
												{leftList.map((item, index) => (
													<Draggable
														key={item.id + ""}
														draggableId={
															item.id + ""
														}
														index={index}
													>
														{(
															provided,
															snapshot
														) => (
															<Box
																ref={
																	leftList.length ===
																	index + 1
																		? lastElementRef
																		: null
																}
															>
																<ListItem
																	button
																	ContainerComponent="li"
																	ContainerProps={{
																		ref: provided.innerRef,
																	}}
																	{...provided.draggableProps}
																	{...provided.dragHandleProps}
																	style={getItemStyle(
																		snapshot.isDragging,
																		provided
																			.draggableProps
																			.style,
																		350
																	)}
																>
																	<ListItemText
																		primary={
																			item[
																				referenceField
																			]
																				? isReferenceTranslatable ===
																				  true
																					? item[
																							referenceField
																					  ][
																							"ar"
																					  ] ??
																					  item[
																							referenceField
																					  ][
																							"en"
																					  ]
																					: item[
																							referenceField
																					  ]
																				: ""
																		}
																		secondary={
																			item.secondary
																		}
																	/>
																	<ListItemSecondaryAction />
																</ListItem>
																<Divider />
															</Box>
														)}
													</Draggable>
												))}
												{provided.placeholder}
											</List>
										</Box>
									</Box>
								)}
							</Droppable>
						</CardContent>
					</Card>
				</Box>
				<Box ml="2rem" minWidth={300} maxWidth="auto">
					<Box mb="0.25rem">
						<Typography color="textSecondary">
							{rightTitle
								? rightTitle
								: translate("dnd.selectedSource", {
										source: translate(
											`resources.${referenceResource}.${referenceField}`
										),
								  })}
						</Typography>
					</Box>
					<Card variant="outlined">
						<CardContent>
							<Droppable droppableId="right">
								{(provided, snapshot) => (
									<Box
										style={{
											height: 415,
											overflow: "auto",
										}}
										ref={rootRef}
									>
										<Box
											ref={provided.innerRef}
											style={getListStyle(
												snapshot.isDraggingOver,
												rightList?.length,
												415
											)}
										>
											<List>
												{rightList.map(
													(item, index) => (
														<Draggable
															key={item.id + ""}
															draggableId={
																item.id + ""
															}
															index={index}
														>
															{(
																provided,
																snapshot
															) => (
																<Box
																	ref={
																		rightList.length ===
																		index +
																			1
																			? lastElementRef
																			: null
																	}
																>
																	<ListItem
																		button
																		ContainerComponent="li"
																		ContainerProps={{
																			ref: provided.innerRef,
																		}}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}
																		style={getItemStyle(
																			snapshot.isDragging,
																			provided
																				.draggableProps
																				.style
																		)}
																	>
																		<ListItemText
																			primary={
																				item[
																					referenceField
																				]
																					? isReferenceTranslatable ===
																					  true
																						? item[
																								referenceField
																						  ][
																								"ar"
																						  ] ??
																						  item[
																								referenceField
																						  ][
																								"en"
																						  ]
																						: item[
																								referenceField
																						  ]
																					: ""
																			}
																			secondary={
																				item.secondary
																			}
																		/>
																		<ListItemSecondaryAction />
																	</ListItem>
																	<Divider />
																</Box>
															)}
														</Draggable>
													)
												)}
												{provided.placeholder}
											</List>
										</Box>
									</Box>
								)}
							</Droppable>
						</CardContent>
					</Card>
				</Box>
			</Box>
		</DragDropContext>
	);
};
export default CustomTransferList;
