import { useMemo } from "react";
import {
	ArrayField,
	ChipField,
	Show,
	SimpleShowLayout,
	SingleFieldList,
	TextField,
	useRedirect,
	useTranslate,
} from "react-admin";

import { useStyles } from "../../utils/styles";
import { CustomAsideSimpleList } from "../../customComponents/CustomAsideSimpleList";
import { CustomShowActions } from "../../customComponents/CustomShowActions";
import CustomImageField from "../../customComponents/CustomImageField";
import { requiredPermissions, requiredStatuses } from "./shared";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import Chip from "@material-ui/core/Chip";

const TagsField = ({ record }) => (
	<>
		{record?.tags?.map((item) => (
			<Chip label={item} />
		))}
	</>
);
TagsField.defaultProps = { addLabel: true };

const CustomChipFieldWithRedirect = ({
	record,
	source,
	basePath,
	emptyText,
	redirectToResource,
	...props
}) => {
	const redirect = useRedirect();
	const handleRedirectClick = () => {
		redirect("show", `/${redirectToResource}`, record.id);
	};
	return (
		<ChipField
			onClick={handleRedirectClick}
			source={source}
			record={record}
			emptyText={emptyText}
			{...props}
		/>
	);
};

const CustomObjectives = ({ record, translate }) => {
	const array = record.objectives;
	if (typeof array === "undefined" || array === null || array.length === 0) {
		return null;
	} else {
		return (
			<>
				{array.map((item) => (
					<CustomChipFieldWithRedirect
						source="titles['ar']"
						emptyText={translate("empty.playlist")}
						redirectToResource="objectives"
						record={item}
					/>
				))}
			</>
		);
	}
};
CustomObjectives.defaultProps = {
	addLabel: true,
};

export const PlaylistShow = (props) => {
	const translate = useTranslate();
	const classes = useStyles();

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);

	return (
		<Show
			aside={<CustomAsideSimpleList source="auditTracker" />}
			{...props}
			actions={
				<CustomShowActions
					source="status"
					requiredStatuses={requiredStatuses}
					requiredPermissions={requiredPermissions}
				/>
			}
			title={translate("playlists.show.title")}
		>
			<SimpleShowLayout>
				<div className={classes.inline}>
					<CustomImageField source="homeImageUrl" sortable={false} />
					<label>Home Image</label>
				</div>
				<CustomImageField source="playlistImageUrl" sortable={false} />
				<label>Playlist Image</label>
				<br />
				<TextField source={`names.${defaultLocale}`} />
				<TextField source={`descriptions.${defaultLocale}`} />
				<TextField source={`category.titles.${defaultLocale}`} />
				<TextField
					source={`backgroundPlaylist.names.${defaultLocale}`}
				/>
				<TextField source="status" />
				<ArrayField source="modules">
					<SingleFieldList linkType={false}>
						<CustomChipFieldWithRedirect
							source="name"
							emptyText={translate("empty.module")}
							redirectToResource="modules"
						/>
					</SingleFieldList>
				</ArrayField>
				<TagsField label={"Tags"} />
				<CustomObjectives source="objectives" translate={translate} />
			</SimpleShowLayout>
		</Show>
	);
};
