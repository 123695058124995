import { ModuleCreate } from "./moduleCreate";
import { ModuleEdit } from "./moduleEdit";
import { ModuleList } from "./moduleList";
import { ModuleShow } from "./moduleShow";

export default {
	create: ModuleCreate,
	edit: ModuleEdit,
	list: ModuleList,
	show: ModuleShow,
};
