import { useTranslate } from "react-admin";
import { useEffect, useState } from "react";
import {
	Avatar,
	Box,
	Card,
	Divider,
	Fade,
	List,
	ListItem,
	ListItemAvatar,
	ListItemText,
	Tooltip,
	Typography,
} from "@material-ui/core";

import { CustomDateWithTooltipField } from "./CustomDateWithTooltipField";
import { getDateWithFormatAndDistance } from "../utils/customFunctions";

export const CustomAsideSimpleList = ({
	source = "auditTracker",
	record,
	resource,
	label,
}) => {
	const [items, setItems] = useState([]);
	const translate = useTranslate();

	useEffect(() => {
		if (!record) return null;

		setItems(record[source]);
	}, [record]);

	return (
		<Box minWidth={400} ml="1.5rem">
			<Card elevation={2}>
				<List
					subheader={
						<Box mx="1rem" pt="1rem">
							<Typography color="textSecondary">
								{label || translate(`resources.${resource}.fields.${source}`)}
							</Typography>
							<Box mt="0.5rem">{/* <Divider /> */}</Box>
						</Box>
					}
					dense
					component="div"
					role="list"
				>
					<Box
						style={{
							overflow: "auto",
							marginTop: "1.25px",
						}}
						minHeight={300}
						maxHeight={500}
					>
						{!!items?.length ? (
							items.map(
								({ id, statusComment, statusMessage, createdOn, byUser }) => {
									const { formatedDate, formatedDistanceDate } = createdOn
										? getDateWithFormatAndDistance(createdOn)
										: { formatedDate: "", formatedDistanceDate: "" };

									return (
										<Box mx="1rem">
											<ListItem
												key={id}
												role="listitem"
												alignItems="flex-start"
											>
												<ListItemAvatar>
													<Tooltip
														title={byUser?.name}
														enterDelay={150}
														leaveDelay={125}
														enterNextDelay={25}
														TransitionComponent={Fade}
														TransitionProps={{ timeout: 600 }}
													>
														<Avatar
															alt={`${byUser?.name}`}
															src={byUser?.imageUrl || "/noPic"}
														/>
													</Tooltip>
												</ListItemAvatar>
												<ListItemText
													id={id}
													primary={
														<Typography variant="body1">
															{statusMessage}
														</Typography>
													}
													secondary={
														<Box mt="0.5rem" width="37.5rem">
															<Typography variant="body2" color="textPrimary">
																{statusComment && (
																	<Typography
																		component="span"
																		variant="body2"
																		color="primary"
																	>
																		{"comment: "}
																	</Typography>
																)}
																{statusComment}
															</Typography>
															<Box height="0.2rem" />

															<CustomDateWithTooltipField
																title={formatedDistanceDate}
																tooltipTitle={formatedDate}
															/>
														</Box>
													}
												/>
											</ListItem>
											<Divider variant="inset" component="li" />
										</Box>
									);
								}
							)
						) : (
							<ListItem>
								<Typography>{translate("empty.history")}</Typography>
							</ListItem>
						)}
						<ListItem />
					</Box>
				</List>
			</Card>
		</Box>
	);
};
