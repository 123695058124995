import React from "react";
import {
	Datagrid,
	EditButton,
	Filter,
	List,
	BooleanInput,
	ShowButton,
	DeleteButton,
	TextField,
	TextInput,
} from "react-admin";
import { useMemo } from "react";
import { constants } from "../../utils/constants";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { CustomBooleanInput } from "../../customComponents/CustomBooleanInput";
import { CustomDeleteButtonWithConfirmation } from "../../customComponents/CustomButtons";

const SubscriptionFilter = ({ ...props }) => (
	<Filter {...props}>
		<TextInput source="search" alwaysOn />
		<BooleanInput source="isActive" />
	</Filter>
);

export const ReminderList = (props) => {
	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);
	return (
		<List
			{...props}
			perPage={constants.perPage}
			bulkActionButtons={false}
			filters={<SubscriptionFilter />}
		>
			<Datagrid>
				<TextField
					source={`title.${defaultLocale}`}
					label={`Title(${defaultLocale})`}
					sortable={false}
				/>
				<CustomBooleanInput
					source="isActive"
					sortable={false}
					title={"Confirm Active Status Change"}
					content={"Please confirm active status change or click cancel"}
				/>
				<ShowButton />
				<EditButton />
				<CustomDeleteButtonWithConfirmation confirmTitle={"Delete Reminder"} />
			</Datagrid>
		</List>
	);
};
