import { useEffect, useState } from "react";
import {
	Edit,
	FileField,
	FileInput,
	Labeled,
	RadioButtonGroupInput,
	SaveButton,
	SimpleForm,
	TextInput,
	Toolbar,
	useGetList,
	useNotify,
	useRedirect,
	useRefresh,
	useTranslate,
} from "react-admin";
import { transformToFormData } from "../../utils/customFunctions";
import { validateBackgroundModuleData } from "./backgroundModuleValidate";

const getChoices = (items) => Object.values(items).map((item) => item);

const transform = ({ ...data }) => {
	let mutatedData = {};

	Object.entries(data).forEach(([key, value]) => {
		const [keyPrefix, keyLanguageId] = key.split("_");

		if (keyPrefix !== "language") mutatedData[key] = value;

		if (keyPrefix === "language" && keyLanguageId === data.languageId)
			Object.entries(value).forEach(([fileName, file]) => {
				if (file) mutatedData[fileName] = value[fileName];
			});
	});

	return transformToFormData(mutatedData);
};

const AudioPlayerWithLabel = ({ record: { audioUrl, audioDisplayName } }) => {
	const translate = useTranslate();
	return (
		<Labeled label={translate(`background-modules.edit.currentAudioFile`)}>
			<>
				<h6>{audioDisplayName}</h6>
				<audio controls src={audioUrl} />
			</>
		</Labeled>
	);
};

const BackgroundModuleEditToolbar = (props) => {
	return (
		<Toolbar {...props}>
			<SaveButton />
		</Toolbar>
	);
};

export const BackgroundModuleEdit = (props) => {
	const [languageData, setLanguageData] = useState([]);
	const translate = useTranslate();
	const redirect = useRedirect();
	const refresh = useRefresh();
	const notify = useNotify();

	const { data: languageDataObj } = useGetList(
		"languages",
		{ page: 1, perPage: 10 },
		{ field: "id", order: "DESC" }
	);

	const onSuccess = () => {
		notify(translate("background-modules.edit.success"), "info");
		redirect("/background-modules");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	useEffect(() => {
		setLanguageData(getChoices(languageDataObj));
	}, [languageDataObj]);

	return (
		<Edit
			transform={transform}
			onSuccess={onSuccess}
			onFailure={onFailure}
			{...props}
			title="background-modules.edit.title"
			mutationMode="pessimistic"
		>
			<SimpleForm
				validate={validateBackgroundModuleData}
				toolbar={<BackgroundModuleEditToolbar />}
			>
				<TextInput source="name" />
				<RadioButtonGroupInput
					source="languageId"
					choices={languageData}
					optionText={({ name }) => `misc.names.languages.${name}`}
					optionValue="id"
				/>
				<AudioPlayerWithLabel source="audioUrl" label="Audio" />

				<FileInput
					key={translate(`background-modules.edit.audioFile`)}
					{...props}
					source="audio"
					label={translate(`background-modules.edit.audioFile`)}
				>
					<FileField source="src" title="Background Module" />
				</FileInput>
			</SimpleForm>
		</Edit>
	);
};
