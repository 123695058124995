import {
	ArrayField,
	ChipField,
	Show,
	SimpleShowLayout,
	SingleFieldList,
	TextField,
	useRedirect,
	useTranslate,
} from "react-admin";
import { CustomBusinessActions } from "../../customComponents/CustomBusinessActions";

const CustomChipFieldWithRedirect = ({
	record,
	source,
	basePath,
	emptyText,
	redirectToResource,
	...props
}) => {
	const redirect = useRedirect();
	const handleRedirectClick = () => {
		redirect("show", `/${redirectToResource}`, record.id);
	};
	return (
		<ChipField
			onClick={handleRedirectClick}
			source={source}
			record={record}
			emptyText={emptyText}
			{...props}
		/>
	);
};

export const BusinessShow = (props) => {
	const translate = useTranslate();
	return (
		<Show
			{...props}
			actions={<CustomBusinessActions />}
			title={translate("businesses.show.title")}
		>
			<SimpleShowLayout>
				<TextField source="name" />
				<TextField source="accessKey" />
				<TextField source="secretKey" />
				<TextField source="validFrom" />
				<TextField source="validTo" />
				<ArrayField source="modules">
					<SingleFieldList linkType={false}>
						<CustomChipFieldWithRedirect
							source="name"
							emptyText={translate("empty.module")}
							redirectToResource="modules"
						/>
					</SingleFieldList>
				</ArrayField>
			</SimpleShowLayout>
		</Show>
	);
};
