import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import QueueMusicIcon from "@material-ui/icons/QueueMusic";
import ContentTypeIcon from "@material-ui/icons/ViewList";
import ModuleIcon from "@material-ui/icons/ViewModule";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import StyleIcon from "@material-ui/icons/Style";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import UserIcon from "@material-ui/icons/People";
import NoteIcon from "@material-ui/icons/Note";
import ListAltIcon from "@material-ui/icons/ListAlt";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import QueueIcon from "@material-ui/icons/Queue";
import { makeStyles } from "@material-ui/core/styles";
import PostAddIcon from "@material-ui/icons/PostAdd";
import SettingsIcon from "@material-ui/icons/Settings";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import ForumIcon from "@material-ui/icons/Forum";
import ListIcon from "@material-ui/icons/List";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import TodayIcon from "@material-ui/icons/Today";
import TocIcon from "@material-ui/icons/Toc";
import MusicNoteIcon from "@material-ui/icons/MusicNote";
import FeaturedPlayListIcon from "@material-ui/icons/FeaturedPlayList";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import MoodIcon from "@material-ui/icons/Mood";
import BusinessIcon from "@material-ui/icons/Business";
import TimelineIcon from "@mui/icons-material/Timeline";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import classnames from "classnames";
import {
	useTranslate,
	DashboardMenuItem,
	MenuItemLink,
	usePermissions,
} from "react-admin";
import SubMenu from "./SubMenu";
import { rolesGlobal } from "../utils/constants";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import PersonIcon from "@material-ui/icons/Person";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import AddCardIcon from "@mui/icons-material/AddCard";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import FolderIcon from "@material-ui/icons/Folder";
import CategoryIcon from "@mui/icons-material/Category";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import PollIcon from "@mui/icons-material/Poll";

const Menu = ({ dense = false }) => {
	const [state, setState] = useState({
		menuModules: true,
		menuCreate: true,
		menuDaily: true,
		menuSections: true,
		menuPlaylists: true,
		menuTimelines: true,
		menuCommunications: true,
		menuSettings: true,
		menuRecommendations: true,
		menuFeatured: true,
	});
	const { permissions, loading } = usePermissions();
	const translate = useTranslate();
	const open = useSelector((state) => state.admin.ui.sidebarOpen);
	useSelector((state) => state.theme);
	const classes = useStyles();

	const handleToggle = (menu) => {
		setState((state) => ({ ...state, [menu]: !state[menu] }));
	};
	const getResources = () => {
		const resources = [<DashboardMenuItem key="menu-22" />];
		if (
			permissions?.includes(rolesGlobal.systemAdmin) ||
			permissions?.includes(rolesGlobal.userStaffAdmin)
		) {
			resources.push(
				<>
					<MenuItemLink
						to={{
							pathname: "/users",
							state: { _scrollToTop: true },
						}}
						primaryText={translate(`resources.users.name`, {
							smart_count: 2,
						})}
						leftIcon={<UserIcon />}
						dense={dense}
						key="menu-21"
					/>
				</>
			);
		}
		if (
			permissions?.includes(rolesGlobal.systemAdmin) ||
			permissions?.includes(rolesGlobal.userStaffAdmin) ||
			permissions?.includes(rolesGlobal.commercialManager)
		) {
			resources.push(
				<>
					<MenuItemLink
						to={{
							pathname: "/members",
							state: { _scrollToTop: true },
						}}
						primaryText={translate(`resources.members.name`, {
							smart_count: 2,
						})}
						leftIcon={<PeopleOutlineIcon />}
						dense={dense}
						key="menu-20"
					/>
				</>
			);
		}
		if (
			permissions?.includes(rolesGlobal.systemAdmin) ||
			permissions?.includes(rolesGlobal.contentManager) ||
			permissions?.includes(rolesGlobal.contentOfficer)
		) {
			resources.push(
				<MenuItemLink
					to={{
						pathname: "/categories",
						state: { _scrollToTop: true },
					}}
					primaryText={translate(`resources.categories.name`, {
						smart_count: 2,
					})}
					leftIcon={<StyleIcon />}
					dense={dense}
					key="menu-2"
				/>
			);

			resources.push(
				<>
					<SubMenu
						handleToggle={() => handleToggle("menuContent")}
						isOpen={state.menuContent}
						name="Content"
						icon={<ContentTypeIcon />}
						dense={dense}
						key="menu-3"
					>
						<MenuItemLink
							key="s-menu-1"
							to={{
								pathname: "/content-types",
								state: { _scrollToTop: true },
							}}
							primaryText={translate(
								`resources.content-types.name`,
								{
									smart_count: 2,
								}
							)}
							leftIcon={<ListIcon />}
							dense={dense}
						/>
						<MenuItemLink
							to={{
								pathname: "/sub-content-types",
								state: { _scrollToTop: true },
							}}
							primaryText={translate(
								`resources.sub-content-types.name`,
								{
									smart_count: 2,
								}
							)}
							leftIcon={<ListAltIcon />}
							dense={dense}
						/>
					</SubMenu>
					<MenuItemLink
						to={{
							pathname: "/doctors",
							state: { _scrollToTop: true },
						}}
						primaryText={translate(`resources.doctors.name`, {
							smart_count: 2,
						})}
						leftIcon={<LocalHospitalIcon />}
						dense={dense}
						key="menu-23"
					/>
					<MenuItemLink
						to={{
							pathname: "/mood-tracker",
							state: { _scrollToTop: true },
						}}
						primaryText={translate(`resources.mood-tracker.name`, {
							smart_count: 2,
						})}
						leftIcon={<MoodIcon />}
						dense={dense}
						key="menu-24"
					/>
					<MenuItemLink
						to={{
							pathname: "/surveys",
							state: { _scrollToTop: true },
						}}
						primaryText={translate(`resources.surveys.name`, {
							smart_count: 2,
						})}
						leftIcon={<PollIcon />}
						dense={dense}
					/>
				</>
			);

			resources.push(
				<Fragment>
					<SubMenu
						key="menu-4"
						handleToggle={() => handleToggle("menuModules")}
						isOpen={state.menuModules}
						name="Modules"
						icon={<ModuleIcon />}
						dense={dense}
					>
						<MenuItemLink
							to={{
								pathname: "/modules",
								state: { _scrollToTop: true },
							}}
							primaryText="Foreground"
							leftIcon={<ModuleIcon />}
							dense={dense}
						/>
						<MenuItemLink
							to={{
								pathname: "/background-modules",
								state: { _scrollToTop: true },
							}}
							primaryText="Background"
							leftIcon={<ModuleIcon />}
							dense={dense}
						/>
					</SubMenu>
					{/* <MenuItemLink
						to={{
							pathname: "/sequential-modules",
							state: { _scrollToTop: true },
						}}
						primaryText="Sequential Modules"
						leftIcon={<EmojiSymbolsIcon />}
						dense={dense}
					/> */}
				</Fragment>
			);

			resources.push(
				<SubMenu
					handleToggle={() => handleToggle("menuPlaylists")}
					isOpen={state.menuPlaylists}
					name="Playlists"
					icon={<QueueMusicIcon />}
					dense={dense}
					key="menu-5"
				>
					<MenuItemLink
						to={{
							pathname: "/playlists",
							state: { _scrollToTop: true },
						}}
						primaryText="Foreground"
						leftIcon={<QueueMusicIcon />}
						dense={dense}
					/>
					<MenuItemLink
						to={{
							pathname: "/background-playlists",
							state: { _scrollToTop: true },
						}}
						primaryText="Background"
						leftIcon={<QueueMusicIcon />}
						dense={dense}
					/>
				</SubMenu>
			);

			resources.push([
				<MenuItemLink
					to={{
						pathname: "/plans",
						state: { _scrollToTop: true },
					}}
					primaryText={translate(`resources.plans.name`, {
						smart_count: 2,
					})}
					leftIcon={<QueueIcon />}
					dense={dense}
					key="menu-6"
				/>,
				<MenuItemLink
					to={{
						pathname: "/lists-singles",
						state: { _scrollToTop: true },
					}}
					primaryText={translate(`resources.lists-singles.name`, {
						smart_count: 2,
					})}
					leftIcon={<TocIcon />}
					dense={dense}
					key="menu-7"
				/>,
				<MenuItemLink
					to={{
						pathname: "/objectives",
						state: { _scrollToTop: true },
					}}
					primaryText={translate(`resources.objectives.name`, {
						smart_count: 2,
					})}
					leftIcon={<ListAltIcon />}
					dense={dense}
					key="menu-8"
				/>,

				<MenuItemLink
					to={{
						pathname: "/journals",
						state: { _scrollToTop: true },
					}}
					primaryText={translate(`resources.journals.name`, {
						smart_count: 2,
					})}
					leftIcon={<NewspaperIcon />}
					dense={dense}
					key="menu-9"
				/>,
				<MenuItemLink
					to={{
						pathname: "/subscriptions",
						state: { _scrollToTop: true },
					}}
					primaryText={translate(`resources.subscriptions.name`, {
						smart_count: 2,
					})}
					leftIcon={<AttachMoneyIcon />}
					dense={dense}
					key="menu-10"
				/>,
				<SubMenu
					handleToggle={() => handleToggle("menuTimelines")}
					isOpen={state.menuTimelines}
					name="Pathways"
					icon={<ForumIcon />}
					dense={dense}
					key="menu-11"
				>
					<MenuItemLink
						to={{
							pathname: "/timelines",
							state: { _scrollToTop: true },
						}}
						primaryText={translate(`resources.timelines.name`, {
							smart_count: 2,
						})}
						leftIcon={<TimelineIcon />}
						dense={dense}
					/>
					<MenuItemLink
						to={{
							pathname: "/badges",
							state: { _scrollToTop: true },
						}}
						primaryText={translate(`resources.badges.name`, {
							smart_count: 2,
						})}
						leftIcon={<CalendarTodayIcon />}
						dense={dense}
					/>
					<MenuItemLink
						to={{
							pathname: "/tasks",
							state: { _scrollToTop: true },
						}}
						primaryText={translate(`resources.tasks.name`, {
							smart_count: 2,
						})}
						leftIcon={<FormatListNumberedIcon />}
						dense={dense}
					/>
				</SubMenu>,
				<MenuItemLink
					to={{
						pathname: "/user-suggestions",
						state: { _scrollToTop: true },
					}}
					primaryText={translate(`resources.user-suggestions.name`, {
						smart_count: 2,
					})}
					leftIcon={<NoteIcon />}
					dense={dense}
					key="menu-12"
				/>,
				<MenuItemLink
					to={{
						pathname: "/demo-requests",
						state: { _scrollToTop: true },
					}}
					primaryText={translate(`resources.demo-requests.name`)}
					leftIcon={<AutoStoriesIcon />}
					dense={dense}
				/>,
			]);

			resources.push(
				<SubMenu
					handleToggle={() => handleToggle("menuCommunications")}
					isOpen={state.menuCommunications}
					name="Communications"
					icon={<ForumIcon />}
					dense={dense}
					key="menu-13"
				>
					<MenuItemLink
						to={{
							pathname: "/communication-pages",
							state: { _scrollToTop: true },
						}}
						primaryText="Pages"
						leftIcon={<PostAddIcon />}
						dense={dense}
					/>
					<MenuItemLink
						to={{
							pathname: "/communication-flows",
							state: { _scrollToTop: true },
						}}
						primaryText="Flows"
						leftIcon={<ForumIcon />}
						dense={dense}
					/>
				</SubMenu>
			);
			resources.push(
				<Fragment>
					<SubMenu
						key="menu-14"
						handleToggle={() => handleToggle("menuSettings")}
						isOpen={state.menuSettings}
						name="Settings"
						icon={<SettingsIcon />}
						dense={dense}
					>
						<MenuItemLink
							to={{
								pathname: "/profileSettings",
								state: { _scrollToTop: true },
							}}
							primaryText="Profile Settings"
							leftIcon={<PersonIcon />}
							dense={dense}
						/>
						<MenuItemLink
							to={{
								pathname: "/appSettings",
								state: { _scrollToTop: true },
							}}
							primaryText="App Settings"
							leftIcon={<SettingsApplicationsIcon />}
							dense={dense}
						/>
						<MenuItemLink
							to={{
								pathname: "/b2bSettings",
								state: { _scrollToTop: true },
							}}
							primaryText="B2B Settings"
							leftIcon={<BusinessIcon />}
							dense={dense}
						/>
					</SubMenu>

					<SubMenu
						handleToggle={() => handleToggle("menuSections")}
						isOpen={state.menuSections}
						name="Sections"
						icon={<PhoneIphoneIcon />}
						dense={dense}
						key="menu-15"
					>
						<MenuItemLink
							to={{
								pathname: "/sections",
								state: { _scrollToTop: true },
							}}
							primaryText={"Manage Sections"}
							leftIcon={<FeaturedPlayListIcon />}
							dense={dense}
						/>
						<MenuItemLink
							to={{
								pathname: "/promo-pathway",
								state: { _scrollToTop: true },
							}}
							primaryText={"Promo Pathway Configuration"}
							leftIcon={<FeaturedPlayListIcon />}
							dense={dense}
						/>
					</SubMenu>
				</Fragment>
			);
		}
		if (permissions?.includes(rolesGlobal.commercialManager)) {
			resources.push(
				<SubMenu
					handleToggle={() => handleToggle("hr")}
					isOpen={state.hr}
					name="HR"
					dense={dense}
					icon={<PermContactCalendarIcon />}
					isNested={true}
					key="menu-1"
				>
					<MenuItemLink
						to={{
							pathname: "/hr/settings",
							state: { _scrollToTop: true },
						}}
						primaryText="HR Settings"
						leftIcon={<SettingsIcon />}
						dense={dense}
					/>
				</SubMenu>
			);
		}
		if (
			permissions?.includes(rolesGlobal.systemAdmin) ||
			permissions?.includes(rolesGlobal.commercialManager)
		) {
			resources.push([
				<MenuItemLink
					to={{
						pathname: "/promo-codes",
						state: { _scrollToTop: true },
					}}
					primaryText={translate(`resources.promo-codes.name`)}
					leftIcon={<MoneyOffIcon />}
					dense={dense}
					key="menu-16"
				/>,
				<MenuItemLink
					to={{
						pathname: "/businesses",
						state: { _scrollToTop: true },
					}}
					primaryText={translate(`resources.businesses.name`)}
					leftIcon={<BusinessCenterIcon />}
					dense={dense}
					key="menu-17"
				/>,
				<MenuItemLink
					to={{
						pathname: "/licenses",
						state: { _scrollToTop: true },
					}}
					primaryText={translate(`resources.licenses.name`)}
					leftIcon={<AddCardIcon />}
					dense={dense}
					key="menu-18"
				/>,
				<MenuItemLink
					to={{
						pathname: "web-products",
						state: { _scrollToTop: true },
					}}
					primaryText={translate(`resources.web-products.name`)}
					leftIcon={<ProductionQuantityLimitsIcon />}
					dense={dense}
				/>,
			]);
		}
		if (
			permissions?.includes(rolesGlobal.systemAdmin) ||
			permissions?.includes(rolesGlobal.contentManager) ||
			permissions?.includes(rolesGlobal.contentOfficer)
		) {
			resources.push([
				<SubMenu
					handleToggle={() => handleToggle("hr")}
					isOpen={state.hr}
					name="HR"
					dense={dense}
					icon={<PermContactCalendarIcon />}
					isNested={true}
					key="menu-1"
				>
					<SubMenu
						handleToggle={() => handleToggle("resources")}
						isOpen={state.resources}
						name="Resources"
						icon={<FolderIcon />}
						dense={dense}
					>
						<MenuItemLink
							to={{
								pathname: "/hr/resources/categories",
								state: { _scrollToTop: true },
							}}
							primaryText="Categories"
							leftIcon={<CategoryIcon />}
							dense={dense}
						/>
						<MenuItemLink
							to={{
								pathname: "/hr/resources/content",
								state: { _scrollToTop: true },
							}}
							primaryText="Content"
							leftIcon={<FileCopyIcon />}
							dense={dense}
						/>
					</SubMenu>

					<MenuItemLink
						to={{
							pathname: "/hr/settings",
							state: { _scrollToTop: true },
						}}
						primaryText="HR Settings"
						leftIcon={<SettingsIcon />}
						dense={dense}
					/>
				</SubMenu>,
				<SubMenu
					handleToggle={() => handleToggle("menuDaily")}
					isOpen={state.menuDaily}
					name="Daily"
					icon={<TodayIcon />}
					dense={dense}
					key="menu-19"
				>
					<MenuItemLink
						to={{
							pathname: "/reminders",
							state: { _scrollToTop: true },
						}}
						primaryText={translate(`resources.reminders.name`)}
						leftIcon={<AccessAlarmIcon />}
						dense={dense}
					/>
					<MenuItemLink
						to={{
							pathname: "/day-portions",
							state: { _scrollToTop: true },
						}}
						primaryText={"Portions"}
						leftIcon={<AccessTimeIcon />}
						dense={dense}
					/>
					<SubMenu
						handleToggle={() => handleToggle("menuRecommendations")}
						isOpen={state.menuRecommendations}
						name="Recommended"
						icon={<TodayIcon />}
						dense={dense}
						key="menu-20"
					>
						<MenuItemLink
							to={{
								pathname: "/daily-recommendations-ar",
								state: { _scrollToTop: true },
							}}
							primaryText={"Arabic Recommended"}
							leftIcon={<MusicNoteIcon />}
							dense={dense}
						/>
						<MenuItemLink
							to={{
								pathname: "/daily-recommendations-en",
								state: { _scrollToTop: true },
							}}
							primaryText={"English Recommended"}
							leftIcon={<MusicNoteIcon />}
							dense={dense}
						/>
					</SubMenu>

					<SubMenu
						handleToggle={() => handleToggle("menuFeatured")}
						isOpen={state.menuFeatured}
						name="Featured"
						icon={<TodayIcon />}
						dense={dense}
						key="menu-21"
					>
						<MenuItemLink
							to={{
								pathname: "/featured-en",
								state: { _scrollToTop: true },
							}}
							primaryText={"English Featured"}
							leftIcon={<FeaturedPlayListIcon />}
							dense={dense}
						/>
						<MenuItemLink
							to={{
								pathname: "/featured-ar",
								state: { _scrollToTop: true },
							}}
							primaryText={"Arabic Featured"}
							leftIcon={<FeaturedPlayListIcon />}
							dense={dense}
						/>
					</SubMenu>
				</SubMenu>,
			]);
		}
		return resources;
	};
	return (
		<div
			className={classnames(classes.root, {
				[classes.open]: open,
				[classes.closed]: !open,
			})}
		>
			{!loading ? getResources() : <></>}
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	open: {
		width: "100%",
	},
	closed: {
		width: 55,
	},
}));

export default Menu;
