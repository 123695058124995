import { isNotInRange } from "../../utils/customFunctions";

export const validateBackgroundModuleData = ({ name, languageId, ...rest }) => {
	const errors = {};

	if (!name)
		errors.name =
			"background-modules.validationErrors.required.isRequired.name";

	if (name && isNotInRange({ min: 3, max: 50, str: name })) {
		errors.name = {
			message: "validationErrors.minMax.name",
			args: { min: 3, max: 50 },
		};
	}

	if (!languageId) return errors;

	const type = rest.audio?.rawFile?.type?.split("/")[0];
	if (type && type !== "audio")
		errors.audio = "background-modules.validationErrors.inCorrectFormat";
	return errors;
};
