import { useEffect, useState } from "react";
import {
	Edit,
	ImageField,
	ImageInput,
	useUpdate,
	TextInput,
	SimpleForm,
	useNotify,
	useRedirect,
	useRefresh,
	useTranslate,
	TranslatableInputs,
	useGetList,
} from "react-admin";

import userPreferences from "../../utils/fakeUserPrefrences";
import { transformToFormData } from "../../utils/customFunctions";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { validateCategoryData } from "./hrResourcesCtegoriesValidate";

export const HRResourcesCtegoriesEdit = (props) => {
	const [languagesArray, setLanguagesArray] = useState([]);

	const [update] = useUpdate("hr/resources/categories", props.id);
	const { data: languagesData } = useGetList("languages");
	const translate = useTranslate();
	const redirect = useRedirect();
	const refresh = useRefresh();
	const notify = useNotify();

	const onSuccess = () => {
		notify(translate("hr.resources.categories.edit.success"), "info");
		redirect("/hr/resources/categories");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	useEffect(() => {
		setLanguagesArray(
			Object.values(languagesData).map((language) =>
				language.name.slice(0, 2).toLowerCase()
			)
		);
	}, [languagesData]);

	return (
		<Edit
			{...props}
			title={translate("hr.resources.categories.edit.title")}
		>
			<SimpleForm
				save={(data) => {
					const transformed = transformToFormData(data);
					update(
						{ payload: { data: transformed.formData } },
						{
							onSuccess,
							onFailure,
						}
					);
				}}
				toolbar={<CustomToolbar />}
				validate={validateCategoryData}
				syncWithLocation={false}
			>
				<TranslatableInputs
					locales={languagesArray}
					defaultLocale={userPreferences.defaultTranslationLanguage}
				>
					<TextInput source="titles" />
				</TranslatableInputs>
				<ImageInput
					source="coverImage"
					label="Profile Picture"
					accept="image/*"
					placeholder={translate(
						"hr.resources.categories.edit.uploadImagePlaceholder"
					)}
				>
					<ImageField source="src" />
				</ImageInput>
			</SimpleForm>
		</Edit>
	);
};
