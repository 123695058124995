import { isNotInRange } from "../../utils/customFunctions";

export const validateFeaturedData = async ({ date, title }) => {
	const errors = {};
	if (!date) {
		errors.date = "featured.validationErrors.required.date";
	}

	if (!title) {
		errors.title = "featured.validationErrors.required.title";
	}

	if (title && isNotInRange({ min: 3, max: 25, str: title })) {
		errors.title = "featured.validationErrors.required.minMax";
	}

	return errors;
};
