import { useMemo } from "react";
import {
	Datagrid,
	EditButton,
	Filter,
	List,
	ShowButton,
	TextField,
	TextInput,
} from "react-admin";
import { constants } from "../../utils/constants";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { CustomDeleteButtonWithCustomConfirm } from "../../customComponents/CustomButtons";

const TaskFilter = (props) => (
	<Filter {...props}>
		<TextInput source="search" alwaysOn />
	</Filter>
);

export const TaskList = (props) => {
	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);
	return (
		<List
			{...props}
			perPage={constants.perPage}
			bulkActionButtons={false}
			filters={<TaskFilter />}
		>
			<Datagrid>
				<TextField source={`name.${defaultLocale}`} sortable={false} />
				<TextField source="taskEventName" label={"Event"} />
				<EditButton />
				<ShowButton />
				<CustomDeleteButtonWithCustomConfirm mutationMode="pessimistic" />
			</Datagrid>
		</List>
	);
};

export default TaskList;
