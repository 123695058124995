import polyglotI18nProvider from "ra-i18n-polyglot";
import Layout from "./layout/Layout";
import Login from "./layout/Login";
import { Admin, Resource } from "react-admin";
import * as React from "react";
import themeReducer from "./themeReducer";
import categories from "./resources/categories";
import backgroundModules from "./resources/backgroundModules";
import backgroundPlaylists from "./resources/backgroundPlaylists";
import communicationPages from "./resources/communicationPages";
import communicationFlows from "./resources/communicationFlows";
import contentTypes from "./resources/contentTypes";
import subContentTypes from "./resources/subContentTypes";
import modules from "./resources/modules";
import objectives from "./resources/objectives";
import plans from "./resources/plans";
import playlists from "./resources/playlists";
import listsSingles from "./resources/listsSingles";
import subscriptions from "./resources/subscriptions";
import users from "./resources/users";
import promoCodes from "./resources/promoCodes";
import userSuggestions from "./resources/userSuggestions";
import reminders from "./resources/reminders";
import members from "./resources/members";
import dayPortions from "./resources/dayPortions";
import doctors from "./resources/doctors";
import tasks from "./resources/tasks";
import moodTracker from "./resources/moodTracker";
import businesses from "./resources/businesses";
import surveys from "./resources/surveys";
import licenses from "./resources/licenses";
import badges from "./resources/badges";
import timelines from "./resources/timelines";
import journals from "./resources/journals";
import hrResourcesCategories from "./resources/hrResourcesCategories";
import hrResourcesContent from "./resources/hrResourcesContent";
import products from "./resources/products";
import demoRequests from "./resources/demoRequests";

import Dashboard from "./pages/Dashboard";
import NotFound from "./pages/NotFound";

import customRoutes from "./pages/customRoutes";
import { envSettings } from "./settings/env";

import authProvider from "./providers/authProvider";
import DataProvider from "./providers/dataProvider";
import {
	CustomEnglishMessages,
	englishMessages,
} from "./providers/translationProvider";

import { userPreferences } from "./utils/fakeUserPrefrences";
import dailyRecommendationsArabic from "./resources/dailyRecommendationsArabic";
import dailyRecommendationsEnglish from "./resources/dailyRecommendationsEnglish";
import featuredArabic from "./resources/featuredArabic";
import featuredEnglish from "./resources/featuredEnglish";

const messages = {
	en: { ...englishMessages, ...CustomEnglishMessages },
};

const i18nProvider = polyglotI18nProvider(
	() => messages[userPreferences.displayLanguage],
	"en",
	{
		allowMissing: true,
	}
);

const dataProvider = DataProvider(envSettings.baseUrl);

const App = () => (
	<Admin
		customRoutes={customRoutes}
		catchAll={NotFound}
		customReducers={{ theme: themeReducer }}
		dashboard={Dashboard}
		layout={Layout}
		authProvider={authProvider}
		dataProvider={dataProvider}
		i18nProvider={i18nProvider}
		loginPage={Login}
	>
		<Resource name="languages" />
		<Resource name="categories" {...categories} />
		<Resource name="background-modules" {...backgroundModules} />
		<Resource name="background-playlists" {...backgroundPlaylists} />
		<Resource name="users" {...users} />
		<Resource name="members" {...members} />
		<Resource name="subscriptions" {...subscriptions} />
		<Resource name="objectives" {...objectives} />
		<Resource name="plans" {...plans} />
		<Resource name="modules" {...modules} />
		<Resource name="content-types" {...contentTypes} />
		<Resource name="user-suggestions" {...userSuggestions} />
		<Resource name="playlists" {...playlists} />
		<Resource name="lists-singles" {...listsSingles} />
		<Resource name="communication-pages" {...communicationPages} />
		<Resource name="communication-flows" {...communicationFlows} />
		<Resource name="promo-codes" {...promoCodes} />
		<Resource name="sub-content-types" {...subContentTypes} />
		<Resource name="reminders" {...reminders} />
		<Resource name="day-portions" {...dayPortions} />
		<Resource
			name="daily-recommendations-ar"
			{...dailyRecommendationsArabic}
		/>
		<Resource
			name="daily-recommendations-en"
			{...dailyRecommendationsEnglish}
		/>
		<Resource name="featured-ar" {...featuredArabic} />
		<Resource name="featured-en" {...featuredEnglish} />
		<Resource name="doctors" {...doctors} />
		<Resource name="mood-tracker" {...moodTracker} />
		<Resource name="surveys" {...surveys} />
		<Resource name="businesses" {...businesses} />
		<Resource name="journals" {...journals} />
		<Resource name="tasks" {...tasks} />
		<Resource name="badges" {...badges} />
		<Resource name="timelines" {...timelines} />
		<Resource name="licenses" {...licenses} />
		<Resource name="hr/resources/categories" {...hrResourcesCategories} />
		<Resource name="hr/resources/content" {...hrResourcesContent} />
		<Resource name="web-products" {...products} />
		<Resource name="demo-requests" {...demoRequests} />
	</Admin>
);

export default App;
