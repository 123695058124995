import {
	Datagrid,
	List,
	DateField,
	TextField,
	Filter,
	TextInput,
	SelectInput,
	downloadCSV,
	BooleanField,
	BooleanInput,
} from "react-admin";
import { constants } from "../../utils/constants";
import { convertToCSV, formatDate } from "../../utils/helpers";

const purposeChoices = [
	{
		name: "Partnerships",
		id: "Partnerships",
	},
	{ name: "Employee Wellness Solutions", id: "Employee Wellness Solutions" },
	{ name: "Advertisement/Sponsorships", id: "Advertisement/Sponsorships" },
	{ name: "Customer Support", id: "Customer Support" },
	{ name: "Customer Support", id: "Customer Support" },
	{ name: "Community Engagement", id: "Community Engagement" },
	{ name: "Other", id: "Other" },
];
const DemoRequestsFilter = (props) => (
	<Filter {...props}>
		<TextInput source="search" alwaysOn />
		<SelectInput source="purposeOfDemo" choices={purposeChoices} />
		<BooleanInput source="callBooked" label="Call Booked" />
	</Filter>
);

const exporter = (data) => {
	const transformedData = data.map((record) => ({
		...record,
		createdOn: formatDate(record.createdOn),
	}));

	const csv = convertToCSV(transformedData);
	downloadCSV(csv, "demo-requests");
};

export const DemoRequestsList = (props) => {
	return (
		<List
			{...props}
			perPage={constants.perPage}
			bulkActionButtons={false}
			filters={<DemoRequestsFilter />}
			exporter={exporter}
		>
			<Datagrid>
				<TextField source="fullName" sortable={false} />
				<TextField source="email" sortable={false} />
				<TextField source="phone" sortable={false} />
				<TextField source="companyName" sortable={false} />
				<TextField source="purposeOfDemo" sortable={false} />
				<BooleanField source="callBooked" looseValue />
				<TextField
					name="Other Pupose"
					source="enquiryDetails"
					sortable={false}
				/>
				<DateField source="createdOn" showTime />
			</Datagrid>
		</List>
	);
};
