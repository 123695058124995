import { useEffect, useState, useMemo } from "react";
import {
	Create,
	TextInput,
	ArrayInput,
	SimpleFormIterator,
	TabbedForm,
	FormTab,
	TranslatableInputs,
	useGetList,
	useNotify,
	useRedirect,
	useRefresh,
	FileField,
	FileInput,
	BooleanInput,
	useTranslate,
	useCreate,
	SelectInput,
	NumberInput,
} from "react-admin";
import constants from "../../utils/constants";
import { CustomTransferList } from "../../customComponents/CustomTransferList";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { validateObjectiveData } from "./objectiveValidate";
import {
	transformToFormData,
	setIndexesForData,
} from "../../utils/customFunctions";

const CustomTranslatableInputs = ({ locales, source }) => {
	return (
		<TranslatableInputs locales={locales}>
			<TextInput source={source} />
		</TranslatableInputs>
	);
};

export const ObjectiveCreate = (props) => {
	const [languagesArray, setLanguagesArray] = useState([]);
	const [surveysArray, setSurveysArray] = useState([]);
	const [outcomesLanguagesArray, setOutcomesLanguagesArray] = useState([]);
	const [outcomesCount, setOutcomesCount] = useState(0);
	const [contentTypesArray, setContentTypesArray] = useState([]);

	const translate = useTranslate();
	const redirect = useRedirect();
	const refresh = useRefresh();
	const notify = useNotify();
	const [create] = useCreate("objectives");

	const { data: contentTypesData } = useGetList("content-types");
	const { data: surveysData } = useGetList("surveys");
	const { data: languagesData } = useGetList("languages");

	const validateObjectDataCreation = ({
		titles,
		recommendationTitles,
		descriptions,
		icon,
		contentTypeId,
	}) => {
		const errors = validateObjectiveData({
			titles,
			recommendationTitles,
			descriptions,
			icon,
			contentTypeId,
		});
		return errors;
	};

	const onSuccess = () => {
		notify(translate("objectives.create.success"), "info");
		redirect("/objectives");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);

	useEffect(() => {
		setOutcomesLanguagesArray(() => {
			let languages = [];
			Object.values(languagesData).map((language) =>
				language.name === "AR"
					? languages.push("ar(Male)", "ar(Female)")
					: languages.push(language.name.toLowerCase())
			);
			return languages;
		});
	}, [languagesData]);

	useEffect(() => {
		setLanguagesArray(
			Object.values(languagesData).map((language) =>
				language.name.slice(0, 2).toLowerCase()
			)
		);
	}, [languagesData]);

	useEffect(() => {
		setSurveysArray(
			Object.values(surveysData).map(({ id, name }) => ({
				id,
				name: name[defaultLocale],
			}))
		);
	}, [surveysData]);

	useEffect(() => {
		setContentTypesArray(
			Object.values(contentTypesData).map(({ id, titles }) => ({
				id,
				name: titles[defaultLocale],
			}))
		);
	}, [contentTypesData]);

	const handleClick = (event) => {
		if (
			event.target.localName === "button" &&
			event.target.textContent === "Add"
		) {
			setOutcomesCount(outcomesCount + 1);
		}
		if (
			event.target.localName === "button" &&
			event.target.textContent === "Remove"
		) {
			const index = event.target.outerHTML
				.split("button-remove-outcomes-")
				.pop()
				.split(" ")[0];
			setOutcomesCount(outcomesCount - 1);
		}
	};
	return (
		<Create {...props} title={translate("objectives.create.title")}>
			<TabbedForm
				toolbar={<CustomToolbar />}
				save={(data) => {
					const indexedData = setIndexesForData(data, ["timelines"]);
					indexedData.outcomes = !data?.outcomes ? [] : data.outcomes;
					const transformed = transformToFormData(indexedData);
					create(
						{
							payload: {
								data: transformed,
							},
						},
						{
							onSuccess,
							onFailure,
						}
					);
				}}
				validate={validateObjectDataCreation}
				syncWithLocation={false}
			>
				<FormTab label={translate("objectives.tabs.basicInfo.label")}>
					<TranslatableInputs
						locales={languagesArray}
						defaultLocale={
							userPreferences.defaultTranslationLanguage
						}
					>
						<TextInput source="titles" />
						<TextInput
							source="recommendationTitles"
							label="Recommendation Titles"
						/>
						<TextInput source="descriptions" />
					</TranslatableInputs>
					<BooleanInput defaultValue={false} source="comingSoon" />
					<SelectInput
						source="contentTypeId"
						choices={contentTypesArray}
						translateChoice={false}
					/>
					<FileInput {...props} source="icon" label="Icon">
						<FileField source="src" title="Icon" />
					</FileInput>
				</FormTab>
				<FormTab
					label={translate(
						"objectives.tabs.outcomesManagement.label"
					)}
				>
					<ArrayInput
						source="outcomes"
						onClick={(event) => {
							handleClick(event);
						}}
					>
						<SimpleFormIterator
							disableAdd={
								outcomesCount > constants.outcomesLimit
									? true
									: false
							}
						>
							<CustomTranslatableInputs
								locales={outcomesLanguagesArray}
							/>
						</SimpleFormIterator>
					</ArrayInput>
				</FormTab>
				<FormTab
					label={translate(
						"objectives.tabs.timelinesManagement.label"
					)}
				>
					<CustomTransferList
						source="timelines"
						referenceSource="timelines"
						referenceResource="timelines"
						referenceField="name"
						isReferenceTranslatable={true}
					/>
				</FormTab>
				<FormTab
					label={translate("objectives.tabs.surveysManagement.label")}
				>
					<SelectInput
						source="surveyId"
						choices={surveysArray}
						translateChoice={false}
					/>
					<NumberInput
						source="surveyWaitDays"
						label={"Wait Period (Days)"}
					/>
				</FormTab>
			</TabbedForm>
		</Create>
	);
};
