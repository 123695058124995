import { useEffect, useState, useMemo } from "react";
import {
	Create,
	FormTab,
	useNotify,
	TextInput,
	ArrayInput,
	useGetList,
	TabbedForm,
	useRedirect,
	useTranslate,
	TranslatableInputs,
	SimpleFormIterator,
	FileInput,
	FileField,
	minLength,
	maxLength,
	useCreate,
} from "react-admin";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { transformToFormData } from "../../utils/customFunctions";
import { validateJournalData } from "./journalValidate";

const CustomTranslatableInputs = ({ locales, source, label }) => {
	return (
		<TranslatableInputs locales={locales} defaultLocale="ar(Male)">
			<TextInput
				source={source}
				label={label}
				validate={[minLength(3), maxLength(200)]}
			/>
		</TranslatableInputs>
	);
};

export const JournalCreate = (props) => {
	const [languagesArray, setLanguagesArray] = useState([]);
	const [customLanguagesArray, setCustomLanguagesArray] = useState([]);
	const [create] = useCreate("journals");

	const notify = useNotify();
	const redirect = useRedirect();
	const translate = useTranslate();

	const { data: languagesData } = useGetList("languages");

	useEffect(() => {
		setCustomLanguagesArray(() => {
			let languages = [];
			Object.values(languagesData).map((language) =>
				language.name === "AR"
					? languages.push("ar(Male)", "ar(Female)")
					: languages.push(language.name.toLowerCase())
			);
			return languages;
		});
		setLanguagesArray(
			Object.values(languagesData).map((language) =>
				language.name.slice(0, 2).toLowerCase()
			)
		);
	}, [languagesData]);

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);

	const onSuccess = () => {
		notify(translate("journals.create.success"), "info");
		redirect("/journals");
	};

	const onFailure = () => {
		notify(translate("journals.create.fail"), "warning");
	};

	return (
		<Create {...props} title={translate("journals.create.title")}>
			<TabbedForm
				toolbar={<CustomToolbar />}
				submitOnEnter={false}
				syncWithLocation={false}
				validate={async (data) =>
					await validateJournalData({ ...data, fromCreate: true })
				}
				save={(data) => {
					const transformed = transformToFormData(data);
					create(
						{ payload: { data: transformed } },
						{
							onSuccess,
							onFailure,
						}
					);
				}}
			>
				<FormTab label={translate("journals.tabs.basicInfo.label")}>
					<TranslatableInputs
						locales={languagesArray}
						defaultLocale={defaultLocale}
					>
						<TextInput source="name" />
					</TranslatableInputs>

					<FileInput source="logo" label="Logo">
						<FileField source="src" title="Logo" />
					</FileInput>
				</FormTab>
				<FormTab label={translate("journals.tabs.questions.label")}>
					<ArrayInput source="questions">
						<SimpleFormIterator>
							<CustomTranslatableInputs
								locales={customLanguagesArray}
								label="Question"
							/>
						</SimpleFormIterator>
					</ArrayInput>
				</FormTab>
			</TabbedForm>
		</Create>
	);
};
