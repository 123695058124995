import React, { useEffect, useState, Fragment, useRef } from "react";
import {
	FileInput,
	FileField,
	SimpleForm,
	useNotify,
	useRedirect,
	Toolbar,
} from "react-admin";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { envSettings } from "../settings/env";
import axios from "axios";
import { convertFrom24To12Format } from "../utils/customFunctions";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { nightTime } from "../utils/constants";
import { buildErrorMessage } from "../utils/helpers";
import { CustomToolbar } from "../customComponents/CustomToolbar";

export const ProfileSettings = () => {
	const [loading, setLoading] = useState(true);
	const [isProfileFound, setIsProfileFound] = useState(false);
	const [currentFreemiumDays, setCurrentFreemiumDays] = useState(null);
	const [isNightTimeSet, setIsNightTimeSet] = useState(false);
	const [nightTimeStart, setNightTimeStart] = useState(nightTime.start);
	const [nightTimeEnd, setNightTimeEnd] = useState(nightTime.end);
	const nightTimeStartRef = useRef(null);
	const nightTimeEndRef = useRef(null);
	const freemiumDaysRef = useRef(null);
	const notify = useNotify();
	const redirect = useRedirect();

	const validateProfileSettings = async ({}) => {
		const errors = {};
		return errors;
	};

	const onFailure = (error) => {
		notify(buildErrorMessage(error), "warning");
	};

	useEffect(() => {
		(async () => {
			const res = await axios.get(
				envSettings.baseUrl + "/members/global/settings",
				{ withCredentials: true }
			);
			if (res?.data?.settings?.profilePictureUrl) setIsProfileFound(true);

			setCurrentFreemiumDays(res.data.settings.freemiumDays);

			if (res?.data?.settings?.nightTimeStart) {
				setNightTimeStart(res.data.settings.nightTimeStart);
				setIsNightTimeSet(true);
			}
			if (res?.data?.settings?.nightTimeEnd)
				setNightTimeEnd(res.data.settings.nightTimeEnd);

			setLoading(false);
		})();
	}, []);
	const useStyles = makeStyles((theme) => ({
		container: {
			display: "flex",
			flexWrap: "wrap",
		},
		textField: {
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
			width: 200,
		},
	}));
	const classes = useStyles();
	return (
		<Card>
			<CardContent>
				{loading ? (
					<center>
						<h1> we are loading data</h1>
					</center>
				) : (
					<Fragment>
						<SimpleForm
							toolbar={<CustomToolbar />}
							submitOnEnter={false}
							save={async (data) => {
								let formData = new FormData();
								if (data?.profileIllustration)
									formData.append(
										"profileIllustration",
										data.profileIllustration.rawFile
									);
								formData.append(
									"nightTimeStart",
									nightTimeStartRef.current.value == ""
										? nightTimeStart
										: nightTimeStartRef.current.value
								);
								formData.append(
									"nightTimeEnd",
									nightTimeEndRef.current.value == ""
										? nightTimeEnd
										: nightTimeEndRef.current.value
								);
								formData.append(
									"freemiumDays",
									freemiumDaysRef?.current?.value
										? freemiumDaysRef.current.value
										: null
								);
								try {
									const res = await axios.post(
										envSettings.baseUrl +
											"/members/global/settings",
										formData,
										{ withCredentials: true }
									);
									if (res.status === 200) {
										notify(
											"Uploaded Successfully!",
											"success"
										);
										redirect("/");
									} else {
										notify("Failed!", "error");
									}
								} catch (error) {
									onFailure(error);
								}
							}}
							validate={validateProfileSettings}
						>
							{isProfileFound && (
								<h4>
									Profile illustration is found.
									<br /> You can change it anytime!
								</h4>
							)}
							<FileInput
								source="profileIllustration"
								label="Profile Illustration"
							>
								<FileField
									source="src"
									title="Profile Illustration"
								/>
							</FileInput>
							<h4>Freemium:</h4>
							{!isNaN(currentFreemiumDays) && (
								<>
									<span>
										Current value is : {currentFreemiumDays}{" "}
										day(s)
									</span>
									<br />
									<br />
								</>
							)}
							<TextField
								source="freemiumDays"
								label="Days"
								type="number"
								InputProps={{
									inputProps: { min: 0 },
								}}
								inputRef={freemiumDaysRef}
							/>
							<br />
							<h4>Global Night Time Range:</h4>
							<>
								{isNightTimeSet && (
									<>
										<span>
											Current Range:{" "}
											{convertFrom24To12Format(
												nightTimeStart
											)}{" "}
											~
											{convertFrom24To12Format(
												nightTimeEnd
											)}
										</span>
										<br />
										<br />
									</>
								)}
							</>
							<TextField
								id="nightTimeStart"
								label="Starts at: "
								type="time"
								inputRef={nightTimeStartRef}
								className={classes.textField}
								defaultValue={
									isNightTimeSet ? null : nightTimeStart
								}
								InputLabelProps={{
									shrink: true,
								}}
								inputProps={{
									step: 300,
								}}
							/>
							<br></br>
							<TextField
								name="nightTimeEnd"
								label="Ends at: "
								type="time"
								inputRef={nightTimeEndRef}
								className={classes.textField}
								defaultValue={
									isNightTimeSet ? null : nightTimeEnd
								}
								InputLabelProps={{
									shrink: true,
								}}
								inputProps={{
									step: 300,
								}}
							/>
						</SimpleForm>
					</Fragment>
				)}
			</CardContent>
		</Card>
	);
};
