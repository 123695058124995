import { UserCreate } from "./userCreate";
import { UserEdit } from "./userEdit";
import { UserList } from "./userList";
import { UserShow } from "./userShow";

export default {
	create: UserCreate,
	edit: UserEdit,
	list: UserList,
	show: UserShow,
};
