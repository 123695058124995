import { useEffect, useMemo, useState, useRef } from "react";
import {
	TextInput,
	Edit,
	TranslatableInputs,
	useGetList,
	useNotify,
	useRedirect,
	useTranslate,
	useRefresh,
	useUpdate,
	TabbedForm,
	FormTab,
} from "react-admin";

import { CustomTransferList } from "../../customComponents/CustomTransferList";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { validateDayPortionData } from "./dayPortionValidate";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { setIndexesForData } from "../../utils/customFunctions";

const CustomTimeInputs = ({
	record: { startTime, endTime },
	startTimeRef,
	endTimeRef,
}) => {
	const useStyles = makeStyles((theme) => ({
		container: {
			display: "flex",
			flexWrap: "wrap",
		},
		textField: {
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
			width: 200,
		},
	}));
	const classes = useStyles();
	return (
		<>
			<br />
			<TextField
				name="startTime"
				label="Starts at: "
				type="time"
				inputRef={startTimeRef}
				defaultValue={startTime}
				className={classes.textField}
				InputLabelProps={{
					shrink: true,
				}}
				inputProps={{
					step: 300,
				}}
			/>
			<br />
			<br />
			<TextField
				name="endTime"
				label="Ends at: "
				type="time"
				defaultValue={endTime}
				inputRef={endTimeRef}
				className={classes.textField}
				InputLabelProps={{
					shrink: true,
				}}
				inputProps={{
					step: 300,
				}}
			/>
		</>
	);
};

export const DayPortionEdit = (props) => {
	const [languagesArray, setLanguagesArray] = useState([]);

	const translate = useTranslate();
	const redirect = useRedirect();
	const notify = useNotify();
	const refresh = useRefresh();

	const { data: languagesData, loading } = useGetList("languages");

	const onSuccess = () => {
		notify(translate("day-portions.edit.success"), "info");
		redirect("/day-portions");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);
	const [update] = useUpdate("day-portions", props.id);

	useEffect(() => {
		setLanguagesArray(
			Object.values(languagesData).map((language) =>
				language.name.slice(0, 2).toLowerCase()
			)
		);
	}, [languagesData]);
	const startTimeRef = useRef(null);
	const endTimeRef = useRef(null);

	return (
		<>
			{loading ? (
				<center>
					<h1>Loading...</h1>
				</center>
			) : (
				<Edit
					{...props}
					mutationMode="pessimistic"
					title={translate("day-portions.edit.title")}
				>
					<TabbedForm
						toolbar={<CustomToolbar />}
						submitOnEnter={false}
						validate={validateDayPortionData}
						save={(data) => {
							if (
								!!!startTimeRef.current?.value ||
								!!!endTimeRef.current?.value ||
								startTimeRef.current?.value?.slice(0, 5) ===
									endTimeRef.current?.value?.slice(0, 5)
							) {
								notify("Invalid Time Range", "warning");
								return;
							}
							const indexedDataToSend = setIndexesForData(data, [
								"modules",
								"playlists",
							]);
							update(
								{
									payload: {
										data: {
											...indexedDataToSend,
											startTime:
												startTimeRef.current.value,
											endTime: endTimeRef.current.value,
										},
									},
								},
								{
									onSuccess,
									onFailure,
								}
							);
						}}
						syncWithLocation={false}
					>
						<FormTab
							label={translate("modules.tabs.basicInfo.label")}
						>
							<TranslatableInputs
								locales={languagesArray}
								defaultLocale={defaultLocale}
							>
								<TextInput source="title" />
								<TextInput source="greeting" />
							</TranslatableInputs>
							<CustomTimeInputs
								startTimeRef={startTimeRef}
								endTimeRef={endTimeRef}
							/>
						</FormTab>
						<FormTab
							label={translate(
								"day-portions.tabs.singlesManagement.label"
							)}
						>
							<CustomTransferList
								source="modules"
								referenceSource="modules"
								referenceResource="modules"
								referenceField="name"
								isReferenceTranslatable={false}
								customFilter="(({isSingle})=>isSingle)"
							/>
						</FormTab>
						<FormTab
							label={translate(
								"day-portions.tabs.playlistsManagement.label"
							)}
						>
							<CustomTransferList
								source="playlists"
								referenceSource="playlists"
								referenceResource="playlists"
								referenceField="names"
								isReferenceTranslatable={true}
							/>
						</FormTab>
					</TabbedForm>
				</Edit>
			)}
		</>
	);
};
