import {
	Datagrid,
	EditButton,
	Filter,
	List,
	SearchInput,
	SelectInput,
	ShowButton,
	TextField,
	useGetList,
	usePermissions,
	useTranslate,
} from "react-admin";

import { useEffect, useState } from "react";
import { checkPermission } from "../../utils/customFunctions";
import { getChoices, requiredPermissions, statusEnumsObject } from "./shared";
import { CustomDeleteButtonWithConfirmation } from "../../customComponents/CustomButtons";
import { requiredStatuses } from "../backgroundModules/shared";

const BackgroundModuleFilter = ({ ...props }) => {
	const [languageData, setLanguageData] = useState([]);
	const translate = useTranslate();

	const { data: languageDataObj } = useGetList(
		"languages",
		{ page: 1, perPage: 10 },
		{ field: "id", order: "DESC" }
	);

	useEffect(() => {
		setLanguageData(getChoices(languageDataObj));
	}, [languageDataObj]);
	return (
		<Filter {...props}>
			<SearchInput source="search" alwaysOn />
			<SelectInput source="status" choices={statusEnumsObject} />
			<SelectInput
				source="languageId"
				choices={languageData}
				optionText={(record) =>
					translate(`misc.names.languages.${record.name}`)
				}
			/>
		</Filter>
	);
};

const EditLimitedBackgroundModule = ({ record }) => {
	if (requiredStatuses.includes(record.status)) {
		return <EditButton record={record} />;
	} else return <EditButton disabled={true} />;
};

export const BackgroundModuleList = (props) => {
	const { loadingPermissions, permissions } = usePermissions();
	const translate = useTranslate();
	let deleteTag = null,
		editTag = null;

	const [hasRequiredStatus, setHasRequiredStatus] = useState(false);
	const [hasPermission, setHasPermission] = useState(false);
	useEffect(() => {
		setHasPermission(checkPermission(props.permissions, requiredPermissions));
	}, [props.permissions]);

	useEffect(() => {
		setHasRequiredStatus(
			checkPermission(props.status, ["PENDING", "REJECTED"])
		);
	}, [props.status]);
	if (
		!loadingPermissions &&
		(!permissions || checkPermission(permissions, requiredPermissions))
	) {
		deleteTag = (
			<CustomDeleteButtonWithConfirmation
				confirmTitle={translate(`background-modules.delete.confirmTitle`)}
			/>
		);
		editTag = <EditButton />;
	} else editTag = <EditLimitedBackgroundModule />;

	return (
		<List
			bulkActionButtons={false}
			{...props}
			filters={<BackgroundModuleFilter />}
		>
			<Datagrid>
				<TextField source="name" />
				<TextField source="language.name" sortable={false} />
				<TextField source="status" sortable={false} />
				<ShowButton />
				{editTag}
				{deleteTag}
			</Datagrid>
		</List>
	);
};
