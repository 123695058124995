import { useEffect, useMemo, useState } from "react";
import {
    Edit,
    FormTab,
    TabbedForm,
    TextInput,
    TranslatableInputs,
    useGetList,
    useNotify,
    useRedirect,
    useTranslate,
    useUpdate,
    NumberField,
    NumberInput
} from "react-admin";

import { CustomTransferList } from "../../customComponents/CustomTransferList";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { validateListSinglesData } from "./listSinglesValidate";
import {
    setIndexesForData,
    transformToFormData,
} from "../../utils/customFunctions";

export const ListSinglesEdit = (props) => {
    const [languagesArray, setLanguagesArray] = useState([]);

    const [update] = useUpdate("lists-singles", props.id);
    const translate = useTranslate();
    const redirect = useRedirect();
    const notify = useNotify();

    const { data: languagesData } = useGetList("languages");

    const onSuccess = () => {
        notify(translate("lists-singles.edit.success"), "info");
        redirect("/lists-singles");
    };

    const onFailure = (error) => {
        notify(error.message, "warning");
    };

    const defaultLocale = useMemo(
        () => userPreferences.defaultTranslationLanguage,
        []
    );

    useEffect(() => {
        setLanguagesArray(
            Object.values(languagesData).map((language) =>
                language.name.slice(0, 2).toLowerCase()
            )
        );
    }, [languagesData]);
    return (
        <Edit
            {...props}
            title={translate("lists-singles.edit.title")}
        >
            <TabbedForm
                toolbar={<CustomToolbar />}
                save={(data) => {
                    const indexedDataToSend = setIndexesForData(data, [
                        "modules",
                        "objectives",
                    ]);
                    const transformed = transformToFormData(indexedDataToSend);
                    update(
                        { payload: { data: transformed } },
                        {
                            onSuccess,
                            onFailure,
                        }
                    );
                }}
                validate={validateListSinglesData}
                syncWithLocation={false}
            >
                <FormTab label={translate("lists-singles.tabs.basicInfo.label")}>
                    <TranslatableInputs
                        locales={languagesArray}
                        defaultLocale={defaultLocale}
                    >
                        <TextInput source="names" />
                        <TextInput multiline source="descriptions" />
                    </TranslatableInputs>
                    <NumberField source="index" label="categories.edit.currentIndex" />
                    <NumberInput source="newIndex" />
                </FormTab>
                <FormTab label={translate("lists-singles.tabs.singlesManagement.label")}>
                    <CustomTransferList
                        source="modules"
                        referenceSource="modules"
                        referenceResource="modules"
                        referenceField="name"
                        isReferenceTranslatable={false}
                        customFilter="(({isSingle, comingSoon})=>isSingle && !comingSoon)"
                    />
                </FormTab>
            </TabbedForm>
        </Edit>
    );
};