import { ListSinglesCreate } from "./listSinglesCreate";
import { ListSinglesEdit } from "./listSinglesEdit";
import { ListSinglesList } from "./listSinglesList";
import { ListSinglesShow } from "./listSinglesShow";

export default {
	create: ListSinglesCreate,
	edit: ListSinglesEdit,
	list: ListSinglesList,
	show: ListSinglesShow,
};
