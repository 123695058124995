import { useState } from "react";
import { Button, EditButton, TopToolbar, useTranslate } from "react-admin";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import { useCustomUpdate } from "../customHooks/customHooks";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { Box, TextField } from "@material-ui/core";
import { CustomButton } from "./CustomButtons";
import { CustomDialog } from "./CustomDialog";
import classnames from "classnames";

export const CustomBusinessActions = ({ basePath, data, resource }) => {
	const [open, setOpen] = useState(false);
	const [error, setError] = useState(false);
	const [clickedButton, setClickedButton] = useState("");

	const translate = useTranslate();
	const { loading, updateInPlace } = useCustomUpdate({
		record: data,
		source: "data",
		resource,
		endPoint: "changeKeys",
	});

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleConfirm = () => {
		updateInPlace();
		setOpen(false);
	};

	const handleCancel = () => {
		setOpen(false);
	};

	return (
		<>
			<TopToolbar>
				<Button
					value="ChangeKeyPairs"
					label="Change Keys"
					color="primary"
					disabled={loading}
					onClick={handleClickOpen}
				>
					<VpnKeyIcon />
				</Button>
				<EditButton basePath={basePath} record={data} />
			</TopToolbar>
			<CustomDialog
				open={open}
				onClose={handleCancel}
				title="Changing Key Pairs"
				content={{
					message:
						"Are you sure you want to change the key pairs? Keep in mind that the old keys won't work anymore!",
				}}
			>
				<Box width="100%">
					<Box
						display="flex"
						justifyContent="flex-end"
						component="div"
						mt="0.5rem"
					>
						<CustomButton
							onClick={handleCancel}
							innerStyle={{
								display: "flex",
								alignItems: "unset",
								justifyContent: "center",
							}}
							innerText="buttons.cancel"
							icon={<CancelIcon />}
							className={classnames("ra-confirm")}
							autoFocus
						/>
						<CustomButton
							disabled={error}
							onClick={handleConfirm}
							innerStyle={{
								display: "flex",
								alignItems: "unset",
								justifyContent: "center",
							}}
							innerText="buttons.confirm"
							icon={<CheckCircleIcon />}
							className={classnames("ra-confirm")}
							color="primary"
						/>
					</Box>
				</Box>
			</CustomDialog>
		</>
	);
};
