import { PlaylistCreate } from "./playlistCreate";
import { PlaylistEdit } from "./playlistEdit";
import { PlaylistList } from "./playlistList";
import { PlaylistShow } from "./playlistShow";

export default {
	create: PlaylistCreate,
	edit: PlaylistEdit,
	list: PlaylistList,
	show: PlaylistShow,
};
