import {
	Edit,
	useUpdate,
	TextInput,
	SimpleForm,
	useNotify,
	useRedirect,
	useRefresh,
	useTranslate,
	useGetList,
	TranslatableInputs,
} from "react-admin";
import { validateBadgeData } from "./badgeValidate";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { useEffect, useState, useMemo } from "react";
import { CustomTransferList } from "../../customComponents/CustomTransferList";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { setIndexesForData } from "../../utils/customFunctions";

export const BadgeEdit = (props) => {
	const [languagesArray, setLanguagesArray] = useState([]);
	const translate = useTranslate();
	const redirect = useRedirect();
	const refresh = useRefresh();
	const notify = useNotify();
	const [update] = useUpdate("badges", props.id);

	const onSuccess = () => {
		notify(translate("badges.edit.success"), "info");
		redirect("/badges");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};
	const { data: languagesData, loading } = useGetList("languages");

	useEffect(() => {
		setLanguagesArray(
			Object.values(languagesData).map((language) =>
				language.name.slice(0, 2).toLowerCase()
			)
		);
	}, [languagesData]);

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);
	return (
		<>
			{loading ? (
				<center>
					<h1>Loading...</h1>
				</center>
			) : (
				<Edit {...props} title={translate("badges.edit.title")}>
					<SimpleForm
						save={(data) => {
							const indexedDataToSend = setIndexesForData(data, [
								"tasks",
							]);
							update(
								{
									payload: {
										data: indexedDataToSend,
									},
								},
								{
									onSuccess,
									onFailure,
								}
							);
						}}
						toolbar={<CustomToolbar />}
						validate={validateBadgeData}
						syncWithLocation={false}
					>
						<TranslatableInputs
							locales={languagesArray}
							defaultLocale={defaultLocale}
						>
							<TextInput source="name" />
						</TranslatableInputs>
						<CustomTransferList
							source="tasks"
							referenceSource="tasks"
							referenceResource="tasks"
							referenceField="name"
							isReferenceTranslatable={true}
						/>
					</SimpleForm>
				</Edit>
			)}
		</>
	);
};
