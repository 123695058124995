import { Show, SimpleShowLayout, TextField, useTranslate } from "react-admin";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { useMemo } from "react";

export const SubscriptionShow = (props) => {
	const translate = useTranslate();
	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);
	return (
		<Show {...props} title={translate("subscriptions.show.title")}>
			<SimpleShowLayout>
				<TextField
					source={`outcomes[0].${defaultLocale}`}
					label={"Outcome 1"}
				/>
				<TextField
					source={`outcomes[1].${defaultLocale}`}
					label={"Outcome 2"}
				/>
			</SimpleShowLayout>
		</Show>
	);
};
