import { BadgeCreate } from "./badgeCreate";
import { BadgeEdit } from "./badgeEdit";
import { BadgeList } from "./badgeList";
import { BadgeShow } from "./badgeShow";

export default {
	create: BadgeCreate,
	edit: BadgeEdit,
	list: BadgeList,
	show: BadgeShow,
};
