import { useEffect, useState } from "react";
import {
	Edit,
	TextInput,
	DateInput,
	useNotify,
	useRedirect,
	useTranslate,
	useRefresh,
	useUpdate,
	TabbedForm,
	FormTab,
	BooleanInput,
	useGetOne,
} from "react-admin";
import { CustomTransferList } from "../../customComponents/CustomTransferList";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { validateFeaturedData } from "./featuredValidate";
import { setIndexesForData } from "../../utils/customFunctions";
import axios from "axios";
import { envSettings } from "../../settings/env";
import { englishLanguageId } from "../../utils/constants";

export const FeaturedEdit = (props) => {
	const translate = useTranslate();
	const redirect = useRedirect();
	const notify = useNotify();
	const refresh = useRefresh();
	const [isSingleDay, setIsSingleDay] = useState(true);
	const [nearestDate, setNearestDate] = useState(null);

	useEffect(() => {
		(async () => {
			const res = await axios.get(
				envSettings.baseUrl + "/featured-en/date/nearest",
				{
					withCredentials: true,
				}
			);
			setNearestDate(res?.data?.nearestDate);
		})();
	}, []);

	const { data = {}, loading: dailyLoaded } = useGetOne(
		"featured-en",
		props.id
	);

	useEffect(() => {
		if (!dailyLoaded) setIsSingleDay(data?.isSingleDay);
	}, [data, dailyLoaded]);

	const onSingleDateChange = (val) => setIsSingleDay(val);

	const onSuccess = () => {
		notify(translate("featured.edit.success"), "info");
		redirect("/featured-en");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	const [update] = useUpdate("featured-en", props.id);

	return (
		<Edit
			{...props}
			mutationMode="pessimistic"
			title={translate("featured.edit.title")}
		>
			<TabbedForm
				toolbar={<CustomToolbar />}
				submitOnEnter={false}
				validate={validateFeaturedData}
				save={(data) => {
					const indexedDataToSend = setIndexesForData(data, [
						"modules",
						"playlists",
						"plans",
						"singlesLists",
					]);
					update(
						{
							payload: {
								data: indexedDataToSend,
							},
						},
						{
							onSuccess,
							onFailure,
						}
					);
				}}
				syncWithLocation={false}
			>
				<FormTab label={translate("featured.tabs.basicInfo.label")}>
					<TextInput source="title" label={"featured.title"} />
					<BooleanInput
						label="Is Single Day"
						source="isSingleDay"
						onChange={onSingleDateChange}
					/>
					<DateInput
						source="date"
						label={isSingleDay ? "Date" : "Starting Date"}
					/>
					{!isSingleDay && (
						<DateInput
							source="endDate"
							label={"End Date"}
							defaultValue={
								new Date(
									new Date().setDate(
										new Date(nearestDate).getDate() + 1
									)
								)
							}
						/>
					)}
				</FormTab>
				<FormTab
					label={translate("featured.tabs.singlesManagement.label")}
				>
					<CustomTransferList
						source="modules"
						referenceSource="modules"
						referenceResource="modules"
						referenceField="name"
						languageId={englishLanguageId}
						isReferenceTranslatable={false}
						customFilter="(({isSingle})=>isSingle)"
					/>
				</FormTab>
				<FormTab
					label={translate("featured.tabs.playlistsManagement.label")}
				>
					<CustomTransferList
						source="playlists"
						referenceSource="playlists"
						referenceResource="playlists"
						referenceField="names"
						languageId={englishLanguageId}
						isReferenceTranslatable={true}
					/>
				</FormTab>
				<FormTab
					label={translate("featured.tabs.plansManagement.label")}
				>
					<CustomTransferList
						source="plans"
						referenceSource="plans"
						referenceResource="plans"
						referenceField="names"
						languageId={englishLanguageId}
						isReferenceTranslatable={true}
					/>
				</FormTab>
			</TabbedForm>
		</Edit>
	);
};
