import { useEffect, useMemo, useRef } from "react";
import {
	BooleanField,
	Datagrid,
	BooleanInput,
	EditButton,
	Filter,
	List,
	SelectInput,
	ShowButton,
	TextField,
	TextInput,
	usePermissions,
} from "react-admin";

import { CustomDeleteButtonWithConfirmation } from "../../customComponents/CustomButtons";
import { checkIfExists, checkPermission } from "../../utils/customFunctions";
import CustomImageField from "../../customComponents/CustomImageField";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import {
	requiredPermissions,
	requiredStatuses,
	statusEnumsObject,
} from "./shared";

const CustomBooleanField = ({ record }) => {
	return record.comingSoon == true ? (
		<BooleanField source="comingSoon" label="Coming Soon" />
	) : (
		<></>
	);
};

const PlaylistFilter = ({ ...props }) => {
	return (
		<Filter {...props}>
			<TextInput source="search" alwaysOn />
			<SelectInput source="status" choices={statusEnumsObject} />
			<BooleanInput source="comingSoon" label="Coming Soon" />
			<TextInput source="category" />
		</Filter>
	);
};

export const PlaylistList = (props) => {
	const { loadingPermissions, permissions } = usePermissions();

	const hasRequiredStatus = useRef(false);
	const hasPermission = useRef(false);

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);

	useEffect(() => {
		hasPermission.current = checkIfExists(
			props.permissions,
			requiredPermissions
		);
	}, [props.permissions]);

	useEffect(() => {
		hasRequiredStatus.current = checkIfExists(
			props.status,
			requiredStatuses
		);
	}, [props.status]);

	const CustomDeleteButton = (props) => {
		if (
			!loadingPermissions &&
			(!permissions ||
				checkPermission(permissions, requiredPermissions)) &&
			props?.record?.isDeletable
		)
			return <CustomDeleteButtonWithConfirmation {...props} />;
		return <></>;
	};
	return (
		<List bulkActionButtons={false} {...props} filters={<PlaylistFilter />}>
			<Datagrid>
				<TextField source={`names.${defaultLocale}`} sortable={false} />
				<TextField
					source={`category.titles.${defaultLocale}`}
					sortable={false}
				/>
				<TextField
					source={`backgroundPlaylist.names.${defaultLocale}`}
					sortable={false}
				/>
				<TextField source="status" sortable={false} />
				<CustomBooleanField label="Coming Soon" />
				<ShowButton />
				<EditButton />
				<CustomDeleteButton />
			</Datagrid>
		</List>
	);
};
