import { useEffect, useMemo, useState } from "react";
import {
	TextInput,
	Edit,
	FileField,
	FileInput,
	TranslatableInputs,
	useGetList,
	useNotify,
	useRedirect,
	useTranslate,
	useRefresh,
	useUpdate,
	SimpleForm,
} from "react-admin";

import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { validateSubscriptionData } from "./subscriptionValidate";
import { transformToFormData } from "../../utils/customFunctions";

export const SubscriptionEdit = (props) => {
	const [languagesArray, setLanguagesArray] = useState([]);

	const [update] = useUpdate("subscriptions", props.id);
	const translate = useTranslate();
	const redirect = useRedirect();
	const notify = useNotify();
	const refresh = useRefresh();

	const { data: languagesData } = useGetList("languages");

	const onSuccess = () => {
		notify(translate("subscriptions.edit.success"), "info");
		redirect("/subscriptions");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);

	useEffect(() => {
		setLanguagesArray(
			Object.values(languagesData).map((language) =>
				language.name.slice(0, 2).toLowerCase()
			)
		);
	}, [languagesData]);

	return (
		<Edit
			{...props}
			title={translate("subscriptions.edit.title")}
		>
			<SimpleForm
				toolbar={<CustomToolbar />}
				save={(data) => {
					data.outcomes = [];
					data.outcomes.push(data.outcome_1, data.outcome_2);
					const transformed = transformToFormData(data);
					update(
						{ payload: { data: transformed } },
						{
							onSuccess,
							onFailure,
						}
					);
				}}
				validate={validateSubscriptionData}
				syncWithLocation={false}
			>
				<TranslatableInputs
					locales={languagesArray}
					defaultLocale={defaultLocale}
				>
					<TextInput source={"outcome_1"} />
					<TextInput source={"outcome_2"} />
				</TranslatableInputs>
				<FileInput {...props} source="maleImage" label="Male Image">
					<FileField source="src" title="Icon" />
				</FileInput>
				<FileInput {...props} source="femaleImage" label="Female Image">
					<FileField source="src" title="Icon" />
				</FileInput>
			</SimpleForm>
		</Edit>
	);
};
