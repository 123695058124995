import { isNotInRange } from "../../utils/customFunctions";

export const validateDayPortionData = async ({ title, greeting }) => {
	const errors = {};
	if (!title?.en && !title?.ar) {
		errors.title = {
			en: {
				message: "validationErrors.required.requiredField",
				args: { field: "Title" },
			},
			ar: {
				message: "validationErrors.required.requiredField",
				args: { field: "Title" },
			},
		};
	}
	if (!greeting?.en && !greeting?.ar) {
		errors.greeting = {
			en: {
				message: "validationErrors.required.requiredField",
				args: { field: "Greeting" },
			},
			ar: {
				message: "validationErrors.required.requiredField",
				args: { field: "Greeting" },
			},
		};
	}

	if (title?.ar && isNotInRange({ min: 3, max: 25, str: title.ar })) {
		errors.title = {
			ar: {
				message: "validationErrors.minMax.title",
				args: { min: 3, max: 25 },
			},
		};
	}

	if (title?.en && isNotInRange({ min: 3, max: 25, str: title.en })) {
		errors.title = {
			en: {
				message: "validationErrors.minMax.title",
				args: { min: 3, max: 25 },
			},
		};
	}

	if (greeting?.ar && isNotInRange({ min: 3, max: 25, str: greeting.ar })) {
		errors.greeting = {
			ar: {
				message: "validationErrors.minMax.greeting",
				args: { min: 3, max: 25 },
			},
		};
	}

	if (greeting?.en && isNotInRange({ min: 3, max: 25, str: greeting.en })) {
		errors.greeting = {
			en: {
				message: "validationErrors.minMax.greeting",
				args: { min: 3, max: 25 },
			},
		};
	}

	return errors;
};
