import {
	Create,
	useCreate,
	TextInput,
	SimpleForm,
	useNotify,
	useRedirect,
	useRefresh,
	TranslatableInputs,
	useGetList,
	useTranslate,
} from "react-admin";
import { validateBadgeData } from "./badgeValidate";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { useEffect, useState, useMemo } from "react";
import { CustomTransferList } from "../../customComponents/CustomTransferList";
import { setIndexesForData } from "../../utils/customFunctions";

export const BadgeCreate = (props) => {
	const [languagesArray, setLanguagesArray] = useState([]);
	const translate = useTranslate();
	const redirect = useRedirect();
	const refresh = useRefresh();
	const notify = useNotify();
	const [create] = useCreate("badges");
	const onSuccess = () => {
		notify(translate("badges.create.success"), "info");
		redirect("/badges");
		refresh();
	};
	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	const { data: languagesData, loading } = useGetList("languages");

	useEffect(() => {
		setLanguagesArray(
			Object.values(languagesData).map((language) =>
				language.name.slice(0, 2).toLowerCase()
			)
		);
	}, [languagesData]);

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);

	return (
		<>
			{loading ? (
				<center>
					<h1>Loading...</h1>
				</center>
			) : (
				<Create {...props} title={translate("badges.create.title")}>
					<SimpleForm
						save={(data) => {
							const indexedDataToSend = setIndexesForData(data, [
								"tasks",
							]);
							create(
								{
									payload: {
										data: indexedDataToSend,
									},
								},
								{
									onSuccess,
									onFailure,
								}
							);
						}}
						toolbar={<CustomToolbar />}
						validate={validateBadgeData}
						syncWithLocation={false}
					>
						<TranslatableInputs
							locales={languagesArray}
							defaultLocale={defaultLocale}
						>
							<TextInput source="name" />
						</TranslatableInputs>
						<CustomTransferList
							source="tasks"
							referenceSource="tasks"
							referenceResource="tasks"
							referenceField="name"
							isReferenceTranslatable={true}
						/>
					</SimpleForm>
				</Create>
			)}
		</>
	);
};
