import { isNotInRange } from "../../utils/customFunctions";

export const validateUserData = ({ firstName, lastName, email, roles }) => {
	const errors = {};

	if (!firstName) {
		errors.firstName = "users.validationErrors.required.firstName";
	} else if (isNotInRange({ min: 3, max: 40, str: firstName, s: true })) {
		errors.firstName = {
			message: "validationErrors.minMax.firstName",
			args: { min: 3, max: 40 },
		};
	}

	if (!lastName) {
		errors.lastName = "users.validationErrors.required.lastName";
	} else if (isNotInRange({ min: 3, max: 40, str: lastName })) {
		errors.lastName = {
			message: "validationErrors.minMax.lastName",
			args: { min: 3, max: 40 },
		};
	}

	if (!email) {
		errors.email = "users.validationErrors.required.email";
	} else if (isNotInRange({ min: 5, max: 30, str: email })) {
		errors.email = {
			message: "validationErrors.minMax.email",
			args: { min: 10, max: 100 },
		};
	}

	if (!roles) {
		errors.roles = "users.validationErrors.required.roles";
	}

	return errors;
};
