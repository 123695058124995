import {
	Button,
	Card,
	CardContent,
	Grid,
	InputLabel,
	MenuItem,
	Select,
} from "@material-ui/core";
import axios from "axios";
import React, { Fragment, useEffect, useState } from "react";
import { ImageField, ImageInput, SimpleForm, Toolbar } from "react-admin";
import { envSettings } from "../settings/env";
import { arabicLanguageId, englishLanguageId } from "../utils/constants";
import CancelIcon from "@mui/icons-material/Cancel";

export const PromoPathway = () => {
	const [loading, setLoading] = useState(false);
	const [arabicPathways, setArabicPathways] = useState([]);
	const [arabicImageUrl, setArabicImageUrl] = useState(null);
	const [englishPathways, setEnglishPathways] = useState([]);
	const [englishImageUrl, setEnglishImageUrl] = useState(null);
	const [selectedArabicPathway, setSelectedArabicPathway] = useState(null);
	const [selectedEnglishPathway, setSelectedEnglishPathway] = useState(null);
	const [arabicValidationError, setArabicValidationError] = useState("");
	const [englishValidationError, setEnglishValidationError] = useState("");

	const handleSave = async (data) => {
		try {
			setArabicValidationError("");
			setEnglishValidationError("");
			let formData = new FormData();
			if (
				(selectedArabicPathway &&
					!data.arabicImage &&
					!arabicImageUrl) ||
				(!selectedArabicPathway && data.arabicImage)
			) {
				setArabicValidationError(
					"Please select an Arabic pathway and an image or leave both unselected."
				);
				return;
			}
			if (
				(selectedEnglishPathway &&
					!data.englishImage &&
					!englishImageUrl) ||
				(!selectedEnglishPathway && data.englishImage)
			) {
				setEnglishValidationError(
					"Please select an English pathway and an image or leave both unselected."
				);
				return;
			}
			formData.append("selectedArabicPathway", selectedArabicPathway);
			formData.append("selectedEnglishPathway", selectedEnglishPathway);
			formData.append("englishImageUrl", englishImageUrl);
			formData.append("arabicImageUrl", arabicImageUrl);
			if (data.arabicImage) {
				formData.append("arabicImage", data.arabicImage.rawFile);
			}
			if (data.englishImage) {
				formData.append("englishImage", data.englishImage.rawFile);
			}
			await axios.post(
				envSettings.baseUrl + "/timelines/promo",
				formData,
				{
					withCredentials: true,
					headers: {
						"Content-Type": "multipart/form-data",
					},
				}
			);
			fetchAndSetPathwaysData();
		} catch (error) {
			console.error("Error:", error.message);
		}
	};

	const fetchAndSetPathwaysData = async () => {
		const pathwaysResponse = await axios.get(
			envSettings.baseUrl +
				"/timelines?filters=%7B%7D&order=ASC&perPage=115&sort=id&start=0",
			{
				withCredentials: true,
			}
		);
		const promoResponse = await axios.get(
			envSettings.baseUrl + "/timelines/promo",
			{
				withCredentials: true,
			}
		);
		let pathways = pathwaysResponse.data.data;
		let promoObject = promoResponse.data;
		const oldArabicPathway = promoObject.arabicPromoTimeline;
		const oldEnglishPathway = promoObject.englishPromoTimeline;
		if (Object.keys(oldArabicPathway).length > 0) {
			setArabicImageUrl(promoObject?.arabicPromoTimeline?.asset?.url);
		}
		if (Object.keys(oldEnglishPathway).length > 0) {
			setEnglishImageUrl(promoObject?.englishPromoTimeline?.asset?.url);
		}
		setArabicPathways(
			pathways.filter(
				(pathway) => pathway.languageId === arabicLanguageId
			)
		);
		setEnglishPathways(
			pathways.filter(
				(pathway) => pathway.languageId === englishLanguageId
			)
		);
		setSelectedArabicPathway(oldArabicPathway?.id);
		setSelectedEnglishPathway(oldEnglishPathway?.id);

		setLoading(false);
	};

	useEffect(() => {
		fetchAndSetPathwaysData();
	}, []);

	const handleArSectionChange = (event) => {
		setArabicImageUrl(null);
		setSelectedArabicPathway(event.target.value);
	};

	const handleEnSectionChange = (event) => {
		setEnglishImageUrl(null);
		setSelectedEnglishPathway(event.target.value);
	};

	const styles = {
		dropZone: {
			border: "1px dashed silver",
			marginBottom: "50px",
			borderRadius: "10px",
		},
		label: {
			marginTop: "100px",
			marginBottom: "15px",
		},
		title: {
			width: "100%",
			marginTop: "10px",
			marginBottom: "60px",
		},
		inputLabel: {
			marginBottom: "15px",
		},
		inputWithClear: {
			display: "flex",
			width: "100%",
		},
		image: {
			width: 200,
		},
	};
	return (
		<Card>
			<CardContent>
				{loading ? (
					<center>
						<h1> we are loading data</h1>
					</center>
				) : (
					<Fragment>
						<SimpleForm
							toolbar={<Toolbar alwaysEnableSaveButton />}
							submitOnEnter={false}
							save={(data) => handleSave(data)}
							syncWithLocation={false}
						>
							<h2 style={styles.title}>
								Configure Promo Pathway
							</h2>
							<Grid container spacing={2} fullWidth>
								<Grid item xs={9}>
									<div style={styles.inputWithClear}>
										<div style={{ width: "100%" }}>
											<InputLabel
												id="arabic-section-label"
												style={styles.inputLabel}
											>
												Arabic Promo Pathway
											</InputLabel>
											<Select
												labelId="arabic-section-label"
												id="arabic-section-select"
												value={selectedArabicPathway}
												onChange={handleArSectionChange}
												fullWidth
											>
												{arabicPathways.map(
													(pathway) => (
														<MenuItem
															key={pathway.id}
															value={pathway.id}
														>
															{pathway.name.ar}
														</MenuItem>
													)
												)}
											</Select>
										</div>

										{/* Cancel Button */}
										<Button
											onClick={() => {
												setSelectedArabicPathway(null);
												setArabicImageUrl(null);
											}}
										>
											<CancelIcon />
										</Button>
									</div>
									<div style={{ color: "red" }}>
										{arabicValidationError}
									</div>

									<InputLabel style={styles.label}>
										Arabic Pathway Image
									</InputLabel>
									{arabicImageUrl && (
										<div>
											<img
												src={arabicImageUrl}
												alt="Arabic Pathway"
												style={styles.image}
											/>
										</div>
									)}
									<div style={styles.dropZone}>
										<ImageInput
											source="arabicImage"
											label=""
											accept="image/*"
											multiple={false}
										>
											<ImageField
												source="src"
												title="title"
											/>
										</ImageInput>
									</div>
								</Grid>
							</Grid>

							<Grid container spacing={2} fullWidth>
								<Grid item xs={9}>
									<div style={styles.inputWithClear}>
										<div style={{ width: "100%" }}>
											<InputLabel
												id="english-section-label"
												style={styles.inputLabel}
											>
												English Promo Pathway
											</InputLabel>

											<Select
												labelId="english-section-label"
												id="english-section-select"
												value={selectedEnglishPathway}
												onChange={handleEnSectionChange}
												fullWidth
											>
												{englishPathways.map(
													(pathway) => (
														<MenuItem
															key={pathway.id}
															value={pathway.id}
														>
															{pathway.name.en}
														</MenuItem>
													)
												)}
											</Select>
										</div>
										<Button
											onClick={() => {
												setSelectedEnglishPathway(null);
												setEnglishImageUrl(null);
											}}
										>
											<CancelIcon />
										</Button>
									</div>
									<div style={{ color: "red" }}>
										{englishValidationError}
									</div>
									<InputLabel style={styles.label}>
										English Pathway Image
									</InputLabel>
									{englishImageUrl && (
										<div>
											<img
												src={englishImageUrl}
												alt="English Pathway"
												style={styles.image}
											/>
										</div>
									)}
									<div style={styles.dropZone}>
										<ImageInput
											source="englishImage"
											label=""
											accept="image/*"
											multiple={false}
										>
											<ImageField
												source="src"
												title="title"
											/>
										</ImageInput>
									</div>
								</Grid>
							</Grid>
						</SimpleForm>
					</Fragment>
				)}
			</CardContent>
		</Card>
	);
};
