import { BackgroundModuleCreate } from "./backgroundModuleCreate";
import { BackgroundModuleEdit } from "./backgroundModuleEdit";
import { BackgroundModuleList } from "./backgroundModuleList";
import { BackgroundModuleShow } from "./backgroundModuleShow";

export default {
	create: BackgroundModuleCreate,
	edit: BackgroundModuleEdit,
	list: BackgroundModuleList,
	show: BackgroundModuleShow,
};
