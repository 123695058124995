import {
	Show,
	SimpleShowLayout,
	TextField,
	useTranslate,
	ArrayField,
	Datagrid,
	BooleanField,
} from "react-admin";

export const LicenseShow = (props) => {
	const translate = useTranslate();
	return (
		<Show {...props} title={translate("licenses.show.title")}>
			<SimpleShowLayout>
				<TextField source="name" />
				<TextField source="startDate" />
				<TextField source="endDate" />
				<TextField source="promoCode['code']" label={"Promo Code"} />
				<BooleanField source="extendBasedOnEndDate" />
				<ArrayField source="domains">
					<Datagrid>
						<TextField source="domain" />
					</Datagrid>
				</ArrayField>
				<ArrayField source="contactEmails">
					<Datagrid>
						<TextField source="contactEmail" />
					</Datagrid>
				</ArrayField>
			</SimpleShowLayout>
		</Show>
	);
};
