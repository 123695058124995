import React, { useEffect, useState } from "react";
import {
	DateTimeInput,
	Create,
	useRefresh,
	TextInput,
	useNotify,
	useRedirect,
	AutocompleteInput,
	useGetList,
	useTranslate,
	SimpleForm,
	ArrayInput,
	SimpleFormIterator,
	BooleanInput,
} from "react-admin";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import constants from "../../utils/constants";
import { validateLicenseData } from "./licenseValidate";

export const LicenseCreate = (props) => {
	const [promoCodes, setPromoCodes] = useState([]);
	const redirect = useRedirect();
	const refresh = useRefresh();
	const translate = useTranslate();
	const notify = useNotify();
	const emailRegex =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	const { data, loading } = useGetList(
		"promo-codes",
		{
			page: 1,
			perPage: constants.perPageMax,
		},
		{ field: "id", order: "DESC" },
		{ search: constants.promocodes.licensePrefix }
	);

	useEffect(() => {
		if (!loading)
			setPromoCodes(
				Object.values(data).map(({ id, code }) => ({ id, name: code }))
			);
	}, [loading, data]);

	const onSuccess = () => {
		notify("licenses.create.success", "info");
		redirect("/licenses");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	return (
		<Create
			{...props}
			title={translate("licenses.create.title")}
			onSuccess={onSuccess}
			onFailure={onFailure}
		>
			<SimpleForm
				toolbar={<CustomToolbar />}
				validate={validateLicenseData}
				syncWithLocation={false}
			>
				<TextInput source="name" />
				<DateTimeInput source="startDate" />
				<DateTimeInput source="endDate" />
				<AutocompleteInput source="promoCodeId" choices={promoCodes} />
				<BooleanInput
					source="extendBasedOnEndDate"
					defaultValue={false}
				/>
				<ArrayInput source="domains">
					<SimpleFormIterator>
						<TextInput
							source="domain"
							helperText={"e.g: tuhoon.com"}
							validate={[
								(val) => {
									if (!val) return "Domain should be filled!";
								},
							]}
						/>
					</SimpleFormIterator>
				</ArrayInput>
				<ArrayInput source="contactEmails" disableReordering={true}>
					<SimpleFormIterator>
						<TextInput
							source="contactEmail"
							helperText={"e.g: email@domain.com"}
							validate={[
								(val) => {
									if (!val) {
										return "Point of contact email should be filled!";
									}
									if (!val.match(emailRegex)) {
										return "Invalid email adress!";
									}
								},
							]}
						/>
					</SimpleFormIterator>
				</ArrayInput>
			</SimpleForm>
		</Create>
	);
};
