import { TaskCreate } from "./taskCreate";
import { TaskEdit } from "./taskEdit";
import { TaskList } from "./taskList";
import { TaskShow } from "./taskShow";

export default {
	create: TaskCreate,
	edit: TaskEdit,
	list: TaskList,
	show: TaskShow,
};
