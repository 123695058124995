export const getChoices = (items) => Object.values(items).map((item) => item);
export const statusEnumsObject = [
	{ name: "Pending", id: "PENDING" },
	{ name: "Published", id: "PUBLISHED" },
	{ name: "UnPublished", id: "UNPUBLISHED" },
	{ name: "Rejected", id: "REJECTED" },
];

export const statusEnumsArray = [
	"PENDING",
	"PUBLISHED",
	"UNPUBLISHED",
	"REJECTED",
];

export const requiredPermissions = ["CONTENT MANAGER", "SYSTEM ADMINISTRATOR"];
export const requiredStatuses = ["PENDING", "REJECTED", "UNPUBLISHED"];
