import React from "react";
import {
	Datagrid,
	EditButton,
	Filter,
	List,
	SelectInput,
	ShowButton,
	TextField,
	TextInput,
	useTranslate,
} from "react-admin";
import { useEffect, useMemo, useRef } from "react";
import CustomImageField from "../../customComponents/CustomImageField";
import { constants, suggestionsChoicesArr } from "../../utils/constants";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { CustomDeleteButtonWithConfirmation } from "../../customComponents/CustomButtons";

const SubscriptionFilter = ({ ...props }) => (
	<Filter {...props}>
		<TextInput source="search" alwaysOn />
	</Filter>
);

export const SubscriptionList = (props) => {
	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);
	const translate = useTranslate();
	return (
		<List
			{...props}
			perPage={constants.perPage}
			bulkActionButtons={false}
			filters={<SubscriptionFilter />}
		>
			<Datagrid>
				<TextField
					source={`outcomes[0].${defaultLocale}`}
					label={"Outcome 1"}
					sortable={false}
				/>
				<TextField
					source={`outcomes[1].${defaultLocale}`}
					label={"Outcome 2"}
					sortable={false}
				/>
				<ShowButton />
				<EditButton />
				<CustomDeleteButtonWithConfirmation />
			</Datagrid>
		</List>
	);
};
