import React from "react";
import {
	Datagrid,
	EditButton,
	Filter,
	List,
	BooleanInput,
	ShowButton,
	DeleteButton,
	TextField,
	TextInput,
} from "react-admin";
import { useMemo } from "react";
import { constants } from "../../utils/constants";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { CustomBooleanInput } from "../../customComponents/CustomBooleanInput";
import { CustomDeleteButtonWithConfirmation } from "../../customComponents/CustomButtons";

const DayPortionFilter = ({ ...props }) => (
	<Filter {...props}>
		<TextInput source="search" alwaysOn />
		<BooleanInput source="isActive" />
	</Filter>
);

export const DayPortionList = (props) => {
	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);
	return (
		<List
			{...props}
			perPage={constants.perPage}
			bulkActionButtons={false}
			filters={<DayPortionFilter />}
		>
			<Datagrid>
				<TextField
					source={`title.${defaultLocale}`}
					label={`Title(${defaultLocale})`}
					sortable={false}
				/>
				<TextField
					source={`greeting.${defaultLocale}`}
					label={`Greeting(${defaultLocale})`}
					sortable={false}
				/>
				<TextField
					source={`startTime`}
					label={`Start Time`}
					sortable={false}
				/>
				<TextField
					source={`endTime`}
					label={`End Time`}
					sortable={false}
				/>
				<CustomBooleanInput
					source="isActive"
					sortable={false}
					title={"Confirm Active Status Change"}
					content={
						"Please confirm active status change or click cancel"
					}
				/>
				<ShowButton />
				<EditButton />
				<CustomDeleteButtonWithConfirmation
					confirmTitle={"Delete A Day Portion"}
				/>
			</Datagrid>
		</List>
	);
};
