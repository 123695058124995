import {
	Edit,
	TextInput,
	TranslatableInputs,
	useNotify,
	useRedirect,
	useRefresh,
	useTranslate,
	useUpdate,
	useGetOne,
	TabbedForm,
	FileInput,
	FileField,
	FormTab,
	NumberField,
	NumberInput
} from "react-admin";
import { useState, useEffect } from "react";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { validateContentTypeData } from "./contentTypeValidate";
import { CustomDialog } from "../../customComponents/CustomDialog";
import { CustomButton } from "../../customComponents/CustomButtons";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import classnames from "classnames";
import { FormControlLabel, FormGroup, Switch } from "@material-ui/core";
import { CustomSortList } from "../../customComponents/CustomSortList";
import {
	setIndexesForData,
	transformToFormData,
} from "../../utils/customFunctions";
import { CustomTransferList } from "../../customComponents/CustomTransferList";

export const CustomBooleanInput = ({
	label,
	color,
	disabled,
	inputRef,
	setCurrentDefault,
	currentDefault,
	initialDefault,
	name,
}) => {
	const [open, setOpen] = useState(false);

	const handleClickOpen = (event) => {
		let value = !(event.target.value === "true");
		if (value != initialDefault) setOpen(true);
		else setCurrentDefault(true);
	};

	return (
		<>
			<FormGroup row>
				<FormControlLabel
					control={
						<Switch
							checked={currentDefault}
							onChange={handleClickOpen}
							name={name}
							color={color || "primary"}
							disabled={disabled}
							value={currentDefault}
							inputRef={inputRef}
						/>
					}
					label={label}
				/>
			</FormGroup>
			<CustomDialog
				open={open}
				title={"WARNING!"}
				content={
					currentDefault
						? "Are you sure you want to remove this content type as the default?"
						: "Are you sure you want to mark this as the default content type?"
				}
			>
				<CustomButton
					onClick={() => {
						setOpen(false);
					}}
					innerStyle={{
						display: "flex",
						alignItems: "unset",
						justifyContent: "center",
					}}
					innerText="No"
					icon={<CancelIcon />}
					className={classnames("ra-confirm")}
					color="primary"
					autoFocus
				/>
				<CustomButton
					onClick={() => {
						setCurrentDefault(!currentDefault);
						setOpen(false);
					}}
					innerStyle={{
						display: "flex",
						alignItems: "unset",
						justifyContent: "center",
					}}
					innerText="Yes"
					icon={<CheckCircleIcon />}
					className={classnames("ra-confirm")}
					color="primary"
					autoFocus
				/>
			</CustomDialog>
		</>
	);
};

const SortList = ({ record: { singles } }) => (
	<CustomSortList
		source="singles"
		referenceSource="singles"
		referenceResource="singles"
		referenceField="name"
		values={singles}
		isReferenceTranslatable={false}
	/>
);

const CustomBooleanInputHelper = ({
	record,
	setCurrentDefault,
	currentDefault,
}) => {
	return (
		<CustomBooleanInput
			source="isDefault"
			label="Default"
			currentDefault={currentDefault}
			setCurrentDefault={setCurrentDefault}
			initialDefault={record.isDefault}
		/>
	);
};

export const ContentTypeEdit = ({ ...props }) => {
	const redirect = useRedirect();
	const refresh = useRefresh();
	const translate = useTranslate();
	const notify = useNotify();
	const [update] = useUpdate("content-types", props.id);
	const [currentDefault, setCurrentDefault] = useState(false);
	const { data: contentType = {}, loading } = useGetOne(
		"content-types",
		props.id
	);

	useEffect(() => {
		if (!loading) setCurrentDefault(contentType.isDefault);
	}, [contentType]);

	const onSuccess = () => {
		notify("content-types.edit.success", "info");
		redirect("/content-types");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	return (
		<>
			{loading ? (
				<center>
					<h1>Loading...</h1>
				</center>
			) : (
				<Edit
					title="Edit Content Type"
					{...props}
					mutationMode="pessimistic"
				>
					<TabbedForm
						validate={validateContentTypeData}
						save={(data) => {
							data.isDefault = currentDefault;
							data = setIndexesForData(data, [
								"singles",
								"subContentTypes",
							]);
							const transformed = transformToFormData(data);
							update(
								{
									payload: {
										data: transformed,
									},
								},
								{
									onSuccess,
									onFailure,
								}
							);
						}}
						toolbar={<CustomToolbar />}
					>
						<FormTab
							label={translate("plans.tabs.basicInfo.label")}
						>
							<TranslatableInputs
								locales={userPreferences.translationLanguages}
								defaultLocale={
									userPreferences.defaultTranslationLanguage
								}
							>
								<TextInput source="titles" />
							</TranslatableInputs>
							<NumberField source="index" label="content-types.edit.currentIndex" />
							<NumberInput source="newIndex" />
							<CustomBooleanInputHelper
								setCurrentDefault={setCurrentDefault}
								currentDefault={currentDefault}
							/>
							<FileInput {...props} source="icon" label="Icon">
								<FileField source="src" title="Icon" />
							</FileInput>
							<FileInput
								{...props}
								source="illustration"
								label="Illustration"
							>
								<FileField source="src" title="Illustration" />
							</FileInput>
						</FormTab>
						<FormTab label={"Manage Sub Types"}>
							<CustomTransferList
								source="subContentTypes"
								referenceSource="sub-content-types"
								referenceResource="sub-content-types"
								referenceField="title"
								isReferenceTranslatable={true}
							/>
						</FormTab>
						<FormTab label={"Re-order Singles"}>
							<SortList />
						</FormTab>
					</TabbedForm>
				</Edit>
			)}
		</>
	);
};

export default ContentTypeEdit;
