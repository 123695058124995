import axios from "axios";
import { useEffect, useRef, useState } from "react";
import {
	Datagrid,
	EditButton,
	CheckboxGroupInput,
	Filter,
	SimpleForm,
	List,
	SearchInput,
	SelectInput,
	ShowButton,
	TextField,
	TextInput,
	BooleanInput,
	BooleanField,
	useGetList,
	usePermissions,
	useTranslate,
	downloadCSV,
} from "react-admin";
import { ExportButton } from "ra-ui-materialui";
import jsonExport from "jsonexport/dist";
import { CustomDeleteButtonWithConfirmation } from "../../customComponents/CustomButtons";
import { CustomEmptyDialog } from "../../customComponents/CustomEmptyDialog";
import { checkIfExists, checkPermission } from "../../utils/customFunctions";
import {
	getChoices,
	requiredPermissions,
	requiredStatuses,
	statusEnumsObject,
} from "./shared";
import { envSettings } from "../../settings/env";
import VideocamIcon from "@mui/icons-material/Videocam";
import AudiotrackIcon from "@mui/icons-material/Audiotrack";

const CustomBooleanField = ({ record }) => {
	return record.comingSoon ? (
		<BooleanField source="comingSoon" label="Coming Soon" />
	) : (
		<></>
	);
};

const ModuleFilter = ({ ...props }) => {
	const [languageData, setLanguageData] = useState([]);
	const translate = useTranslate();

	const { data: languageDataObj } = useGetList(
		"languages",
		{ page: 1, perPage: 10 },
		{ field: "id", order: "DESC" }
	);

	useEffect(() => {
		setLanguageData(getChoices(languageDataObj));
	}, [languageDataObj]);

	return (
		<Filter {...props}>
			<SearchInput source="search" alwaysOn />
			<SelectInput source="status" choices={statusEnumsObject} />
			<BooleanInput source="isSingle" label="Single" />
			<BooleanInput source="isVideo" label="Video" />
			<BooleanInput source="isSharable" label="Sharable" />
			<BooleanInput source="comingSoon" label="Coming Soon" />
			<BooleanInput source="isRecommended" label="Recommended" />
			<TextInput source="contentType" />
			<TextInput source="description" />
			<TextInput source="writer" />
			<TextInput source="narrator" />
			<TextInput source="composer" />
			<SelectInput
				source="languageId"
				choices={languageData}
				optionText={(record) =>
					translate(`misc.names.languages.${record.name}`)
				}
			/>
		</Filter>
	);
};

export const ModuleList = (props) => {
	const { loadingPermissions, permissions } = usePermissions();
	const hasRequiredStatus = useRef(false);
	const hasPermission = useRef(false);
	const [open, setOpen] = useState(false);

	useEffect(() => {
		hasPermission.current = checkIfExists(
			props.permissions,
			requiredPermissions
		);
	}, [props.permissions]);

	useEffect(() => {
		hasRequiredStatus.current = checkIfExists(
			props.status,
			requiredStatuses
		);
	}, [props.status]);

	const CustomDeleteButton = (props) => {
		if (
			!loadingPermissions &&
			(!permissions ||
				checkPermission(permissions, requiredPermissions)) &&
			props?.record?.isDeletable
		)
			return <CustomDeleteButtonWithConfirmation {...props} />;
		return <></>;
	};

	const handleCancel = () => {
		setOpen(false);
	};
	return (
		<List
			bulkActionButtons={false}
			{...props}
			filters={<ModuleFilter />}
			exporter={() => {
				setOpen(true);
			}}
		>
			<>
				<Datagrid>
					<TextField source="name" />
					<TextField source="description" />
					<TextField source="writer" />
					<TextField source="narrator" />

					<TextField source="composer" />
					<BooleanField source="isSingle" label="Single" />
					<CustomBooleanField label="Coming Soon" />
					<TextField source="language.name" sortable={false} />
					<TextField source="status" sortable={false} />
					<BooleanField
						source="isVideo"
						label="Type"
						looseValue={true}
						valueLabelFalse="Audio"
						valueLabelTrue="Video"
						TrueIcon={VideocamIcon}
						FalseIcon={AudiotrackIcon}
					/>
					<ShowButton />
					<EditButton />
					<CustomDeleteButton />
				</Datagrid>
				<CustomEmptyDialog
					open={open}
					onClose={handleCancel}
					title="Changing Key Pairs"
					content={{
						message: "Select the fields to export:",
					}}
				>
					<SimpleForm
						save={async ({ fields }) => {
							const {
								data: { data: _recordsToExport },
							} = await axios.post(
								envSettings.baseUrl + "/modules/export",
								{ exportFields: fields },
								{
									withCredentials: true,
								}
							);

							const BOM = "\uFEFF";
							let recordsToExport = [..._recordsToExport];
							const headers = recordsToExport.splice(0, 1)[0];

							jsonExport(
								recordsToExport,
								{
									headers: headers,
								},
								(err, csv) => {
									downloadCSV(
										`${BOM} ${csv}`,
										`Modules_${new Date().toLocaleString()}`
									);
								}
							);
						}}
					>
						<CheckboxGroupInput
							source="fields"
							HelperText="Pick the fields you want to export"
							choices={[
								{ id: "id", name: "id" },
								{ id: "name", name: "name" },
								{ id: "description", name: "description" },
								{ id: "writer", name: "writer" },
								{ id: "narrator", name: "narrator" },
								{ id: "composer", name: "composer" },
								{ id: "isSingle", name: "is single" },
								{ id: "comingSoon", name: "coming soon" },
								{ id: "status", name: "status" },
								{ id: "language", name: "language" },
								{ id: "contentTypes", name: "content type" },
								{
									id: "contentSubTypes",
									name: "content sub type",
								},
								{
									id: "createdOn",
									name: "publishing(creation) date",
								},
							]}
						/>
					</SimpleForm>
				</CustomEmptyDialog>
			</>
		</List>
	);
};
