import { useEffect, useState } from "react";
import {
	Create,
	FileInput,
	FileField,
	SimpleFormIterator,
	ArrayInput,
	TabbedForm,
	useGetOne,
	FormTab,
	TextInput,
	RadioButtonGroupInput,
	useGetList,
	BooleanInput,
	useMutation,
	useNotify,
	useRedirect,
	useRefresh,
	useTranslate,
	useCreate,
} from "react-admin";
import { getChoices } from "./shared";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { validateCommunicationPageData } from "./communicationPageValidate";
import { transformToFormData } from "../../utils/customFunctions";
import { communicationTypes } from "../../utils/constants";

export const CommunicationPageCreate = (props) => {
	const translate = useTranslate();
	const [languagesData, setLanguageData] = useState([]);
	const [create] = useCreate("communication-pages", props.id);
	const [entityType, setEntityType] = useState(null);
	const redirect = useRedirect();
	const [mutate] = useMutation();
	const refresh = useRefresh();
	const notify = useNotify();

	const { data: languagesDataObj } = useGetList(
		"languages",
		{ page: 1, perPage: 10 },
		{ field: "id", order: "DESC" }
	);
	useEffect(() => {
		setLanguageData(getChoices(languagesDataObj));
	}, [languagesDataObj]);

	const { data = {}, loading } = useGetOne("communication-pages", props.id);

	const onSuccess = () => {
		notify(`Created successfully`);
		redirect("/communication-pages");
		refresh();
	};

	const onFailure = (error) => {
		notify(error.message, "warning");
	};

	useEffect(() => {
		setEntityType(data.entityType);
	}, [data.entityType]);

	return (
		<>
			{loading ? (
				<center>
					<h1>Loading...</h1>
				</center>
			) : (
				<Create
					{...props}
					mutationMode="pessimistic"
					title={translate("objectives.edit.title")}
				>
					<TabbedForm
						toolbar={<CustomToolbar />}
						save={(data) => {
							const transformed = transformToFormData(data);
							create(
								{
									payload: {
										data: transformed,
									},
								},
								{
									onSuccess,
									onFailure,
								}
							);
						}}
						validate={validateCommunicationPageData}
						syncWithLocation={false}
					>
						<FormTab label={translate("objectives.tabs.basicInfo.label")}>
							<TextInput source="title" />
							<RadioButtonGroupInput
								source="languageId"
								choices={languagesData}
								optionText={({ name }) => `misc.names.languages.${name}`}
								optionValue="id"
							/>
							<RadioButtonGroupInput
								source="entityType"
								choices={communicationTypes}
								optionText={({ name }) => name}
								optionValue="id"
								onClick={(event) => setEntityType(event.target.value)}
							/>
							{entityType === "INFO" && (
								<>
									<TextInput
										source="message"
										helperText="Add % at the start and the end of a dynamic attribute. e.g: %firstName%"
									/>
									{/* <ImageField source="imageUrl" /> */}
									<FileInput
										source="image"
										label="Illustration"
										accept="image/*"
										placeholder={translate(
											"communication-pages.create.uploadImagePlaceholder"
										)}
									>
										<FileField source="src" title="Illustration" />
									</FileInput>
								</>
							)}
						</FormTab>
						{entityType === "QUESTION" && (
							<FormTab
								label={translate(
									"communication-pages.tabs.questionDetails.label"
								)}
							>
								<TextInput label="question" source="question" />
								<BooleanInput label="Multiple Choice" source="multiple" />
								<ArrayInput source="options">
									<SimpleFormIterator>
										<TextInput source="option" label="option" />
									</SimpleFormIterator>
								</ArrayInput>
							</FormTab>
						)}
					</TabbedForm>
				</Create>
			)}
		</>
	);
};
