import { isNotInRange } from "../../utils/customFunctions";

export const validateCommunicationPageData = async ({
	title,
	entityType,
	languageId,
	message,
	question,
	options,
	image,
}) => {
	const errors = {};

	if (!title) errors.title = "Title is a required field";

	if (title && isNotInRange({ min: 3, max: 50, str: title })) {
		errors.title = {
			message: "validationErrors.minMax.titles",
			args: { min: 3, max: 50 },
		};
	}
	if (!entityType) errors.entityType = "It's required to choose a type!";
	if (!languageId) errors.languageId = "It's required to choose a language!";
	if ((entityType === "Info") & !message)
		errors.message = "It's required to add a message!";
	if (entityType === "Question" && !question)
		errors.question = "It's required to add a question!";
	if (
		entityType === "Question" &&
		(!options || (options && options?.length < 2))
	)
		errors.options = "It's required to add two options at least!";
	return errors;
};
