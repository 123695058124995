import {
	isNotInRange,
	isNotInRangeValue,
	isWholeNumber,
	checkURLReservedChars,
} from "../../utils/customFunctions";

export const validatePromoCodeData = ({
	code,
	percentage,
	startDate,
	endDate,
	userUsageLimit,
	globalUsageLimit,
	isFreeDays,
	numberOfFreeDays,
}) => {
	const errors = {};
	if (!code) errors.code = "promo-codes.validationErrors.required.code";
	if (code && isNotInRange({ min: 3, max: 50, str: code })) {
		errors.code = {
			message: "promo-codes.validationErrors.minMax.code",
			args: { min: 3, max: 50 },
		};
	}
	if (checkURLReservedChars(code))
		errors.code = "promo-codes.validationErrors.value.code";

	if (!startDate)
		errors.startDate = "promo-codes.validationErrors.required.startDate";
	if (!endDate)
		errors.endDate = "promo-codes.validationErrors.required.endDate";
	if (startDate && endDate) {
		let _startDate = new Date(startDate).toISOString();
		let _endDate = new Date(endDate).toISOString();
		if (new Date(_startDate) >= new Date(_endDate)) {
			errors.startDate = "promo-codes.validationErrors.logic.endDate";
			errors.endDate = "promo-codes.validationErrors.logic.endDate";
		}
	}
	if (!userUsageLimit)
		errors.userUsageLimit =
			"promo-codes.validationErrors.required.userUsageLimit";
	if (userUsageLimit && isWholeNumber({ str: userUsageLimit })) {
		errors.userUsageLimit = {
			message: "promo-codes.validationErrors.value.userUsageLimit",
		};
	}
	if (!globalUsageLimit)
		errors.globalUsageLimit =
			"promo-codes.validationErrors.required.globalUsageLimit";
	if (globalUsageLimit && isWholeNumber({ str: globalUsageLimit })) {
		errors.globalUsageLimit = {
			message: "promo-codes.validationErrors.value.globalUsageLimit",
		};
	}
	if (isFreeDays) {
		if (!numberOfFreeDays)
			errors.numberOfFreeDays =
				"promo-codes.validationErrors.required.numberOfFreeDays";
		if (
			numberOfFreeDays &&
			isNotInRangeValue({ min: 1, max: 365, str: numberOfFreeDays })
		) {
			errors.numberOfFreeDays = {
				message: "promo-codes.validationErrors.minMax.numberOfFreeDays",
				args: { min: 1, max: 365 },
			};
		}
		if (numberOfFreeDays && isWholeNumber({ str: numberOfFreeDays })) {
			errors.numberOfFreeDays = {
				message: "promo-codes.validationErrors.value.numberOfFreeDays",
			};
		}
	} else {
		if (!percentage)
			errors.percentage =
				"promo-codes.validationErrors.required.percentage";
		if (
			percentage &&
			isNotInRangeValue({ min: 1, max: 99, str: percentage })
		) {
			errors.percentage = {
				message: "promo-codes.validationErrors.minMax.percentage",
				args: { min: 1, max: 99 },
			};
		}
		if (percentage && isWholeNumber({ str: percentage })) {
			errors.percentage = {
				message: "promo-codes.validationErrors.value.percentage",
			};
		}
	}
	return errors;
};
