import { JournalCreate } from "./journalCreate";
import { JournalEdit } from "./journalEdit";
import { JournalList } from "./journalList";
import { JournalShow } from "./journalShow";

export default {
	create: JournalCreate,
	edit: JournalEdit,
	list: JournalList,
	show: JournalShow,
};
