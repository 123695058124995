import { isNotInRange } from "../../utils/customFunctions";

export const validateProductData = ({ name, price, currency }) => {
	const errors = {};
	if (!price) errors.price = "web-products.validationErrors.required.price";
	if (!currency)
		errors.currency = "web-products.validationErrors.required.currency";
	if (!name) errors.name = "web-products.validationErrors.required.name";
	if (name && isNotInRange({ min: 3, max: 50, str: name })) {
		errors.name = {
			message: "web-products.validationErrors.minMax.name",
			args: { min: 3, max: 50 },
		};
	}

	return errors;
};
