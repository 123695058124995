import { useEffect, useState } from "react";
import {
	Create,
	SelectInput,
	SimpleForm,
	TextInput,
	TranslatableInputs,
	useCreate,
	useGetList,
	useNotify,
	useRedirect,
	useTranslate,
} from "react-admin";

import { CustomTransferList } from "../../customComponents/CustomTransferList";
import { backgroundPlaylistValidate } from "./backgroundPlaylistValidate";
import { setIndexesForData } from "../../utils/customFunctions";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { CustomToolbar } from "../../customComponents/CustomToolbar";

export const BackgroundPlaylistCreate = (props) => {
	const [languagesArray, setLanguagesArray] = useState([]);
	const [categoriesArray, setCategoriesArray] = useState([]);

	const [create] = useCreate("background-playlists");
	const translate = useTranslate();
	const redirect = useRedirect();
	const notify = useNotify();

	const { data: languagesData } = useGetList("languages", {
		page: 1,
		perPage: 10,
	});

	const { data: categoriesData } = useGetList("categories", {
		page: 1,
		perPage: 10,
	});

	const onSuccess = () => {
		notify(translate("background-playlists.create.success"), "info");
		redirect("/background-playlists");
	};

	useEffect(() => {
		languagesData &&
			setLanguagesArray(
				Object.values(languagesData).map((language) =>
					language.name.slice(0, 2).toLowerCase()
				)
			);
	}, [languagesData]);

	useEffect(() => {
		setCategoriesArray(
			Object.values(categoriesData).map(({ id, titles }) => ({
				id,
				name: titles[userPreferences.defaultTranslationLanguage],
			}))
		);
	}, [categoriesData]);

	const onFailure = (error) => {
		notify(error.message, "warning");
	};
	return (
		<Create {...props} title={translate("playlists.create.title")}>
			<SimpleForm
				save={(data) => {
					const dataToSend = setIndexesForData(data, ["backgroundModules"]);
					create(
						{ payload: { data: dataToSend } },
						{
							onSuccess,
							onFailure,
						}
					);
				}}
				validate={backgroundPlaylistValidate}
				syncWithLocation={false}
				toolbar={<CustomToolbar />}
			>
				<TranslatableInputs
					locales={languagesArray}
					defaultLocale={userPreferences.defaultTranslationLanguage}
				>
					<TextInput source="names" />
					<TextInput multiline source="descriptions" />
				</TranslatableInputs>
				<SelectInput source="categoryId" choices={categoriesArray} />
				<CustomTransferList
					source="backgroundModules"
					referenceSource="backgroundModules"
					referenceResource="background-modules"
					referenceField="name"
					isReferenceTranslatable={false}
				/>
			</SimpleForm>
		</Create>
	);
};
