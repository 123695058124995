import React, { useMemo } from "react";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { useTranslate } from "ra-core";
import {
	Datagrid,
	FunctionField,
	Show,
	SimpleShowLayout,
	TextField,
	ChipField,
	ArrayField,
	useRedirect,
	SingleFieldList,
} from "react-admin";
import { Typography } from "@material-ui/core";

const CustomQuestions = () => {
	return (
		<Datagrid>
			<TextField source="questions.ar(Male)" label="Arabic (male)" />
			<TextField source="questions.ar(Female)" label="Arabic (female)" />
			<TextField source="questions.en" label="English" />
		</Datagrid>
	);
};

const CustomResponses = ({ record: { responses } }) => {
	if (responses.length > 0) {
		return (
			<>
				<Typography>{`Responses`}</Typography>
				<ArrayField source="responses">
					<Datagrid>
						<TextField
							source="responses.ar(Male)"
							label="Arabic (male)"
						/>
						<TextField
							source="responses.ar(Female)"
							label="Arabic (female)"
						/>
						<TextField source="responses.en" label="English" />
						<TextField source="value" label="Value" />
					</Datagrid>
				</ArrayField>
			</>
		);
	}
	return <></>;
};

const CustomMessage = ({ record }) => {
	const translate = useTranslate();
	const message = translate("mood-tracker.show.isActive", {
		status: record?.isActive ? "" : "not",
	});
	return <Typography>{`${message}`}</Typography>;
};

export const MoodTrackerShow = (props) => {
	const translate = useTranslate();
	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);
	return (
		<Show {...props} title={translate("mood-tracker.show.title")}>
			<SimpleShowLayout>
				<TextField source={`titles.${defaultLocale}`} />
				<FunctionField
					source="isActive"
					render={(record) => <CustomMessage record={record} />}
				/>
				<TextField source="questions.ar(Male)" label="Arabic (male)" />
				<TextField
					source="questions.ar(Female)"
					label="Arabic (female)"
				/>
				<TextField source="questions.en" label="English" />

				<CustomResponses source="responses" />
			</SimpleShowLayout>
		</Show>
	);
};
