import React, { useEffect, useState, Fragment } from "react";
import {
	SimpleForm,
	useNotify,
	useRedirect,
	Toolbar,
	SelectInput,
	BooleanInput,
	NumberInput,
	TextInput,
} from "react-admin";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { envSettings } from "../settings/env";
import axios from "axios";
import { buildErrorMessage } from "../utils/helpers";
import { Typography } from "@material-ui/core";

export const AppSettings = () => {
	const [loading, setLoading] = useState(true);
	const [twilio, setTwilio] = useState(false);
	const [sns, setSNS] = useState(false);
	const [iosMinimumVersion, setIosMinimumVersion] = useState(null);
	const [iosRecommendedVersion, setIosRecommendedVersion] = useState(null);
	const [androidMinimumVersion, setAndroidMinimumVersion] = useState(null);
	const [androidRecommendedVersion, setAndroidRecommendedVersion] =
		useState(null);
	const [versions, setVersions] = useState([]);

	const [maximumSecondsToListen, setMaximumSecondsToListen] = useState(null);
	const [isMoodTrackerPaid, setIsMoodTrackerPaid] = useState(false);
	const [isJournalPaid, setIsJournalPaid] = useState(false);
	const [extraInfo, setExtraInfo] = useState(false);

	const notify = useNotify();
	const redirect = useRedirect();

	const validateAppSettings = async ({
		iosMinimumVersion,
		iosRecommendedVersion,
		androidMinimumVersion,
		androidRecommendedVersion,
		useTwilio,
		useSNS,
		maximumSecondsToListen,
	}) => ({
		...(!iosRecommendedVersion && {
			iosRecommendedVersion: "Required",
		}),
		...(!androidRecommendedVersion && {
			androidRecommendedVersion: "Required",
		}),
		...(!iosMinimumVersion && {
			iosMinimumVersion: "Required",
		}),
		...(!androidMinimumVersion && {
			androidMinimumVersion: "Required",
		}),
		...(typeof maximumSecondsToListen !== "number" && {
			maximumSecondsToListen: "Required",
		}),
	});

	const onFailure = (error) => {
		notify(buildErrorMessage(error), "warning");
	};

	useEffect(() => {
		(async () => {
			const res = await axios.get(envSettings.baseUrl + "/settings/app", {
				withCredentials: true,
			});
			setVersions(
				res?.data?.data?.versions?.map((item) => ({
					id: item,
					title: item,
				}))
			);
			setIosMinimumVersion(res?.data?.data?.iosMinimumVersion);
			setIosRecommendedVersion(res?.data?.data?.iosRecommendedVersion);
			setAndroidMinimumVersion(res?.data?.data?.androidMinimumVersion);
			setIsJournalPaid(res?.data?.data?.isJournalPaid);
			setExtraInfo(res?.data?.data?.extraInfo);
			setIsMoodTrackerPaid(res?.data?.data?.isMoodTrackerPaid);
			setTwilio(res?.data?.data?.useTwilio);
			setSNS(res?.data?.data?.useSNS);
			setAndroidRecommendedVersion(
				res?.data?.data?.androidRecommendedVersion
			);
			setMaximumSecondsToListen(res?.data?.data?.maximumSecondsToListen);
			setLoading(false);
		})();
	}, []);
	return (
		<Card>
			<CardContent>
				{loading ? (
					<center>
						<h1> we are loading data</h1>
					</center>
				) : (
					<Fragment>
						<SimpleForm
							toolbar={<Toolbar alwaysEnableSaveButton />}
							submitOnEnter={false}
							save={async (data) => {
								try {
									const res = await axios.post(
										envSettings.baseUrl + "/settings/app",
										data,
										{ withCredentials: true }
									);
									if (res.status === 200) {
										notify(
											"Updated Successfully!",
											"success"
										);
										redirect("/appSettings");
									} else {
										notify("Failed!", "error");
									}
								} catch (error) {
									onFailure(error);
								}
							}}
							validate={validateAppSettings}
						>
							<SelectInput
								source="iosMinimumVersion"
								choices={versions}
								defaultValue={iosMinimumVersion}
								label={"IOS Minimum Version"}
								optionText={"title"}
								style={{ float: "left" }}
							/>
							<SelectInput
								source="iosRecommendedVersion"
								choices={versions}
								defaultValue={iosRecommendedVersion}
								label={"IOS Recommended Version"}
								optionText={"title"}
								style={{ marginLeft: "20px" }}
							/>
							<SelectInput
								source="androidMinimumVersion"
								defaultValue={androidMinimumVersion}
								choices={versions}
								label={"Android Minimum Version"}
								optionText={"title"}
								style={{ float: "left" }}
							/>
							<SelectInput
								source="androidRecommendedVersion"
								choices={versions}
								defaultValue={androidRecommendedVersion}
								label={"Android Recommended Version"}
								optionText={"title"}
								style={{ marginLeft: "20px" }}
							/>
							<BooleanInput
								source="useTwilio"
								defaultValue={twilio}
							/>
							<BooleanInput
								source="useSNS"
								label={"Use SNS"}
								defaultValue={sns}
							/>

							<NumberInput
								source="maximumSecondsToListen"
								label="Seconds"
								defaultValue={maximumSecondsToListen}
								onChange={(data) => {
									setMaximumSecondsToListen(
										data.target.value
									);
								}}
							/>
							<div>
								<Typography
									style={{
										fontSize: "0.875rem",
										color: "rgba(0, 0, 0, 0.54)",
									}}
								>
									Paid Features
								</Typography>
								<div style={{ display: "flex" }}>
									<BooleanInput
										source="isMoodTrackerPaid"
										defaultValue={isMoodTrackerPaid}
										style={{
											flex: 1,
											whiteSpace: "nowrap",
										}}
										label="Mood Tracker"
									/>
									<BooleanInput
										source="isJournalPaid"
										defaultValue={isJournalPaid}
										style={{ flex: 1 }}
										label="Journals"
									/>
								</div>
							</div>
							<div>
								<Typography
									style={{
										fontSize: "0.875rem",
										color: "rgba(0, 0, 0, 0.54)",
									}}
								>
									JSON Extra Info
								</Typography>
								<div style={{ display: "flex" }}>
									<TextInput
										source="extraInfo"
										defaultValue={JSON.stringify(extraInfo)}
										style={{
											flex: 1,
											whiteSpace: "nowrap",
										}}
										label="JSON Extra Info"
									/>
								</div>
							</div>
							<br />
						</SimpleForm>
					</Fragment>
				)}
			</CardContent>
		</Card>
	);
};
