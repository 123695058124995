import { useState, useEffect } from "react";
import {
	BooleanInput,
	Datagrid,
	EditButton,
	Filter,
	List,
	useTranslate,
	TextField,
	useGetList,
	SelectInput,
	ShowButton,
	TextInput,
	downloadCSV,
} from "react-admin";
import { ExportButton } from "ra-ui-materialui";
import jsonExport from "jsonexport/dist";

import { CustomDeleteButtonWithConfirmation } from "../../customComponents/CustomButtons";

import { constants } from "../../utils/constants";
import { getChoices } from "./shared";
import { genders } from "../../utils/constants";

export const CommunicationFlowFilter = (props) => {
	const [languageData, setLanguageData] = useState([]);
	const translate = useTranslate();

	const { data: languageDataObj } = useGetList(
		"languages",
		{ page: 1, perPage: 10 },
		{ field: "id", order: "DESC" }
	);

	useEffect(() => {
		setLanguageData(getChoices(languageDataObj));
	}, [languageDataObj]);

	return (
		<Filter {...props}>
			<TextInput source="search" alwaysOn />
			<SelectInput
				source="languageId"
				choices={languageData}
				optionText={(record) =>
					translate(`misc.names.languages.${record.name}`)
				}
			/>
			<SelectInput
				source="gender"
				choices={genders}
				optionText={({ name }) => name}
			/>
		</Filter>
	);
};
const CustomExportButton = ({ record }) => {
	const exporter = ({ id, submissions }) => {
		const BOM = "\uFEFF";
		jsonExport(
			submissions,
			{
				headers: [
					"userId",
					"plan",
					"module",
					"type",
					"question",
					"answers",
				],
			},
			(err, csv) => {
				downloadCSV(`${BOM} ${csv}`, "Communication Flow " + id);
			}
		);
	};
	return (
		<ExportButton label="Submissions" exporter={() => exporter(record)} />
	);
};

export const CommunicationFlowList = (props) => {
	return (
		<List
			{...props}
			perPage={constants.perPage}
			bulkActionButtons={false}
			filters={<CommunicationFlowFilter />}
			exporter={<CustomExportButton />}
		>
			<Datagrid>
				<TextField source="name" />
				<TextField source="language.name" />
				<TextField source="gender" />
				<EditButton />
				<CustomDeleteButtonWithConfirmation />
			</Datagrid>
		</List>
	);
};
