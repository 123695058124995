import { format, formatDistanceToNow, parseJSON } from "date-fns";

export const transformToFormData = ({ ...rest }) => {
	let formData = new FormData();

	Object.entries({ ...rest }).forEach(([key, value]) => {
		if (Array.isArray(value) && value[0]?.rawFile) {
			value.forEach((item) => {
				formData.append(key, item.rawFile);
			});
		} else if (value && value.rawFile) {
			formData.append(key, value.rawFile);
		} else if (Array.isArray(value)) {
			const data = JSON.stringify(value);
			formData.append(key, data);
		} else {
			const data =
				typeof value === "object" && value !== null
					? JSON.stringify(value)
					: value;
			formData.append(key, data);
		}
	});

	return { formData };
};

export const transformToFormDataNestedArray = ({ ...rest }) => {
	let formData = new FormData();

	Object.entries({ ...rest }).forEach(([key, value]) => {
		if (Array.isArray(value)) {
			if ("rawFile" in value[0] && value[0]?.rawFile)
				value.forEach((item) => {
					formData.append(key, item.rawFile);
				});
			else if (!("rawFile" in value[0])) {
				let dataArray = [];
				value.forEach((item, index) => {
					let dataItem = {};
					Object.entries(item).forEach(([itemKey, itemValue]) => {
						if (itemValue && itemValue.rawFile) {
							formData.append(
								`${key}_${index}`,
								itemValue.rawFile
							);
						} else {
							dataItem[itemKey] = itemValue;
						}
					});
					dataArray.push(dataItem);
				});
				formData.append(key, JSON.stringify(dataArray));
			}
		} else if (value && value.rawFile) {
			formData.append(key, value.rawFile);
		} else {
			const data =
				typeof value === "object" && value !== null
					? JSON.stringify(value)
					: value;
			formData.append(key, data);
		}
	});

	return { formData };
};

export const setIndexesForData = (data, sources) => {
	let result = { ...data };
	for (let source of sources) {
		result[source] = data[source]?.map(({ id, ...rest }, index) => ({
			...rest,
			id,
			index: index + 1,
		}));
	}
	return result;
};

export const encodeWithNewkeys = ({ keys, data }) => {
	const newDataObj = keys.map(({ oldKey, newKey }) => ({
		[newKey]: data[oldKey],
	}));

	return transformToFormData(newDataObj);
};

export const checkPermission = (permissions = [], requiredPermissions = []) =>
	!!requiredPermissions?.find((requiredPermission) =>
		permissions?.includes(requiredPermission)
	);

export const convertFrom12To24Format = (time12) => {
	const [sHours, minutes, period] = time12
		.match(/([0-9]{1,2}):([0-9]{2}) (AM|PM)/)
		.slice(1);
	const PM = period === "PM";
	const hours = (+sHours % 12) + (PM ? 12 : 0);

	return `${("0" + hours).slice(-2)}:${minutes}`;
};

export const convertFrom24To12Format = (time24) => {
	const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
	const period = +sHours < 12 ? "AM" : "PM";
	const hours = +sHours % 12 || 12;

	return `${hours}:${minutes} ${period}`;
};

export const isNotInRange = ({ min, max, str = "" }) => {
	let isNotMin = false;
	let isNotMax = false;

	if (min) {
		isNotMin = str.length < min;
	}

	if (max) {
		isNotMax = str.length > max;
	}

	return isNotMin || isNotMax;
};

export const isNotInRangeValue = ({ min, max, str = "" }) => {
	let isNotMin = false;
	let isNotMax = false;
	let val = parseFloat(str);
	if (min) {
		isNotMin = val < min;
	}

	if (max) {
		isNotMax = val > max;
	}

	return isNotMin || isNotMax;
};

export const isWholeNumber = ({ str = "" }) => {
	let val = parseFloat(str);
	return val % 1 !== 0;
};

export const converToBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
		return reader;
	});

const calculateGCD = (a, b) => {
	if (b > a) [a, b] = [b, a];
	while (b != 0) {
		let m = a % b;
		a = b;
		b = m;
	}
	return a;
};

export const calculateRatio = (width, height) => {
	const gcd = calculateGCD(width, height);
	return width / gcd + ":" + height / gcd;
};

export const aspectRatioValidator = (aspectRatio, expectedAspectRatio) => {
	return aspectRatio === expectedAspectRatio;
};

export const checkIfExists = (permissions = [], requiredPermissions = []) =>
	!!requiredPermissions?.find((requiredPermission) =>
		permissions?.includes(requiredPermission)
	);

export const isValidImage = async (rawFile, expectedAspectRatio) => {
	const raw = await converToBase64(rawFile);
	let i = new Image();
	i.src = raw;
	const aspectRatio = calculateRatio(i.width, i.height);
	return aspectRatioValidator(aspectRatio, expectedAspectRatio);
};

export const isValidSvg = async (rawFile) => {
	const raw = await converToBase64(rawFile);
	const type = raw.split(";")[0].split("/")[1];
	return type.includes("svg");
};

export const isValidEmail = (email) => {
	return (
		email?.length &&
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
			email
		)
	);
};

export const getDateWithFormatAndDistance = (date) => {
	const parsedDate = parseJSON(date);
	const formatedDistanceDate = formatDistanceToNow(parsedDate, {
		addSuffix: true,
	});
	const formatedDate = format(parsedDate, "ddMMMyyyy h':'mmbbb");

	return { formatedDistanceDate, formatedDate };
};

export const checkURLReservedChars = (str) => {
	const regexValidator = /[!*'();:@&=+$,/?%#[\]]/gm;
	return regexValidator.test(str);
};
