import React from "react";
import {
	Datagrid,
	EditButton,
	Filter,
	List,
	ShowButton,
	TextField,
	TopToolbar,
	CreateButton,
	ExportButton,
	TextInput,
	DeleteButton,
} from "react-admin";

import { constants } from "../../utils/constants";
import userPreferences from "../../utils/fakeUserPrefrences";
import CustomImageField from "../../customComponents/CustomImageField";

const ResourcesCategoryFilter = (props) => (
	<Filter {...props}>
		<TextInput source="search" alwaysOn />
	</Filter>
);

const CustomDelete = ({ record: { isDeletable }, record, ...props }) =>
	isDeletable ? <DeleteButton {...props} record={record} /> : null;

const ListActions = (props) => (
	<TopToolbar>
		<CreateButton />
		<ExportButton />
	</TopToolbar>
);

export const HRResourcesCtegoriesList = (props) => {
	return (
		<List
			{...props}
			perPage={constants.perPage}
			bulkActionButtons={false}
			filters={<ResourcesCategoryFilter />}
			actions={<ListActions />}
		>
			<Datagrid>
				<CustomImageField
					label="Cover image"
					source="coverImageUrl"
					sortable={false}
				/>
				<TextField
					source={`titles.${userPreferences.defaultTranslationLanguage}`}
					sortable={false}
					label="Title (Arabic)"
				/>
				<TextField
					source={`titles.${userPreferences.displayLanguage}`}
					sortable={false}
					label="Title (English)"
				/>
				<EditButton />
				<CustomDelete />
			</Datagrid>
		</List>
	);
};

export default HRResourcesCtegoriesList;
