import {
	Datagrid,
	EditButton,
	Filter,
	List,
	useTranslate,
	TextField,
	TextInput,
	ShowButton,
} from "react-admin";
import { constants } from "../../utils/constants";
import { CustomBooleanInput } from "../../customComponents/CustomBooleanInput";

const ProductFilter = ({ ...props }) => (
	<Filter {...props}>
		<TextInput source="search" alwaysOn />
	</Filter>
);

export const ProductList = (props) => {
	const translate = useTranslate();
	return (
		<List
			title={translate("web-products.list.title")}
			{...props}
			perPage={constants.perPage}
			bulkActionButtons={false}
			filters={<ProductFilter />}
		>
			<Datagrid>
				<TextField source="name" />
				<TextField source="price" />
				<TextField source="currency" />
				<CustomBooleanInput source="isActive" sortable={false} />
				<ShowButton />
				<EditButton />
			</Datagrid>
		</List>
	);
};
