import React from "react";
import {
	BooleanInput,
	Datagrid,
	EditButton,
	Filter,
	List,
	TextField,
	TextInput,
	ShowButton,
	BooleanField,
} from "react-admin";

import { CustomDeleteButtonWithCustomConfirm } from "../../customComponents/CustomButtons";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { constants } from "../../utils/constants";

const MoodTrackerFilter = (props) => (
	<Filter {...props}>
		<TextInput source="search" alwaysOn />
		<BooleanInput source="isActive" defaultValue={true} />
	</Filter>
);
const CustomBooleanField = ({ record }) => {
	return record.isActive === true ? (
		<BooleanField source="isActive" label="Active" />
	) : (
		<></>
	);
};

export const MoodTrackerList = (props) => {
	return (
		<List
			{...props}
			perPage={constants.perPage}
			bulkActionButtons={false}
			filters={<MoodTrackerFilter />}
			exporter={false}
		>
			<Datagrid>
				<TextField
					source={`titles.${userPreferences.defaultTranslationLanguage}`}
					sortable={false}
				/>
				<CustomBooleanField label="Active" />
				<ShowButton />
				<EditButton />
				<CustomDeleteButtonWithCustomConfirm mutationMode="pessimistic" />
			</Datagrid>
		</List>
	);
};

export default MoodTrackerList;
