import React from "react";
import {
	BooleanInput,
	Datagrid,
	EditButton,
	Filter,
	List,
	TextField,
	TextInput,
} from "react-admin";

import { CustomDeleteButtonWithCustomConfirm } from "../../customComponents/CustomButtons";
import { CustomBooleanInput } from "../../customComponents/CustomBooleanInput";
import CustomImageField from "../../customComponents/CustomImageField";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { constants } from "../../utils/constants";

const CategoryFilter = (props) => (
	<Filter {...props}>
		<TextInput source="search" alwaysOn />
		<BooleanInput source="isPublished" />
	</Filter>
);

export const CategoryList = (props) => {
	return (
		<List
			{...props}
			perPage={constants.perPage}
			bulkActionButtons={false}
			filters={<CategoryFilter />}
		>
			<Datagrid>
				<CustomImageField source="imageUrl" sortable={false} />
				<TextField source="index" />
				<TextField
					source={`titles.${userPreferences.defaultTranslationLanguage}`}
					sortable={false}
				/>
				<CustomBooleanInput source="isPublished" sortable={false} />
				<EditButton />
				<CustomDeleteButtonWithCustomConfirm mutationMode="pessimistic" />
			</Datagrid>
		</List>
	);
};

export default CategoryList;
