import { isNotInRange } from "../../utils/customFunctions";

export const validateDoctorData = ({
	fullName,
	description,
	isPublic,
	fromCreate = false,
	image,
	imageUrl,
}) => {
	const errors = {};
	if (!fullName) {
		errors.fullName = {
			message: "validationErrors.required.requiredField",
			args: { field: "Full Name" },
		};
	}

	if (fullName && isNotInRange({ min: 3, max: 50, str: fullName })) {
		errors.fullName = {
			message: "validationErrors.minMax.fullName",
			args: { min: 3, max: 50 },
		};
	}

	if (!description) {
		errors.description = {
			message: "validationErrors.required.requiredField",
			args: { field: "Description" },
		};
	}

	if (description && isNotInRange({ min: 3, max: 100, str: description })) {
		errors.description = {
			message: "validationErrors.minMax.descriptions",
			args: { min: 3, max: 50 },
		};
	}

	if (!image && fromCreate && isPublic) {
		errors.image = {
			message: "validationErrors.required.requiredField",
			args: { field: "Profile Picture" },
		};
	}

	if (!image && !fromCreate && isPublic && !imageUrl) {
		errors.image = {
			message: "validationErrors.required.requiredField",
			args: { field: "Profile Picture" },
		};
	}

	return errors;
};
