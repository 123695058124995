import { Show, SimpleShowLayout, TextField, BooleanField } from "react-admin";

export const MemberShow = (props) => {
	return (
		<Show {...props} title="Member">
			<SimpleShowLayout>
				<TextField source="firstName" />
				<TextField source="gender" />
				<TextField source="timeZone" />
				<TextField source="verified" />
				<TextField source="email" />
				<TextField source="phoneNumber" />
				<TextField source="method" />
				<TextField source="subscriptionType" />
				<TextField source="revokeDate" />
				<TextField source="validUntil" />
				<BooleanField source="isDeleted" />
			</SimpleShowLayout>
		</Show>
	);
};
