import {
	Datagrid,
	EditButton,
	Filter,
	List,
	ShowButton,
	TextField,
	TextInput,
	BooleanField, // Import BooleanField component
} from "react-admin";
import { constants } from "../../utils/constants";
import React, { useMemo } from "react";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { CustomDeleteButtonWithCustomConfirm } from "../../customComponents/CustomButtons";

const BadgeFilter = (props) => (
	<Filter {...props}>
		<TextInput source="search" alwaysOn />
	</Filter>
);

export const TimelineList = (props) => {
	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);

	return (
		<List
			{...props}
			perPage={constants.perPage}
			bulkActionButtons={false}
			filters={<BadgeFilter />}
		>
			<Datagrid>
				<TextField source={`name.${defaultLocale}`} sortable={false} />
				<BooleanField
					source="isChallenge"
					label="Challenge"
					sortable={false}
					looseValue={true}
				/>

				<EditButton />
				<ShowButton />
				<CustomDeleteButtonWithCustomConfirm mutationMode="pessimistic" />
			</Datagrid>
		</List>
	);
};

export default TimelineList;
