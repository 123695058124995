import axios from "axios";
const {
	envSettings: { baseUrl },
} = require("../settings/env");

export const getCustomEndpoint = async (endpoint) =>
	await axios({
		url: baseUrl + endpoint,
		method: "get",
		withCredentials: true,
	});
