import { useEffect, useState, useMemo } from "react";
import {
	Edit,
	FormTab,
	useNotify,
	TextInput,
	ArrayInput,
	useGetList,
	TabbedForm,
	useRedirect,
	useTranslate,
	TranslatableInputs,
	SimpleFormIterator,
	NumberInput,
	useUpdate,
	useGetOne,
} from "react-admin";
import { CustomDialog } from "../../customComponents/CustomDialog";
import { userPreferences } from "../../utils/fakeUserPrefrences";
import { CustomToolbar } from "../../customComponents/CustomToolbar";
import { CustomButton } from "../../customComponents/CustomButtons";
import { FormControlLabel, FormGroup, Switch } from "@material-ui/core";
import { validateSurveyData } from "./surveyValidate";
import classnames from "classnames";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

export const CustomBooleanInput = ({
	label,
	color,
	disabled,
	inputRef,
	setIsActive,
	isActive,
	name,
}) => {
	const [open, setOpen] = useState(false);

	const handleClickOpen = (event) => {
		let value = !(event.target.value === "true");
		if (value) setOpen(true);
		else setIsActive(false);
	};

	return (
		<>
			<FormGroup row>
				<FormControlLabel
					control={
						<Switch
							checked={isActive}
							onChange={handleClickOpen}
							name={name}
							color={color || "primary"}
							disabled={disabled}
							value={isActive}
							inputRef={inputRef}
						/>
					}
					label={label}
				/>
			</FormGroup>
			<CustomDialog
				open={open}
				title={"WARNING!"}
				content={
					"Are you sure you want to mark this as the active mood tracker?"
				}
			>
				<CustomButton
					onClick={() => {
						setIsActive(false);
						setOpen(false);
					}}
					innerStyle={{
						display: "flex",
						alignItems: "unset",
						justifyContent: "center",
					}}
					innerText="No"
					icon={<CancelIcon />}
					className={classnames("ra-confirm")}
					color="primary"
					autoFocus
				/>
				<CustomButton
					onClick={() => {
						setIsActive(true);
						setOpen(false);
					}}
					innerStyle={{
						display: "flex",
						alignItems: "unset",
						justifyContent: "center",
					}}
					innerText="Yes"
					icon={<CheckCircleIcon />}
					className={classnames("ra-confirm")}
					color="primary"
					autoFocus
				/>
			</CustomDialog>
		</>
	);
};

const CustomTranslatableInputs = ({ locales, source, label }) => {
	return (
		<TranslatableInputs locales={locales} defaultLocale="ar(Male)">
			<TextInput source={source} label={label} />
		</TranslatableInputs>
	);
};

export const SurveyEdit = (props) => {
	const { data = {}, loading } = useGetOne("surveys", props.id);
	const [languagesArray, setLanguagesArray] = useState([]);
	const [isActive, setIsActive] = useState(false);
	const [customLanguagesArray, setCustomLanguagesArray] = useState([]);
	const [update] = useUpdate("surveys", props.id);

	const notify = useNotify();
	const redirect = useRedirect();
	const translate = useTranslate();

	const { data: languagesData } = useGetList("languages");

	useEffect(() => {
		setCustomLanguagesArray(() => {
			let languages = [];
			Object.values(languagesData).map((language) =>
				language.name === "AR"
					? languages.push("ar(Male)", "ar(Female)")
					: languages.push(language.name.toLowerCase())
			);
			return languages;
		});
		setLanguagesArray(
			Object.values(languagesData).map((language) =>
				language.name.slice(0, 2).toLowerCase()
			)
		);
	}, [languagesData]);

	const defaultLocale = useMemo(
		() => userPreferences.defaultTranslationLanguage,
		[]
	);
	useEffect(() => {
		setIsActive(data.isActive);
	}, [loading]);

	const onSuccess = () => {
		notify(translate("surveys.edit.success"), "info");
		redirect("/surveys");
	};

	const onFailure = () => {
		notify(translate("surveys.edit.fail"), "warning");
	};

	return (
		<>
			{loading ? (
				<center>
					<h1>Loading...</h1>
				</center>
			) : (
				<Edit {...props} title={translate("surveys.edit.title")}>
					<TabbedForm
						toolbar={<CustomToolbar />}
						submitOnEnter={false}
						validate={validateSurveyData}
						syncWithLocation={false}
						save={(data) => {
							data.isActive = isActive;
							data.questions = data.allQuestions;
							update(
								{ payload: { data } },
								{
									onSuccess,
									onFailure,
								}
							);
						}}
					>
						<FormTab label={translate("surveys.tabs.basicInfo")}>
							<TranslatableInputs
								locales={languagesArray}
								defaultLocale={defaultLocale}
							>
								<TextInput source="name" />
								<TextInput source="title" />
							</TranslatableInputs>
						</FormTab>
						<FormTab label={translate("surveys.tabs.questions")}>
							<ArrayInput source="allQuestions">
								<SimpleFormIterator>
									<CustomTranslatableInputs
										source="title"
										locales={customLanguagesArray}
										label="Question"
									/>
									<ArrayInput source="answers">
										<SimpleFormIterator>
											<CustomTranslatableInputs
												source="title"
												locales={customLanguagesArray}
												label="Answer"
											/>
											<NumberInput source="weight" />
										</SimpleFormIterator>
									</ArrayInput>
								</SimpleFormIterator>
							</ArrayInput>
						</FormTab>
					</TabbedForm>
				</Edit>
			)}
		</>
	);
};
